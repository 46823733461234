import React, { useEffect, useState, useContext } from 'react'
import ReactToPrint from 'react-to-print';
import Header from '../../Components/Common/Header'
import mainLogo from '../../assets/img/Logo-v1-inverted.svg'
import Footer from '../../Components/Common/Footer'
import { Field, Form, Formik } from 'formik'
import EditModal from '../../Components/Print/EditModal'
import EmailPrintOutModal from '../../Components/Print/EmailPrintOutModal';
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext'
//import generatePDF  from "react-to-pdf";
import generatePDF from 'react-to-pdf';
import SelectField from '../../Components/Form/SelectField';
import { intFormatter,floatFormatter } from '../../Components/Utils/NumberFormatter';

const PrintoutBuilder = () => {
    const {user} = useContext(UserContext); 
    const { getProperty, sendPDF } = useContext(PropertyContext);

    const [propertyData, setPropertyData] = useState(null);

    const queryParameters = new URLSearchParams(window.location.search)
    const place_id = queryParameters.get("place_id");

    //download PDF
    // const options = {
    //     orientation: 'landscape',
    //     unit: 'in',
    //     format: [4,2]
    // };
    // Value

    // Email pintOut
    const [emailPrintout, setEmailPrintout] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const componentRef = React.useRef(null);
    const onBeforeGetContentResolve = React.useRef(null);

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");
  
    const handleAfterPrint = React.useCallback(() => {
      console.log("`onAfterPrint` called");
    }, []);
  
    const handleBeforePrint = React.useCallback(() => {
      console.log("`onBeforePrint` called");
    }, []);
  
    const handleOnBeforeGetContent = React.useCallback(() => {
      console.log("`onBeforeGetContent` called");
      setLoading(true);
      setText("Loading new text...");
  
      return new Promise((resolve) => {
        onBeforeGetContentResolve.current = resolve;
  
        setTimeout(() => {
          setLoading(false);
          setText("New, Updated Text!");
          resolve();
        }, 2000);
      });
    }, [setLoading, setText]);

    useEffect(() => {
        if (
          text === "New, Updated Text!" &&
          typeof onBeforeGetContentResolve.current === "function"
        ) {
          onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current, text]);

    const [primaryImage, setPrimaryImage] = useState();
    const [mapImage, setMapImage] = useState();

    useEffect(() => {       

        const fetchData = async () => {
            const propData = await getProperty(place_id,user.username);

            if(Object.keys(propData).length > 0) {

                setPropertyData(propData);

                console.log(propData.images.length);

                if (!propData.primary_image || propData.primary_image.length === 0) return;
                try {
                    const response = await fetch(propData.primary_image);

                    if (!response.ok) {
                        throw new Error(
                            `Image fetch failed with status: ${response.status}`,
                        );
                    }

                    const blob = await response.blob();
                    const blogToLocalURL = URL.createObjectURL(blob);

                    setPrimaryImage(blogToLocalURL);
                } catch (error) {
                    console.error('Error fetching pdf image:', error);
                }

                if (!propData.static_map_url || propData.static_map_url.length === 0) return;
                try {
                    const response = await fetch(propData.static_map_url);

                    if (!response.ok) {
                        throw new Error(
                            `Image fetch failed with status: ${response.status}`,
                        );
                    }

                    const blob = await response.blob();
                    const blogToLocalURL = URL.createObjectURL(blob);

                    setMapImage(blogToLocalURL);
                } catch (error) {
                    console.error('Error fetching pdf image:', error);
                }
                
            }
        }        
        
       if(place_id && place_id != "") fetchData();        

    }, [place_id]); 

    const [showModal, setShowModal] = useState(false);

    const [editFields, setEditFields] = useState([]);
    const [editTitle, setEditTitle] = useState("");

    const openEditModal = (title,fields) => {
        setEditTitle(title);
        setEditFields(fields);
        setShowModal(true);
    }

    const updatePropertyData = (values) => {
        let propData = {...propertyData, ...values};

        setPropertyData(propData);
    }

    const [sectionVisiblity, setSectionVisiblity] = useState({
        address:true,
        property_name:true,
        description:true,
        type:true,
        acreage:true,
        price_per_acre:true,
        list_price:true,
        directions:true,
        seller_info:true,
        image:true,
        map:true
    })

    const showSection = (e) => {
        console.log(e.target.name);
        console.log(e.target.checked);

        let secData = {...sectionVisiblity};
        secData[e.target.name] = e.target.checked;

        setSectionVisiblity(secData);
    }

    const handleImageChange = async (e) => {
        console.log(e.target.value);

        try {
            const response = await fetch(propertyData.images[e.target.value]);

            if (!response.ok) {
                throw new Error(
                    `Image fetch failed with status: ${response.status}`,
                );
            }

            const blob = await response.blob();
            const blogToLocalURL = URL.createObjectURL(blob);

            setPrimaryImage(blogToLocalURL);
        } catch (error) {
            console.error('Error fetching pdf image:', error);
        }
    }

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
      }, [componentRef.current]);
    const reactToPrintTrigger = React.useCallback(() => {
        return <button type='button' className='theme-trns-btn ms-4 w-150 position-relative text-center text-uppercase'>
                <span className="print-icon"></span> Print
            </button>;
    }, []);

    const completeTest = (e) => {
        console.log(e);
    }

    const emailPdfFile = async (values) => {

        setIsSending(true);

        let pdf = await generatePDF(componentRef, options); 
        
        //console.log(pdf.output("datauristring"));

        values.file = pdf.output("datauristring");

        let response = await sendPDF(values);

        console.log(response);

        if(response.success) {
            setEmailPrintout(false);
            setIsSending(false);
            alert("email sent");
        }

    }

    const options = {
        // default is `save`
        method: 'build',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.

        // customize any value passed to the jsPDF instance and html2canvas
        // function
        overrides: {
           // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
           pdf: {
              compress: true
           },
           // see https://html2canvas.hertzen.com/configuration for more options
           canvas: {
              useCORS: false
           }
        },
        filename:'landdetailPdf.pdf'
     };

    const pageStyle = `
        @media print {
            html, body {
              height: 100vh; /* Use 100% here to support printing more than a single page*/
              margin: 0 !important;
              padding: 0 !important;
              overflow: hidden;
            }            
            .printable-area img {
                max-height:300px;
            }
          }
        `;

    return (
        <>
            <Header />
            <section className='printout-wrap mb-5'>
                <div className='container'>
                    <div className='printout-top-flex d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <button type='button' className='theme-btn w-150 position-relative text-center text-uppercase me-4'>
                                <span className="back-icon"></span> Back
                            </button>
                            <div className='sm-title text-white font-24 text-uppercase'>Printout Builder</div>
                        </div>
                        <div>
                            <button type='button' className='theme-trns-btn w-150 position-relative text-center text-uppercase' onClick={() => {setEmailPrintout(true);}}>
                                <span className="email-icon"></span> Email
                            </button>
                            <button type='button' className='theme-trns-btn ms-4 w-150 position-relative text-center text-uppercase' onClick={() => generatePDF(componentRef, {filename: 'page.pdf'})}>
                                <span className="download-icon"></span> Download
                            </button>
                            {/*<Pdf targetRef={componentRef} filename="landdetailPdf.pdf" options={{method:'build'}} scale={0.85} onComplete={(e) => {console.log("done",e);}}>
                                {({toPdf}) => (
                                    <button type='button' className='theme-trns-btn ms-4 w-150 position-relative text-center text-uppercase' onClick={toPdf}>
                                        <span className="download-icon"></span> Download
                                    </button>
                                )}
                            </Pdf>*/}
                            {/* <button type='button' className='theme-trns-btn ms-4 w-150 position-relative text-center text-uppercase' onClick={Print}>
                                <span className="print-icon"></span> Print
                            </button>  */}
                            {/* <ReactToPrint
                                trigger={() => {
                                    return <button type='button' className='theme-trns-btn ms-4 w-150 position-relative text-center text-uppercase'>
                                    <span className="print-icon"></span> Print
                                </button> ;
                                }}
                                content={() => componentRef}
                            /> */}
                            <ReactToPrint
                                content={reactToPrintContent}
                                documentTitle="landdetail"
                                onAfterPrint={handleAfterPrint}
                                onBeforeGetContent={handleOnBeforeGetContent}
                                onBeforePrint={handleBeforePrint}
                                removeAfterPrint
                                trigger={reactToPrintTrigger}
                                pageStyle={pageStyle}
                            />
                        </div>
                    </div>
                    <div className='printout-block d-flex'>
                        <div className='printout-left'>
                        <Formik
                            // validationSchema={validationSchema}
                            initialValues={{
                                acreage: (propertyData && propertyData.acreage) || '',
                                price_per_acre: (propertyData && propertyData.price_per_acre) || '',
                                list_price: (propertyData && propertyData.list_price) || '',
                            }}
                            enableReinitialize={true}
                            onSubmit={(values) => {
                                console.log(values)
                                updatePropertyData(values)
                            }}
                        >
                            {({
                                values
                            }) => (
                                <Form className='custom-form'>
                                    <div className='printout-left-header'>Configuration</div>
                                    <ul className='printout-actions'>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="AddressChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.address} name="address"/>
                                                <label htmlFor="AddressChk"><span>Address</span></label>
                                            </div>
                                            <div>
                                                <button className="trns-edit-btn text-uppercase" type='button' onClick={() => {() => {openEditModal('Address',['address'])}}}>
                                                    <span className="edit-icon"></span>&nbsp;Edit
                                                </button>
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="PropertyTitleChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.property_name} name="property_name"/>
                                                <label htmlFor="PropertyTitleChk"><span>Property Title</span></label>
                                            </div>
                                            <div>
                                                <button className="trns-edit-btn text-uppercase" type='button' onClick={() => {openEditModal('Property Title',['property_name'])}}>
                                                    <span className="edit-icon"></span>&nbsp;Edit
                                                </button>
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="DescriptionChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.description} name="description"/>
                                                <label htmlFor="DescriptionChk"><span>Description</span></label>
                                            </div>
                                            <div>
                                                <button className="trns-edit-btn text-uppercase" type='button' onClick={() => {openEditModal('Description',['description'])}}>
                                                    <span className="edit-icon"></span>&nbsp;Edit
                                                </button>
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="TypeChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.type} name="type"/>
                                                <label htmlFor="TypeChk"><span>Type</span></label>
                                            </div>
                                            <div>
                                                <button className="trns-edit-btn text-uppercase" type='button' onClick={() => {openEditModal('Property Type',['type'])}}>
                                                    <span className="edit-icon"></span>&nbsp;Edit
                                                </button>
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="AcresChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.acreage} name="acreage"/>
                                                <label htmlFor="AcresChk"><span>Acres</span></label>
                                            </div>
                                            <div>
                                                <Field
                                                    name="acreage"
                                                    type='text'
                                                    className="form-control w-92"
                                                    placeholder="Acres"
                                                    // value={updatedValue.acres}
                                                />
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="PricePerAcreChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.price_per_acre} name="price_per_acre"/>
                                                <label htmlFor="PricePerAcreChk"><span>Price per Acre</span></label>
                                            </div>
                                            <div>
                                                <Field
                                                    name="price_per_acre"
                                                    type='text'
                                                    className="form-control w-92"
                                                    placeholder="Price per Acre"
                                                    // value={updatedValue.priceperacre}
                                                />
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="PriceChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.list_price} name="list_price"/>
                                                <label htmlFor="PriceChk"><span>Price</span></label>
                                            </div>
                                            <div>
                                                <Field
                                                    name="list_price"
                                                    type='text'
                                                    className="form-control w-92"
                                                    placeholder="Price"
                                                    // value={updatedValue.price}
                                                />
                                            </div>
                                        </li>
                                        {/*<li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="DirectionsChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.directions} name="directions"/>
                                                <label htmlFor="DirectionsChk"><span>Directions</span></label>
                                            </div>
                                            <div>
                                                <button className="trns-edit-btn text-uppercase" type='button' onClick={() => openEditModal('Directions',['directions'])}>
                                                    <span className="edit-icon"></span>&nbsp;Edit
                                                </button>
                                            </div>
                                        </li>*/}
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="SellerInfoChk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.seller_info} name="seller_info"/>
                                                <label htmlFor="SellerInfoChk"><span>Seller Info</span></label>
                                            </div>
                                            <div>
                                                <button className="trns-edit-btn text-uppercase" type='button' onClick={() => openEditModal('Seller Info',['broker_name','broker_company_name','broker_phone_number','broker_email'])}>
                                                    <span className="edit-icon"></span>&nbsp;Edit
                                                </button>
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="imagechk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.image} name="image"/>
                                                <label htmlFor="imagechk"><span>Image</span></label>
                                            </div>
                                            <div>
                                            {propertyData && propertyData.images.length > 0 && <SelectField
                                                name="images"
                                                label="Images"
                                                data={propertyData.images.map((v,i) => {return {key:i,value:i,display:"Image " + (i+1)}})}
                                                onChange={handleImageChange}
                                            />}
                                            </div>
                                        </li>
                                        <li className='d-flex align-items-center justify-content-between'>
                                            <div className="custom-checkbox sm-checkbox">
                                                <input className="styled-checkbox" id="mapchk" type="checkbox" onChange={(e) => showSection(e)} defaultChecked={sectionVisiblity.map} name="map"/>
                                                <label htmlFor="mapchk"><span>Map</span></label>
                                            </div>
                                            <div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className='mt-5 mb-5 text-center ps-3 pe-3'>
                                        <button type="submit" className='theme-btn w-100 md-btn font-14'>APPLY CHANGES</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        </div>
                        <div className='printout-right'>
                            {loading && <div className="loader-block"><div className='loader-pos'>Loading...</div></div>}
                            <div className='printable-area' ref={componentRef} text={text} id="test-print-area">
                                <div>
                                    <img src={mainLogo} alt='logo' />
                                </div>
                                <div className='mt-3 d-flex printable-flex'>
                                    <div className='printable-left'>
                                        {sectionVisiblity.address && <div>
                                            <div className='xs-title text-uppercase text-start lgray-text'>Address</div>
                                            <div className="xs-title fw-500 mt-1 text-start font-12 mt-1 gray-text">
                                                <span className="location-icon"></span> {propertyData && propertyData.address}
                                            </div>
                                        </div>}
                                        <div className='pt-3 mt-3 printable-border-top'>
                                            {sectionVisiblity.acreage && <div>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className="xs-title fw-500 text-start font-12 gray-text">Acres:</div>
                                                    <div className="xs-title fw-900 text-end font-12 lgray-text">{propertyData && floatFormatter(propertyData.acreage)}</div>
                                                </div>
                                            </div>}
                                            {sectionVisiblity.price_per_acre && <div>
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <div className="xs-title fw-500 text-start font-12 gray-text">Price per Acre:</div>
                                                    <div className="xs-title fw-900 text-end font-12 lgray-text">{propertyData && floatFormatter(propertyData.price_per_acre,true)}</div>
                                                </div>
                                            </div>}
                                            {sectionVisiblity.list_price && <div>
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <div className="xs-title fw-500 text-start font-12 gray-text">Price:</div>
                                                    <div className="xs-title fw-900 text-end font-12 lgray-text">{propertyData && floatFormatter(propertyData.list_price,true)}</div>
                                                </div>
                                            </div>}
                                        </div>
                                        {sectionVisiblity.description && <div className='pt-3 mt-3 printable-border-top'>
                                            <div className='xs-title text-uppercase text-start lgray-text'>Description</div>
                                            <div className="xs-title fw-500 text-start font-12 gray-text mt-1">{propertyData && propertyData.description}</div>
                                        </div>}
                                        {sectionVisiblity.type && <div className='pt-4'>
                                            <div className='xs-title text-uppercase text-start lgray-text'>Type</div>
                                            <div className="xs-title fw-500 text-start font-12 gray-text">{propertyData && propertyData.type}</div>
                                        </div>}
                                        {sectionVisiblity.seller_info && <div className='printable-left-bottom mt-4'>
                                            <div className='xs-title text-uppercase text-start lgray-text'>Seller Info</div>
                                            <div className='pt-2'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className="xs-title fw-500 text-start font-12 gray-text">Name</div>
                                                    <div className="xs-title fw-900 text-end font-12 lgray-text">{propertyData && propertyData.broker_name}</div>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <div className="xs-title fw-500 text-start font-12 gray-text">Company</div>
                                                    <div className="xs-title fw-900 text-end font-12 lgray-text">{propertyData && propertyData.broker_company_name}</div>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <div className="xs-title fw-500 text-start font-12 gray-text">Phone</div>
                                                    <div className="xs-title fw-900 text-end font-12 lgray-text">{propertyData && propertyData.broker_phone_number}</div>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-between mt-2'>
                                                    <div className="xs-title fw-500 text-start font-12 gray-text">Email</div>
                                                    <div className="xs-title fw-900 text-end font-12 lgray-text">{propertyData && propertyData.broker_email}</div>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className='printable-right'>
                                        <div className='printable-img-box d-inline-block'>
                                            <div className='pib-top-box'>
                                                <div className='xs-title text-uppercase text-start text-white'>{propertyData && propertyData.property_name}</div>
                                                <div className='xs-title font-12 text-start fw-300 text-white'>{propertyData && propertyData.city}</div>
                                            </div>
                                            <div className='pib-img'>
                                                {sectionVisiblity.image && primaryImage !== undefined && <img src={primaryImage} alt='img' />}
                                            </div>
                                        </div>
                                        {propertyData && propertyData.amenities.length > 0 && <div className='mt-4'>
                                            <div className='xs-title text-uppercase text-start lgray-text'>Amenities</div>
                                            <ul className='amenities-list'>
                                            {propertyData && propertyData.amenities.map((v,i) => {
                                                return <li key={i}><div>{v}</div></li>
                                            })}
                                            </ul>
                                        </div>}
                                        {sectionVisiblity.map && <div className='mt-5'>
                                            <div className='xs-title text-uppercase text-start lgray-text mb-1'>Map</div>
                                            <div className='pib-img'>
                                                {mapImage !== undefined && <img src={mapImage} alt='img' />}
                                            </div>
                                        </div>}
                                        {/* {sectionVisiblity.directions && <div className='mt-4'>
                                            <div className='xs-title text-uppercase text-start lgray-text'>Eliminate Directions</div>
                                            <div className="xs-title fw-500 text-start font-12 gray-text">{propertyData && propertyData.directions}</div>
                                        </div>} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            {propertyData && <EditModal 
                showModal={showModal} 
                setShowModal={setShowModal}
                propertyData={propertyData}
                updatePropertyData={updatePropertyData}
                editFields={editFields}
                editTitle={editTitle}
            />}
            {/*<EditAddressModal 
                editAddress={editAddress} 
                setEditAddress={setEditAddress}
                updatedAddress={updatedAddress}
                setUpdatedAddress={setUpdatedAddress}
            />
            <EditPerportyTitleModal 
                perportyTitle={perportyTitle}  
                setPerportyTitle={setPerportyTitle}
                updatedPerportyTitle={updatedPerportyTitle}
                setUpdatedPerportyTitle={setUpdatedPerportyTitle}
            />
            <EditDescriptionModal 
                editDescription={editDescription} 
                setEditDescription={setEditDescription}
                updatedDescription={updatedDescription}
                setUpdatedDescription={setUpdatedDescription}
            />
            <EditTypeModal 
                editType={editType} 
                setEditType={setEditType}
                updatedType={updatedType}
                setUpdatedType={setUpdatedType}
            />
            <EditDirectionsModal 
                editDirections={editDirections} 
                setEditDirections={setEditDirections}
                updatedDirections={updatedDirections}
                setUpdatedDirections={setUpdatedDirections}
            />
            <EditSellerInfoModal 
                editSellerInfo={editSellerInfo} 
                setEditSellerInfo={setEditSellerInfo} 
                updatedSellerInfo={updatedSellerInfo}
                setUpdatedSellerInfo={setUpdatedSellerInfo}
            />*/}
            {propertyData && <EmailPrintOutModal
                emailPrintout={emailPrintout}
                setEmailPrintout={setEmailPrintout}
                propertyData={propertyData}
                emailPdfFile={emailPdfFile}
            />}
        </>
    )
}

export default PrintoutBuilder