

import starFilled from '../../assets/img/star-filled.svg'
import starEmpty from '../../assets/img/star-empty.svg'
import { useContext, useState } from 'react';
import { Field, Formik, Form } from 'formik'
import TextAreaField from "../../Components/Form/TextAreaField";
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext';

const ContactSeller = ({propertyData, openModal, clickOpenReview}) => {
    const {user} = useContext(UserContext); 
    const { sendEmail } = useContext(PropertyContext);
    


    return (
        <>
            <div className="gray-box contact-seller-box">
                <Formik
                    initialValues={{
                        place_id:propertyData.place_id,
                        message:'',
                        user:user.username,
                        template_email:'contactseller',
                        recipients:propertyData.broker_email
                    }} 
                    validationSchema=""
                    onSubmit={(values) => {
                        if(user.username != "publicuser") {
                            console.log(values);
                            sendEmail(values);
                            alert("Message sent");
                        } else
                            openModal("register");
                    }}
                >
                    {({ values }) => (
                        <Form>                             
                            <div className="box-title text-uppercase">Contact Seller</div>
                            <div className="xs-title fw-300 font-17 text-start text-white mt-3">{propertyData.broker_name}</div>
                            <div className="xs-title fw-300 font-17 text-start text-white mt-2 mb-2">{propertyData.broker_company_name}</div>
                            <div className="xs-title fw-300 font-14 text-start text-white mt-2 mb-2">{propertyData.broker_phone_number}</div>
                            <div className="xs-title fw-300 font-14 text-start text-white mt-2 mb-2">{propertyData.broker_email}</div>
                            <TextAreaField className="form-control" placeholder="Ask the Seller a question" name="message"/>
                            <div className="mt-3">
                                <button className="w-100 theme-btn text-uppercase" type="submit"> {user.username == "publicuser" ? 'Log In' : 'Contact Seller'}</button>
                            </div>
                    </Form>
                )}</Formik>
                <div className="seller-review mt-5 border-t pt-4">
                    <div className="xs-title fw-500 font-14 text-start mt-3 text-white">Seller Reviews</div>
                    <div className="mt-3 d-flex align-items-center">
                        <div className="rating-stars">
                            <img src={propertyData.review_avg > 0 ? starFilled : starEmpty} alt="star" />
                            <img src={propertyData.review_avg > 1 ? starFilled : starEmpty} alt="star" />
                            <img src={propertyData.review_avg > 2 ? starFilled : starEmpty} alt="star" />
                            <img src={propertyData.review_avg > 3 ? starFilled : starEmpty} alt="star" />
                            <img src={propertyData.review_avg > 4 ? starFilled : starEmpty} alt="star" />
                        </div>
                        <div className="xs-title fw-500 font-14 text-start ps-3 text-white"><span className="theme-text">({propertyData.review_count})</span> <span onClick={clickOpenReview} className='review-pointer'>Reviews</span></div>
                    </div>
                    <div className="mt-3">
                        <table className="perporty-info-table w-100">
                            <tbody>
                                <tr>
                                    <th>Properties Sold:</th>
                                    <td>1</td>
                                </tr>
                                <tr>
                                    <th>Properties Listed:</th>
                                    <td>3</td>
                                </tr>
                                <tr>
                                    <th>Sold Ratio:</th>
                                    <td>33%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-3 text-center">
                        <button className="theme-trns-btn text-uppercase w-100" type='button' onClick={() => {openModal('review');}}>Write a Review</button>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default ContactSeller