import closeIcon from '../../../assets/img/close-modal.svg'

const SaveModal = ({saveModal, setSaveModal, btnSaved}) => {

    const closeSaveModal = () => {
        setSaveModal(false);
    }
  return (
        <>
            <div className={`modal fade custom-modal common-modal save-modal ${saveModal ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-450 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile text-uppercase">Property Saved</div>
                            <button type="button" className="btn-close" onClick={closeSaveModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={closeSaveModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-26 text-uppercase">Property Saved</div>
                            {btnSaved ? 
                                <div className='xs-title text-start text-orange2'>You just saved:</div> :
                                <div className='xs-title text-start text-orange2'>You have unsaved:</div> 
                            }
                            <div className='sm-title text-uppercase text-start font-22 mt-2'>Hot Springs Summit Ranch</div>
                            <div className='xss-title'>Boise, Idaho</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default SaveModal