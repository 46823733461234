import React from 'react'

const Amenities = ({propertyData}) => {
  return (
    <>
        <div className="gray-box pe-2 amenities-box">
            <div className="box-title text-uppercase">Amenities</div>
            <div className="mt-4 d-flex justify-content-between pe-4">
                <div>
                    <div className="xs-title fw-500 text-start theme-text">Activities</div>
                </div>
                <div>
                    <div className="xs-title fw-500 text-start theme-text">Lot Description</div>
                </div>
            </div>
            <div className="activity-scroll mt-2">
                <div className="d-flex justify-content-between">
                    <div>
                        <ul className="list-item">
                            {propertyData.amenities && propertyData.amenities.map((v,i) => { return (<li key={i}>{v} </li>)})}
                        </ul>
                    </div>
                    <div>
                        <ul className="list-item">
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Amenities