import { useState, useContext } from 'react';
import { Link } from 'react-router-dom'
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext';
import { useNavigate } from 'react-router-dom';

const AddPropertyReview = ({propertyData}) => {
    const {user} = useContext(UserContext); 
    const { setPropertyLayout } = useContext(PropertyContext);

    const navigate = useNavigate();

    const [layout,setLayout] = useState(propertyData.layout);

    const submitForm = async (e) => {
        console.log("was submitted");
        e.preventDefault();
        
        const formData = Object.fromEntries(new FormData(e.target));
        console.log(formData);

        if(!Object.values(formData).every(val => val.trim() !== '')){
            console.log('Please Fill in all Required Fields!');
            return;
        }

        const data = await setPropertyLayout(formData);
        if(data.success){
            navigate('/properties/share-your-property?place_id=' + propertyData.place_id);
            return;
        } else 
            console.log(data.error);
    }

    return (
        <>
            <div className='preview-gray-box'>
                <div className='container'>
                    <div className='gray-box ps-5 pe-5 pt-4 pb-4'>
                        <div className="xs-title mb-4 text-uppercase text-start text-white">Select Layout Style</div>
                        <form className='d-flex align-items-center justify-content-between custom-form' onSubmit={submitForm}>
                            <input type="hidden" name="place_id" value={propertyData.place_id} readOnly/>
                            <input type="hidden" name="user" value={user.username} readOnly/>
                            <div className='w-50'>
                                <select className='form-control w-100' name="layout" value={layout} onChange={(e) => setLayout(e.target.value)}>
                                    <option value="mapbanner">Map as Top Banner</option>
                                    <option value="photobanner">Photo as Top Banner</option>
                                </select>
                            </div>
                            <div>
                                <Link to={"/properties/add-my-property?place_id=" + propertyData.place_id + "&edit_listing"} type='button' className='theme-trans-btn text-uppercase mnw-180'>Go Back</Link>                            
                                <button type='submit' className='theme-btn text-uppercase d-inline-block mnw-180 ms-3'>Proceed</button>                            
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPropertyReview