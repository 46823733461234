import React from 'react'
import closeIcon from '../../assets/img/close-modal.svg'

const ReadMoreItemTextModal = ({readMoreModal, closeReadMoreModal, readMoreText}) => {
  return (
    <>
        <div className={`modal fade custom-modal common-modal write-review-modal read-more-modal ${readMoreModal ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-550 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile text-uppercase">Description</div>
                            <button type="button" className="btn-close" onClick={closeReadMoreModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body ps-5 pe-5 pt-4">
                            <button type="button" className="btn-close m-d-none" onClick={closeReadMoreModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-26 text-uppercase d-none d-md-block">Description</div>
                            <div><p>{readMoreText}</p></div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ReadMoreItemTextModal