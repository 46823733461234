import closeIcon from '../../../assets/img/close-modal.svg'
import starFill from '../../../assets/img/star-fill.svg'
import starBlank from '../../../assets/img/star-blank.svg'

const ViewReviewModal = ({setOpenViewReview, openViewReview}) => {
    const closeViewReview = () => {
        setOpenViewReview(false);
    }
  return (
    <>
        
        <div className={`modal fade custom-modal common-modal write-review-modal ${openViewReview ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-680 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile">Reviews</div>
                            <button type="button" className="btn-close" onClick={closeViewReview}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body px-4 pb-4">
                            <button type="button" className="btn-close m-d-none" onClick={closeViewReview}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading text-center font-26 text-uppercase d-none d-md-block">Reviews</div>
                            <div className='review-box mt-4'>
                                <div className='d-flex justify-content-between mb-3'>
                                    <div>
                                        <div className='box-title font-16 fw-600 mb-2'>Michael Jordan</div>
                                        <div class="rating-stars">
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starBlank} alt="star"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='box-title font-16 fw-600 opacity-0-5'>Yesterday</div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                            </div>
                            <div className='review-box mt-4'>
                                <div className='d-flex justify-content-between mb-3'>
                                    <div>
                                        <div className='box-title font-16 fw-600 mb-2'>Jackie Smith</div>
                                        <div class="rating-stars">
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starBlank} alt="star"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='box-title font-16 fw-600 opacity-0-5'>2 weeks</div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                            </div>
                            <div className='review-box mt-4'>
                                <div className='d-flex justify-content-between mb-3'>
                                    <div>
                                        <div className='box-title font-16 fw-600 mb-2'>Peter Glenn</div>
                                        <div class="rating-stars">
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starBlank} alt="star"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='box-title font-16 fw-600 opacity-0-5'>4 months</div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <p>Lorem ipsum dolor sit amet adipiscing elit, sed do eiusmod tempor incididunt .</p>
                                </div>
                            </div>
                            <div className='review-box mt-4'>
                                <div className='d-flex justify-content-between mb-3'>
                                    <div>
                                        <div className='box-title font-16 fw-600 mb-2'>Jackie Smith</div>
                                        <div class="rating-stars">
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starBlank} alt="star"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='box-title font-16 fw-600 opacity-0-5'>2 weeks</div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                </div>
                            </div>
                            <div className='review-box mt-4'>
                                <div className='d-flex justify-content-between mb-3'>
                                    <div>
                                        <div className='box-title font-16 fw-600 mb-2'>Peter Glenn</div>
                                        <div class="rating-stars">
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starFill} alt="star"/>
                                            <img style={{maxWidth: "20px"}} src={starBlank} alt="star"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='box-title font-16 fw-600 opacity-0-5'>4 months</div>
                                    </div>
                                </div>
                                <div className='content'>
                                    <p>Lorem ipsum dolor sit amet adipiscing elit, sed do eiusmod tempor incididunt .</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ViewReviewModal