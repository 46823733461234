import axios from 'axios'
import {createContext, useContext, useEffect, useState} from 'react';
import {EnvContext} from './EnvContext';
import MainLoaderModal from '../Components/Common/MainLoaderModal.js';
import WelcomeModal from '../Components/Common/WelcomeModal.js';

export const PropertyContext = createContext();

export const PropertyContextProvider = ({children}) => {

    const { landdealBaseURL } = useContext(EnvContext);

    const [openLoadingModal, setOpenLoadingModal] = useState(false);

    const Axios = axios.create({
        baseURL: landdealBaseURL + "bridge/"
    });

    const email_axios_error = (errorInfo) => {
            
        var email_values = {
            "subject" : "Land Deal error property database",
            "message" : JSON.stringify(errorInfo)
        };	

        Axios.get('email/email_error.php',{
            retry: 0, retryDelay: 3000, params: email_values
        });
    }

    const getProperty = async (place_id,user) => {

        var values = {
            'place_id' : place_id,
            'user' : user
        };

        setOpenLoadingModal(true);

        const {data} = await Axios.post('property/get_property.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        setOpenLoadingModal(false);

        console.log(data);

        return data.values ? data.values : {};
    }

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const saveLandIDScreenshot = async (place_id) => {
        var data = await screenshotHelper(place_id);
        var attempts_left = 60;

        //console.log(data.image,data.image == "https://dev.landdeal.com/images/gears-gold.svg");

        while(data.loading && attempts_left > 0) {
            console.log("new attempt " + attempts_left + " left");
            data = await screenshotHelper(place_id);
            await sleep(2000);

            attempts_left--;
        }

        await sleep(1000);

        return true;
        
    }

    const screenshotHelper = async (place_id) => {
        var values = {
            'place_id' : place_id
        };

        const {data} = await Axios.post('property/save_landid_screenshot.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const getProperties = async (values) => {

        console.log(values);

        setOpenLoadingModal(true);

        const {data} = await Axios.post('property/get_properties.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        setOpenLoadingModal(false);

        return data.values ? data.values : {};
    }

    const setPropertyLayout = async (values) => {

        const {data} = await Axios.post('property/set_property_layout.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const setPropertyStatus = async (values) => {

        const {data} = await Axios.post('property/set_property_status.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const getMembers = async (user) => {

        var values = {
            'user' : user
        };

        const {data} = await Axios.post('property/get_members.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        return data.values ? data.values : {};
    }

    const sendSMS = async (values) => {

        const {data} = await Axios.post('property/send_sms.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data.values ? data.values : {};
    }

    const sendEmail = async (values) => {

        const {data} = await Axios.post('property/send_email.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data.values ? data.values : {};
    }

    const sendPDF = async (values) => {

        const {data} = await Axios.post('property/sendpdf.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const getDocuments = async (place_id,user) => {

        var values = {
            'place_id' : place_id,
            'user' : user
        };

        console.log(values);

        const {data} = await Axios.post('property/s3_get_documents.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const moveDocument = async (place_id,user,current_name,new_name) => {

        var values = {
            'place_id' : place_id,
            'user' : user,
            'current_name' : current_name,
            'new_name' : new_name
        };

        console.log(values);

        const {data} = await Axios.post('property/s3_move_document.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const deleteDocument = async (place_id,user,filename) => {

        var values = {
            'place_id' : place_id,
            'user' : user,
            'filename' : filename
        };

        const {data} = await Axios.post('property/s3_delete_document.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const uploadDocument = async (values) => {
        
        const {data} = await Axios.post('property/s3_upload_file.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const addFolder = async (place_id,user,foldername) => {

        var values = {
            'place_id' : place_id,
            'user' : user,
            'foldername' : foldername
        };

        const {data} = await Axios.post('property/s3_add_folder.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const editFileHeaders = async (key,headers) => {

        var values = {
            'key' : key,
            'headers' : headers
        };

        console.log(values);

        const {data} = await Axios.post('property/s3_edit_headers.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const editPrivacy = async (place_id,user,name,privacy) => {

        var values = {
            'place_id' : place_id,
            'user' : user,
            'name' : name,
            'privacy' : privacy == "Private" ? "private" : (privacy == "Members Only" ? "members_only" : "public")
        };

        const {data} = await Axios.post('property/document_privacy.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const addReview = async (values) => {
        const {data} = await Axios.post('property/add_review.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const saveProperty = async (values) => {
        const {data} = await Axios.post('property/save_property.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const acceptOffer = async (values) => {
        console.log(values);
        
        const {data} = await Axios.post('property/accept_offer.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    const checkPlaceID = async (place_id,user) => {

        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

        var values = {
            'place_id' : place_id,
            'user' : user
        };

        const {data} = await Axios.post('property/check_place_id.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(values,data);

        return data;
    }

    const makePropertyPublic = async (place_id,user) => {

        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;

        var values = {
            'username':user,
            'place_id':place_id,
            'field':'privacy',
            'value':'public'
        }

        const {data} = await Axios.post('property/update_property.php',values).catch(function (error) {
            if (error.response) console.log(error.response.data, error.response.status, error.response.headers);
            else if (error.request) console.log(error.request);
            else console.log('Error', error.message);
            console.log(error.config);
            var error_data = {"error" : error, "url" : error.request && error.request.url ? error.request.url : "none provided"};
            email_axios_error(error_data);
        });

        console.log(data);

        return data;
    }

    return (
        <PropertyContext.Provider value={{getProperty, getProperties, setPropertyLayout, setPropertyStatus, getMembers, sendSMS, sendPDF, sendEmail, getDocuments, moveDocument, deleteDocument, uploadDocument, addFolder, editPrivacy, addReview, saveProperty, makePropertyPublic, saveLandIDScreenshot, checkPlaceID, editFileHeaders, acceptOffer}}>
            {children}
            
            <MainLoaderModal 
                openLoadingModal={openLoadingModal}
                setOpenLoadingModal={setOpenLoadingModal}
            />
        </PropertyContext.Provider>
    );

}

export default PropertyContextProvider;