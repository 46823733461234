import React, { useEffect, useLayoutEffect, useState } from 'react';
// import Map, {useControl} from 'react-map-gl';
import Map, {
  Marker,
  NavigationControl,
  Popup,
  useControl
} from 'react-map-gl';
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import DynamicPin from '../DynamicPin';
import { useNavigate } from 'react-router-dom';
import WebMercatorViewport, { Bounds } from "viewport-mercator-project"
import { floatFormatter } from '../Utils/NumberFormatter';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
// mapboxgl.Control

const MABBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

function MapMarker({allProperties}) {
  const fills = {
    "private": '#EE1F15',
    "members": '#D2AB67',
    "public": '#7EC530'
  };

  const navigate = useNavigate();
  const applyToArray = (func, array) => func.apply(Math, array)

  const getBoundsForPoints = (points) => {
    // Calculate corner values of bounds
    const pointsLong = points.map(property => property.longitude);
    const pointsLat = points.map(property => property.latitude);
    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
    ]
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({ width: 800, height: 600 })
      .fitBounds(cornersLongLat, { padding: 200 }) // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport
    return { longitude, latitude, zoom }
  }


  const [viewState, setViewState] = useState(getBoundsForPoints(allProperties));
  const [popupInfo, setPopupInfo] = React.useState(null);
  const [popupPosition, setPopupPosition] = React.useState("");
  const pins = React.useMemo(
    () => allProperties.map((property, index) => (
      <Marker
        key={`marker-${index}`}
        longitude={property.longitude}
        latitude={property.latitude}
        anchor="bottom"
        onClick={e => { 

            var lat = e.target._lngLat.lat
            var lng = e.target._lngLat.lng
              
              while (Math.abs(lng - lat) > 180) {
                lat += lng > lat ? 360 : -360;
              }
              property.longitude = lng;
              property.latitude = lat;
              
            e.originalEvent.stopPropagation();
          setPopupInfo(property);
        }}
      >
        <DynamicPin fill={fills[property.property_status]} />
      </Marker>
    )),
    [allProperties]
  );
  const [zoom, setZoom] = useState(0);
  var mq = window.matchMedia("(max-width: 600px)");
  //console.log(mq)
  function zoomMatch() {
    //console.log("zoom value")
    if (mq.matches) {
      setZoom(2.2);
    } else {
      setZoom(3);
    };
  }
  useLayoutEffect(() => { zoomMatch()},[]);

  useEffect(() => {
    setViewState(getBoundsForPoints(allProperties));
  },[allProperties]);

  const clickHandle = () => { navigate("/properties/land-details?place_id=" + popupInfo.place_id); };
  
  return (
    <>
    {zoom > 0 ? (<Map
      {...viewState}
      center={(popupInfo)?[popupInfo.longitude,popupInfo.latitude]:[]}
      onMove={evt => setViewState(evt.viewState)} 
      style={{ height: 450 }}
      
      mapStyle="mapbox://styles/mapbox/dark-v10"
      mapboxAccessToken={MABBOX_TOKEN}
      useControl={(e)=>console.log(e)}
      onLoad={() => {
        const logo = document.getElementsByClassName('mapboxgl-ctrl-bottom-left');
        const links = document.getElementsByClassName('mapboxgl-ctrl-bottom-right');
        if (logo) {
          logo[0].style.display = "none";
        }
        if (links) {
          links[0].style.display = "none";
        }
      }}
    >
      {pins}
      {popupInfo && (
        <Popup
          // anchor={popupPosition}
          longitude={Number(popupInfo.longitude)}
          latitude={Number(popupInfo.latitude)}
          onClose={() => setPopupInfo(null)}
          style={{ maxWidth: 380 }}
        >
          <div className='container'>
            <div className='d-flex align-items-center p-0'>
              <div className='map-img'>
                {popupInfo.images && popupInfo.images.length > 0 && <img src={`${popupInfo.images[0]}`} alt={popupInfo.property_name} onClick={clickHandle}/>}
              </div>
              <div className='map-content ps-2'>
                <div className='d-flex justify-content-between'>
                  <div className='map-popup-location'>{popupInfo.city}</div>
                  <div className='mp-area ps-2'>{popupInfo.acreage} Acres</div>
                </div>
                <div className='map-popup-title mt-1'>{popupInfo.property_name}</div>
                <div className='d-flex align-items-center justify-content-between mt-1'>
                  <div>
                    <button type="button" className={`tag-type ${popupInfo.property_status}-btn`}  onClick={clickHandle}>{popupInfo.property_status.replace("members_only","Members Only")}</button>
                  </div>
                  <div className='mp-price'>{floatFormatter(popupInfo.list_price,true)}</div>
                </div>
                <div className='mapmarker mt-2 text-end'>

                    {popupInfo.offer_accepted ? 
                    (popupInfo.sale_finalized ? <div className='offer-status mb-2'>
                        <button type='button' className='offer-status-btn'><span className='os-circle sale-finalized'></span> Sale Finalized</button>
                    </div> : 
                    <div className='offer-status mb-2'>
                        <button type='button' className='offer-status-btn'><span className='os-circle sale-pending'></span> Sale Pending</button>
                    </div>) :
                    <div className='offer-status mb-2'>
                        <button type='button' className='offer-status-btn'><span className='os-circle offers-accepted'></span> Accepting Offers</button>
                    </div>}

                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
       <NavigationControl position="top-right" />
    </Map>): "Loading"}
    </>
  )
}

export default MapMarker;
