import loaderImg1 from '../../assets/img/gears-gold.svg'
import closeIcon from '../../assets/img/close-modal.svg'

const MainLoaderModal = ({openLoadingModal, setOpenLoadingModal}) => {
    return (
        <>

            <div className={`modal fade custom-modal loading-modal  ${openLoadingModal?"show":""}`} id="loadingModal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={() => { setOpenLoadingModal(false); }}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="sm-title text-center fw-600">Loading</div>
                            <div className='text-center gears-icon mt-5 mb-5'>
                                <img src={loaderImg1} alt='ld' />
                            </div>
                            <div className='xs-title text-center opacity-0-8'>This may take up to 15 seconds, <br/>please don't refresh your browser.</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainLoaderModal