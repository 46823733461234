import React from 'react'
import chatIcon from '../../assets/img/lg-chat-icon.svg';

const ChatMessageBody = ({ chatData, chatList, chatAttachmentInfo }) => {

    return (
        <>
            <div className="modal-body p-0">
                <div className="msg-body">
                    {!chatData ? 
                    (chatList?.length ? 
                        '' 
                        :
                        <div className='no-chat-block pt-5 pb-5'>
                            <div className='no-chat-box pt-5 pb-5'>
                                <div className='text-center'>
                                    <img src={chatIcon} />
                                </div>
                                <div className='md-title text-uppercase text-center mt-3'>No Chats yet</div>
                                <div className='content mt-3 text-center'>
                                    <p className='font-16 text-center text-white fw-300'>Start a new chat by seaching or selecting a contact from the <br/>list. For a group chat click the plus sign next to search.</p>
                                </div>
                            </div>
                        </div>
                    )
                    :

                        <ul>
                            {(chatData) ? <li className="chat-date text-center">
                                <span className='chat-date-btn'>Today</span>
                            </li> : ""}


                            {
                                (!chatData) && (
                                    <li className="sender">
                                        <div className='msg-box'>
                                            <p>This is a brand new chat</p>
                                            <span className="time">10:06 am</span>
                                        </div>
                                    </li>)
                            }
                            {chatData && chatData.map((message,i) => {
                                return (message.attachment_url == "") ?
                                                (message.sent ?
                                                    <li className="sender" key={i}>
                                                        <div className='msg-box'>
                                                            <p>{message.msg}</p>
                                                            <span className="time">{message.relative_time}</span>
                                                        </div>
                                                    </li> :
                                                    <li className="repaly" key={i}>
                                                        <div className='msg-box'>
                                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                                <div className='sender-name'>{message.from_user_fullname}</div>
                                                                <span className="time position-relative top-0 end-0 ms-4">{message.relative_time}</span>
                                                            </div>
                                                            <p>{message.msg}</p>
                                                        </div>
                                                    </li>
                                                )
                                            : (message.attachment_url && chatAttachmentInfo && message.attachment_url in chatAttachmentInfo && chatAttachmentInfo[message.attachment_url].metadata['content-type'].includes("image")) ?

                                                    (message.sent ?
                                                        <li className="sender" key={i}>
                                                            <div className='text-end'>
                                                                <div className='msg-box'>
                                                                    <img src={chatAttachmentInfo[message.attachment_url].url} style={{ maxWidth: "350px" }} />
                                                                    <div className="time position-relative bottom-0 end-0 mt-1">{message.relative_time}</div>
                                                                </div>
                                                            </div>
                                                        </li> :

                                                        <li className="repaly" key={i}>
                                                            <div className='text-end'>
                                                                <div className='msg-box'>
                                                                    <div className='d-flex align-items-center justify-content-between mb-1'>
                                                                        <div className='sender-name'>{chatData.name}</div>
                                                                        <span className="time position-relative top-0 end-0 ms-4">{message.relative_time}</span>
                                                                    </div>
                                                                    <img src={chatAttachmentInfo[message.attachment_url].url} style={{ maxWidth: "350px" }} />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )

                                                : (message.attachment_url && chatAttachmentInfo && message.attachment_url in chatAttachmentInfo && chatAttachmentInfo[message.attachment_url].metadata['content-type'].includes("pdf")) && (
                                                        (message.sent ?
                                                            <li className="sender" key={i}>
                                                                <div className='text-end'>
                                                                    <div className='msg-box d-inline-block text-start'>
                                                                        {/* <div className='d-flex align-items-center justify-content-between mb-1'>
                                                                            <div className='sender-name'>{chatData.name}</div>
                                                                            <span className="time position-relative top-0 end-0 ms-4">10:06 am</span>
                                                                        </div> */}
                                                                        <div className='chat-file-box'>
                                                                            <div className='d-flex align-items-center '>
                                                                                <div className='chat-file-icon'></div>
                                                                                <div className='chat-file-name'>{message.files.name}</div>
                                                                                <div className='chat-download-icon ms-auto'></div>
                                                                            </div>
                                                                            <div className='chat-file-info mt-2'><span>File</span> <span className='ps-4'>{message.fileSize}</span></div>
                                                                            {/* <div className="time text-end text-block position-relative top-0 end-0">10:06 am</div>    */}
                                                                        </div>
                                                                        {/* <div className='mt-2'><Link to='/'>http://www.domain.com/file.pdf</Link></div> */}
                                                                    </div>
                                                                </div>
                                                            </li> :
                                                            <li className="repaly" key={i}>
                                                                <div className='text-start'>
                                                                    <div className='msg-box d-inline-block'>
                                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                                            <div className='sender-name'>{message.name}</div>
                                                                            <span className="time position-relative top-0 end-0 ms-4">{message.relative_time}</span>
                                                                        </div>
                                                                        <div className='chat-file-box'>
                                                                            <div className='d-flex align-items-center '>
                                                                                <div className='chat-file-icon'></div>
                                                                                <div className='chat-file-name'>{message.files.name}</div>
                                                                                <div className='chat-download-icon ms-auto'></div>
                                                                            </div>
                                                                            <div className='chat-file-info mt-2'><span>File</span> <span className='ps-4'>{message.fileSize}</span></div>
                                                                        </div>
                                                                        {/* <div className='mt-2'><Link to='/'>http://www.domain.com/file.pdf</Link></div> */}
                                                                    </div>
                                                                </div>
                                                            </li>)
                                                
                                     
                                )
                            })}

                            {/* <img src={message.data}/> */}





                            {/* <li className="sender">
                            <div className='msg-box d-inline-block'>
                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                    <div className='sender-name'>Adam Scheidler</div>
                                    <span className="time position-relative top-0 end-0 ms-4">5:12pm</span>
                                </div>
                                <img src={chatImg} />
                            </div>
                            </li>  */}


                            {/* <li className="sender">
                            <div className='msg-box d-inline-block'>
                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                    <div className='sender-name'>Adam Scheidler</div>
                                    <span className="time position-relative top-0 end-0 ms-4">5:12pm</span>
                                </div>
                                <img src={chatImg} />
                            </div>
                            </li>  */}


                            {/* New Chat */}
                            {/* <li className="sender">
                            <div className='msg-box'>
                                <p>This is a brand new chat</p>
                                <span className="time">10:06 am</span>
                            </div>
                        </li> */}
                            {/* one of one Chat List */}
                            {/* <li className="sender">
                            <div className='msg-box'>
                                <p>I’m ready to buy this, lets pull the trigger sit amet, consectetur adipiscing elit. Praesent eu justo sapien.</p>
                                <span className="time">10:06 am</span>
                            </div>
                        </li>
                         <li className="repaly">
                            <div className='msg-box'>
                                <p>I’m ready to buy this, lets pull the trigger sit amet, consectetur adipiscing elit. Praesent eu justo sapien.</p>
                                <span className="time">10:06 am</span>
                            </div>
                        </li> */}


                            {/*  Group Chat */}
                            {/* <li className="sender">
                            <div className='msg-box'>
                                <div className='sender-name'>Adam Scheidler</div>
                                <p>Hey Tim! Can you send me the title and photo of the property?</p>
                                <span className="time">10:06 am</span>
                            </div>
                        </li>
                        <li className="sender">
                            <div className='msg-box d-inline-block'>
                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                    <div className='sender-name'>Adam Scheidler</div>
                                    <span className="time position-relative top-0 end-0 ms-4">10:06 am</span>
                                </div>
                                <div className='chat-file-box'>
                                    <div className='d-flex align-items-center '>
                                        <div className='chat-file-icon'></div>
                                        <div className='chat-file-name'>File Name Here</div>
                                        <div className='chat-download-icon ms-auto'></div>
                                    </div>
                                    <div className='chat-file-info mt-2'>5 pages <span className='ps-4'>PDF</span> <span className='ps-4'>346 KB</span></div>
                                </div>
                                <div className='mt-2'><Link to='/'>http://www.domain.com/file.pdf</Link></div>
                            </div>
                        </li>
                        <li className="sender">
                            <div className='msg-box d-inline-block'>
                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                    <div className='sender-name'>Adam Scheidler</div>
                                    <span className="time position-relative top-0 end-0 ms-4">5:12pm</span>
                                </div>
                                <img src={chatImg} />
                            </div>
                        </li> */}
                        </ul>
                    }
                </div>
            </div>
        </>
    )
}

export default ChatMessageBody