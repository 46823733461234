import React from 'react';

function DynamicPin({
  stroke = "none",
  size= 20,
  fill = '#d00'
}) {
  return (
    <svg width="17px" height="24px" viewBox="0 0 17 24" style={{ cursor:"pointer" }}>
        <title>pin-b</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="My-Property-Investments" transform="translate(-742.000000, -305.000000)" fill={fill} fillRule="nonzero">
                <g id="Map" transform="translate(40.000000, 90.000000)">
                    <g id="pin-b" transform="translate(702.000000, 215.000000)">
                        <path d="M2.496875,2.46428571 C4.16145833,0.821428571 6.1625,0 8.5,0 C10.8375,0 12.8385417,0.821428571 14.503125,2.46428571 C16.1677083,4.10714286 17,6.08928571 17,8.41071429 C17,10.2678571 16.2916667,12.5 14.875,15.1071429 C13.4583333,17.7142857 12.0416667,19.8571429 10.625,21.5357143 L8.5,24 C8.25208333,23.75 7.9421875,23.4017857 7.5703125,22.9553571 C7.1984375,22.5089286 6.52552083,21.6428571 5.5515625,20.3571429 C4.57760417,19.0714286 3.71875,17.8214286 2.975,16.6071429 C2.23125,15.3928571 1.54947917,14.0178571 0.9296875,12.4821429 C0.309895833,10.9464286 0,9.58928571 0,8.41071429 C0,6.08928571 0.832291667,4.10714286 2.496875,2.46428571 Z M6.321875,10.5535714 C6.92395833,11.125 7.65,11.4107143 8.5,11.4107143 C9.35,11.4107143 10.0671875,11.1160714 10.6515625,10.5267857 C11.2359375,9.9375 11.528125,9.23214286 11.528125,8.41071429 C11.528125,7.58928571 11.2359375,6.88392857 10.6515625,6.29464286 C10.0671875,5.70535714 9.35,5.41071429 8.5,5.41071429 C7.65,5.41071429 6.9328125,5.70535714 6.3484375,6.29464286 C5.7640625,6.88392857 5.471875,7.58928571 5.471875,8.41071429 C5.471875,9.23214286 5.75520833,9.94642857 6.321875,10.5535714 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default DynamicPin;
