import { Field } from "formik";
import { ValidationError } from "../Errors/ValidationError";

const SelectField = ({ label, name, data, placeholder, ...props }) => {
  return (
    <>
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <Field {...props} name={name} as="select" className="form-control">
          {placeholder ? <option value="">{placeholder}</option> : ""}
          {data.map((val, key) => {
            return (
              <option key={key} value={val.value} disabled={val.disabled ? true : null}>
                {val.display !== undefined ? val.display : val.value}
              </option>
            );
          })}
          
        </Field>
        <ValidationError name={name} />
      </div>
    </>
  );
};

export default SelectField;
