import React from 'react'
import closeIcon from '../../assets/img/close-modal.svg'
import {intFormatter, floatFormatter} from "../../Components/Utils/NumberFormatter.js";
import numberFormatter from '../Form/NumberFormatter';

const AcceptOfferModal = ({ acceptOfferModal, closeAcceptOfferModal, propertyData, acceptOfferButton, finalizeSaleButton }) => {

    //console.log(propertyData.offers);
    return (
        <>

            <div className={`modal fade custom-modal common-modal make-offer-modal ${acceptOfferModal ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog mxw-780">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile">{propertyData.offer_accepted ? 'Finalize Sale' : 'Accept Offer'}</div>
                            <button type="button" className="btn-close" onClick={closeAcceptOfferModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body ps-4 pe-4 pt-2">
                            <button type="button" className="btn-close m-d-none" onClick={closeAcceptOfferModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-40 d-none d-md-block">{propertyData.offer_accepted ? 'Finalize Sale' : 'Accept Offer'}</div>
                            <div className='accept-offer-table'>
                                <table className='w-100'>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th className='text-center'>Acreage</th>
                                            <th className='text-center'>Amount</th>
                                            <th className='text-end'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {propertyData && propertyData.offers && propertyData.offers.map((offer,i) => {
                                            return (<tr key={i}>
                                                <td>
                                                    <div className='aot-title1 mb-1'>{offer.first_name} {offer.last_name}</div>
                                                    <div className='aot-title2'>{offer.company}</div>
                                                </td>
                                                <td className='text-center'><div className='aot-title1'><span className='fw-300 d-md-none'>Acreage:&nbsp;&nbsp;&nbsp;</span> {floatFormatter(offer.acres)}</div></td>
                                                <td className='text-center'><div className='aot-amount'><span className='fw-300 d-md-none'>Amount:&nbsp;&nbsp;&nbsp;</span> {floatFormatter(offer.offer,true)}</div></td>
                                                <td className='text-end'>
                                                    {offer.accepted ? 
                                                        (offer.finalized ? 
                                                            <button className='theme-btn text-uppercase fw-600' type='button'>Sale Finalized!</button> :
                                                            <button className='theme-btn text-uppercase fw-600' type='button' onClick={()=> {acceptOfferButton(offer.username,offer.place_id,true)}}>Finalize Sale</button>                                                            
                                                        )
                                                    :
                                                        <button  className='theme-btn text-uppercase fw-600' type='button' onClick={()=> {acceptOfferButton(offer.username,offer.place_id)}}>Accept Offer</button>
                                                    }
                                                </td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AcceptOfferModal