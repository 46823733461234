import React, { useState, useRef } from 'react';
import YouTube from 'react-youtube';
import Header from '../Frontend/Common/Header';
import Footer from '../Frontend/Common/Footer';

const LandDealWalkThrough = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const playerRef = useRef(null);

    const opts = {
        height: '740',
        width: '100%',
        playerVars: {
            autoplay: 0,
            rel: 0, // Auto-play is set to 0 to allow manual play control
        },
    };

    const onReady = (event) => {
        // You can now control the player instance (event.target) if needed
        playerRef.current = event.target;
    };

    const handleTogglePlayback = () => {
        const player = playerRef.current;

        if (player) {
            if (isPlaying) {
                player.pauseVideo();
            } else {
                player.playVideo();
            }

            setIsPlaying(!isPlaying);
        }
    };
    return (
        <>
            <Header/>
            <div className='walk-throught-wrap'>
                <div className='container-fluid ps-5 pe-5'>
                    <div className='md-title text-uppercase fw-700 mb-4'>Land Deal Walk Through</div>
                    <div className={`video-block mb-5 ${isPlaying ? 'pause-video' : 'play-video'}`}>
                        <YouTube
                            videoId="yRtXCUgtBhg"
                            opts={opts}
                            onReady={onReady}
                        />
                        <div className='cs-action-btn' onClick={handleTogglePlayback}>
                            <button className={isPlaying ? 'pause-icon' : 'play-icon'} ></button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default LandDealWalkThrough