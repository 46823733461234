import React from 'react'
import Header from '../../Components/Common/Header'
import Footer from '../../Components/Common/Footer'
import deleteIcon from '../../assets/img/icon-delete-photo.svg'

import GeneralDetails from '../../Components/AddProperty/GeneralDetails'
import ExtendedInfo from '../../Components/AddProperty/ExtendedInfo'
import MapIntegration from '../../Components/AddProperty/MapIntegration'
import DueDiligenceDocuments from '../../Components/AddProperty/DueDiligenceDocuments'
import PhotosUpload from '../../Components/AddProperty/PhotosUpload'
import { useState, useContext, useEffect, useRef } from 'react';
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext'
import {EnvContext} from '../../context/EnvContext';
import ListingAlert from '../../Components/Common/ListingAlert'
import { useNavigate, useLocation } from 'react-router-dom';

const AddProperty = () => {
    const {user} = useContext(UserContext); 
    const { getProperty, checkPlaceID, getProperties } = useContext(PropertyContext);
    const { landdealBaseURL } = useContext(EnvContext);

    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    const place_id = queryParameters.get("place_id");
    const edit_listing = queryParameters.get("edit_listing");
    const copy_property = queryParameters.get("copy_property");

    const [propertyData, setPropertyData] = useState(null);

    const [allProperties, setAllProperties] = useState({});

    const [items, setItems] = useState([1, 2, 3, 4, 5]);
    const [step, setstep] = useState(1);
    const [nextActiveHeading,  setNextActiveHeading] = useState("");
    const [allValues, setAllValues] = useState({});

    const [propertyAddress, setPropertyAddress] = useState("");
    const nextStep = (stepCount,values) => {
        // setstep(step + 1);
        //console.log(stepCount);
        if(stepCount > 0) {
            setstep(stepCount);
            setNextActiveHeading(stepCount);
            $('#collapse' + (stepCount-1)).collapse('toggle');
        }


        if(values && Object.keys(values).length) {
            //setAllValues({...allValues,...values});
            setAllValues((prev) => Object.assign({}, prev, values));
        }
         console.log("nextstep",Object.keys(values).length,allValues,values);
      };

    const finishForm = () => {
        // console.log(allValues);

        generalFormikRef.current.validateForm().then((errors) => {
            if(Object.keys(errors).length > 0) {
                //alert(Object.values(errors).join('\n'));
            }
        });

        Promise.all([generalFormikRef.current.validateForm(), extendedFormikRef.current.validateForm(), mapFormikRef.current.validateForm(), documentsFormikRef.current.validateForm()]).then((all_errors) => {
            var errors = [];
            console.log(all_errors);

            all_errors.map((v,i) => {
                Object.values(v).map((x,j) => {
                    errors.push("Step " + (i+1) + ": " + x);
                })
            });

            if(errors.length > 0) {
                alert(errors.join('\n'));
            } else {
                $.ajax({
                    type: 'post',
                    url: landdealBaseURL + 'bridge/property/add_property.php',
                    data: allValues,
                    success: function (result) {
                       console.log(result);
                       if(result.success && result.place_id) {
                        alert('Congratulations! Your Property is Now Listed');
                        window.location.href = landdealBaseURL + "properties/land-details?place_id=" + result.place_id + "&edit_layout";
                       }
                       else if(result.error) alert(result.error);
                       else alert("An issue occured");
                    },
                    error: function (textStatus, errorThrown) {
                        console.log(textStatus);
                        console.log(errorThrown);
                    }
                });
            }
        });

        return;

        
    }

    const generalFormikRef = useRef(null);
    const extendedFormikRef = useRef(null);
    const mapFormikRef = useRef(null);
    const documentsFormikRef = useRef(null);

    const sendFormikRef = (section,formik) => {
        console.log("got a formik",section,formik);
        switch(section) {
            case "general": generalFormikRef.current = formik;
            case "extended": extendedFormikRef.current = formik;
            case "map": mapFormikRef.current = formik;
            case "documents": documentsFormikRef.current = formik;
        }

        console.log(generalFormikRef.current);
    }

    useEffect(() => {       

        const fetchData = async () => {
            const propData = await getProperty(place_id,user.username);
            // console.log(propData);
            if(propData.company_id == user.company_id) {
                setPropertyData(propData);
                nextStep(0,propData);
            }
        }        
        
       if(place_id && (edit_listing !== null || copy_property !== null) && place_id != "") fetchData();        
       else {
            setPropertyData({
                "company_id": user.company_id,
                "property_status": "",
                "listing_status": "",
                "type": "",
                "sub_type": "",
                "specialty_type": "",
                "property_name": "",
                "address": "",
                "city": "",
                "latitude": 0,
                "longitude": 0,
                "place_id": "",
                "layout": "",
                "acreage": 0,
                "list_price": "",
                "roi": "",
                "property_website": "",
                "description": "",
                "legal_description": null,
                "is_broker": 0,
                "broker_name": user.first_name + " " + user.last_name,
                "broker_company_name": user.company,
                "broker_phone_number": user.phone,
                "broker_email": user.email,
                "map_link": "",
                "document_link_descriptions": "",
                "document_links": "",
                "document_link_privacies": [],
                "images": [],
                "static_map_url": "",
                "amenities": "",
                "social_link_types": null,
                "social_link_urls": null,
                "doc_links": [],
                "documents": [],
                "social_links": [],
                "price_per_acre": 1
            }); 
       }

    }, [place_id]); 

    useEffect(() => {      
    
        const fetchData = async () => {
            var values = {user:user.username,admin:true,no_massage:true};
            const propData = await getProperties(values);
            setAllProperties(propData);
        }        
  
        fetchData();      
        
    }, []); 
    
    // Listing Alert Modal
    const [listAlert, setListingAlert] = useState(false);

    const [validatingPlaceID, setValidatingPlaceID] = useState("");

    const placeIDValidation = async (place_id) => {
        setValidatingPlaceID(place_id);

        var placeIDData = await checkPlaceID(place_id,user.username);

        console.log(placeIDData,edit_listing === null);

        if(placeIDData.property_present && copy_property !== null) {
            console.log("setting listing alert");

            return placeIDData.place_id;
        } else if(placeIDData.property_present && edit_listing === null) {
            console.log("setting listing alert");
            setListingAlert(true);

            return placeIDData.place_id;
        } else {
            return place_id;
        }
    }

    const editListing = () => {
        navigate("/properties/add-my-property?place_id=" + validatingPlaceID + "&edit_listing");
        navigate(0);
        setListingAlert(false);
    }

    const handleDuplicateListing = (values) => {
        console.log(values);

        navigate("/properties/add-my-property?place_id=" + values.place_id + "&copy_property",{state:{related_property:values.place_id,relationship:values.relationship}});
        navigate(0);
    }

    const { state } = useLocation();

    useEffect(()=>{
        if(state){
            console.log("uselocation state",state);

            if(state.relationship !== undefined && state.related_property !== undefined) {
                if(state.relationship != "NONE") {
                    nextStep(0,{property_relationship:state.relationship,related_property:state.related_property});
                }
            }
        }
    },[state]);

    return (
        <>
            <Header />
            <section className="main-wrap ps-0 pe-0 property-accordion-wrap">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between mt-5 mb-5 ap-heading">
                        <div className="xl-title text-uppercase">Add My Property</div>
                        <div className="steps-block d-flex">
                            {items.map((tab, index)=>(
                                <div className={(index+1) <= step ? "step-col active" : "step-col"} key={index}>
                                    <span className='count-num'>{index+1}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="accordion property-accordion" id="accordionExample1">
                        {propertyData && <div className='custom-form '>
                            {items.map((item, i) => {
                                
                                return (
                                    
                                    <div className="accordion-item" key={i}>
                                        <h2 className="accordion-header" id={'#heading' + i} onClick={()=>nextStep(item,{})}>
                                            <button 
                                            className={`accordion-button 
                                            ${i === 0 ? "": "collapsed"} 
                                            `} type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={'#collapse' + i}>
                                                
                                                {(() => {
                                                    switch (item) {
                                                        case 1:
                                                            return <><span className="rg-arrow-icon"></span> 1. General Details</>
                                                        case 2:
                                                            return <><span className="rg-arrow-icon"></span> 2. Extended Info</>
                                                        case 3:
                                                            return <><span className="rg-arrow-icon"></span> 3. Map Integration</>
                                                        case 4:
                                                            return <><span className="rg-arrow-icon"></span> 4. Due Diligence Documents</>
                                                        case 5:
                                                            return <><span className="rg-arrow-icon"></span> 5. Photos Upload</>
                                                        default:
                                                            return null
                                                    }
                                                })()}
                                            </button>
                                        </h2>
                                        <div id={'collapse' + i} 
                                        className={`accordion-collapse collapse 
                                        ${i === 0 ? "show": ""} 
                                        `} 
                                        aria-labelledby={'heading' + i} data-bs-parent="#accordionExample1">

                                            {(() => {
                                                switch (item) {
                                                    case 1:
                                                        return <GeneralDetails nextStep={nextStep} item={item} setNextActiveHeading={setNextActiveHeading} propertyAddress={propertyAddress} propertyData={propertyData} sendFormikRef={sendFormikRef} allProperties={allProperties} handleDuplicateListing={handleDuplicateListing}/>
                                                    case 2:
                                                        return <ExtendedInfo nextStep={nextStep} item={item} setNextActiveHeading={setNextActiveHeading} propertyData={propertyData} sendFormikRef={sendFormikRef}/>
                                                    case 3:
                                                        return <MapIntegration nextStep={nextStep} item={item} setNextActiveHeading={setNextActiveHeading} setPropertyAddress={setPropertyAddress} propertyData={propertyData} sendFormikRef={sendFormikRef} placeIDValidation={placeIDValidation} setListingAlert={setListingAlert}/>
                                                    case 4:
                                                        return <DueDiligenceDocuments nextStep={nextStep} item={item}  setNextActiveHeading={setNextActiveHeading} propertyData={propertyData} allValues={allValues} sendFormikRef={sendFormikRef}/>
                                                    case 5:
                                                        return <PhotosUpload nextStep={nextStep} item={item}  setNextActiveHeading={setNextActiveHeading} finishForm={finishForm} propertyData={propertyData} allValues={allValues}/>
                                                    default:
                                                        return null
                                                }
                                            })()}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </div>
            </section>
            <Footer />
            <ListingAlert 
                listAlert={listAlert}
                setListingAlert={setListingAlert}
                editListing={editListing}
            />
        </>
    )
}

export default AddProperty