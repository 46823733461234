import React, { useState } from 'react'
import pdficon from "../../assets/img/file-photo/file-pdf.svg";
import folderIcon from "../../assets/img/folder-icon.svg";
import excelicon from "../../assets/img/file-photo/file-excel.svg";
import docicon from "../../assets/img/file-photo/file-word.svg";
import documentIcon from "../../assets/img/document.svg";
import imageicon from "../../assets/img/file-photo/file-photo.svg";
import closeIcon from "../../assets/img/close-modal.svg";
import { intFormatter, byteFormatter } from '../Utils/NumberFormatter'

const DocumentLink = ({ propertyData }) => {
    const document_icon = {
        "folder": folderIcon,
        "application/pdf": pdficon,
        "application/msword": docicon,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": docicon,
        "text/csv": excelicon,
        "application/vnd.ms-excel": excelicon,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": excelicon,
        "image/png": imageicon,
        "image/jpg": imageicon,
        "image/jpeg": imageicon,
    }

    const [openView, setOpenView] = useState("");
    const closeOpenView = () => {
        setOpenView(false);
    };
    const OpenModal = (props) => {
        const { mime, url, id,type} = props;
        return (
            <div
                className={`modal sm pmovemodal fade custom-modal common-modal ${openView === id ? "show" : ""
                    }`}
                id="moveFolderModal"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex="-1"
                aria-labelledby="moveFolderModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog mxw-820 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-1">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeOpenView}
                            >
                                <img src={closeIcon} alt="close" />
                            </button>

                            {
                                (mime === "application/pdf" || mime === "pdf") &&
                                <div className="pdfwrap">
                                    <iframe src={url} />
                                </div>
                            }
                            {
                                (mime && mime.includes('image')) &&
                                <div className="imagewrap text-center">
                                    <img src={url} className="img-fluid" />
                                </div>
                            } 
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <>
            <div className="document-nots-block mt-30">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="DocumentsLinks-tab" data-bs-toggle="tab"
                            data-bs-target="#DocumentsLinks" type="button" role="tab" aria-controls="DocumentsLinks"
                            aria-selected="true">Documents</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="Legal-tab" data-bs-toggle="tab" data-bs-target="#Legal"
                            type="button" role="tab" aria-controls="Legal"
                            aria-selected="false">LEGAL</button>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="DocumentsLinks" role="tabpanel" aria-labelledby="DocumentsLinks-tab">
                        <div className="gray-box">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="box-title text-uppercase">Due Diligence Documents</div>
                                <div className="xs-title fw-500 text-white">Total Items: {propertyData.documents.length}</div>
                            </div>
                            <div className="mt-4 ddd-view">
                                <table className="custom-table w-100">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}></th>
                                            <th style={{ width: "50%" }} className="text-start">Name / Description</th>
                                            <th style={{ width: "20%" }} className="text-center">Size</th>
                                            <th style={{ width: "20%" }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {propertyData.documents.map((item, i) => {
                                            return (

                                                <tr key={i}>
                                                    <td style={{ width: "10%" }}>
                                                       {item.type != "dir"&&
                                                        <OpenModal
                                                            mime={item.headers['content-type']}
                                                            url={item.url}
                                                            type={item.type}
                                                            id={item.key} />}

                                                        <img onClick={() => setOpenView(item.key)}
                                                            src={item.headers && item.headers['content-type'] ? document_icon[item.headers['content-type']] : documentIcon}
                                                            alt="icon" /></td>
                                                    <td style={{ width: "50%" }} className="xs-name">{item.name}</td>
                                                    <td style={{ width: "20%" }} className="xs-title gray-text text-center fw-400">
                                                        {byteFormatter(item.headers['content-length'] ? item.headers['content-length'] : 0)}
                                                    </td>
                                                    <td className='text-center' style={{ width: "20%" }}
                                                    ><a href={item.url} target="_blank" className="xs-title font-12 theme-text text-uppercase download-btn">
                                                            Download</a></td>
                                                </tr>
                                            )

                                        })

                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="Legal" role="tabpanel" aria-labelledby="Legal-tab">
                        <div className="gray-box">
                            <div className="box-title text-uppercase">FULL LEGAL DESCRIPTION</div>
                            <div className="mt-3">
                                <div className='content'>
                                    <p>{propertyData.legal_description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentLink