import closeIcon from '../../assets/img/close-modal.svg'
import { useState } from 'react'
import RangeSliderCom from './RangeSliderCom'
import { useContext } from 'react';
import { Field, Formik, Form } from 'formik'
import TextAreaField from "../../Components/Form/TextAreaField";
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext';

import {intFormatter} from "../../Components/Utils/NumberFormatter";
import TextField from '../Form/TextField';


export var sliderValue  = 0;
const MakeOffer = ({ makeOffer, setMakeOffer, propertyData, openModal, saveOfferValues}) => {
    const {user} = useContext(UserContext); 
    const { sendEmail } = useContext(PropertyContext);

    const [acres,setAcres] = useState([0,propertyData.acreage]);

    const closeMakeOffer = () => {
        setMakeOffer(false);
    }

    const [formikData, setFormikData] = useState({
        place_id:propertyData.place_id,
        message:'',
        user:user.username,
        template_email:'makeoffer',
        recipients:propertyData.broker_email,
        acres:propertyData.acreage,
        offer:propertyData.list_price
    });

    return (
        <>
            <div className={`modal fade custom-modal common-modal make-offer-modal ${makeOffer ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-700">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile">Make Offer</div>
                            <button type="button" className="btn-close" onClick={closeMakeOffer}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={closeMakeOffer}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-40">Make Offer</div>
                            <Formik
                                initialValues={formikData} 
                                validationSchema=""
                                onSubmit={(values) => {
                                    console.log(values);

                                    if(user && user.username !== undefined && user.username != "publicuser") {
                                        sendEmail(values);
                                        alert("Your Offer Has Been Sent");
                                        closeMakeOffer();
                                    } else {
                                        saveOfferValues(values);
                                        closeMakeOffer();
                                        openModal('contactinfo');
                                    }
                                }}
                            >
                            {(formik) => (
                                <Form>  
                                    <div className='black-box mxw-400'>
                                        <div className='row'>
                                            <div className='col-md-8 offset-md-2 text-center'>
                                                <label className='text-inherit'>Offer Amount</label>
                                                <TextField type='text' 
                                                    className='form-control' 
                                                    name="offer"
                                                    onChange={(e) => {
                                                        formik.setFieldValue("offer", intFormatter(e.target.value,true));
                                                    }}
                                                    value={formik.values.offer}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='select-box-area mt-4 mb-0 mxw-400'>
                                        <div className='xs-title text-center avenir-next fw-500 font-16'>Select the Amount of Acres</div>
                                        <div className="progress-block mb-4 mt-3">
                                            <RangeSliderCom acres={acres} setAcres={(s) => {formik.values.acres = s[1]; setAcres(s);}} max={propertyData.acreage}/>
                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                <div>0</div>
                                                <div>{intFormatter(propertyData.acreage)}</div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <div className='d-inline-flex align-items-center'>
                                                <div className='number-label'>Number of Acres</div>
                                                <div className='ms-4 me-4'>
                                                    <Field 
                                                        type='text' 
                                                        value={acres[1]} 
                                                        name="acres" 
                                                        onChange={(e)=>{
                                                            var acresValue=0;
                                                            acresValue =(isNaN(e.target.value))?0:(e.target.value *1)
                                                            setAcres([0,acresValue]);
                                                            formik.values.acres = intFormatter(e.target.value);
                                                        }} 
                                                        className='form-control w-100px' 
                                                    />
                                                </div>
                                                <div className='lot-off text-uppercase'><span>{Math.round(1000*acres[1]/propertyData.acreage)/10}%</span> of Lot</div>
                                            </div>
                                        </div>
                                        <div className='mt-4'>
                                            <label className='text-inherit mb-1'>Comments to Owner</label>
                                            <TextAreaField className='form-control h-80' name="message"/>
                                            <div className='info-text mt-2'>Tip: Add any notes here to owner (optional)</div>
                                        </div>
                                    </div>
                                    <div className='col-12 plr-35 text-center'>
                                        <button className='theme-btn lg-btn text-uppercase w-290 mt-5' type="submit">Make Offer</button>
                                    </div>
                                </Form>
                            )}</Formik>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default MakeOffer