import React, { useEffect, useState, useContext, useCallback } from 'react'
import Header from '../../Components/Common/Header';
import Footer from '../../Components/Common/Footer';
import { NavLink, useNavigate } from 'react-router-dom';
import ManageDocumentsIcon from '../../assets/img/lm-icon1.svg';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'react-toastify/dist/ReactToastify.css';
import Drag from './DragComponent';
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext';
import { useDropzone } from "react-dropzone";


const AddDocuments = () => {
    const {user} = useContext(UserContext);
    const { getProperties, getDocuments, moveDocument, deleteDocument, uploadDocument, addFolder, editPrivacy } = useContext(PropertyContext);
 
    const [selectedProperty, setSelectedProperty] = useState("disabledOption");
    const [documentData, setDocumentData] = useState({});
    const [allDocumentData, setAllDocumentData] = useState({});
    const [homeDataArray, setHomeDataArray] = useState([]);
    const [BreadCrumbArrayChange, setBreadCrumbArrayChange] = useState(true);
    
    const navigate = useNavigate()

    const handleSelectChange = (e) => {
        setCurrentProperty(allProperties[e.target.value]);
        setSelectedProperty(e.target.value);
        fetchDocuments(e.target.value);
        setBreadCrumbArrayChange(!BreadCrumbArrayChange);
        queryParameters.set('place_id',e.target.value);

        navigate({
            pathname: '/properties/admin/add-documents',
            search: queryParameters.toString(),
        });
    };

    const [currentPath, setCurrentPath] = useState("");

    const fetchDocuments = async (place_id) => {
        setLoading(true)
        const docData = getDocuments(place_id, user.username);
        docData.then((data)=>{
            setDocumentData(data);
            setAllDocumentData(data);
            
            setLoading(false);
        }).catch(()=>{});
    }
    
    const addFolderWrapper = async (foldername) => {
        const data = await addFolder(currentProperty.place_id,user.username,foldername);
        fetchDocuments(currentProperty.place_id);
    }

    const moveDocumentWrapper = async (current_name,new_name) => {
        if(current_name == new_name) {
            console.log("no movement");
            return;
        }
        const data = await moveDocument(currentProperty.place_id,user.username,current_name,new_name);
        fetchDocuments(currentProperty.place_id);
    }

    const deleteDocumentWrapper = async (filename) => {
        const data = await deleteDocument(currentProperty.place_id,user.username,filename);
        fetchDocuments(currentProperty.place_id);
    }

    const editPrivacyWrapper = async (name,privacy) => {
        const data = await editPrivacy(currentProperty.place_id,user.username,name,privacy);
        fetchDocuments(currentProperty.place_id);
    }

    //const [uploadFile, setUploadFile] = useState();

    const [loading, setLoading] = useState(false);
    
    const documentChange = async (files) => {
        console.log(files);
        //setDocUploading(true);
        for (const file of files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {

                const formData = new FormData();
                formData.append('place_id',currentProperty.place_id);
                formData.append('user',user.username);
                formData.append('file', file);
                formData.append('fileName', (currentPath == "" ? "" : currentPath.substring(1) + '/') + file.name);
                const upload = uploadDocument(formData);
                upload.then((data)=>{
                    fetchDocuments(currentProperty.place_id)
                });
                
            };
            reader.onerror = () => {
                console.log(reader.error);
                //setDocUploading(false);
            };
        }
    }; 

    const queryParameters = new URLSearchParams(window.location.search)
    const place_id = queryParameters.get("place_id");

    const [allProperties, setAllProperties] = useState({});
    const [currentProperty, setCurrentProperty] = useState({});
    const [searchText, setSearchText] = useState("");

    const handleChange = ({ target }) => {
      setSearchText(target.value);
      
      let tempOptions = [...allDocumentData];
      var record_filter = tempOptions.filter((obj) => obj.name?.toLowerCase().includes(target.value?.toLowerCase()) );
      
      if (record_filter.length) setDocumentData(record_filter);
      else setDocumentData([]);
    };

    useEffect(() => {             
        const fetchData = async () => {
            const propData = await getProperties({user:user.username,admin:true,assoc:true});
            setAllProperties(propData);
            if(place_id) {
                setCurrentProperty(propData[place_id]);
                setSelectedProperty(place_id);
                fetchDocuments(place_id);
            }
        }        
        fetchData();      
    }, []);  
    

    function getFormatDate (timestamp){
        const date = new Date(timestamp);
        const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const year = date.getFullYear();
        const month = months[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${month} ${day} ${year} ${hours}:${minutes}:${seconds}`;
    }
    
    const [IDCount,setIDCount] = useState(500);
    
    const addNewFolder = () => {
        var this_id = IDCount + 1;

        const newFolder = {
            id: this_id + '',
            name: "New Folder",
            type: "folder",
            obj_key: (currentPath == "" ? "" : currentPath.substring(1) + "/") + "New Folder",
            date: getFormatDate(Date.now()),  
            privacy: "Private",
            children_data: [],
            editable: false,
            dragged: false,
            mime: "folder",
            is_new_folder: true
        };        

        const updatedProperty = [...documentData];
        updatedProperty.unshift(newFolder);

        /*if(currentPath != "") {
            newFolder.folder = currentPath;
            for(var i = 0; i < updatedProperty.length; i++) {
                if(currentPath == updatedProperty[i].obj_key + "/") updatedProperty[i].children_data.push(this_id + '');
            }
        }*/

        setIDCount(this_id);

        setDocumentData(updatedProperty);
    };

    const onDrop = useCallback(acceptedFiles => {
        console.log('setting callback');
        documentChange(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({onDrop: acceptedFiles => {  documentChange(acceptedFiles);   }});

    return (
        <>
            <Header />
            <section className="main-wrap ps-0 pe-0">
                <div className='add-property-block'>
                    <div className='container'>
                        <div className='gray-box add-property-box'>
                            <div className='d-flex apb-flex align-items-center'>
                                <div className='apb-left'>
                                    <div className='apb-transparent-box'>
                                        <div className="xs-title text-gray font-14 fw-500 mb-2 text-start">Admin for:</div>
                                        <div className="xs-title text-uppercase text-white text-start font-16">{user.company}</div>
                                    </div>
                                </div>
                                <div className='apb-right custom-form'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-5 pe-5'>
                                            <div className="xs-title fw-500 mb-2 text-start text-white">Select Property</div>
                                            <select className="form-control" value={selectedProperty} onChange={handleSelectChange}>
                                                <option value="disabledOption" disabled>Select Property</option>
                                                {allProperties && Object.keys(allProperties).map((place_id,i) => {
                                                    return (<option key={i} value={place_id}>{allProperties[place_id].property_name}</option>)
                                                })}
                                            </select>
                                        </div>
                                        <div className='col-md-7'>
                                            <div className='d-flex align-items-center loct-flex'>
                                                <div className='loct-img propertyimg'>
                                                    <img 
                                                    src={currentProperty.images ? currentProperty.images[0] : ""}
                                                     alt='location' />
                                                </div>
                                                <div className='loct-right'>
                                                    <div className="xs-title text-gray font-14 fw-500 mb-3 text-start">Location: <span className='text-white'>{currentProperty.city}</span></div>
                                                    <div>
                                                        <a className='trns-edit-btn text-uppercase' href={"/properties/add-my-property?place_id=" + currentProperty.place_id + "&edit_listing"}><span className='edit-icon'></span> Edit Property</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex apb-flex pt-4 mt-5 bw-top'>
                                <div className='apb-left adddoc'>
                                    <ul className='apb-menu'>
                                        <li>
                                            <NavLink ><span><img src={ManageDocumentsIcon} alt='Manage Documents' /></span> Manage <br/>Documents</NavLink>
                                        </li>
                                     </ul>
                                </div>
                                <div className='apb-right'>
                                    <div className='d-flex align-items-center apb-top-filter mb-4'>
                                        <div className="search-block sp-search-block ad-search-block" style={{ width: "35%" }}>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search Documents"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    onChange={handleChange}
                                                    value={searchText}
                                                />
                                                <span className="search-icon"></span>
                                            </div>
                                        </div>
                                        <div style={{ width: "20%" }} className='text-center bulk-action-btn'>
                                            {/*<button type="button" className="trns-edit-btn text-uppercase">
                                                <span className="setting-icon"></span> Bulk Actions
                                            </button>*/}
                                        </div>
                                        <div style={{ width: "20%" }} className='text-center upload-fl-btn' {...getRootProps({ className: "dropzone" })}>
                                            <input {...getInputProps()} />
                                            {/*<input className="form-control d-none" id='formIdUpload' type="file" onChange={(e) => { documentChange(e.target.files); }} multiple/>*/}
                                            <label htmlFor={"formIdUpload"} type="button" className="trns-edit-btn text-uppercase">
                                                <span className="upload-icon"></span> Upload Files
                                            </label>
                                        </div>
                                        <div style={{ width: "25%" }} className='text-end new-fl-btn'>
                                            <button type="button" onClick={addNewFolder} className="theme-trns-btn text-uppercase">
                                                <span className="add-folder-icon"></span> New Folder
                                            </button>
                                        </div>
                                    </div>
                                    {loading && <div className='progressbar my-2' >
                                        <ProgressBar animated now={100} />
                                    </div>}
                                    {(documentData && documentData.length > 0) 
                                    &&  
                                    // <div {...getRootProps({ className: "dropzone" })}>
                                    <Drag
                                    documentChange={documentChange}
                                    BreadCrumbArrayChange={BreadCrumbArrayChange} homeDataArray={homeDataArray} documentData={documentData} moveDocumentWrapper={moveDocumentWrapper} deleteDocumentWrapper={deleteDocumentWrapper} editPrivacyWrapper={editPrivacyWrapper} addFolderWrapper={addFolderWrapper} currentPath={currentPath} setCurrentPath={setCurrentPath}/>
                                    // </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="toast align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="d-flex">
                        <div className="toast-body">
                            Hello, world! This is a toast message.
                        </div>
                        <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                </div>

            </section>
            <Footer />
        </>
    )
}

export default AddDocuments