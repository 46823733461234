import React from 'react'
import { useEffect, useState, useRef } from "react";
import { intFormatter } from "../../Components/Utils/NumberFormatter";
import { Formik, Form, Field } from 'formik';
import SelectField from '../../Components/Form/SelectField';

const PropertyOverview = ({ allProperties, currentProperties,propertyTitleName, handleFilterChange, acreGroups }) => {

    var private_count = 0;
    const [propertyData, setPropertyData] = useState({
        property_count: 0,
        private_count: 0,
        public_count: 0,
        members_only_count: 0,
        acreage: 0
    });

    const queryParameters = new URLSearchParams(window.location.search);

    const [formikData, setFormikData] = useState({
        state:queryParameters.get("state") ?? "",
        property_group:queryParameters.get("property_group") ?? "",
        amenity:queryParameters.get("amenity") ?? "",
        acres:queryParameters.get("acres") ?? ""
    }); 

    const formikRef = useRef(null);    
    
    

    const [selectValues,setSelectValues] = useState({
        states:[],
        property_groups:[],
        amenities:[],
        acres:acreGroups
    });

    const calcPropertyData = (theseProperties) => {
        var temp_property_data = {
            property_count: 0,
            private_count: 0,
            public_count: 0,
            members_only_count: 0,
            acreage: 0
        };

        Object.keys(theseProperties).map(function (i) {
            temp_property_data.property_count++;
            temp_property_data.private_count += theseProperties[i].property_status == "private" ? 1 : 0;
            temp_property_data.public_count += theseProperties[i].property_status == "public" ? 1 : 0;
            temp_property_data.members_only_count += theseProperties[i].property_status == "members_only" ? 1 : 0;
            temp_property_data.acreage += theseProperties[i].acreage;
        });
        setPropertyData(temp_property_data);
    }

    const setFilterData = () => {

        const states = [];
        const property_groups = [];
        const amenities = [];

        allProperties.map(function(d) { if (states.indexOf(d.state) === -1) {
            states.push(d.state)
        } });

        allProperties.map(function(d) { if (property_groups.indexOf(d.property_group) === -1) {
            property_groups.push(d.property_group)
        } });

        allProperties.map(function(d) { 
            for(var i = 0; i < d.amenities.length; i++) {
                if (amenities.indexOf(d.amenities[i]) === -1) {
                    amenities.push(d.amenities[i])
                } 
            }
        });

        setSelectValues({
            states:states.map(function(d,i) { return {key:i,value:d} }).sort(function(a,b) { return (a.value < b.value) ? -1 : (a.value > b.value) ? 1 : 0 }),
            property_groups:property_groups.map(function(d,i) { return {key:i,value:d} }).sort(function(a,b) { return (a.value < b.value) ? -1 : (a.value > b.value) ? 1 : 0 }),
            amenities:amenities.map(function(d,i) { return {key:i,value:d} }).sort(function(a,b) { return (a.value < b.value) ? -1 : (a.value > b.value) ? 1 : 0 }),
            acres:acreGroups
        });

    }

    useEffect(() => {
        calcPropertyData(currentProperties);
        setFilterData();
    }, [allProperties]); 

    useEffect(() => {
        calcPropertyData(currentProperties);
    }, [currentProperties]); 

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        const selectedIndex = e.target.selectedIndex;
        const selectedName = e.target.name;

        formikRef.current.setFieldValue(selectedName,selectedValue);

        handleFilterChange(selectedName,selectedName == "acres" ? selectedIndex : selectedValue);

    };
      
    return (
        <>
            <section className="pt-0 filter-block mb-5">
                <div className="container mxw-1140 ps-5 pe-5">
                    <Formik
                        initialValues={formikData}
                        enableReinitialize={true}
                        innerRef={formikRef}
                        onSubmit={(values) => {
                            console.log(values);
                        }}
                    >
                        {(formik) => (
                            <Form className='custom-form'>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <SelectField
                                            name="state"
                                            label="State"
                                            placeholder="All States"
                                            data={selectValues.states}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <SelectField
                                            name="property_group"
                                            label="Group"
                                            placeholder="All Groups"
                                            data={selectValues.property_groups}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <SelectField
                                            name="amenity"
                                            label="Type"
                                            placeholder="All Types"
                                            data={selectValues.amenities}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <SelectField
                                            name="acres"
                                            label="Acres"
                                            placeholder="All Acreage"
                                            data={selectValues.acres}
                                            onChange={handleSelectChange}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
            <section className="pt-0 mpi-block">
                <div className="container mxw-1140">
                    <div className="md-title pt-0 text-uppercase px-5">{propertyTitleName}</div>
                    <div className="row pt-5 pb-5 align-items-center">
                        <div className="col">
                            <div className="xs-title theme-text text-uppercase">Properties</div>
                            <div className="sm-title text-white mt-2">{intFormatter(propertyData.property_count)}</div>
                        </div>
                        <div className="col">
                            <div className="xs-title theme-text text-uppercase">Private</div>
                            <div className="sm-title text-white mt-2">{intFormatter(propertyData.private_count)}</div>
                        </div>
                        <div className="col">
                            <div className="xs-title theme-text text-uppercase">Public</div>
                            <div className="sm-title text-white mt-2">{intFormatter(propertyData.public_count)}</div>
                        </div>
                        <div className="col">
                            <div className="xs-title theme-text text-uppercase">Members Only</div>
                            <div className="sm-title text-white mt-2">{intFormatter(propertyData.members_only_count)}</div>
                        </div>
                        <div className="col">
                            <div className="xs-title theme-text text-uppercase">Total Acres</div>
                            <div className="sm-title text-white mt-2">{intFormatter(propertyData.acreage)}</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PropertyOverview