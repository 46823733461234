
import Amenities from '../../Components/RightWidgets/Amenities'
import PropertyDetails from '../../Components/RightWidgets/PropertyDetails'
import ContactSeller from '../../Components/RightWidgets/ContactSeller'
import SimilarListings from '../../Components/RightWidgets/SimilarListings'
import {UserContext} from '../../context/UserContext';
import { useContext, useState } from 'react';
import ViewOfferBox from '../../Components/RightWidgets/ViewOfferBox';
const DetailRight = ({propertyData,openModal, clickOpenReview}) => {
    const {user} = useContext(UserContext); 
    
    return (
        <>
            <div className="dtl-right">
                <PropertyDetails propertyData={propertyData} openModal={openModal}/>
                {propertyData && propertyData.company_id == user.company_id && !propertyData.sale_finalized && <ViewOfferBox openModal={openModal} offerCount={propertyData.offers && propertyData.offers.length} offerAccepted={propertyData.offer_accepted}/>}
                <Amenities propertyData={propertyData}/>
                <ContactSeller propertyData={propertyData} openModal={openModal} clickOpenReview={clickOpenReview}/>
                <SimilarListings propertyData={propertyData}/>
                {propertyData && propertyData.company_id == user.company_id && <div className="gray-box mt-3 text-center"><a className="theme-trns-btn text-uppercase w-100" href={"/properties/add-my-property?place_id=" + propertyData.place_id + "&edit_listing"}>Edit Property</a></div>}
            </div>
        </>
    )
}

export default DetailRight