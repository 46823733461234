import React from 'react'
import LandDetailLoading from '../../Components/Common/LandDetailLoading';

const DetailMap = ({propertyData, landDealLoading}) => {

    setTimeout(() => {
        if(propertyData.mapright_link == undefined) loadMap();
    }, 1000);

    const loadMap = () => {
        //console.log(document.getElementById('map'));
        var map = new google.maps.Map(document.getElementById('map'), {
            center: new google.maps.LatLng(-19.257753, 146.823688),
            zoom: 10,
            mapTypeId: 'hybrid'
        });

        var kmlLayer = new google.maps.KmlLayer(propertyData.map_link, {
            suppressInfoWindows: true,
            preserveViewport: false,
            map: map
        });
        kmlLayer.addListener('click', function (event) {
            console.log('click');
            var content = event.featureData.infoWindowHtml;
            var testimonial = document.getElementById('capture');
            testimonial.innerHTML = content;
        });
    }
    return (
        <>
            <section className="banner-map">
                {/* <iframe src="https://greenlightautomotivesolutions.com/testmap/"
                    loading="lazy"
                    width={"100%"}
                    height={"480"}>
                </iframe> */}
                {landDealLoading? 
                <LandDetailLoading cssClass="loading-box-black"/> :
                <>
                    {propertyData.mapright_link == undefined ? <div id="map" style={{ width: "100%", height: "480px" }}></div> : <iframe loading='lazy' frameBorder='0' height='460px' width='100%' src={propertyData.mapright_link}></iframe>}
                </>
            }
                
                

            </section>
        </>
    )
}

export default DetailMap