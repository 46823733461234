import React from 'react'
import Description from '../../Components/LandDetails/Description'
import DocumentLink from '../../Components/LandDetails/DocumentLink'
import Map from '../../Components/LandDetails/Map'
import InvestmentProjection from '../../Components/LandDetails/InvestmentProjection'
import Gallery from '../../Components/LandDetails/Gallery'

const DetailLeftDesc = ({propertyData, landDealLoading}) => {
  return (
    <>
        <div className="dtl-left">
            <Gallery propertyData={propertyData} landDealLoading={landDealLoading}/>
            <Description propertyData={propertyData}/>
            <DocumentLink propertyData={propertyData}/>
            {propertyData.mapright_link !== undefined ? <iframe loading='lazy' frameBorder='0' height='460px' width='100%' src={propertyData.mapright_link}></iframe> : <Map propertyData={propertyData}/>}
        </div>
    </>
  )
}

export default DetailLeftDesc