import mainLogo from '../../assets/img/logo-powerbook-Logo-v1.svg';
import messageIcon from '../../assets/img/icon-message.png';
import userIcon from '../../assets/img/icon-user.png';
import iconLogout from '../../assets/img/icon-logout.svg';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useState, useContext, useEffect, useRef } from 'react';
import { UserContext } from '../../context/UserContext';
import { ChatContext } from '../../context/ChatContext';
import userIcon2 from '../../assets/img/icon-chat-user.png'
import ChatSmallBox from '../Chat/ChatSmallBox';
import SignIn from '../Frontend/Common/SignIn';
import { io } from 'socket.io-client';
import chatIcon from '../../assets/img/chat-icon.svg';
import WelcomeModal from './WelcomeModal';


const Header = ({ headerHideForMobile }) => {

    /* chat stuff */

    const navigate = useNavigate();
    const getLocation = useLocation();
    const { chatUsers, chatDataByUser, chatList, onlineChatUsers, unreadMessages, socketRef, closeSmChat, setChatUsers, sendChat, uploadDocument, chatAttachmentInfo, unreadByUser, markChatRead } = useContext(ChatContext);

    const openChatSmBox = (with_user) => {
        console.log(with_user, chatUsers);

        var is_group = with_user.indexOf("group") >= 0;

        if (getLocation.pathname !== '/chat-standalone') {

            if (chatUsers.includes(with_user)) {

                console.log("removing one", with_user);

                var chat_users = chatUsers.filter(name => name !== with_user);
                console.log(chat_users);
                setChatUsers(chat_users);
            } else {
                socketRef.current.emit('message', {
                    chatCommand: "getSingleChatHistory",
                    with_user: is_group ? with_user.replace('group', '') : with_user,
                    is_group: is_group
                });

                if (unreadByUser[with_user] > 0) markChatRead(with_user, is_group);
            }
        }
        else {
            navigate('/chat-standalone', { state: { 'name': with_user } })
        }
    }

    const [isLoading, setIsLoading] = useState(false);

    const uploadFile = (file, chatUser) => {
        console.log(file);

        setIsLoading(true);
        if (chatUser && file[0]) {

            console.log(file[0]);

            var fileSize = 0;
            var messageType = "";
            // if(['application/pdf'].includes(file[0].type)>-1){
            if ((file[0].type).includes('application') || (file[0].type).includes('text')) {
                fileSize = ((file[0].size / (1024 * 1024)));
                var ext = (fileSize <= 0) ? "KB" : "MB";
                fileSize = (fileSize.toFixed(2)).padStart(2, ' ') + ext;
                messageType = "pdf"
            }

            if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file[0].type) > -1) {
                messageType = "png"
            }

            const formData = new FormData();
            formData.append('file', file[0]);
            formData.append('folder', 'chatfiles/' + user.username + '/');
            formData.append('fileName', file[0].name);
            formData.append('base_url', true);

            const upload = uploadDocument(formData);
            upload.then((data) => {
                if (data.url) {
                    sendChat("", chatUser, data.url);

                } else if (data.error) alert(data.error);
            });

            setIsLoading(false);
        }

    }


    /* chat stuff */

    const { user, logout } = useContext(UserContext);
    const [isMob, setisMob] = useState(false);
    const onChangeBodyClass = (value) => {
        setisMob(value);
        value
            ? document.body.classList.add('overflow-active')
            : document.body.removeAttribute('class');
    };

    const [signIn, setSignIn] = useState(false);
    const openSignInModal = () => {
        setSignIn(true);
    }

    const queryParameters = new URLSearchParams(window.location.search)
    const filter_properties = queryParameters.get("filter_properties");

    // Welcome Modal
    const [openWelcome, setOpenWelcome] = useState(false);
    const closeWelcome = () => {
        localStorage.setItem('hideWelcomeModal', 'true');
        setOpenWelcome(false)
    }
    // const dontShow = () => {
    //     setOpenWelcome(false)
    // }
    // useEffect(() => {
    //     const isModalHidden = localStorage.getItem('hideModal');

    //     // Check if the modal should be hidden based on local storage
    //     if (isModalHidden) {
    //       const hideUntil = new Date(isModalHidden).getTime() + 24 * 60 * 60 * 1000; // 1 day
    //       const now = new Date().getTime();

    //       if (now < hideUntil) {
    //         setOpenWelcome(false);
    //       }
    //     }
    //   }, []);
    useEffect(() => {
        const isModalHidden = localStorage.getItem('hideWelcomeModal');

        // Check if the modal should be hidden based on local storage
        if (!isModalHidden) {
            setTimeout(() => {
                setOpenWelcome(true);
            }, 2000); // 2000 milliseconds = 2 seconds
        }
    }, []);
    const dontShow = () => {
        // Store the information that the user clicked on the checkbox
        localStorage.setItem('hideWelcomeModal', 'true');
    };
    const proceedBtn = () => {
        // Store the information that the user clicked on the checkbox
        localStorage.setItem('hideWelcomeModal', 'true');
        setOpenWelcome(false);
    };

    return (
        <>
            <header className={`header-wrap ${headerHideForMobile === true ? "hide-for-mobile" : ""}`}>
                <div className="container-fluid ps-5 pe-5">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="main-logo">
                            <Link to="/properties"><img src={mainLogo} alt="logo" /></Link>
                        </div>
                        <div className={`d-flex align-items-center justify-content-between menu-block ${isMob ? 'active' : ''}`}>
                            <div className="actions-block order-2 hide-in-mob">
                                {/* <div className="d-inline-flex align-items-center">
                                    <button type="button" className="position-relative icon-btn">
                                        <img src={wrenchIcon} alt="wrench" />
                                        <span className="position-absolute top-0 start-100 translate-middle bg-danger rounded-circle">
                                            <span>{unreadMessages}</span>
                                        </span>
                                    </button>
                                    <button type="button" className="position-relative icon-btn">
                                        <img src={mailIcon} alt="mail" />
                                        <span className="position-absolute top-0 start-100 translate-middle bg-danger rounded-circle">
                                            <span>{unreadMessages}</span>
                                        </span>
                                    </button>
                                    <button type="button" className="position-relative icon-btn">
                                        <img src={messageIcon} alt="message" />
                                        <span className="position-absolute top-0 start-100 translate-middle bg-danger rounded-circle">
                                            <span>{unreadMessages}</span>
                                        </span>
                                    </button>
                                    <button type="button" className="position-relative icon-btn">
                                        <img src={alertIcon} alt="alert" />
                                        <span className="position-absolute top-0 start-100 translate-middle bg-warning rounded-circle">
                                            <span>{unreadMessages}</span>
                                        </span>
                                    </button>
                                </div> */}
                                <div className="btn-group custom-dropdown notifaction-dropdown ms-5 hide-in-mob position-relative">
                                    <button type="button" className="position-relative icon-btn dropdown-toggle" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <img src={messageIcon} alt="message" />
                                        <span className="position-absolute top-0 start-100 translate-middle bg-danger rounded-circle">
                                            <span>{unreadMessages}</span>
                                        </span>
                                    </button>
                                    <ul aria-labelledby="dropdownMenu2" className="chat-notifications-list dropdown-menu pb-2">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className='rc-chat-title'>RECENT CHATS</div>
                                            <div>
                                                <Link to="/chat-standalone?new_group" className='nt-link'>New Group</Link>
                                                <Link to="/chat-standalone?new_chat" className='nt-link ms-4'>New Message</Link>
                                            </div>
                                        </div>
                                        {chatList.length > 0 ?
                                            <>
                                                <div className="chat-list notifaction-list mt-2">
                                                    {chatList.map((item, index) =>
                                                        <button type="button" key={index} className="d-flex w-100" onClick={() => { openChatSmBox(item.from_user); }}>
                                                            <div className="flex-shrink-0">
                                                                <span className='user-icon'><img src={userIcon2} alt="user" /></span>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h3>{item.from_user_fullname} - {item.from_user_company}<span className={`status-circle ${onlineChatUsers.includes(item.from_user) ? "green" : "red"}`}></span></h3>
                                                                <p>{item.msg}</p>
                                                            </div>
                                                            <div className='flex-grow-1 text-end pt-1'>
                                                                <div className='chat-time text-gray'>{item.relative_time} ago</div>
                                                            </div>
                                                        </button>
                                                    )
                                                    }
                                                </div>
                                                <div className='see-all w-100'>
                                                    <Link to="/chat-standalone" className='see-all-btn'>See All</Link>
                                                </div>
                                            </>
                                            :
                                            <div className='no-chat-block pt-5 pb-5'>
                                                <div className='text-center'>
                                                    <img src={chatIcon} />
                                                </div>
                                                <div className='md-title text-uppercase text-center mt-3'>No Chats yet</div>
                                                <div className='content mt-3 text-center'>
                                                    <p className='font-16 text-center text-white fw-300 no-sm-chat-text'>Start a new chat by clicking <Link to="/chat-standalone?new_chat">New Message</Link> above</p>
                                                </div>
                                            </div>
                                        }
                                    </ul>
                                </div>
                                {user && user.username != "publicuser" ?
                                    <div className="btn-group custom-dropdown ms-5 hide-in-mob">
                                        <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <img src={userIcon} alt="user" />
                                            Welcome {user && user.first_name !== undefined ? user.first_name : ""} <span className="down-arrow"></span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            {/*<li><NavLink to="/profile" className="dropdown-item" >Profile</NavLink></li>*/}
                                            <li><NavLink to="/properties/admin/select-property" className="dropdown-item" >Manage Properties</NavLink></li>
                                            <li><NavLink to="/properties/admin/preferences" className="dropdown-item" >Preferences</NavLink></li>
                                            {/*<li><NavLink to="/properties/admin" className="dropdown-item" >Admin</NavLink></li>*/}
                                            <li><button type="button" className="dropdown-item" onClick={() => logout()}>Logout</button></li>
                                        </ul>
                                    </div>
                                    :
                                    <div className="btn-group custom-dropdown ms-5 hide-in-mob">
                                        <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <img src={userIcon} alt="user" />
                                            Welcome <span className="down-arrow"></span>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><NavLink to="/properties/" className="dropdown-item" >View Properties</NavLink></li>
                                            <li><button type="button" className="dropdown-item" onClick={openSignInModal}>Sign In</button></li>
                                        </ul>
                                    </div>
                                }
                            </div>
                            <div className='main-menu-block'>
                                <div className="main-menu">
                                    <div className='heading-for-mob d-none'>Main</div>
                                    {user && user.username != "publicuser" && <ul className="d-inline-flex align-items-center">
                                        {/*<li>
                                            <NavLink to="/properties/live-auctions/" className={({isActive}) => (isActive ? "active" : 'none')}>All Auctions</NavLink>
                                        </li>
                                         <li>
                                            <NavLink to="/properties/" className={({isActive}) => (isActive ? "active" : 'none')}>All Properties</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/properties/?filter_properties&" className={({isActive}) => (isActive ? "active" : 'none')}>My Properties</NavLink>
                                        </li> */}
                                        <li>
                                            <NavLink to="/properties" caseSensitive end className={({ isActive }) => (isActive && filter_properties === null ? "active" : 'none')}>All Properties</NavLink>
                                        </li>
                                        {/*<li>
                                            <NavLink to="/properties/?filter_properties&" caseSensitive end className={({ isActive }) => (isActive && filter_properties !== null ? "active" : 'none')}>My Properties</NavLink>
                                        </li>*/}
                                        <li>
                                            <NavLink to="/properties/add-my-property" className={({ isActive }) => isActive ? "active" : 'none'}>Add My Property</NavLink>
                                        </li>
                                    </ul>}
                                </div>
                                <div className="main-menu my-account-menu d-none mt-5">
                                    <div className='heading-for-mob d-none'>My Account</div>

                                    {user && user.username != "publicuser" ?
                                        <ul className="d-inline-flex align-items-center">
                                            {/*<li><NavLink to="/profile" className="dropdown-item" >Profile</NavLink></li>*/}
                                            <li><NavLink to="/properties/admin/select-property" className="dropdown-item" >Manage Properties</NavLink></li>
                                            <li><NavLink to="/properties/admin/preferences" className="dropdown-item" >Preferences</NavLink></li>
                                            {/*<li><NavLink to="/properties/admin" className="dropdown-item" >Admin</NavLink></li>*/}
                                            <li>
                                                <NavLink to="/chat-standalone" className="dropdown-item">Chat
                                                    <span className="position-relative rounded-circle mob-badge bg-danger text-white">
                                                        <span>{unreadMessages}</span>
                                                    </span>
                                                </NavLink>
                                            </li>
                                            {/* <li><button type="button" className="dropdown-item" onClick={() => logout()}>Logout</button></li> */}
                                        </ul> :

                                        <ul className="d-inline-flex align-items-center">
                                            <li><NavLink to="/properties/" className="dropdown-item" >View Properties</NavLink></li>
                                            {/* <li><button type="button" className="dropdown-item" onClick={openSignInModal}>Sign In</button></li> */}
                                        </ul>
                                    }
                                </div>
                            </div>

                            {user && user.username != "publicuser" ? <div className='logout-block d-none'><button type="button" onClick={() => logout()}><img src={iconLogout} /> Logout</button></div> : 
                            <div className='logout-block d-none'><button type="button" onClick={() => openSignInModal()}><img src={userIcon} alt="user" /> Sign In</button></div>  }
                        </div>
                        <div className={`overflow-bg d-none ${isMob ? 'active' : ''
                            }`}></div>
                        <div
                            className={`mobile-inner-header ${isMob ? 'active' : ''}`}
                            onClick={() => onChangeBodyClass(!isMob)}
                        >
                            <div
                                className={`mobile-inner-header-icon mobile-inner-header-icon-out ${isMob ? 'active' : ''
                                    }`}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className={`sm-chats-block ${chatUsers.length > 0 ? "open" : ""}`}>
                <div className='d-flex flex-row-reverse'>
                    {getLocation.pathname !== '/chat-standalone' && chatUsers.map((user, index) =>
                        <ChatSmallBox
                            key={index}
                            chatUser={user}
                            chatData={chatDataByUser[user]}
                            closeSmChat={closeSmChat}
                            sendChat={sendChat}
                            uploadFile={uploadFile}
                            isLoading={isLoading}
                            chatAttachmentInfo={chatAttachmentInfo}
                        />
                    )}
                </div>
            </div>

            <SignIn signIn={signIn} setSignIn={setSignIn} />

            {user && user.username != "publicuser" &&
                <WelcomeModal
                    open={openWelcome}
                    close={closeWelcome}
                    dontShow={dontShow}
                    proceedBtn={proceedBtn}
                />}
        </>
    )
}

export default Header