export const floatFormatter = (value, currency = false) => {
    var num = value ? value.toString().replace(/[^0-9\.]+/g,"") : 0;
    var thousands = num > 1000;
    var millions = num > 1000000;

    if(num == 0) return "-";

    if(millions) num = Math.round(num / 100000) / 10;
    else if(thousands) num = Math.round(num / 100) / 10;
    
    var sign = num >= 0 ? "" : "-";
    var str = num.toString().replace("$", ""), parts = false, output = [], i = 1, formatted = null;
    if(str.indexOf(".") > 0) {
        parts = str.split(".");
        str = parts[0];
    }
    str = str.split("").reverse();
    for(var j = 0, len = str.length; j < len; j++) {
        if(str[j] != ",") {
            output.push(str[j]);
            if(i%3 == 0 && j < (len - 1)) {
                output.push(",");
            }
            i++;
        }
    }
    formatted = output.reverse().join("");
    return((currency ? "$" : "") + sign + formatted + ((parts) ? "." + parts[1].substr(0, 2) : "") + (millions ? "m" : (thousands ? "k" : "")));
}

export const intFormatter = (value, currency = false) => {
    var num = value ? value.toString().replace(/[^0-9\.]+/g,"") : 0;
    //var thousands = num > 1000;

    if(num == 0) return "-";

    //if(thousands) num = Math.round(num / 100) / 10;
    
    var sign = num >= 0 ? "" : "-";
    var str = num.toString().replace("$", ""), parts = false, output = [], i = 1, formatted = null;
    if(str.indexOf(".") > 0) {
        parts = str.split(".");
        str = parts[0];

        parts = false;
    }
    str = str.split("").reverse();
    for(var j = 0, len = str.length; j < len; j++) {
        if(str[j] != ",") {
            output.push(str[j]);
            if(i%3 == 0 && j < (len - 1)) {
                output.push(",");
            }
            i++;
        }
    }
    formatted = output.reverse().join("");
    return((currency ? "$" : "") + sign + formatted + ((parts) ? "." + parts[1].substr(0, 2) : ""));
}

export const byteFormatter = (bytes) => {
    var units = ['B', 'KB', 'MB', 'GB', 'TB']; 

    var bytes = Math.max(bytes, 0); 
    var pow = Math.floor((bytes ? Math.log(bytes) : 0) / Math.log(1024)); 
    var pow = Math.min(pow, units.length - 1); 

    var result = bytes / Math.pow(1024, pow);

    return Math.round(result * 10) / 10 + ' ' + units[pow]; 
}