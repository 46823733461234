import thumbImg1 from '../../assets/img/img1.jpg'
import thumbImg2 from '../../assets/img/thumb-img1.jpg'
import thumbImg3 from '../../assets/img/thumb-img2.jpg'
import thumbImg4 from '../../assets/img/thumb-img3.jpg'
import LandDetailLoading from '../Common/LandDetailLoading'
import Fancybox from './Fancybox'

const Gallery = ({propertyData, liveAuction, landDealLoading}) => {
    const imgs= propertyData && propertyData.images.length > 0 ? propertyData.images : [
        thumbImg1,
        thumbImg2,
        thumbImg3,
        thumbImg4,
        thumbImg1,
        thumbImg2,
        thumbImg3,
        thumbImg4,
        thumbImg1,
        thumbImg2,
        thumbImg3,
        thumbImg4
    ]
    const moreImg = imgs.length - 4;
    return (
        <>
            <div className={`${liveAuction? '' : "gray-box"}`}>
                {liveAuction? '' : (<div className="box-title text-uppercase">Photos</div>)}
                <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}
                >
                    {landDealLoading? 
                        <LandDetailLoading/> : 
                        <>
                            <div className="d-flex flex-wrap mt-2">
                                <div className="col-12 full-photo photo-full-width">
                                            <a data-fancybox="gallery" href={imgs[0]}  className='position-relative'>
                                                <img src={propertyData && propertyData.images[0] !== undefined ? propertyData.images[0] : thumbImg1} alt="th img" />
                                            </a>
                                </div>
                                <div className='d-flex photos-list'>
                                    
                                    {imgs &&
                                        imgs.slice(1).map((item, index) => {
                                        return (
                                            <div className="col-3" key={index}>
                                                <a data-fancybox="gallery" href={item}  className='position-relative'>
                                                    <img src={item} alt="th img" />
                                                    {index >= 3 ? <div className='countimg'>+{moreImg}</div> : ""}
                                                    
                                                </a>
                                            </div>
                                        );
                                    })}
                                    {/* <div className="col-3">
                                        <a data-fancybox="gallery" href={thumbImg2} >
                                            <img src={thumbImg2} alt="th img" />
                                        </a>
                                    </div>
                                    <div className="col-3">
                                        <a data-fancybox="gallery" href={thumbImg3} >
                                            <img src={thumbImg3} alt="th img" />
                                        </a>
                                    </div>
                                    <div className="col-3">
                                        <a data-fancybox="gallery" href={thumbImg4} className='position-relative' >
                                            <img src={thumbImg4} alt="th img" />
                                            <div className='countimg'>+1</div>
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </>
                    }
                </Fancybox>
            </div>
        </>
    )
}

export default Gallery