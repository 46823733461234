import { Link } from 'react-router-dom'
import {floatFormatter, intFormatter} from '../Utils/NumberFormatter.js'

const SimilarListings = ({propertyData}) => {
    return (
        <>
            <div className="gray-box pe-2 similar-listing-box">
                <div className="box-title text-uppercase">Similar Listings</div>
                <div className="sl-block">
                    {propertyData && propertyData.similar_listings !== undefined && propertyData.similar_listings.map((v,i) => {
                        return (
                            <Link to={"/properties/land-details?place_id=" + v.place_id} className='sl-link' key={i + "_link"}>
                                <div className="d-flex align-items-center sl-flex">
                                    <div className="sl-img">
                                        <img src={v.primary_image} alt="large" />
                                    </div>
                                    <div className="sl-content">
                                        <div className="xs-title fw-500 font-14 text-start theme-text">{v.city}</div>
                                        <div className="lb-title mt-1">Acres: <span className="text-white">{floatFormatter(v.acreage)}</span></div>
                                        {/*<div className="d-flex align-items-end justify-content-between mt-1">
                                            <div className="lb-title text-orange">List Price</div>
                                            <div className="xs-title font-18 text-white">{floatFormatter(v.list_price,true)}</div>
                                        </div>*/}
                                    </div>
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default SimilarListings