import closeIcon from '../../../assets/img/close-modal.svg'
import iconPdf from '../../../assets/img/icon-pdf.svg'
import iconExcel from '../../../assets/img/icon-excel.svg'
import iconPhoto from '../../../assets/img/icon-photo.svg'
import iconLink from '../../../assets/img/icon-link.svg'
import iconWord from '../../../assets/img/icon-word.svg'
import { useState } from 'react'

const RegisterModal = ({registerModal, setRegisterModal, registerForm}) => {
    const closeRegisterModal = () => {
        setRegisterModal(false);
    }

    const [formData, setFormData] = useState({
        
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.name == "terms" ? e.target.checked : e.target.value
        })
    }

    return (
        <>
            <div className={`modal fade custom-modal common-modal ${registerModal ? "show" : ""}`} id="RegisterModal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="RegisterModalLabel" aria-hidden="true">
                <div className="modal-dialog mxw-820">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={closeRegisterModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-40">Sign Up</div>
                            <form className='custom-form front-custom-form' onSubmit={(e) => {e.preventDefault(); console.log(formData); registerForm(formData);}}>
                                <div className='row mlr-35'>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>First Name</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="first_name"/>
                                    </div>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>Last Name</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="last_name"/>
                                    </div>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>Email</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="email"/>
                                    </div>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>Company</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="company"/>
                                    </div>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>Phone</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="phone"/>
                                    </div>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>City</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="city"/>
                                    </div>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>State</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="state"/>
                                    </div>
                                    <div className='col-md-6 form-group plr-35'>
                                        <label>Zip Code</label>
                                        <input type='text' className='form-control' required onChange={onChangeInput} name="zip"/>
                                    </div>
                                    <div className='col-12 plr-35 text-center'>
                                        <button className='theme-btn lg-btn text-uppercase w-250 mt-5' type="submit">Sign Up</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterModal