import React from 'react'

const AuctionType = () => {
    return (
        <>
            <div className="gray-box pe-2">
                <div className="box-title text-uppercase">Auction Type <span className="info-icon ms-2"></span></div>
                <div className="at-scroll mt-4">
                    <ul className="at-list">
                        <li><span className="at-circle green-circle">Green</span> Clean Title, No Issues</li>
                        <li><span className="at-circle yellow-circle">Yellow</span> Clean Title, No Issues</li>
                        <li><span className="at-circle red-circle">Red</span> Clean Title, No Issues</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AuctionType