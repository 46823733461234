
import { Field, Formik, Form } from 'formik'
import TextAreaField from "../../../Components/Form/TextAreaField";
import closeIcon from '../../../assets/img/close-modal.svg'
import starBlank from '../../../assets/img/star-blank.svg'
import starFill from '../../../assets/img/star-fill.svg'
import Rating from 'react-rating';
import {useState, useRef } from 'react';
import * as yup from "yup";
const validationSchema = yup.object({
    review: yup
      .string()
      .required("This field is required."),
})
const WriteReviewModal = ({ addReviewModal, setAddReviewModal, submitReview }) => {
    const closeAddReviewModal = () => {
        setAddReviewModal(false);
    }

    const [formikData, setFormikData] = useState({
        'review':'',
        'stars': 0
    });

    const formikRef = useRef(null);
    return (
        <>
            <div className={`modal fade custom-modal common-modal write-review-modal ${addReviewModal ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-450 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile text-uppercase">Add Review</div>
                            <button type="button" className="btn-close" onClick={closeAddReviewModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={closeAddReviewModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-26 text-uppercase">Add Review</div>
                            <Formik
                                initialValues={formikData}
                                validationSchema={validationSchema}
                                onSubmit={(values,{resetForm}) => {
                                    submitReview(values);
                                    resetForm()
                                }}
                                innerRef={formikRef}
                                enableReinitialize={true}
                            >
                                {({ values }) => (
                                    <Form>
                                        <Field type="hidden" name="stars" />
                                        <div className='form-group mb-3'>
                                            <label>Write a Review</label>
                                            <TextAreaField className="form-control" placeholder="Ask the Seller a question" name="review"/>
                                        </div>
                                        <div className="gray-box">
                                            <div className="xs-title text-start fw-500">How would you rate it?</div>
                                            {/* https://www.npmjs.com/package/react-rating */}
                                            <div className='mt-2 give-rating'>
                                                <Rating initialRating={values.stars}
                                                    emptySymbol={<img src={starBlank} className="icon" />}
                                                    fullSymbol={<img src={starFill} className="icon" />}
                                                    name="rating"
                                                    onChange={(val) => {values.stars = val;}} 
                                                    value={values.stars}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-4 row">
                                            <div className='col-md-6'>
                                                <button className="w-100 theme-trans-btn text-uppercase" type="button" onClick={closeAddReviewModal}> Cancel</button>
                                            </div>
                                            <div className='col-md-6'>
                                                <button className="w-100 theme-btn text-uppercase" type="submit"> Submit</button>
                                            </div>
                                        </div>
                                    </Form>
                                )}</Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WriteReviewModal