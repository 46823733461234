import React, {useContext} from 'react'
import Header from '../../Components/Frontend/Common/Header'
import Footer from '../../Components/Frontend/Common/Footer'
import bannerImg from '../../assets/img/frontend/Hero-Img.jpg'
import spImg from '../../assets/img/frontend//showcase-properties.png'
import serviceIcon1 from "../../assets/img/service-icon1.svg"
import serviceIcon2 from "../../assets/img/service-icon2.svg"
import serviceIcon3 from "../../assets/img/service-icon3.svg"
import JoinMembership from './JoinMembership'
import { UserContext } from '../../context/UserContext'

const FrontendRegister = () => {
    const {registrationForm} = useContext(UserContext);

    const registerForm = async (registerFormData) => {
        console.log(registerFormData);

        const data = await registrationForm(registerFormData);
        if(data.success) {
            alert("Thanks for registering! We will reach out soon");
        } else if(data.error)
            alert(data.error);        
    }

    return (
        <>
            <Header />
            <section className='front-banner-wrap pre-login-banner wow fadeIn' data-wow-delay="0.1s" style={{ background: `url(${bannerImg}) no-repeat center center` }}>
                <div className='container'>
                    <div className='d-flex align-items-center'>
                        <div className='w-40'>
                            <div className='fb-heading text-start'>Join Our <br />Membership</div>
                            <div className='md-title text-start fw-300 mt-4 mb-5'>To list your Properties</div>
                        </div>
                        <div className='w-60 join-form-box'>
                            <JoinMembership cssClassName="join-membership-top" registerForm={registerForm}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='services-wrap'>
                <div className='container'>
                    <div className='mlr-40 row'>
                        <div className='col-lg-4 col-12 plr-40'>
                            <div className='gray-box'>
                                <div className='d-block'>
                                    <div className='text-center service-icon'>
                                        <img src={serviceIcon1} alt='icon'/>
                                    </div>
                                    <div className='ss-md-title pt-0 text-uppercase text-center font-20'>Market Your <br/>Property Listings</div>
                                </div>
                                <div className='common-text text-center mt-4'>
                                    <p>Your properties can easily be sent to our community or just posted to accept offers directly from other members.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12 plr-40'>
                            <div className='gray-box'>
                                <div className='d-block'>
                                    <div className='text-center service-icon'>
                                        <img src={serviceIcon2} alt='icon'/>
                                    </div>
                                    <div className='ss-md-title pt-0 text-uppercase text-center font-20'>Document <br/>Management</div>
                                </div>
                                <div className='common-text text-center mt-4'>
                                    <p>Your properties can easily be sent to our community or just posted to accept offers directly from other members.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-12 plr-40'>
                            <div className='gray-box'>
                                <div className='d-block'>
                                    <div className='text-center service-icon'>
                                        <img src={serviceIcon3} alt='icon'/>
                                    </div>
                                    <div className='ss-md-title pt-0 text-uppercase text-center font-20'>Connect with <br/>Landowners</div>
                                </div>
                                <div className='common-text text-center mt-4'>
                                    <p>Your properties can easily be sent to our community or just posted to accept offers directly from other members.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='privacy-doc-wrap front-common-block'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-12 order-2 wow bounceInUp' data-wow-delay="0.2s">
                            <div className='sp-img'>
                                <img src={spImg} alt='Showcase Properties' />
                            </div>
                        </div>
                        <div className='col-lg-6 col-12 wow bounceInUp' data-wow-delay="0.3s">
                            <div className='fb-heading text-start'>Showcase Properties</div>
                            <ul className='common-list mb-3'>
                                <li>List your Property</li>
                                <li>Store Documents</li>
                                <li>Share with Prospects</li>
                            </ul>
                            <div className='mxw-80 common-text'>
                                <p>We support Property Owners first. We’re here to help connect you to a community of landowners empowering you to easily showcase your properties and sell directly saving everyone time & money.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='join-membership-wrap'>
                <div className='container'>
                    <div className='fb-heading text-center mb-5'>Join Our Membership</div>
                    <div className='mxw-680'>
                        <JoinMembership cssClassName="join-membership-bottom" registerForm={registerForm}/>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default FrontendRegister