import React from 'react'
import { Field, Form, Formik } from 'formik'
import closeIcon from '../../assets/img/close-modal.svg'
import imgPdfIcon from '../../assets/img/file-photo/file-pdf.svg'
const EmailPrintOutModal = ({ emailPrintout, setEmailPrintout, propertyData, emailPdfFile, isSending}) => {
    const closeEmailPrintOutModal = () => {
        setEmailPrintout(false);
    }
    return (
        <>
            <div className={`modal fade custom-modal common-modal save-modal ${emailPrintout ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-450 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile text-uppercase">Email Printout</div>
                            <button type="button" className="btn-close" onClick={closeEmailPrintOutModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={closeEmailPrintOutModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-26 text-uppercase">Email Printout</div>

                            <Formik
                                // validationSchema={validationSchema}
                                initialValues={{
                                    customeremail: propertyData.broker_email,
                                    agentemail: propertyData.broker_email,
                                    subject: "Check out " + propertyData.property_name,
                                    message: "Check out " + propertyData.property_name

                                }}

                                onSubmit={(values) => {
                                    emailPdfFile(values);
                                }}
                            >
                                {({
                                    values
                                }) => (
                                    <Form className='custom-form'>
                                        <div className='form-group'>
                                            <label className="font-13 mb-1">Customer Email</label>
                                            <Field
                                                name="customeremail"
                                                type='text'
                                                className="form-control"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label className="font-13 mb-1">Agent Email</label>
                                            <Field
                                                name="agentemail"
                                                type='text'
                                                className="form-control"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label className="font-13 mb-1">Subject</label>
                                            <Field
                                                name="subject"
                                                type='text'
                                                className="form-control"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <label className="font-13 mb-1">Message</label>
                                            <Field
                                                name="message"
                                                className="form-control"
                                                placeholder=""
                                                component="textarea" rows="2"
                                            />
                                        </div>
                                        <div className='form-group'>
                                            <div className='email-pdf'><img src={imgPdfIcon} alt='pdf'/> landdetailPdf.pdf</div>
                                        </div>
                                        {!isSending && <button type="submit" className="theme-btn w-100 md-btn font-14 text-uppercase mt-4">Send Email</button>}
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EmailPrintOutModal
