import { useState, useContext } from 'react'
import closeIcon from '../../../assets/img/close-modal.svg'
import {EnvContext} from '../../../context/EnvContext'

const SmShareModal = ({smShareModal, setSmShareModal, propertyData}) => {
    const { landdealBaseURL } = useContext(EnvContext);

    const closeSmShareModal = () => {
        setSmShareModal(false)
    }

    const [showCopyText, setShowCopyText] = useState(false);
    return (
        <>
            <div className={`modal fade custom-modal common-modal sm-share-modal ${smShareModal ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-680 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile text-uppercase">Share Property</div>
                            <button type="button" className="btn-close" onClick={closeSmShareModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={closeSmShareModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-26 text-uppercase">Share Property</div>
                            <div className="gray-box pd-20-50">
                                <div className='xs-title text-start fw-500'>Copy the link and share with your prospects</div>
                                <div className="custom-form mt-3">
                                    <div className="form-group mb-0">
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='position-relative'>
                                                    <input type="text" className="form-control copy-link" placeholder={landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id}/>
                                                    <button type='button' className='copy-icon' onClick={() => {navigator.clipboard.writeText(landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id); setShowCopyText(true);}}></button>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <button type="buttton" className="theme-btn text-uppercase w-100 fw-700 md-btn" onClick={() => {navigator.clipboard.writeText(landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id); setShowCopyText(true);}}>Copy Link</button>
                                            </div>
                                        </div>
                                        {showCopyText && <div className='mt-2 font-13 hide'><span className='chk-icon'></span> Link has been copied to your clipboard</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmShareModal