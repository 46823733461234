import React from 'react'

const Description = ({propertyData}) => {
  return (
    <>
        <div className="gray-box">
                    <div className="box-title text-uppercase">Description</div>
                    <div className="sm-title fw-500 text-start mt-4">{propertyData.property_name}</div>
                    <div className="content mt-2">
                        <p>{propertyData.description} <br /><a
                                href="#" className="theme-text text-decoration-none">Read More</a></p>
                    </div>
                </div>
    </>
  )
}

export default Description