import { ErrorMessage, useField } from "formik";

export const ValidationError = (props) => {
  const [field, meta] = useField(props);
  return (
    <>
      <ErrorMessage
        name={field.name}
        render={(msg) => (
          <>
            <label htmlFor={field.name} className="error text-danger mt-1">
              {msg}
            </label>
          </>
        )}
      />
    </>
  );
};
