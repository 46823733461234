
import { Formik, Form, Field, FieldArray } from "formik";
import * as yup from "yup";
import TextAreaField from "../Form/TextAreaField";
import closeIcon from '../../assets/img/close-modal.svg'
import { useState } from "react";
const ZoomTextareaModal = ({ 
    openZoomModal,
    setOpenZoomModal,
    zoomText,
    updateZoomText
}) => {

    return (
        <>
            <div className={`modal fade custom-modal share-modal ${openZoomModal ? "show" : ""}`} id="shareModal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog mxw-1360">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="text-uppercase">Description</div>
                            <button type="button" className="btn-close" onClick={() => {setOpenZoomModal(false);}}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <textarea
                                type="text"
                                className="form-control h-100vh"
                                placeholder="Write your description"
                                name="description"
                                value={zoomText}
                                onChange={(e) => updateZoomText(e.target.value)}
                            ></textarea>
                            <div className="text-end mt-3">
                                <button type="button" className="trns-edit-btn text-uppercase mnw-180 fw-700" onClick={() => {setOpenZoomModal(false);}}>Cancel</button>
                                <button type="submit" className="theme-btn text-uppercase mnw-180 fw-700" onClick={() => {setOpenZoomModal(false);}}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ZoomTextareaModal