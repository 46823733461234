
import { Formik, Form, Field, FieldArray } from "formik";
import * as yup from "yup";
import TextAreaField from "../Form/TextAreaField";
import closeIcon from '../../assets/img/close-modal.svg'
import { useRef, useState } from "react";
import SelectField from "../Form/SelectField";
const DuplicateListingModal = ({
    duplicateListingModal,
    setDuplicateListingModal,
    closeDuplicateListing,
    allProperties,
    handleDuplicateListing
}) => {
    const formikRef = useRef(null);

    const [formikData, setFormikData] = useState({
        place_id:"",
        relationship:""
    });
    
  const duplicate_list = allProperties?.length > 0 ? allProperties.map((v,i) => {
    return {key:i,value:v.place_id,display:v.property_name}
  }) : [];

  const relationship_list = [
    {key: 1, value : "NONE"},
    {key: 2, value : "SIBLING"},
    {key: 3, value : "CHILD"},
    {key: 4, value : "PARENT"},
  ];
    return (
        <>
            <div className={`modal fade custom-modal common-modal duplicate-listing-modal  ${duplicateListingModal ? "show" : ""}`} id="shareModal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog mxw-700 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile">Duplicate Listing</div>
                            <button type="button" className="btn-close" onClick={closeDuplicateListing}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={closeDuplicateListing}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-3 text-center font-40">Duplicate Listing</div>
                            <div className="content dl-content mb-4"><p>This will create a copy using a previous listing. Select your listing you’d like to duplicate, then you have the option to select the relationship to that copied property.</p></div>
                            <Formik
                                initialValues={formikData}
                                validationSchema=""
                                enableReinitialize={true}
                                innerRef={formikRef}
                                onSubmit={(values) => {
                                    setDuplicateListingModal(false)
                                    handleDuplicateListing(values);
                                }}
                            >
                                {(formik) => (
                                    <Form className='custom-form'>
                                        <div className="dl-gray-box">
                                            <div className='mb-4'>
                                                <SelectField
                                                    name="place_id"
                                                    label="Select Listing to Duplicate"
                                                    placeholder="Select"
                                                    data={duplicate_list}
                                                    required
                                                />
                                            </div>
                                            <div className='rl-field label-d-none'>
                                                <label htmlFor="relationship">
                                                    Relationship to copied listing
                                                    <span className="info-rel position-relative ms-2">
                                                        <span
                                                            className="info-icon"></span>
                                                        <span className="info-pos dlc-info-pos">
                                                            <p className="text-black">This feature allows you to connect this listing being
created to another previous listing. Your options are:</p>  
                                                            <ul>
                                                                <li><strong>None-</strong> No connection</li>
                                                                <li><strong>Sibling-</strong> Equal connection hierarchy</li>
                                                                <li><strong>Child-</strong> The listing you’re creating is a sub property
of the listing you select</li>
                                                                <li><strong>Parent-</strong> The listing you select is a sub property of 
the listing you’re creating </li>
                                                            </ul>
                                                        </span>
                                                    </span>
                                                </label>
                                                <SelectField
                                                    name="relationship"
                                                    placeholder="Select"
                                                    data={relationship_list}
                                                    required
                                                />
                                            </div>
                                            <div className="tip-text text-start">Tip: If you are unsure of hierachy, select “None”</div>
                                        </div>
                                        <div className='text-end mt-5'>
                                            <button className="trns-edit-btn text-uppercase mnw-180 fw-700" type="button" onClick={closeDuplicateListing}>Cancel</button>
                                            <button className='theme-btn text-uppercase sm-btn font-12 mnw-180 fw-400' type="submit">Duplicate Listing</button>
                                        </div>
                                    </Form>
                                )}</Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DuplicateListingModal