import closeIcon from '../../../assets/img/close-modal.svg'
import iconPdf from '../../../assets/img/icon-pdf.svg'
import iconExcel from '../../../assets/img/icon-excel.svg'
import iconPhoto from '../../../assets/img/icon-photo.svg'
import iconLink from '../../../assets/img/icon-link.svg'
import iconWord from '../../../assets/img/icon-word.svg'
import { useState, useContext } from 'react'
import { Field } from 'formik'
import { Link } from 'react-router-dom'
import {UserContext} from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import RegisterModal from './RegisterModal'
import Forgot from './Forgot'


const SignIn = ({signIn, setSignIn}) => {

    const navigate = useNavigate();

    const {loginUser, wait, loggedInCheck, forgotPassword, registrationForm} = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [formData, setFormData] = useState({
        signin_username:'',
        signin_password:'',
        terms:true
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.name == "terms" ? e.target.checked : e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!formData.terms){
            setErrMsg('Please accept terms & conditions');
            return;
        }

        if(formData.signin_username.trim() === '' || formData.signin_password.trim() === ''){
            setErrMsg('Please Fill in all Required Fields!');
            return;
        }

        const data = await loginUser(formData);
        if(data.success){
            e.target.reset();
            setRedirect('Signing in...');
            await loggedInCheck();
            navigate(window.location.pathname == "/" ? "/properties" : window.location.pathname == "/walk-through" ? "/properties" : 0);
            return;
        }
        setErrMsg(data.message);
    }

    const closeSignIn = () => {
        setSignIn(false);
    }
    // const [emailActive, setEmailActive] = useState(false);
    // const activeEmail = () => {
    //     setEmailActive(!emailActive)
    // }
    // const [shareNextStep, setShareNextStep] = useState(false);
    // const shareNextBtn = () => {
    //     setShareNextStep(true)
    // }
    // const shareBackBtn = () => {
    //     setShareNextStep(false)
    // }

    // Register Modal
    const [registerModal, setRegisterModal] = useState(false);
    const openRegisterModal = () => {
        setRegisterModal(true);
        setSignIn(false);
    }

    const registerForm = async (registerFormData) => {
        console.log(registerFormData);

        const data = await registrationForm(registerFormData);
        if(data.success) {
            alert("Thanks for registering! We will reach out soon");
            setRegisterModal(false);
            setSignIn(true);
        } else if(data.error)
            alert(data.error);        
    }

    // Forgot Modal
    const [forgotModal, setForgotModal] = useState(false);
    const forgotPasswordModal = async () => {
        setForgotModal(true);
        setSignIn(false);
    }

    const forgotPasswordForm = async (email) => {
        console.log(email);

        const data = await forgotPassword(email);
        if(data.success) {
             alert("Forgot password email sent");
             setForgotModal(false);
             setSignIn(true);
        } else if(data.error)
            alert(data.error);
    }

    return (
        <>
            <div className={`modal fade custom-modal signin-modal ${signIn ? "show" : ""}`} id="SignIn" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="SignInLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={closeSignIn}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-40">Sign In</div>
                            {errMsg && <p className='text-center text-danger'>{errMsg}</p>}
                            <form className='custom-form front-custom-form' onSubmit={submitForm} method="post">
                                <div className='form-group'>
                                    <label>Username</label>
                                    <input type='text' name="signin_username" className='form-control' onChange={onChangeInput}/>
                                </div>
                                <div className='form-group'>
                                    <label>Password</label>
                                    <input type='password' name="signin_password" className='form-control' onChange={onChangeInput}/>
                                </div>
                                <div className='text-center'>
                                    {redirect ? redirect : <button className='theme-btn lg-btn text-uppercase w-100 mt-5' type="submit">Sign In</button>}
                                </div>
                                <div className="custom-checkbox sign-checkbox mt-4 text-center">
                                    <input type='checkbox' name="terms" value="agree" className="styled-checkbox" id="termsconditions" defaultChecked={true} onChange={onChangeInput}/>
                                    <label htmlFor="termsconditions" className='text-inherit fw-500 mb-0 font-12'> I agree to the <Link to="" className='theme-text text-decoration-none'>Terms & Conditions</Link></label>
                                </div>
                                <div className='d-flex align-items-center mt-5 justify-content-between'>
                                    <div>
                                        <button type='button' onClick={forgotPasswordModal} className='link-btn'>Forgot Password?</button>
                                    </div>
                                    <div>
                                        {/* <Link to="" className='link-btn' onClick={openRegisterModal}>Apply to Join</Link> */}
                                        <button type='button' onClick={openRegisterModal} className='link-btn'>Apply to Join</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <RegisterModal registerModal={registerModal} setRegisterModal={setRegisterModal} registerForm={registerForm}/>
            <Forgot forgotModal={forgotModal} setForgotModal={setForgotModal} forgotPasswordForm={forgotPasswordForm}/>
        </>
    )
}

export default SignIn