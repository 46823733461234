import { useState } from 'react'
import { Link, useHistory, useLocation, useNavigate } from 'react-router-dom'
import userIcon from '../../assets/img/icon-chat-user.svg'
import iconAdd from '../../assets/img/icon-plus.svg'
import backArrow from '../../assets/img/white-back-arrow.png'
import editIcon from '../../assets/img/exitIcon.svg'
import chatIcon from '../../assets/img/lg-chat-icon.svg';

const ChatStandaloneList = ({ chatList, activeChatUser, openChat, unreadByUser, onlineChatUsers, setNewChat, setNewGroup }) => {
    
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const filteredChatList = chatList.filter((item) =>
        item.from_user_fullname.toLowerCase().includes(searchTerm.toLowerCase())
    );

    //console.log(onlineChatUsers);
    // const history = useHistory();
    const navigate = useNavigate();
    const goBack = () => {
      navigate(-1);
    };
    return (
        <>
            <div className="chatlist p-0">
                <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="msg-head d-flex align-items-center justify-content-between d-md-none group-mob-header mb-3">
                            <div className='d-flex align-items-center w-100'>
                                <div className='chat-sm-title text-white fw-500 me-3'>Chat</div>
                            </div>
                            <button onClick={goBack} type='button' className='chat-edit-icon'><img src={editIcon}/></button>
                        </div>
                        <div className="chat-header">
                            <div className="msg-search w-100">
                                <div className='position-relative search-input'>
                                    <input type="text" className="form-control" 
                                     placeholder="Search"
                                     value={searchTerm}
                                     onChange={handleSearchChange} />
                                    <span className="gray-search-icon"></span>
                                </div>
                                <div className="btn-group chat-dropdown">
                                    <button className="add" type='button' data-bs-toggle="dropdown" aria-expanded="false"><img className="img-fluid" src={iconAdd} alt="add" /></button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        {/*<li><Link to="/chat-group" className="dropdown-item">New Group</Link></li>
                                        <li><Link to="/new-chat" className="dropdown-item" type="button">New Chat</Link></li>*/}
                                        <li><button onClick={()=> {setNewGroup(true);}} className="dropdown-item" type="button">New Group</button></li>
                                        <li><button onClick={()=> {setNewChat(true);}} className="dropdown-item" type="button">New Chat</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {chatList?.length ? 
                        <div className="modal-body p-0">
                            <div className="chat-lists">
                                <div className="chat-list">
                                    {/* {chatList.map((item, index) => */}
                                    {filteredChatList.map((item, index) => (
                                        <div key={index} >
                                            <button type="button"  className={`d-flex w-100 ${item.from_user == activeChatUser ? "open" : ""}`} onClick={()=> openChat(item.from_user)}>
                                                <div className="flex-shrink-0">
                                                    <span className='user-icon'><img src={userIcon} alt="user" /></span>
                                                </div>
                                                <div className="flex-grow-1 ms-3 pt-2">
                                                    <h3>{item.from_user_fullname} <span className={`status-circle ${onlineChatUsers.includes(item.from_user) ? "green" : "red"}`}></span></h3>
                                                    <p>{item.company}</p>
                                                </div>
                                                <div className='flex-grow-1 text-end pt-2'>
                                                    <div className='chat-time mb-1'>{item.chattime}</div>
                                                    {unreadByUser[item.from_user] !== undefined && unreadByUser[item.from_user] > 0 ? 
                                                        <div className='unread-count'>{unreadByUser[item.from_user]}</div> : ""}
                                                </div>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="modal-body px-5 py-5">
                                <div className='custom-form chat-custom-form'>
                                    <div className='chat-ssm-title text-center mb-4 fw-300'>Start your first Chat</div>
                                    <button onClick={()=> {setNewChat(true);}} type="button" className='chat-theme-btn w-100 ms-0 mb-3'>Start New Chat</button>
                                    <div className='chat-border-or'>
                                        <div className='chat-or-circle'>OR</div>
                                    </div>
                                    <button onClick={()=> {setNewGroup(true);}} type="button" className='chat-theme-btn w-100 ms-0 mt-3'>Start Group Chat</button>
                                </div>
                                <div className='no-chat-block pt-5 pb-5 d-md-none'>
                                    <div className='no-chat-box pt-5 pb-5'>
                                        <div className='text-center'>
                                            <img src={chatIcon} />
                                        </div>
                                        <div className='md-title text-uppercase text-center mt-3'>No Chats yet</div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatStandaloneList