
import { NavLink } from "react-router-dom";
import iconUsers from "../../assets/img/icon-users.svg";
import iconCard from "../../assets/img/icon-card.svg";

const AdminLeft = ({cardTitle, userCompany}) => {
    return (
        <>
            <div className="apb-left">
                <div className="apb-transparent-box">
                    <div className="xs-title text-gray font-14 fw-500 mb-2 text-start">
                        {cardTitle? cardTitle : "Property Settings for:"}
                    </div>
                    <div className="xs-title text-uppercase text-white text-start font-16">
                        {userCompany ? userCompany : "Delta Southern, LLC"}
                    </div>
                </div>
                <ul className="apb-menu up-flex-tab mt-5">
                    <li>
                        <NavLink to="/properties/admin/preferences">
                            <span>
                                <img src={iconUsers} alt='viewonly' />
                            </span>{" "}
                            Users & Permissions
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/properties/admin/payment">
                            <span>
                                <img src={iconCard} alt="card" />
                            </span>{" "}
                            Payment
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="/web-analytics"> 
                        <NavLink>
                            <span>
                            <img src={iconSubcompany} alt="sub company" />
                            </span>{" "}
                            Sub Companies
                        </NavLink>
                    </li> */}
                </ul>
            </div>
        </>
    )
}

export default AdminLeft