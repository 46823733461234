import {createContext} from 'react'

export const EnvContext = createContext();

export const EnvContextProvider = ({children}) => {

    const landdealBaseURL = window.location.host == "localhost:3000" || window.location.host == "dev.landdeal.com" ? "https://dev.landdeal.com/" : "https://landdeal.com/";

    console.log(landdealBaseURL);

    const s3FileUpload = async (selectedFile,folder='propertyfiles') => {
        // Step 1 - get pre-signed POST data.
  
        //console.log(selectedFile);
  
        console.log(folder);
  
        const presignedPostData = await getPresignedPostData(selectedFile,folder);

        console.log(presignedPostData);
  
        //console.log(presignedPostData)
        // Step 2 - upload the file to S3.
        try {
            //const { file } = selectedFile.src;
            //console.log(file);
            //console.log(selectedFile.src);
            var filename = await uploadFileToS3(presignedPostData, selectedFile.src);
            var url = 'https://landdeal.s3.us-west-2.amazonaws.com/' + filename;
            console.log("File was successfully uploaded!",url);
            //setData([...imgURLs, url]);
            //nextStep(0,{"images":imgURLs});
  
            return url;
        } catch (e) {
            console.log("An error occurred!", e.message);
        }
    }
  
    const getPresignedPostData = (selectedFile,folder) => {
  
        return new Promise(resolve => {
          const xhr = new XMLHttpRequest();
          
          // Set the proper URL here.
          const url = landdealBaseURL + "bridge/property/s3_upload.php";
          
          xhr.open("POST", url, true);
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.send(
            JSON.stringify({
              name: selectedFile.name,
              type: selectedFile.type,
              folder: folder
            })
          );
          xhr.onload = function() {
            resolve(JSON.parse(this.responseText));
          };
        });
    };
      /**
       * Upload file to S3 with previously received pre-signed POST data.
       * @param presignedPostData
       * @param file
       * @returns {Promise<any>}
       */
    const uploadFileToS3 = (presignedPostData, file) => {
        //console.log(presignedPostData,file);
        var filename = '';
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          Object.keys(presignedPostData.fields).forEach(key => {
            formData.append(key, presignedPostData.fields[key]);
          });
          // Actual file has to be appended last.
          formData.append("file", file);
          const xhr = new XMLHttpRequest();
          xhr.open("POST", presignedPostData.url, true);
          xhr.send(formData);
          //console.log(formData);
          for (var pair of formData.entries()) {
            //if(pair[0] != "file") console.log(pair[0]+ ', ' + pair[1]); 
            if(pair[0] == "key") filename = pair[1];
        }
        
          xhr.onload = function() {
            this.status === 201 ? resolve(filename) : reject(this.responseText);
          };
        });
    };
  
    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);
  
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
  
        return new Blob([ia], {type:mimeString});
    }

    return (
        <EnvContext.Provider value={{landdealBaseURL, s3FileUpload, dataURItoBlob}}>
            {children}
        </EnvContext.Provider>
    );

}

export default EnvContextProvider;