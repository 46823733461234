import closeIcon from '../../../assets/img/close-modal.svg'
import { useState } from 'react'

const Forgot = ({forgotModal, setForgotModal, forgotPasswordForm}) => {
    const closeForgot = () => {
        setForgotModal(false);
    }

    const [email, setEmail] = useState("");
  return (
    <>
        
        <div className={`modal fade custom-modal signin-modal forgot-modal ${forgotModal ? "show" : ""}`} id="SignIn" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="SignInLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={closeForgot}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-5 text-center font-40">Forgot Password?</div>
                            {/* {errMsg && <p className='text-center text-danger'>{errMsg}</p>} */}
                            <form className='custom-form front-custom-form'>
                                <div className='form-group'>
                                    <label>EMAIL ADDRESS</label>
                                    <input type='text' name="forgot_email_address" className='form-control' value={email} onChange={e => setEmail(e.target.value)}/>
                                </div>
                                <div className='text-center'>
                                    <button className='theme-btn lg-btn text-uppercase w-100 mt-4' type="submit" onClick={(e) => {e.preventDefault(); forgotPasswordForm(email);}}>EMAIL MY LOGIN</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Forgot