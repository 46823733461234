import React, { useId } from "react";
import ReactSwitch from "react-switch";
import { Formik, Form, Field } from "formik";
import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {intFormatter, floatFormatter} from "../../Components/Utils/NumberFormatter.js";
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from "../../context/PropertyContext.js";
import ReadMoreItemTextModal from "../../Components/Common/ReadMoreItemTextModal.js";
import AcceptOfferModal from "../../Components/RightWidgets/AcceptOfferModal.js";

const PropertyItem = ({ property, handleSwitchChange, readMoreOpenModal }) => {
  const {user} = useContext(UserContext); 
  const { setPropertyStatus, acceptOffer } = useContext(PropertyContext);

  //const id = useId();
  const [accordionToggle, setAccordionToggle] = useState(false);

  const [propStatus, setPropStatus] = useState(property.property_status)
  const toggleAccordion = () => {
    setAccordionToggle(!accordionToggle)
  }
  const handleScopeChange = async (e) => {

    var values = {
      "place_id": property.place_id,
      "property_status": e.target.value,
      "old_status" : property.property_status,
      "user": user.username
    }

    setPropStatus(e.target.value);

    handleSwitchChange(values);

    const result = await setPropertyStatus(values);

    if(result.success) {
        console.log("good");
    } else if(result.error)
        alert(result.error);
    else
        alert("some other issue");

        
  }

  const navigate = useNavigate();

  const makeOfferButton = () => {
    navigate("/properties/land-details?place_id=" + property.place_id,{state:{open_modal:'makeoffer'}});
  }

  const viewOffersButton = () => {
    navigate("/properties/land-details?place_id=" + property.place_id,{state:{open_modal:'viewoffers'}});
  }

  const maxLength = 500;
  const truncatedText = property?.description?.length > maxLength ? property?.description?.slice(0, maxLength) + "... " : property?.description;

  //view offers
  const [acceptOfferModal, setAcceptOfferModal] = useState(false)
  const openAcceptModal = () => {
      setAcceptOfferModal(true)
  }
  const closeAcceptOfferModal = () => {
      setAcceptOfferModal(false)
  }

  const acceptOfferButton = async (offer_user,place_id,finalize_sale=false) => {
    var values = {
        user:user.username,
        offer_user: offer_user,
        place_id: place_id
    }

    if(finalize_sale) values.finalize_sale = true;

    const result = await acceptOffer(values);

    if(result.success) {
      alert(finalize_sale ? "Sale Finalized!" : "Offer Accepted!");
        window.location.reload();
    } else if(result.error)
        alert(result.error);
    else
        alert("some other issue");    
    
  }

  return (
    <>
      <div className={`accordion-item mb-30 ${accordionToggle ? "active" : ""}`}>
        <table className="custom-table-inside">
          <tbody>
            <tr>
              <td style={{ width: "5%" }} className="arrow-for-desktop">
                <h2 className="accordion-header" id={`heading-${property.place_id}`}>
                  <button
                    onClick={toggleAccordion}
                    className={accordionToggle? "accordion-button" : "accordion-button collapsed"}
                    type="button"
                    // data-bs-toggle="collapse"
                    // data-bs-target={`#collapse-${property.place_id}`}
                    // aria-expanded="false"
                    // aria-controls={`collapse-${property.place_id}`}
                  >
                    <span className="accordion-arrow"></span>
                  </button>
                </h2>
              </td>

              <td style={{ width: "20%" }} className="img">
                <Link to={"/properties/land-details?place_id=" + property.place_id}>
                  <img src={property.images && property.images[0]} alt="photo" />
                </Link>
              </td>

              <td style={{ width: "55%" }} colSpan="3">
                <table className="custom-sub-table heading-sub-table">
                  <tbody>
                    <tr>
                      <td
                        style={{ width: "50%" }}
                        className="xs-title avenir-next fw-600 text-start align-top inn-link-text"
                      >
                        <Link to={"/properties/land-details?place_id=" + property.place_id}>  {property.property_name}</Link>

                      </td>
                      <td
                        style={{ width: "25%" }}
                        className="xs-title text-center font-19 avenir-next align-top city-for-mobile-td"
                      >
                        <span className="d-none city-for-mobile">{property.city}</span> <span className="area">{intFormatter(property.acreage)}</span>
                      </td>
                      <td
                        style={{ width: "25%" }}
                        className="xs-title text-center avenir-next fw-600 align-top city-for-desktop"
                      >
                        {property.city}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className={accordionToggle? "collapse-in-mobile active" : "collapse-in-mobile"}>
                  <table className="custom-sub-table ">
                    <tbody>
                      <tr>
                        <td colSpan="3" className="pt-3 pb-3">
                          {property && property.company_id == user.company_id &&  <button className="trns-edit-btn text-uppercase"><span className="edit-icon"></span><a className="theme-text text-uppercase w-100" href={"/properties/add-my-property?place_id=" + property.place_id + "&edit_listing"}>Edit Property</a></button>}                          
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="btns-for-mobile">
                          <a className="theme-btn me-2 text-uppercase sm-w-50" href={"/properties/land-details?place_id=" + property.place_id}>
                            Open Property
                          </a>
                          <a className="theme-btn me-2 text-uppercase sm-w-50" href={"/properties/printout-builder?place_id=" + property.place_id}>
                            Print Property
                          </a>
                          {/*<button className="theme-btn me-2 text-uppercase sm-w-50">
                            List for Auction
                          </button>*/}
                          {/* <button className="theme-btn text-uppercase sm-w-100">
                            E-Blast Accepting Offers
                          </button> */}
                          {property && property.company_id == user.company_id ?
                           (property.offers?.length > 0 && !property.sale_finalized ? <button className="theme-btn text-uppercase sm-w-100" type="button"onClick={() => {openAcceptModal();}}>{property.offer_accepted ? 'Finalize Sale' : 'View Offers'}</button> : '')
                           : 
                           <button className="theme-btn text-uppercase sm-w-100" type='button' onClick={() => {makeOfferButton();}}>Make Offer</button>
                           }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
              <td style={{ width: "20%" }} className={`${accordionToggle ? "border-l collapse-in-mobile active" : "border-l collapse-in-mobile"}`}>
                <table className="actions-table w-100">
                  {user.company_id == property.company_id &&<tbody>
                    <tr>
                      <td className="xs-title avenir-next fw-600 text-start text-uppercase">
                        Private
                      </td>
                      <td className="xs-title avenir-next fw-600 text-end">
                        <label className="switch">
                          <input name={`property_status-${property.place_id}`} type="radio" value="private" onChange={handleScopeChange} checked={propStatus == "private"}/>
                          <div className="slider"></div>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="xs-title avenir-next fw-600 text-start text-uppercase">
                        Members Only
                      </td>
                      <td className="xs-title avenir-next fw-600 text-end">
                        <label className="switch">
                          <input name={`property_status-${property.place_id}`} type="radio" value="members_only" onChange={handleScopeChange} checked={propStatus == "members_only"} />
                          <div className="slider"></div>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className="xs-title avenir-next fw-600 text-start text-uppercase">
                        Public
                      </td>
                      <td className="xs-title avenir-next fw-600 text-end">
                        <label className="switch">
                          <input name={`property_status-${property.place_id}`} type="radio" value="public" onChange={handleScopeChange} checked={propStatus == "public"} />
                          <div className="slider"></div>
                        </label>
                      </td>
                    </tr>
                  </tbody>}
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          id={`collapse-${property.place_id}`}
          className={`${accordionToggle ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'}`}
          aria-labelledby={`heading-${property.place_id}`}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="d-flex justify-content-between">
              <div>
                <div className="xxs-title">
                  <span className="lbl">Type:</span> <span className="lbl-value">{property.type || ""}</span>
                </div>
              </div>
            </div>
            <div className="pt-3 ">
              <p>
                {truncatedText || ""}{" "}
                {property?.description?.length > maxLength ? 
                  <button type="button" onClick={()=> readMoreOpenModal(property?.description)}
                    className="trns-edit-btn text-decoration-none fw-500 font-14"
                  >
                    Read More
                  </button> : ""
              
                }
                
              </p>
            </div>
          </div>
        </div>
        <div className="arrow-for-mobile d-none">
          <button
            onClick={toggleAccordion}
            className={`${accordionToggle ? "accordion-button" : "accordion-button collapsed"}`}
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target={`#collapse-${property.place_id}`}
            // aria-expanded="false"
            // aria-controls={`collapse-${property.place_id}`}
          >
            <span className="accordion-arrow"></span> <span className="show-dtl-label">Show Details</span> <span className="close-dtl-label">Close Details</span>
          </button>
        </div>
      </div>
      <AcceptOfferModal 
          acceptOfferModal={acceptOfferModal} 
          closeAcceptOfferModal={closeAcceptOfferModal}
          propertyData={property}
          acceptOfferButton={acceptOfferButton}
      />
    </>
  );
};

export default PropertyItem;
