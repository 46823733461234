import React from 'react'

const ViewOfferBox = ({openModal, offerCount, offerAccepted}) => {
    return (
        <>
            <div className="gray-box view-offer-box pt-4 pb-4">
                <div className='d-flex align-items-center justify-content-between'>
                    <div className="box-title text-uppercase">{offerAccepted ? 'Finalize Sale' : 'View Offers'}</div>
                    <div>
                        <span className='vob-tag'>{offerCount}</span>
                    </div>
                </div>
                <div className='mt-4 text-center'>
                    <button type='button' className='theme-trns-btn text-uppercase w-100' onClick={() => {openModal('viewoffers');}}>{offerAccepted ? 'Finalize Sale' : 'View Offers'}</button>
                </div>
            </div>
        </>
    )
}

export default ViewOfferBox