import LandDetailLoading from '../../Components/Common/LandDetailLoading'
import bannerImg from '../../assets/img/banner2.jpg'

const DetailBanner = ({propertyData, landDealLoading}) => {
  return (
    <>
        <section className="banner-map dtl-main-banner">
            
            
            {landDealLoading? 
              <LandDetailLoading cssClass="loading-box-black"/> : 
              <>
                <img src={propertyData && propertyData.images[0] !== undefined ? propertyData.images[0] : bannerImg} alt="banner"/>
                <div className='banner-pos'>
                    <div className='md-title text-uppercase font-22 text-end'>{propertyData.property_name}</div>
                    <div className='xs-title font-16 text-end text-white fw-300'>{propertyData.city}</div>
                </div>
              </>
            }
        </section>
    </>
  )
}

export default DetailBanner