import closeIcon from '../../assets/img/close-modal.svg'
import pdficon from "../../assets/img/file-photo/file-pdf.svg";
import folderIcon from "../../assets/img/folder-icon.svg";
import excelicon from "../../assets/img/file-photo/file-excel.svg";
import docicon from "../../assets/img/file-photo/file-word.svg";
import documentIcon from "../../assets/img/document.svg";
import imageicon from "../../assets/img/file-photo/file-photo.svg";
import { useState, useContext, useRef, useEffect } from 'react'
import AutoComplete from '../../Components/Utils/AutoComplete'
import { Field, Formik, Form } from 'formik'
import TextAreaField from "../../Components/Form/TextAreaField";
import CreatableSelect from 'react-select/creatable'
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext'
import {EnvContext} from '../../context/EnvContext';

const recipientOptions = []; 

const ShareModal = ({shareModal, setShareModal, propertyData}) => {

    const {user} = useContext(UserContext); 
    const { getMembers, sendSMS, sendEmail, editPrivacy, makePropertyPublic } = useContext(PropertyContext);
    const { landdealBaseURL } = useContext(EnvContext);

    var tempPrivacies = [];

    for(var i = 0; i < propertyData.documents.length; i++) {
        tempPrivacies["privacy_" + i] = propertyData.documents[i].privacy;
    }

    const [documentData, setDocumentData] = useState(propertyData.documents.map((item,i) => {
        return { ...item, radioName: "privacy_" + i, mime: item.headers && item.headers['Content-Type'] !== undefined ? item.headers['Content-Type'] : ""}
    }));

    const [showCopyText, setShowCopyText] = useState(false);

    const [formikData2, setFormikData2] = useState({
        recipients: "",
        message: "",
        subject: "A New Property Has Been Listed @ LandDeal.com"
    }); 

    const [formikData3, setFormikData3] = useState({
        recipients: "",
        message: "",
        primary_image: ""
    }); 

    const formikRef2 = useRef(null);

    const formikRef3 = useRef(null);

    useEffect(() => {     
            
        const fetchUsers = async () => {
            const memData = await getMembers(user.username);
            
            console.log(memData);

            memData.map((v,i) => {
                recipientOptions.push( { value: v.phone, label: v.first_name + " " + v.last_name});
            });
        }    
        
        const setFormikData = () => {

            formikRef2.current.setFieldValue("message", `Hello LandDeal.com Members-\n\nCheck out a new listing from ${propertyData.broker_name} at ${propertyData.broker_company_name}. With ${propertyData.acreage} acres in ${propertyData.city} it's a must see:\n${landdealBaseURL}properties/land-details?place_id=${propertyData.place_id}\n\nRegards,\n${propertyData.broker_name}\n${propertyData.broker_company_name}\n${propertyData.broker_phone_number}`);

            formikRef3.current.setFieldValue("message", propertyData.broker_name + " posted a new property listing at LandDeal.com\n" + landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id);

            formikRef3.current.setFieldValue("primary_image", propertyData.primary_image);               

        }

        if(propertyData && formikRef2.current && formikRef3.current) {
            setFormikData();
            fetchUsers();
        }

    }, [propertyData]); 
    
    const closeShareModal = () => {
        setShareModal(false);
    }
    const [emailActive, setEmailActive] = useState(false);
    const activeEmail = () => {
        setEmailActive(!emailActive)
    }
    const [shareNextStep, setShareNextStep] = useState(user.company_id != propertyData.company_id);
    const shareNextBtn = () => {
        setShareNextStep(true)
    }
    const shareBackBtn = () => {
        setShareNextStep(false)
    }

    const editPrivacyWrapper = async (name,privacy) => {
        console.log(name,privacy);
        const data = await editPrivacy(propertyData.place_id,user.username,name,privacy);
        
    }

    const publicPropertyWrapper = async (place_id,user) => {
        const data = await  makePropertyPublic(place_id,user);

        for(var i = 0; i < propertyData.documents.length; i++) {
            var item = propertyData.documents[i];
            editPrivacyWrapper(item.name,"public");
        }

        setDocumentData(documentData.map((item,i) => {
            return { ...item, radioName: "privacy_" + i, mime: item.headers && item.headers['Content-Type'] !== undefined ? item.headers['Content-Type'] : "", privacy: "public"}
        }));

        if(data.success) alert("Property set to public");


    }

    const onPrivacyChange = e => {
        //setTopping(e.target.value)
        console.log(e.target.name,e.target.value);

        const { name, value } = e.target;

        var tempDocData = [];

        for(var i = 0; i < documentData.length; i++) {
            var item = documentData[i];
            if("privacy_" + i == name) editPrivacyWrapper(item.name,value);
            tempDocData.push({ ...item, radioName: "privacy_" + i, mime: item.headers && item.headers['Content-Type'] !== undefined ? item.headers['Content-Type'] : "", privacy: name == "privacy_" + i ? value : item.privacy})
        }

        setDocumentData(tempDocData);
    }

    return (
        <>
            <div className={`modal fade custom-modal share-modal ${shareModal ? "show" : ""}`} id="shareModal" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
                <div className="modal-dialog mxw-1360">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="text-uppercase">Share Property</div>
                            <button type="button" className="btn-close" onClick={closeShareModal}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="xl-title mb-4 text-uppercase">Share my Property Listing</div>
                            <div className="smpl-box syp-dtl-box">
                                <div className="row">
                                    <div className={`${shareNextStep? "col-md-5 share-left d-none": "col-md-5 share-left"}`}>
                                        {user.company_id == propertyData.company_id && <div>
                                        <div className="md-title pt-0 mb-4 font-18 text-uppercase">1. Control what you share</div>
                                        <div className={`${emailActive ? "gray-box mb-4 active" : "gray-box mb-4"}`}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <div className="xs-title fw-500 text-start theme-text">Share Complete Listing
                                                    </div>
                                                    <div className="lb-title fw-500 mt-1 mb-1">When clicked this will make your listing and all documents public</div>
                                                </div>
                                                <div>
                                                    <label className="switch">
                                                        <input type="checkbox" name="public_listing" value="public" onChange={(e) => {if(e.target.checked) publicPropertyWrapper(propertyData.place_id,user.username);}} defaultChecked={propertyData.property_status == "public"}/>
                                                        <div className="slider"></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="share-permission-table">
                                            <table className="w-100 mt-3">
                                                <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-start ps-0" style={{ width: "50%" }}>Upload
                                                            Documents</th>
                                                        <th className="text-uppercase text-center" style={{ width: "10%" }}>Private</th>
                                                        <th className="text-uppercase text-center" style={{ width: "10%" }}>Members Only</th>
                                                        <th className="text-uppercase text-center" style={{ width: "10%" }}>Public</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {documentData.map((item,i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td style={{ width: "50%" }}>
                                                                        <div className="text-uppercase pa-ss d-inline-flex align-items-center">
                                                                            <span>
                                                                                <img src={
                                                                                item.mime === "folder"
                                                                                ? folderIcon : item.mime === "application/pdf"
                                                                                ? pdficon : item.mime === "application/msword" || item.mime === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                                ? docicon : item.mime === "text/csv" || item.mime === "application/vnd.ms-excel" || item.mime == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                                ? excelicon : item.mime && item.mime.includes("image")
                                                                                ? imageicon : documentIcon
                                                                                } /></span> <div className="truncate-share-text">{item.name}</div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center" style={{ width: "10%" }}>
                                                                        <label className="switch">
                                                                            <input type="radio" name={item.radioName} onChange={onPrivacyChange} value="private" checked={item.privacy === "private"}/>
                                                                            <div className="slider"></div>
                                                                        </label>
                                                                    </td>
                                                                    <td className="text-center" style={{ width: "10%" }}>
                                                                        <label className="switch">
                                                                            <input type="radio" name={item.radioName} onChange={onPrivacyChange} value="members_only" checked={item.privacy === "members_only"}/>
                                                                            <div className="slider"></div>
                                                                        </label>
                                                                    </td>
                                                                    <td className="text-center" style={{ width: "10%" }}>
                                                                        <label className="switch">
                                                                            <input type="radio" name={item.radioName} onChange={onPrivacyChange} value="public" checked={item.privacy === "public"}/>
                                                                            <div className="slider"></div>
                                                                        </label>
                                                                    </td>
                                                                </tr>
                                                            )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='btn-for-mobile mt-5'>
                                            <button type='button' className='theme-btn w-100 md-btn text-uppercase' onClick={shareNextBtn}>Next</button>
                                        </div>
                                        </div>}
                                    </div>
                                    <div className={`${shareNextStep? "col-md-7 share-right activepage": "col-md-7 share-right"}`}>
                                        <div className="md-title pt-0 mb-4 font-18 text-uppercase">{user.company_id == propertyData.company_id ? "2." : ""} Sharing Method</div>
                                        <div className="document-nots-block sharing-method-tabs">
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="viaLink-tab" data-bs-toggle="tab" data-bs-target="#viaLink" type="button" role="tab" aria-controls="viaLink" aria-selected="true">Via Link</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="email-tab" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email" aria-selected="false" onClick={activeEmail}>Email</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="sms-tab" data-bs-toggle="tab" data-bs-target="#sms" type="button" role="tab" aria-controls="sms" aria-selected="false">SMS</button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade active show" id="viaLink" role="tabpanel" aria-labelledby="viaLink-tab">
                                                    <div className="gray-box pd-20-50">
                                                        <div className='xs-title text-start fw-500'>Copy the link and share with your prospects</div>
                                                        <div className="custom-form mt-4">
                                                            <div className="form-group mb-0">
                                                                <div className='row'>
                                                                    <div className='col-md-8'>
                                                                        <div className='position-relative'>
                                                                            <input type="text" className="form-control copy-link" placeholder={landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id} />
                                                                            <button type='button' className='copy-icon' onClick={() => {navigator.clipboard.writeText(landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id); setShowCopyText(true);}}></button>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-4'>
                                                                        <button type="buttton" className="theme-btn text-uppercase w-100 fw-700 md-btn" onClick={() => {navigator.clipboard.writeText(landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id); setShowCopyText(true);}}>Copy Link</button>
                                                                    </div>
                                                                </div>
                                                                {showCopyText && <div className='mt-2 font-13'><span className='chk-icon'></span> Link has been copied to your clipboard</div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Formik
                                                    validationSchema=""
                                                    initialValues={formikData2}
                                                    onSubmit={(values) => {
                                                        console.log(values);
                                                        sendEmail(values);
                                                        alert("Your message has been sent!");
                                                    }}
                                                    innerRef={formikRef2}
                                                    enableReinitialize={true}
                                                >
                                                {({ values }) => (
                                                    <div className="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
                                                        <Form>
                                                            <div className="gray-box pd-20-50">
                                                                <div className="custom-form">
                                                                    <div className="form-group">
                                                                        <label className="font-13 mb-2">To</label>
                                                                        <Field type="text" name="recipients" className="form-control" placeholder='youremail@domain.com, email2@domain.com' />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="font-13 mb-2">Subject</label>
                                                                        <Field type="text"  name="subject"className="form-control" placeholder='Great business pontential property' />
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <label className="font-13 mb-1">Message</label>
                                                                        <TextAreaField type="text" className="form-control" name="message" />
                                                                    </div>
                                                                    <div className="text-end mb-5">
                                                                        <button type="submit" className="theme-btn text-uppercase mnw-180 fw-700">Send</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                )}</Formik>
                                                <Formik
                                                    validationSchema=""
                                                    initialValues={formikData3}
                                                    onSubmit={(values) => {
                                                        console.log(values);
                                                        sendSMS(values);
                                                        alert("Your message has been sent!");
                                                    }}
                                                    innerRef={formikRef3}
                                                    enableReinitialize={true}
                                                >
                                                {({ values }) => (
                                                    <div className="tab-pane fade" id="sms" role="tabpanel" aria-labelledby="sms-tab">
                                                        <Form>
                                                            <Field type="hidden" name="primary_image" />
                                                            <div className="gray-box pd-20-50">
                                                                <div className="custom-form">
                                                                    <div className="form-group">
                                                                        <label className="font-13 mb-1">Select Recipients</label>
                                                                        <CreatableSelect 
                                                                            className="create-tags"
                                                                            placeholder="Select Recipient"
                                                                            name="recipients"
                                                                            onChange={(newValue) => {values.recipients = newValue; console.log(newValue); console.log(formikData3,formikRef3.current.values)}}
                                                                            // onClick={}
                                                                            isMulti options={recipientOptions} />
                                                                        {/*<div className="mt-2">
                                                                            <button className="trns-edit-btn text-uppercase font-12"><span className="plus-icon"></span> Add Recipients</button>
                                                                        </div>*/}
                                                                    </div>
                                                                    <div className="form-group mt-5">
                                                                        <label className="font-13 mb-1">Message</label>
                                                                        <TextAreaField type="text" className="form-control" name="message" />
                                                                    </div>
                                                                    <div className="text-end mb-5">
                                                                        <button type="submit" className="theme-btn text-uppercase mnw-180 fw-700">Send Via Link</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                )}</Formik>
                                            </div>
                                        </div>
                                        <div className={`${shareNextStep? "mt-4 text-center": "mt-4 text-center d-none"}`}>
                                            <button className='goback-btn' type='button' onClick={shareBackBtn}><span className="rg-arrow-icon"></span> Go Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShareModal