import closeIcon from '../../assets/img/close-modal.svg'
import closeIconMob from '../../assets/img/exit.svg'
import { useState } from "react";

const AddUser = ({addUserModal, setAddUserModal,registerForm,addCompany}) => {

    const closeAddUser = () => {
        setAddUserModal(false);
    }

    const [formData, setFormData] = useState({
        
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.name == "terms" ? e.target.checked : e.target.value
        })
    }
    
  return (
    <>
        
        <div className={`modal fade custom-modal signin-modal add-user-modal ${addUserModal ? "show" : ""}`} id="SignIn" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="SignInLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-body pt-5 pb-4">
                            <div className='mobile-header'>
                                <button type="button" className="btn-close for-desktop" onClick={closeAddUser}>
                                    <img src={closeIcon} alt="close" />
                                </button>
                                <div className="fb-heading mb-4 text-center font-26 text-uppercase">Add User</div>
                                <button type="button" className="btn-close for-mobile" onClick={closeAddUser}>
                                    <img src={closeIconMob} alt="close" />
                                </button>
                            </div>
                            <form className='custom-form front-custom-form' onSubmit={(e) => {e.preventDefault(); console.log(formData); registerForm(formData);}}>
                                <div className='form-group'>
                                    <label className="text-capitalize">First Name</label>
                                    <input type='text' required onChange={onChangeInput} name="first_name" className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label className="text-capitalize">Last Name</label>
                                    <input type='text' required onChange={onChangeInput} name="last_name" className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label className="text-capitalize">Email</label>
                                    <input type='text' required onChange={onChangeInput} name="email" className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label className="text-capitalize">Cell Phone</label>
                                    <input type='text' required onChange={onChangeInput} name="phone" className='form-control'/>
                                </div>
                                <div className='form-group'>
                                    <label className="text-capitalize">Business Phone</label>
                                    <input type='text' onChange={onChangeInput} name="business_phone" className='form-control'/>
                                </div>
                                {addCompany &&
                                    <div className='form-group'>
                                        <label className="text-capitalize">Company</label>
                                        <input type='text' required onChange={onChangeInput} name="company" className='form-control'/>
                                    </div>
                                }
                                <div className='form-group'>
                                    <div className='gray-box bradius-10 pt-4 pb-4'>
                                        <div className='mb-2'><label className="text-capitalize fw-600">Send Credentials Via:</label></div>
                                        <div className="custom-checkbox sm-checkbox d-inline-block me-5">
                                            <input 
                                                type="checkbox" 
                                                name="email_credentails"
                                                className="styled-checkbox"
                                                id="addUserEmail"
                                                onChange={onChangeInput}
                                            />
                                            <label className="text-capitalize fw-600" htmlFor="addUserEmail"> Email</label>
                                        </div>
                                        <div className="custom-checkbox sm-checkbox d-inline-block">
                                            <input 
                                                type="checkbox" 
                                                name="text_credentials"
                                                className="styled-checkbox"
                                                id="addUserTextSms"
                                                onChange={onChangeInput}
                                            />
                                            <label className="text-capitalize fw-600" htmlFor="addUserTextSms"> Text / SMS</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <button className='theme-btn md-btn text-uppercase w-100 mt-4' type="submit">Add New User</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default AddUser