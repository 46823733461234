import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import userIcon from '../../assets/img/icon-chat-user.svg'
import backArrow from '../../assets/img/white-back-arrow.png'

const ChatGroupList = ({chatList, groupChatNames, addGroupChatUser, setGroupChatName,groupName,setGroupName,setNewGroup, createGroupChat}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const filteredChatList = chatList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <>
            <div className="chatlist p-0">
                <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="msg-head d-flex align-items-center justify-content-between d-md-none group-mob-header">
                            <button className='white-back-arrow cursor-pointer d-md-none' onClick={() => {setNewGroup(false);}}>
                                <img src={backArrow} />
                            </button>
                            <div className='d-flex align-items-center w-100'>
                                <div className='chat-sm-title text-white fw-500 me-3'>Add Group Participants</div>
                            </div>
                        </div>
                        <div className="chat-header d-none d-md-block">
                            <div className='d-flex align-items-center justify-content-between mb-4'>
                                <span className='ch-xs-title' onClick={() => {setNewGroup(false);}}><span className='chat-back-arrow me-2'></span>Add Group Participants</span>
                                <div className='member-count'>3 of 10</div>
                            </div>
                            <div className="msg-search w-100 ">
                                <div className='position-relative search-input'>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="Search" 
                                        value={searchTerm}
                                        onChange={handleSearchChange} 
                                    />
                                    <span className="gray-search-icon"></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body p-0 pb-5">
                            <div className="chat-lists">
                                <div className="chat-list chat-group-list">
                                    {/* {chatList.map((item, index) => */}
                                    {filteredChatList.map((user, index) => (
                                        <div key={index}>
                                            <button type="button" className={`d-flex align-items-center w-100 ${(groupChatNames.includes(user.name)) ? "selected" : ""}`} onClick={() => addGroupChatUser(user)}>
                                                <div className="flex-shrink-0">
                                                    <span className='user-icon'><img src={userIcon} alt="user" /></span>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h3>{user.name} <span className="status-circle green"></span></h3>
                                                    <p>{user.company}</p>
                                                </div>
                                                <div className='flex-grow-1 text-end'>
                                                    <div className="custom-checkbox sm-checkbox group-chat-checkbox">
                                                        <label htmlFor={`groupMember_${index}`}><span></span></label>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className='ps-4 pt-5 pe-4'>
                                    <div className='chat-ssm-title text-start mb-5'>You can select up to <span>10</span> participants</div>
                                    <div className='custom-form chat-custom-form'>
                                        <div className='form-group mb-30'>
                                            <label>Group Name</label>
                                            <input type='text'
                                             className='form-control'
                                             value={groupName}
                                            onChange={(e)=>setGroupName(e.target.value)}
                                            onKeyPress={(e) => {if (e.key === 'Enter') {setGroupChatName()}}}
                                             placeholder='ABC Investments Trust' />
                                        </div>
                                        <button type="button" onClick={createGroupChat} className='chat-theme-btn w-100 ms-0'>Create Group</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatGroupList