import React, { useState } from 'react'

import {intFormatter, floatFormatter} from "../../Components/Utils/NumberFormatter.js";

const PropertyDetails = ({propertyData,openModal}) => {

    

    return (
        <>
            <div className="gray-box property-detail-box">
                {propertyData.offer_accepted ? 
                (propertyData.sale_finalized ? <div className='offer-status mb-2'>
                    <button type='button' className='offer-status-btn'><span className='os-circle sale-finalized'></span> Sale Finalized</button>
                </div> : 
                <div className='offer-status mb-2'>
                    <button type='button' className='offer-status-btn'><span className='os-circle sale-pending'></span> Sale Pending</button>
                </div>) :
                <div className='offer-status mb-2'>
                    <button type='button' className='offer-status-btn'><span className='os-circle offers-accepted'></span> Accepting Offers</button>
                </div>}
                <div className="box-title text-uppercase">Property Details</div>
                <div className="mt-3 border-t">
                    <div className="lb-title pt-3">Type:</div>
                    <p className="mt-2">{propertyData.amenities && propertyData.amenities.map((v,i) => `${v} ` ,'')}</p>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                    <div>
                        <div className="lb-title">Price</div>
                        <div className="xs-title font-20 text-white mt-1 text-start">{floatFormatter(propertyData.list_price,true)}</div>
                    </div>
                    <div>
                        <div className="lb-title">Size</div>
                        <div className="xs-title font-20 text-white mt-1 text-start">{intFormatter(propertyData.acreage)}</div>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-4">
                    <div>
                        <button className="theme-trns-btn text-uppercase md-btn" type='button' onClick={() => {openModal('smallshare');}}>Share</button>
                    </div>
                    <div>
                        <button className="theme-btn text-uppercase md-btn" type='button' onClick={() => {openModal('makeoffer');}}>Make Offer</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyDetails