
import loaderImg1 from '../../assets/img/gears-gold.svg'
import closeIcon from '../../assets/img/close-modal.svg'

const LandDetailLoading = ({cssClass}) => {
  return (
    <>
        <div className={`loading-box ${cssClass ? cssClass : ""}`}>
            {/* <button type="button" className="btn-close">
                <img src={closeIcon} alt="close" />
            </button> */}
            <div className='lb-box'>
              <div className="sm-title text-center fw-600">Loading</div>
              <div className='text-center gears-icon mt-4 mb-4'>
                  <img src={loaderImg1} alt='ld' />
              </div>
              <div className='xs-title text-center opacity-0-8'>This image may take a minute to load as <br/>we’re downloading from Land ID</div>
            </div>
        </div>
    </>
  )
}

export default LandDetailLoading