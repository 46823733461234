import TextField from "../Form/TextField";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as yup from "yup";
import SelectField from "../Form/SelectField";
import { ValidationError } from "../Errors/ValidationError";
import { useState, useEffect, useRef, useContext } from 'react';
import { intFormatter, floatFormatter } from "../Utils/NumberFormatter.js";
import { UserContext } from '../../context/UserContext';
import DuplicateListingModal from "./DuplicateListingModal.jsx";

const validationSchema = yup.object({
  property_status: yup.string().required("Property status field is required."),
  listing_status: yup.string().required("Listing Status field is required."),
  type: yup.string().required("Type field is required."),
  //sub_type: yup.string().required("Sub type field is required."),
  //specialty_type: yup.string().required("Specialty type field is required."),
  property_name: yup.string().required("Property name field is required."),
  acreage: yup.string().required("Areage field is required.")
});

const GeneralDetails = ({ nextStep, setNextActiveHeading, item, propertyAddress, propertyData, sendFormikRef, allProperties, handleDuplicateListing }) => {
  const { user } = useContext(UserContext);
  const listing_status = [
    { key: 1, value: "Accept Offers" },
    { key: 2, value: "Auction", disabled: true }
  ];

  const property_types = [
    { key: 1, value: "Commercial" },
    { key: 2, value: "Farms and Ranches" },
    { key: 3, value: "Homesite" },
    { key: 4, value: "House" },
    { key: 5, value: "Hunting" },
    { key: 6, value: "Lakefront" },
    { key: 7, value: "Oceanfront" },
    { key: 8, value: "Recreational" },
    { key: 9, value: "Riverfront" },
    { key: 10, value: "Timberland" },
    { key: 11, value: "Undeveloped" },
    { key: 12, value: "Waterfront" }
  ];

  const sub_types = [
    { key: 1, value: "Sub Type 1" },
    { key: 2, value: "Sub Type 2" }
  ];

  const specialty_types = [
    { key: 1, value: "Specialty Type 1" },
    { key: 2, value: "Specialty Type 2" }
  ];

  const [value, updateValue] = useState("");
  const [timer, setTimer] = useState(null);

  const currencyInputChanged = e => {
    console.log(e.target.value);
    updateValue(e.target.value)

    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      updateValue(intFormatter(e.target.value, true))
    }, 500)

    setTimer(newTimer)
  }

  const [formikData, setFormikData] = useState({
    company_id: user.company_id,
    property_status: propertyData && propertyData.property_status ? propertyData.property_status : "",
    listing_status: propertyData && propertyData.listing_status ? propertyData.listing_status : "",
    type: propertyData && propertyData.type ? propertyData.type : "",
    sub_type: propertyData && propertyData.sub_type ? propertyData.sub_type : "",
    specialty_type: propertyData && propertyData.specialty_type ? propertyData.specialty_type : "",
    property_name: propertyData && propertyData.property_name ? propertyData.property_name : "",
    acreage: propertyData && propertyData.acreage ? propertyData.acreage : "",
    list_price: propertyData && propertyData.list_price ? propertyData.list_price : "",
    roi: propertyData && propertyData.roi ? propertyData.roi : ""
  });

  const formikRef = useRef(null);

  useEffect(() => {
    sendFormikRef('general', formikRef.current);
  }, [formikRef])

  const prevValues = useRef(null);

  const SendFormikData = () => {
    const { values } = useFormikContext();
    useEffect(() => {

      if (JSON.stringify(values) != prevValues.current) {
        nextStep(0, values);
        console.log(values, JSON.stringify(values));
        prevValues.current = JSON.stringify(values);
      }
    }, [JSON.stringify(values)]);
    return null;
  };

  const [duplicateListingModal, setDuplicateListingModal] = useState(false)
  const openDuplicateListing = () => {
    setDuplicateListingModal(true)
  }
  
  const closeDuplicateListing = () => {
    setDuplicateListingModal(false)
  }

  return (
    <>
      <div className="accordion-body">
        <Formik
          validationSchema={validationSchema}
          initialValues={formikData}
          innerRef={formikRef}
          onSubmit={(values) => {
            // data send serve side
            console.log(values);
            nextStep(item + 1, values);
          }}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form>
              <SendFormikData />
              <div className="row mlr-40">
                <div className="col-md-6 plr-40">
                  <div className="form-group">
                    <div className="perporty-box">
                      <label>
                        Property Status * <span className="info-rel position-relative ms-2">
                          <span
                            className="info-icon"></span>
                          <span className="info-pos">This makes the file available on the photos section too.</span>
                        </span>
                      </label>
                      <div className="mt-3 mb-3">
                        <div className="d-inline-flex">
                          <div className="d-flex align-items-center me-4">
                            <label className="switch">
                              <Field
                                type="radio"
                                name="property_status"
                                value="private"
                              />
                              <div className="slider"></div>
                            </label>
                            <div className="xs-title fw-400 text-uppercase ms-2">
                              Private
                            </div>
                          </div>
                          <div className="d-flex align-items-center me-4">
                            <label className="switch">
                              <Field
                                type="radio"
                                name="property_status"
                                value="members_only"
                              />
                              <div className="slider"></div>
                            </label>
                            <div className="xs-title fw-400 text-uppercase ms-2">
                              Members Only
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <label className="switch">
                              <Field
                                type="radio"
                                name="property_status"
                                value="public"
                              />
                              <div className="slider"></div>
                            </label>
                            <div className="xs-title fw-400 text-uppercase ms-2">
                              Public
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tip-text">
                        Tip: This sets the visibility of the property
                      </div>
                      <ValidationError name="property_status" />
                    </div>
                  </div>
                  <SelectField
                    name="type"
                    label="Type *"
                    placeholder="Select Type"
                    data={property_types}
                  />
                  {/* <div className="row">
                    <div className="col-md-6">
                      <SelectField
                        name="sub_type"
                        label="Sub Type"
                        placeholder="Select Sub Type"
                        data={sub_types}
                      />
                    </div>
                    <div className="col-md-6">
                      <SelectField
                        name="specialty_type"
                        label="Specialty Type"
                        placeholder="Select Specialty Type"
                        data={specialty_types}
                      />
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        label="List Price"
                        type="text"
                        placeholder="Enter your List Price"
                        name="list_price"
                        onChange={(e) => {
                          formik.setFieldValue("list_price", intFormatter(e.target.value, true));
                        }}
                        value={formik.values.list_price}
                      />
                    </div>

                    <div className="col-md-6">
                      <TextField
                        label="Annual ROI"
                        type="text"
                        placeholder="Enter your Annual ROI"
                        name="roi"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 plr-40">
                  <SelectField
                    name="listing_status"
                    label="Listing Status *"
                    placeholder="Select Listing Status"
                    data={listing_status}
                  />
                  <TextField
                    label="Property Name *"
                    type="text"
                    placeholder="Enter your property name"
                    name="property_name"
                  />
                  <Field
                    type="hidden"
                    name="company_id"
                    value={user.company_id}
                  />
                  {/*<Field
                    type="hidden"
                    name="place_id"
                    value={formik.values.place_id}
                  />
                  <Field
                    type="hidden"
                    name="latitude"
                    value={formik.values.latitude}
                  />
                  <Field
                    type="hidden"
                    name="longitude"
                    value={formik.values.longitude}
                  />
                  <Field
                    type="hidden"
                    name="city"
                    value={formik.values.city}
                  />
                  <div className="form-group">
                    <label for="address">Address *</label>
                    <input name="address" id="pac-input" className="form-control" type="text" placeholder="Enter your Address" />
                  </div>*/}


                  <TextField
                    label="Acreage *"
                    type="number"
                    placeholder="Enter your Acreage"
                    name="acreage"
                  />
                  
                  <div className="d-flex align-items-center active-broker duplicate-action justify-content-between mt-5">
                      <label>Duplicate From Previous Listing?
                      <span className="info-rel position-relative ms-2">
                          <span
                            className="info-icon"></span>
                          <span className="info-pos">This will create a copy of a previous listing that you can select on the pop up window after toggling to “Yes”</span>
                        </span>
                      </label>
                      <div className="cr-bg d-flex ms-3">
                        <div className="custom-radio active-bg">
                          <Field
                            type="radio"
                            name="duplicate_listing"
                            value="1"
                            id="duplicateListingYes"
                            onClick={openDuplicateListing}
                          />
                          <label
                            htmlFor="duplicateListingYes"
                            className="text-uppercase"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-radio">
                          <Field
                            type="radio"
                            name="duplicate_listing"
                            value="0"
                            id="duplicateListingNo"
                          />
                          <label
                            htmlFor="duplicateListingNo"
                            className="text-uppercase"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    
                  {/* <div className="mt-3 mb-3">
                    <div>
                      <label>
                        Duplicate From Previous Listing?
                      </label><br />
                      <div className="d-flex align-items-center me-4">
                        <label className="switch">
                          <input 
                            type="checkbox"
                            name="duplicate_listing"
                            value="duplicateListing"
                            ref={checkboxRef}
                            onClick={openDuplicateListing}
                          />
                          <div className="slider"></div>
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-12 plr-40 text-end mt-4">
                  <button
                    type="submit"
                    className="theme-btn text-uppercase w-25 sm-btn"
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <DuplicateListingModal
        duplicateListingModal={duplicateListingModal}
        setDuplicateListingModal={setDuplicateListingModal}
        closeDuplicateListing={closeDuplicateListing}
        allProperties={allProperties}
        handleDuplicateListing={handleDuplicateListing}
      />
    </>
  );
};

export default GeneralDetails;