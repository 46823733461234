import React, { useState, useContext } from 'react'
import Header from '../../../Components/Frontend/Common/Header'
import Footer from '../../../Components/Frontend/Common/Footer'
import bannerImg from '../../../assets/img/frontend/Hero-Img.jpg'
import spImg from '../../../assets/img/frontend//showcase-properties.png'
import privacyDocImg from '../../../assets/img/frontend/Img-Docs.png'
import propertyMember from '../../../assets/img/frontend/Img-Email.png'
import ConnectLandImg from '../../../assets/img/frontend/connecting-landowners.jpg'
import contactUsImg from '../../../assets/img/frontend/sec6-bg.png'
import RegisterModal from '../../../Components/Frontend/Common/RegisterModal'
import WOW from 'wowjs';
import { useEffect } from 'react';
import { UserContext } from '../../../context/UserContext'
import { useLocation } from 'react-router'

const FrontendHome = () => {
  const {registrationForm} = useContext(UserContext);

  const [registerModal, setRegisterModal] = useState(false);
  const openRegisterModal = () => {
      setRegisterModal(true);
  }
  
  // useEffect(()=> {
  //   new WOW.WOW({
  //       live: false
  //   }).init();
  // });
  

  const [formData, setFormData] = useState({
        
  });
  const onChangeInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:e.target.name == "terms" ? e.target.checked : e.target.value
    })
  }
  
  const registerForm = async (registerFormData) => {
    console.log(registerFormData);
    
    const data = await registrationForm(registerFormData);
    if(data.success) {
      alert("Thanks for registering! We will reach out soon");
      setRegisterModal(false);
    } else if(data.error)
    alert(data.error);        
  }
  const location = useLocation();
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const targetElement = document.getElementById(hash.slice(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
        <Header/>
        <section className='front-banner-wrap wow fadeIn' data-wow-delay="0.1s" style={{background:`url(${bannerImg}) no-repeat center center`}} id='home'>
          <div className='container'>
            <div className='fb-heading text-center wow bounceInUp' data-wow-delay="0.3s">Connect <span className='dot'>•</span> Buy <span className='dot'>•</span> List</div>
            <div className='md-title text-center fw-300 mt-4 mb-5 wow bounceInUp' data-wow-delay="0.5s">Land Exchange Community Built for Owners</div>
            <div className='md-title text-center fw-300 wow bounceInUp' data-wow-delay="0.7s">
              <button type='button' className='theme-btn text-uppercase' onClick={openRegisterModal}>Apply to Join</button>
            </div>
          </div>
        </section>
        <RegisterModal  registerModal={registerModal} setRegisterModal={setRegisterModal} registerForm={registerForm} />
        <section className='showcase-propertie-wrap front-common-block' id='about'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-12 order-2 wow bounceInUp' data-wow-delay="0.2s">
                <div className='sp-img'>
                  <img src={spImg} alt='Showcase Properties'/>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='fb-heading text-start  wow bounceInUp' data-wow-delay="0.4s">Showcase Properties</div>
                <ul className='common-list mb-3 wow bounceInUp' data-wow-delay="0.6s">
                  <li>List your Property</li>
                  <li>Store Documents</li>
                  <li>Share with Prospects</li>
                </ul>
                <div className='mxw-80 common-text wow bounceInUp' data-wow-delay="0.8s">
                  <p>We support Property Owners first. We’re here to help connect you to a community of landowners empowering you to easily showcase your properties and sell directly saving everyone time & money.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='privacy-doc-wrap front-common-block wow fadeIn' data-wow-delay="0.2s">
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-12 wow bounceInUp' data-wow-delay="0.3s">
                <div className='sp-img'>
                  <img src={privacyDocImg} alt='Showcase Properties'/>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='fb-heading text-start diffrent-text mb-4 wow bounceInUp' data-wow-delay="0.6s">
                  Control the <span>Privacy</span> of <br/>your  <span>Documents</span> & <br/>Property Listings
                </div>
                <div className='mxw-80 common-text wow bounceInUp' data-wow-delay="0.9s">
                  <p>Store all your due diligence documents in 1 location with the ability to control privacy by document. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='property-member-wrap front-common-block' id='whoWeAre'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-12 order-2 wow bounceInUp' data-wow-delay="0.3s">
                <div className='sp-img'>
                  <img src={propertyMember} alt='Email'/>
                </div>
              </div>
              <div className='col-lg-6 col-12'>
                <div className='fb-heading text-start diffrent-text mb-4 wow bounceInUp' data-wow-delay="0.6s">
                  <span>E-Blast</span> your Property <br/>Out to Members
                </div>
                <div className='mxw-80 common-text wow bounceInUp' data-wow-delay="0.9s">
                  <p>With a few clicks, easily send your property listing out to our entire community of members. We also support text options and the ability to share your property on any social platform.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className='connecting-landowners-wrap front-common-block wow fadeIn' data-wow-delay="0.2s" style={{background:`url(${ConnectLandImg}) no-repeat left center`}}>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6 col-12'>
                &nbsp;
              </div>
              <div className='col-lg-6 col-12'>
                <div className='fb-heading text-start wow bounceInUp' data-wow-delay="0.4s">Connecting Landowners</div>
                <ul className='common-list mb-3 wow bounceInUp' data-wow-delay="0.6s">
                  <li>Network with Large Property Owners</li>
                  <li>Gain Advice & Help</li>
                  <li>Collaborate Resources</li>
                </ul>
                <div className='mxw-80 common-text wow bounceInUp' data-wow-delay="0.8s">
                  <p>Our platform is built by landowners for landowners. We allow brokers and other third party professionals to contribute, but our site is for landowners first.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className='contact-us-wrap wow fadeIn' data-wow-delay="0.2s" style={{background:`url(${contactUsImg}) no-repeat center center`}} id='contact'>
          <div className='container'>
            <div className='row mlr-35'>
              <div className='col-lg-6 col-12 plr-35 wow bounceInUp' data-wow-delay="0.4s">
                <div className='fb-heading text-start mb-2'>Contact Us</div>
                <div className='common-text'>
                  <p>Drop us a line, we'd love to hear from you</p>
                </div>
              </div>
              <div className='col-lg-6 col-12 plr-35 address-info wow bounceInUp' data-wow-delay="0.6s">
                <div className='xs-title text-start text-orange text-uppercase font-18 mb-3 mt-3'>General Inquiries</div>
                <div className='xs-title text-start fw-400 text-white lh-26'>
                  123 Place Holder Road Baton Rouge, LA 22113 <br/>
                  <span className='text-orange'>info@landdeal.com</span> <br/>
                  T:  555-123-4567
                </div>
              </div>
            </div>
            <form className='custom-form front-custom-form mt-5' onSubmit={(e) => {e.preventDefault(); console.log(formData); registerForm(formData);}}>
              <div className='row mlr-35'>
                <div className='col-lg-6 col-12 plr-35'>
                  <div className='form-group'>
                    <label>Full Name</label>
                    <input type='text' className='form-control' required onChange={onChangeInput} name="full_name"/>
                  </div>
                  <div className='form-group'>
                    <label>Email</label>
                    <input type='text' className='form-control' required onChange={onChangeInput} name="email"/>
                  </div>
                  <div className='form-group'>
                    <label>Phone</label>
                    <input type='text' className='form-control' required onChange={onChangeInput} name="phone"/>
                  </div>
                  <div className='form-group'>
                    <label>Company</label>
                    <input type='text' className='form-control' required onChange={onChangeInput} name="company"/>
                  </div>
                  <div className='form-group'>
                    <label>Referring Member</label>
                    <input type='text' className='form-control' required onChange={onChangeInput} name="referring_member"/>
                  </div>
                </div>
                <div className='col-lg-6 col-12 plr-35'>
                  <div className='form-group'>
                    <label>Comments</label>
                    <textarea className='form-control' onChange={onChangeInput} name="comments"></textarea>
                  </div>
                  <div className='mt-5 pt-5 text-end'>
                    <button type='submit' className='theme-btn text-uppercase md-btn w-75'>Send</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <Footer/>
    </>
  )
}

export default FrontendHome