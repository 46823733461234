import userIcon from '../../assets/img/icon-chat-user.svg'
import iconAdd from '../../assets/img/icon-plus.svg'
import iconAttach from '../../assets/img/icon-attach.svg'
import { useEffect, useState } from 'react';

const ChatSmallBox = ({ 
        chatUser,
        chatData, 
        closeSmChat, 
        sendChat,
        isLoading,
        uploadFile,
        chatAttachmentInfo
    }) => {
    
    const [message, setMessage] = useState("");
    
    const handleEnterKeyPress = (e, user) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the form from submitting
            sendChat(e.target.value,user);
            setMessage("");
        }
    };

    const handleInputChange = (e,chatuser) => {
        setMessage(e.target.value);
    };

    /*const submitForm = (name,value) => {
        if (value.trim() !== '') {
            const now = new Date();
            const formatDateTime = (date) => {
                const options = {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true, // 24-hour format
                };
                return date.toLocaleString(undefined, options);
            }
            const formattedDateTime = formatDateTime(now);

            const newMessage = { text: value, id: Date.now(), time: formattedDateTime };
            var chatSmDatas = chatSmData.map((item) => { if (item.name == name) { item.messages = [...item.messages, newMessage];item.value = "" } return item; })
            console.log(chatSmDatas);
            setChatSmData(chatSmDatas);

        }
    }*/

    useEffect(() => {
        //console.log("chatData test",chatData,Object.keys(chatData).length );
    },[]);

    
    return (
                 
        <div className="sm-chat-box">
            <div className="d-flex align-items-center w-100 sm-chat-header">
                <div className="flex-shrink-0">
                    <span className='user-icon'><img src={userIcon} alt="user" /></span>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h3>{chatData && chatData.length > 0 && chatData[0].this_chat_fullname} - {chatData && chatData.length > 0 && chatData[0].this_chat_company}</h3>
                </div>
                <div className='flex-grow-1 text-end'>
                    <div className='d-inline-flex align-items-center ms-auto'>
                        {isLoading ?
                            <div className="button-wrapper me-2 spinner-border text-light" role="status" style={{ width: "1.2rem", height: "1.2rem" }}>
                                <span className="visually-hidden">Loading...</span>
                            </div> 
                            :
                            <div className="button-wrapper me-2">
                                <span className="label">
                                    <img className="img-fluid" src={iconAttach} alt="image title" />
                                </span>
                                <input type="file" name="upload" id="upload" className="upload-box" placeholder="Upload File" aria-label="Upload File" onChange={(e)=>uploadFile(e.target.files,chatUser)}/>
                            </div>
                        }
                        <button type='button' className='chat-exit-icon me-0' onClick={() => closeSmChat(chatUser)}></button>
                    </div>
                </div>
            </div>
            <div className="w-100 sm-chat-body">
                <div className='msg-body p-0'>
                    <ul >
                        
                        {chatData && chatData.map((message,i) => {
                            return message.sent ? 
                            <li className="repaly" key={i}>
                                <div className='msg-box'>
                                    { <div className="d-flex align-items-center justify-content-between">
                                        <div className="sender-name">{message.this_user_fullname}</div>
                                        <span className="time position-relative bottom-0 end-0">{message.relative_time}</span>
                                    </div> }
                                    {message.attachment_url && chatAttachmentInfo && message.attachment_url in chatAttachmentInfo && chatAttachmentInfo[message.attachment_url] ? <img src={chatAttachmentInfo[message.attachment_url].url} /> : <p>{message.msg}</p>}
                                    {/*<div className='text-end'>
                                        <span className="time position-relative bottom-0 end-0">{message.relative_time}</span>
                                    </div>*/}
                                </div>
                            </li> 
                            : 
                            <li className="sender" key={i}>
                                <div className='msg-box'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="sender-name">{message.this_user_fullname}</div>
                                        <span className="time position-relative top-0 end-0 ms-4">{message.relative_time}</span>
                                    </div>
                                    {message.attachment_url && chatAttachmentInfo && message.attachment_url in chatAttachmentInfo && chatAttachmentInfo[message.attachment_url] ? <img src={chatAttachmentInfo[message.attachment_url].url} /> : <p>{message.msg}</p>}
                                </div>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
            <div className="send-box sm-chat-footer d-block">
                <form className='d-block'>
                    <input type="text" className="form-control w-100"
                        value={message}
                        aria-label="message…"
                        onChange={(e) => handleInputChange(e, chatUser)}
                        onKeyPress={(e) => handleEnterKeyPress(e, chatUser)}
                        placeholder="Write message…" />                       
                </form>
                {/* <button className='d-none' onClick={() => submitForm(item.name)}>Submit</button> */}
            </div>
        </div>

    )
}

export default ChatSmallBox