import React from 'react'
import TopInfo from './TopInfo'
import DetailMap from './DetailMap'
import DetailLeft from './DetailLeft'
import DetailRight from './DetailRight'
import Header from '../../Components/Common/Header'
import Footer from '../../Components/Common/Footer'
import DetailLeftDesc from './DetailLeftDesc'
import DetailBanner from './DetailBanner'
import AddPropertyReview from '../AddProperty/AddPropertyReview'
import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext'
import SaveModal from '../../Components/Frontend/Common/SaveModal'
import ContactInfoModal from '../../Components/Common/ContactInfoModal';
import MakeOffer from '../../Components/Common/MakeOffer';
import ShareModal from '../../Components/Common/ShareModal';
import SmShareModal from '../../Components/Frontend/Common/SmShareModal';
import RegisterModal from '../../Components/Frontend/Common/RegisterModal';
import SignIn from '../../Components/Frontend/Common/SignIn';
import WriteReviewModal from '../../Components/Frontend/Common/WriteReviewModal';
import AcceptOfferModal from '../../Components/RightWidgets/AcceptOfferModal';
import ViewReviewModal from '../../Components/Frontend/Common/ViewReviewModal'

const LandDetail = () => {
    const {user} = useContext(UserContext); 
    const { getProperty, saveProperty, addReview, saveLandIDScreenshot, acceptOffer } = useContext(PropertyContext);

    const queryParameters = new URLSearchParams(window.location.search)
    const place_id = queryParameters.get("place_id");
    const edit_layout = queryParameters.get("edit_layout");

    const [propertyData, setPropertyData] = useState({});
    
    const [propertyReviewBox, setPropertyReviewBox] = useState(false);

    const savePropertyButton = (save) => {
        var values = {
            'username': user.username,
            'place_id': propertyData.place_id,
            'save': save
        }

        saveProperty(values);
    }

    /*modal functions*/
    const [shareModal, setShareModal] = useState(false);
    const openShareModal = () => {

        console.log("open share modal");
        setShareModal(true);
    }
    // Make Offer Modal
    const [makeOffer, setMakeOffer] = useState(false);
    const openMakeOfferModal = () => {
        setMakeOffer(true);
    }

    // SM Share Modal
    const [smShareModal, setSmShareModal] = useState(false);
    const openSmShareModal = () => {
        setSmShareModal(true);
    }


    // Save Modal
    const [saveModal, setSaveModal] = useState(false);
    const [btnSaved, setBtnSaved] = useState(false);
    const openSaveModal = () => {

        savePropertyButton(!btnSaved);

        // document.getElementById("savebtn").classList.add('saved');
        setSaveModal(true);
        setBtnSaved(!btnSaved);
        setTimeout(()=>{
            setSaveModal(false);     
        },3000)
    }
    // Contact Info  Modal
    const [contactInfo, setContactInfo] = useState(false);
    const openContactInfoModal = () => {
        setContactInfo(true);
    }

    //open modal
    const openModal = (modal) => {
        console.log("open modal",modal);

        if(modal != "makeoffer" && user.username == "publicuser") {
            openSignInModal();
        } else {
            switch(modal) {
                case "save": openSaveModal(); break;
                case "makeoffer": openMakeOfferModal(); break;
                case "share": openShareModal(); break;
                case "smallshare": openSmShareModal(); break;
                case "contactinfo": openContactInfoModal(); break;
                case "review": openReviewModal(); break;
                case "viewoffers": openAcceptModal(); break;
            }
        }
    }

    const [offerValues,setOfferValues] = useState();
    const saveOfferValues = (values) => {
        setOfferValues(values);
    }

    //register modal
    const [registerModal, setRegisterModal] = useState(false);
    const openRegisterModal = () => {
        setRegisterModal(true);
    }

    //signin modal
    const [signIn, setSignIn] = useState(false);
    const openSignInModal = () => {
        setSignIn(true);
    }

    //review modal
    const [addReviewModal, setAddReviewModal] = useState(false);
    const openReviewModal = () => {
        setAddReviewModal(true);
    }

    const [landDealLoading, setLandDealLoading] = useState(true);

    const submitReview = (values) => {

        values.username = user.username;
        values.company_id = propertyData.company_id;
        addReview(values);
        setAddReviewModal(false)
    }

    //view offers
    const [acceptOfferModal, setAcceptOfferModal] = useState(false)
    const openAcceptModal = () => {
        setAcceptOfferModal(true)
    }
    const closeAcceptOfferModal = () => {
        setAcceptOfferModal(false)
    }

    /*modal functions*/

    const acceptOfferButton = async (offer_user,place_id,finalize_sale=false) => {
        var values = {
            user:user.username,
            offer_user: offer_user,
            place_id: place_id
        }
    
        if(finalize_sale) values.finalize_sale = true;
    
        const result = await acceptOffer(values);
    
        if(result.success) {
            alert(finalize_sale ? "Sale Finalized!" : "Offer Accepted!");
            window.location.reload();
        } else if(result.error)
            alert(result.error);
        else
            alert("some other issue");    
        
    }

    useEffect(() => {       

        console.log('edit layout',edit_layout,propertyReviewBox,place_id);

        const fetchData = async () => {
            const propData = await getProperty(place_id,user.username);
            setPropertyData(propData);
            setPropertyReviewBox(edit_layout !== null && user.company_id == propData.company_id);
            setBtnSaved(propData.saved);
            
            if(propData.static_map_loading) {
                console.log("attempting to get land.id screenshot");
                const screenshotSuccess = await saveLandIDScreenshot(place_id);
                if(screenshotSuccess) {
                    console.log("getting data again");
                    const propData2 = await getProperty(place_id,user.username);
                    setPropertyData(propData2);
                    setLandDealLoading(false);
                }
            } else {
                setLandDealLoading(false);
            }
        }        
        
        fetchData();        

    }, [place_id]); 

    const { state } = useLocation();

    useEffect(()=>{
        console.log(state);
        if(state && state.open_modal !== undefined){
            openModal(state.open_modal)
        }
    },[state]);

    // View Review
    const [openViewReview, setOpenViewReview] = useState(false);
    const clickOpenReview = () => {
        setOpenViewReview(true)
    }
    return (
        
        <>
            <Header/>
            {propertyData && propertyData.place_id !== undefined && 
                <section className="main-wrap ps-0 pe-0">
                    {propertyReviewBox ? <AddPropertyReview propertyData={propertyData}/> : ""}
                    {propertyData.layout && propertyData.layout == "photobanner" ? <DetailBanner propertyData={propertyData} landDealLoading={landDealLoading}/> : <DetailMap propertyData={propertyData} landDealLoading={landDealLoading}/>}
                    <TopInfo propertyData={propertyData} savePropertyButton={savePropertyButton} openModal={openModal} btnSaved={btnSaved}/>
                    <section className="dtl-block w-100">
                        <div className="container">
                            <div className="dtl-flex d-flex">
                                <DetailLeftDesc propertyData={propertyData}  landDealLoading={landDealLoading}/>
                                <DetailRight propertyData={propertyData} openModal={openModal} clickOpenReview={clickOpenReview}/>
                            </div>
                        </div>
                    </section>
                </section>
            }            
            {propertyData && propertyData.place_id !== undefined && <ShareModal shareModal={shareModal} setShareModal={setShareModal} propertyData={propertyData}/>}
            {propertyData && propertyData.place_id !== undefined && <MakeOffer makeOffer={makeOffer} setMakeOffer={setMakeOffer} propertyData={propertyData} openModal={openModal} saveOfferValues={saveOfferValues}/>}
            {propertyData && propertyData.place_id !== undefined && <SaveModal saveModal={saveModal} setSaveModal={setSaveModal} btnSaved={btnSaved}/>}
            {propertyData && propertyData.place_id !== undefined && <ContactInfoModal contactInfo={contactInfo} setContactInfo={setContactInfo} offerValues={offerValues}/>}
            {propertyData && propertyData.place_id !== undefined && <SmShareModal smShareModal={smShareModal} setSmShareModal={setSmShareModal} propertyData={propertyData}/>}
            <WriteReviewModal addReviewModal={addReviewModal} setAddReviewModal={setAddReviewModal} submitReview={submitReview} />
            <ViewReviewModal openViewReview={openViewReview} setOpenViewReview={setOpenViewReview} />
            <RegisterModal  registerModal={registerModal} setRegisterModal={setRegisterModal} />
            <SignIn signIn={signIn} setSignIn={setSignIn} />
            <AcceptOfferModal 
                acceptOfferModal={acceptOfferModal} 
                closeAcceptOfferModal={closeAcceptOfferModal}
                propertyData={propertyData}
                acceptOfferButton={acceptOfferButton}
            />
            {propertyData && propertyData.place_id === undefined && 
            <div className='no-land-dtl'>
                <div className='gray-box mxw-550'>
                    <div className='md-title fw-700 mb-4 text-center'>Sorry</div>
                    <p className='text-center lh-20'>This listing is set as private. <br/>Seller must change access settings.</p>
                </div>
            </div>
            }
            <Footer/>
        </>
        
    )
}

export default LandDetail