import deleteIcon from "../../assets/img/icon-delete-photo.svg";
import iconPdf from "../../assets/img/icon-pdf.svg";
import { Formik, Form, Field, useFormikContext, FieldArray } from "formik";
import * as yup from "yup";
import { useRef, useState, useEffect, useContext } from "react";
//import { EnvContext } from "../../context/EnvContext";
import { PropertyContext } from "../../context/PropertyContext";
import {UserContext} from '../../context/UserContext';

const validationSchema = yup.object({
  //description: yup.string().required("this field is required."),
});

const DueDiligenceDocuments = ({ nextStep, item, propertyData, allValues, sendFormikRef }) => {

  //const { s3FileUpload, dataURItoBlob } = useContext(EnvContext);
  const {uploadDocument, editFileHeaders, deleteDocument} = useContext(PropertyContext);
  const {user} = useContext(UserContext);

  const [formikData, setFormikData] = useState({documents:propertyData.documents,document_links:propertyData.doc_links}); 
  const formikRef = useRef(null);

  const [dummy, setDummy] = useState("");
  const [docUploading, setDocUploading] = useState(false);

  let boundArrayHelpers;

  const bindArrayHelpers = (arrayHelpers) => {
    boundArrayHelpers = arrayHelpers;
  }

  const addDocument = (filename,url,key) => {    

    boundArrayHelpers.push({"name": filename, "privacy": "private", "url" : url, "reel" : false, "key" : key});

    setDummy(formikRef.current.values.documents.length);
      
  }

  useEffect(() => {
    sendFormikRef('documents',formikRef.current);
  }, [formikRef]);

  const docUploads =  (e) => {
      setDocUploading(true);
      for (const file of e.target.files) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async (e) => {

              /*file.src = dataURItoBlob(reader.result);

              var url = await s3FileUpload(file,'propertyfiles/' + (propertyData.place_id ? propertyData.place_id : ""));

              console.log(url,file);

              addDocument(file.name,url);

              return {"name": file.name, "privacy": "private", "url" : url, "reel" : false};*/

              const formData = new FormData();
              formData.append('place_id',allValues.place_id ? allValues.place_id : "");
              formData.append('file', file);
              formData.append('fileName', file.name);
              formData.append('metadata', JSON.stringify({'privacy':'private','reels':'false'}));
              const upload = uploadDocument(formData);
              upload.then((data)=>{
                addDocument(file.name,data.url,data.data.key);
                setDocUploading(false);
                return {"name": file.name, "privacy": "private", "url" : data.url, "reel" : false, "key": data.data.key};
              });
              
          };
          reader.onerror = () => {
              console.log(reader.error);
              setDocUploading(false);
          };
      }
  }; 

  useEffect(() => { 
    nextStep(0,{"documents":formikRef.current.values.documents,"document_links":formikRef.current.values.document_links});
  }, [formikData,dummy]); 

  const prevValues = useRef(null);

  const SendFormikData = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      
      if(JSON.stringify(values) != prevValues.current) {
        nextStep(0,values);      
        console.log(values,JSON.stringify(values));
        prevValues.current = JSON.stringify(values);
      }
    }, [JSON.stringify(values)]);
    return null;
  };

  //console.log(link);
  return (
    <>

      <div className="accordion-body ddd-body">
        <Formik
          validationSchema={validationSchema}
          initialValues={formikData}
          onSubmit={(values) => {
            console.log(values);
            nextStep(item + 1,values);
          }}
          enableReinitialize={true}
          innerRef={formikRef}
        >
          {({ values }) => (
            <Form>
              <SendFormikData />
              {/* Document List */}

              <FieldArray name="documents">
                {arrayHelpers => {
                  bindArrayHelpers(arrayHelpers);
                  return (
                  <div className="ddd-box">
                    <div className="xs-title fw-500 text-start font-16">
                      Document List
                    </div>
                    <table className="custom-table w-100 document-list-table mt-3">
                      <thead>
                          <tr key="header">
                            <th className="text-uppercase text-start ps-0" style={{ width: "50%", }}>
                              Upload Documents
                            </th>
                            <th className="text-uppercase text-center" style={{ width: "10%" }}>
                              Private
                            </th>
                            <th className="text-uppercase text-center" style={{ width: "10%" }}>
                              Members Only
                            </th>
                            <th className="text-uppercase text-center" style={{ width: "10%" }}>
                              Public
                            </th>
                            <th className="text-uppercase text-center" style={{ width: "10%" }}>
                              <span className="info-rel position-relative">
                                <span className="info-icon"></span>
                                <span className="info-pos">
                                  This makes the file available on the photos section
                                  too.
                                </span>
                              </span>
                              <br />
                              Add To Reel
                            </th>
                          </tr>
                      </thead>
                      <tbody>
                        {values.documents.map((item, index) => { 
                          return (
                            <tr key={index}>
                              <td style={{ width: "50%" }}>
                                <div className="dlt-label">Upload Documents</div>
                                <div className="d-flex align-items-center">
                                  <div className="col-10">
                                    <div className="position-relative dlt-control">
                                    <Field 
                                        type="hidden"
                                        className="form-control"
                                        name={`documents.${index}.url`}
                                      />
                                      <Field 
                                        type="text"
                                        className="form-control"
                                        name={`documents.${index}.name`}
                                      />
                                        {/*({ field, form, meta }) => (
                                          <div>
                                            <input
                                              type="text"
                                              {...field}
                                              value={item.name}
                                              className="form-control"
                                              name={"document_name_" + index}
                                            />
                                            {meta.touched && meta.error && (
                                              <div className="error">{meta.error}</div>
                                            )}
                                          </div>
                                            )}
                                      </Field>*/}
                                      <span className="dtl-icon">
                                        <img src={iconPdf} alt="iconPdf" />
                                      </span>

                                      <span className="dtl-icon">
                                        <img src={iconPdf} alt="iconPdf" />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-2 text-center">
                                    <div className="action-link" onClick={() => {
                                        console.log(allValues,user,values.documents[index]);
                                        deleteDocument(allValues.place_id,user.username,values.documents[index]['name']);
                                        arrayHelpers.remove(index);
                                      }}>
                                      <img src={deleteIcon} alt="delete" />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center" style={{ width: "10%" }}>
                                <label className="switch">
                                  <Field
                                    type="radio"
                                    name={`documents.${index}.privacy`}
                                    value="private"
                                    onClick={(e) => { editFileHeaders(values.documents[index]['key'],{"privacy":e.target.value}); }}
                                  />
                                  <div className="slider"></div>
                                </label>
                              </td>
                              <td className="text-center" style={{ width: "10%" }}>
                                <label className="switch">
                                  <Field
                                    type="radio"
                                    name={`documents.${index}.privacy`}
                                    value="members_only"
                                    onClick={(e) => { editFileHeaders(values.documents[index]['key'],{"privacy":e.target.value}); }}
                                  />
                                  <div className="slider"></div>
                                </label>
                              </td>
                              <td className="text-center" style={{ width: "10%" }}>
                                <label className="switch">
                                  <Field
                                    type="radio"
                                    name={`documents.${index}.privacy`}
                                    value="public"
                                    onClick={(e) => { editFileHeaders(values.documents[index]['key'],{"privacy":e.target.value}); }}
                                  />
                                  <div className="slider"></div>
                                </label>
                              </td>
                              <td className="text-center" style={{ width: "10%" }}>
                                <div className="custom-checkbox sm-checkbox d-inline-block">
                                  <Field
                                    type="checkbox"
                                    name={`documents.${index}.reel`}
                                    className="styled-checkbox"
                                    id={"checkbox_" + index}
                                    checked={values.documents[index].reel}
                                    onClick={(e) => { 
                                      //console.log(values.documents[index].reel,e.target.checked,values.documents[index].reel === "true",!values.documents[index].reel,!!values.documents[index].reel);
                                      editFileHeaders(values.documents[index]['key'],{"reels":e.target.checked + ""}); 
                                      values.documents[index].reel = !values.documents[index].reel;
                                      //console.log(values.documents[index].reel,e.target.checked,values.documents[index].reel === "true",!values.documents[index].reel,!!values.documents[index].reel);
                                    }}
                                  />
                                  <label htmlFor={"checkbox_" + index}></label>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                        <tr className="doc-list-fields">
                          <td style={{ width: "50%" }}>
                            <div className="row">
                              {/*<div className="col-8">
                                <select ref={addDocumentUpload} className="form-control" id="addDocumentDropDown" defaultValue={'DEFAULT'}>
                                  <option value="DEFAULT" disabled >Select or Type Name</option>
                                  <option value="image">Image</option>
                                  <option value="pdf">PDF</option>
                                </select>
                              </div>*/}
                              <div className="col-4">
                                {docUploading ? <>
                                  <button className='theme-btn text-uppercase sm-btn upload-photo-btn plr-65 disabled' disabled={true}>
                                    <div className="float-points">
                                      <div className="dot-collision"></div>
                                    </div>
                                  </button>
                                </> : <>
                                  <button className="theme-btn text-uppercase sm-btn upload-photo-btn plr-65">
                                    <input type="file" onChange={(e) => { docUploads(e); }} name="file" multiple />
                                    Browse
                                  </button>
                                </>}
                                
                              </div>
                            </div>
                          </td>
                          <td style={{ width: "10%" }}>&nbsp;</td>
                          <td style={{ width: "10%" }}>&nbsp;</td>
                          <td style={{ width: "10%" }}>&nbsp;</td>
                          <td style={{ width: "10%" }}>&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                    {/*<div className="mt-3">
                      <div className="trns-edit-btn text-uppercase" onClick={addDocument}>
                        <span className="plus-icon"></span> Add Document
                      </div>
                    </div>*/}
                  </div>
                )}}
              </FieldArray>


              {/* Property Links */}
              <FieldArray name="document_links">
                {({ push, remove }) => (
                  <div className="ddd-box mt-30">

                    <div className="xs-title fw-500 text-start font-16">
                      Property Links
                    </div>
                    <table className="custom-table w-100 document-list-table property-links-table mt-3">
                        <thead>
                          <tr>
                            <th
                              className="text-uppercase text-start ps-0"
                              style={{ width: "25%" }}
                            >
                              Link Title/Description
                            </th>
                            <th
                              className="text-uppercase text-start ps-0"
                              style={{ width: "25%" }}
                            >
                              URL
                            </th>
                            <th className="text-uppercase text-center" style={{ width: "10%" }}>
                              Private
                            </th>
                            <th className="text-uppercase text-center" style={{ width: "10%" }}>
                              Members Only
                            </th>
                            <th className="text-uppercase text-center" style={{ width: "10%" }}>
                              Public
                            </th>
                          </tr>
                        </thead>
                      <tbody>

                        {values.document_links.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ width: "25%" }}>
                                {/*<div className="dlt-label">Link Title/Description</div>*/}
                                <div className="d-flex align-items-center">
                                  <div className="col-12">
                                    <div className="position-relative dlt-control">
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name={`document_links.${index}.description`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ width: "25%" }}>
                                {/*<div className="dlt-label">URL</div>*/}
                                <div className="d-flex align-items-center">
                                  <div className="col-10">
                                    <div className="position-relative dlt-control">
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name={`document_links.${index}.url`}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-2 text-center">
                                    <button type="button" className="action-link" onClick={() => remove(index)}>
                                      <img src={deleteIcon} alt="delete" />
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td className="text-center" style={{ width: "10%" }}>
                                <label className="switch">
                                  <Field
                                    type="radio"
                                    name={`document_links.${index}.privacy`}
                                    value="private"
                                  />
                                  <div className="slider"></div>
                                </label>
                              </td>
                              <td className="text-center" style={{ width: "10%" }}>
                                <label className="switch">
                                  <Field
                                    type="radio"
                                    name={`document_links.${index}.privacy`}
                                    value="members_only"
                                  />
                                  <div className="slider"></div>
                                </label>
                              </td>
                              <td className="text-center" style={{ width: "10%" }}>
                                <label className="switch">
                                  <Field
                                    type="radio"
                                    name={`document_links.${index}.privacy`}
                                    value="public"
                                  />
                                  <div className="slider"></div>
                                </label>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <div className="mt-3">
                      <div className="trns-edit-btn text-uppercase" type="button"
                        onClick={() => push({ description: '', url: '', privacy: "private" })}
                      >
                        <span className="plus-icon"></span> Add Link
                      </div>
                    </div>                 
                  </div>
                )}
              </FieldArray>
              <div className="text-end mt-4">
                <button
                  className="theme-btn text-uppercase w-25 sm-btn"
                  type="submit"
                >
                  NEXT
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default DueDiligenceDocuments;
