import React from 'react'
import { Link } from 'react-router-dom'
import fbIcon from '../../../assets/img/frontend/fb-icon.svg'
import instaIcon from '../../../assets/img/frontend/insta-icon.svg'
import inIcon from '../../../assets/img/frontend/link-icon.svg'

const Footer = () => {
  return (
    <>
      <footer className='front-footer-wrap'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-12 text-end order-2'>
              <div className='d-inline-flex align-items-center'>
                <ul className='front-social-link d-inline-flex align-items-center order-2'>
                  <li>
                    <Link to="/"><img src={fbIcon} /></Link>
                  </li>
                  <li>
                    <Link to="/"><img src={instaIcon} /></Link>
                  </li>
                  <li>
                    <Link to="/"><img src={inIcon} /></Link>
                  </li>
                </ul>
                <ul className='d-inline-flex front-f-menu'>
                  <li>
                    <Link to="/">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-12 front-copyright'>
              &copy; 2023 Copyright Info Here
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer