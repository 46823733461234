import { ErrorMessage, useField, Field } from 'formik';
import { ValidationError } from '../Errors/ValidationError';

const TextAreaField = ({ label,...props }) => {
    const [field, meta] = useField(props);
  return (
    <>
      <div className="form-group">
        {label && <label htmlFor={field.name}>{label}</label>}
        <textarea className={props.class || `form-control`} {...field} {...props}/>
        {meta.touched && meta.error ? (
                    <ValidationError name={field.name} />

                ) : null}
                {props.tip ? <div className="tip-text mt-1">
                    {props.tip}
                  </div> : ""}
      </div>
    </>
  );
};

export default TextAreaField;