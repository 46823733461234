import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../Components/Common/Header";
import { NavLink } from "react-router-dom";
import loctImg from "../../assets/img/property-thumb.jpg";
import ManageDocumentsIcon from "../../assets/img/lm-icon1.svg";
import ManagePhotosIcon from "../../assets/img/lm-icon2.svg";
import WebAnalyticsIcon from "../../assets/img/lm-icon3.svg";
import SetRemindersIcon from "../../assets/img/lm-icon4.svg";
import hmMenuIcon from "../../assets/img/dots.png";
import iconSuperuser from "../../assets/img/icon-superuser.svg";
import iconAdministrator from "../../assets/img/icon-admin.svg";
import iconEye from "../../assets/img/icon-eye.svg";
import iconUsers from "../../assets/img/icon-users.svg";
import iconCard from "../../assets/img/icon-card.svg";
import iconSubcompany from "../../assets/img/icon-subcompany.svg";
import Footer from "../../Components/Frontend/Common/Footer";
import AddUser from "./AddUser";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import closeIcon from '../../assets/img/close-modal.svg'
import {UserContext} from '../../context/UserContext';
import AdminLeft from "../../Components/Admin/AdminLeft";

const Preferences = () => {
  const {user, addLandDealUser, getLandDealUsers, changeUserData, deleteUser} = useContext(UserContext);

  // Register Modal˜
  const [addUserModal, setAddUserModal] = useState(false);
  const [userType, setUserType] = useState("");

  const [addCompany, setAddCompany] = useState(false);

  const openAddUserModal = (userType,company=false) => {
    setAddCompany(company);
    setUserType(userType);
    setAddUserModal(true);
  };

  const [allUserData, setAllUserData] = useState();

  const [superAdmins, setSuperAdmins] = useState();
  const [admins, setAdmins] = useState();
  const [users, setUsers] = useState();

  const [newUserCount, setNewUserCount] = useState(0);

  const [searchText, setSearchtext] = useState("");

  const handleDragEnd = (result) => {
    if (!result.destination) return; // Item was not dropped in a valid droppable area

    const { source, destination } = result;

    // Determine the source and destination lists based on their droppableIds
    let sourceList, destinationList;
    switch (source.droppableId) {
      case 'superuser':
        sourceList = superAdmins;
        break;
      case 'admin':
        sourceList = admins;
        break;
      case 'viewonly':
        sourceList = users;
        break;
      default:
        break;
    }
    switch (destination.droppableId) {
      case 'superuser':
        destinationList = superAdmins;
        break;
      case 'admin':
        destinationList = admins;
        break;
      case 'viewonly':
        destinationList = users;
        break;
      default:
        break; 
    }


    const [removed] = sourceList.splice(source.index, 1);

    if(removed.username == user.username) {
      alert("Cannot change own role");
      destinationList = sourceList;
    } else {
      var values = {
        'username':removed.username,
        'field':'role',
        'value':destination.droppableId
      }

      changeUserData(values);
    }

    destinationList.splice(destination.index, 0, removed);

    // Create new arrays to update the state
    const newSuperAdmins = [...superAdmins];
    const newAdmins = [...admins];
    const newUsers = [...users];
    setSuperAdmins(newSuperAdmins);
    setAdmins(newAdmins);
    setUsers(newUsers);
  };

  const [editingId, setEditingId] = useState(null);
  const [editedName, setEditedName] = useState("");
  const handleRename = (id) => {
    setEditingId(id);
  };
  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };
  
  const handleSaveName = (id, from, username) => {
    // Get the original name from the respective user list based on the provided id
    let originalName;
    if (from === 'superuser') {
      const admin = superAdmins.find((admin) => admin.id === id);
      originalName = admin ? admin.name : '';
    } else if (from === 'admin') {
      const admin = admins.find((admin) => admin.id === id);
      originalName = admin ? admin.name : '';
    } else if (from === 'viewonly') {
      const user = users.find((user) => user.id === id);
      originalName = user ? user.name : '';
    }
  
    // If the editedName is empty, use the original name; otherwise, use the edited name
    const newName = editedName.trim() === '' ? originalName : editedName;
  
    // Update the name in the respective user list
    if (from === 'superuser') {
      const updatedSuperAdmins = superAdmins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            name: newName,
          };
        }
        return admin;
      });
  
      setSuperAdmins(updatedSuperAdmins);
    } else if (from === 'admin') {
      const updatedAdmins = admins.map((admin) => {
        if (admin.id === id) {
          return {
            ...admin,
            name: newName,
          };
        }
        return admin;
      });
      setAdmins(updatedAdmins);
    } else if (from === 'viewonly') {
      const updatedUsers = users.map((user) => {
        if (user.id === id) {
          return {
            ...user,
            name: newName,
          };
        }
        return user;
      });
      setUsers(updatedUsers);
    }
  
    // Reset the editing state only if the user entered a non-empty value
    if (newName !== '') {
      setEditingId(null);
      setEditedName("");

      var values = {
        'username':username,
        'field':'full_name',
        'value':newName
      }
  
      changeUserData(values);
    }
  };
  
  
  

  // Delete Button
  const handleDelete = (id, from, username) => {
    if(from === 'superuser'){
      const updatedSuperAdmins = superAdmins.filter((admin) => admin.id !== id);
      setSuperAdmins(updatedSuperAdmins);
    }
    if(from === 'admin'){
      const updatedSuperAdmins = admins.filter((admin) => admin.id !== id);
      setAdmins(updatedSuperAdmins);
    }
    if(from === 'viewonly'){
      const updatedSuperAdmins = users.filter((admin) => admin.id !== id);
      setUsers(updatedSuperAdmins);
    }

    var values = {
      'username':username
    }

    deleteUser(values);
  };
  

  // Move To
  const [itemMoved, setItemMoved] = useState(1)
  const handleMove = (id, destination, from, username) => {
    console.log("id, destination", id, destination);

    const itemToMove = superAdmins.find((admin) => admin.id === id) ||
    admins.find((admin) => admin.id === id) ||
    users.find((admin) => admin.id === id);
    
    if (!itemToMove) {
      return; // Exit if the item to move is not found
    }
    const updatedSuperAdmins = superAdmins.filter((admin) => admin.id !== id);
    const updatedAdmins = admins.filter((admin) => admin.id !== id);
    const updatedUsers = users.filter((admin) => admin.id !== id);

    if(from === 'superuser'){
      setSuperAdmins(updatedSuperAdmins)
    }
    if(from === 'admin'){
      setAdmins(updatedAdmins)
    }
    if(from === 'viewonly'){
      setUsers(updatedUsers)
    }
    switch (destination) {
      case 'superuser':
        setSuperAdmins([...updatedSuperAdmins, itemToMove]);
        setItemMoved(itemMoved+1);
        break;
      case 'admin':
        setAdmins([...updatedAdmins, itemToMove]);
        setItemMoved(itemMoved+1);

        break;
      case 'viewonly':
        setUsers([...updatedUsers, itemToMove]);
        setItemMoved(itemMoved+1);
        break;
      default:
        break;
    }
    closemoveFolderModal();

    var values = {
      'username':username,
      'field':'role',
      'value':destination
    }

    changeUserData(values);
  };

  const [moveFolderModal, setMoveFolderModal] = useState()
  const closemoveFolderModal = () => {
      setMoveFolderModal(false);
  }


  const MoveModal = (props) => {
    return <div className={`modal sm pmovemodal fade custom-modal common-modal ${moveFolderModal === props.id ? "show" : ""}`} id="moveFolderModal" data-bs-backdrop="static" data-bs-keyboard="false"
        tabIndex="-1" aria-labelledby="moveFolderModalLabel" aria-hidden="true">
        <div className="modal-dialog mxw-820 modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                    <button type="button" className="btn-close" onClick={closemoveFolderModal}>
                        <img src={closeIcon} alt="close" />
                    </button>
                    <div className="xl-title mb-4 text-center">Move to</div>
                    <div className="xs-title mb-4 text-center font-18 fw-400">Current Location : {props.location}</div>
                    <div className='mt-5 bw-top folder-list'>
                        <ul>
                            <li className={`${props.location === 'superuser' ? 'd-none': ''}`} ><button onClick={()=>handleMove(props.id, 'superuser', props.location, props.username)} type='button' className='move-right-btn'><div>Super Admin</div> <span className='theme-text'>Move <span className='mr-arrow'></span></span></button></li>
                            <li className={`${props.location === 'admin' ? 'd-none': ''}`} ><button onClick={()=>handleMove(props.id, 'admin', props.location, props.username)} type='button' className='move-right-btn'><div>Adminstrative</div> <span className='theme-text'>Move <span className='mr-arrow'></span></span></button></li>
                            <li className={`${props.location === 'viewonly' ? 'd-none': ''}`} ><button onClick={()=>handleMove(props.id, 'viewonly', props.location, props.username)} type='button' className='move-right-btn'><div>View Only</div> <span className='theme-text'>Move <span className='mr-arrow'></span></span></button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  }

  const searchChange = (search) => {
    setSearchtext(search);

    if(allUserData['superuser']) {
      setSuperAdmins(
        allUserData['superuser'].filter(user => {
          return user.name.includes(search) || user.email.includes(search)
        })
      )
    }

    if(allUserData['admin']) {
      setAdmins(
        allUserData['admin'].filter(user => {
          return user.name.includes(search) || user.email.includes(search)
        })
      )
    }

    if(allUserData['viewonly']) {
      setUsers(
        allUserData['viewonly'].filter(user => {
          return user.name.includes(search) || user.email.includes(search)
        })
      )
    }
  }

  const registerForm = async (registerFormData) => {

      registerFormData.role = userType;
      if(registerFormData.company == undefined || !addCompany) {
        registerFormData.company_id = user.company_id;
        registerFormData.company = user.company;
      } else {
        registerFormData.company_id = registerFormData.company.replace(/\s/g, "").toLowerCase();
      }

      console.log(registerFormData);

      const data = await addLandDealUser(registerFormData);
      if(data.success == 1) {
          alert("User added!");
          setAddUserModal(false);
          setNewUserCount(newUserCount + 1);
      } else if(data.success == 0 && data.message)
          alert(data.message);    
  }

  useEffect(() => { 
    const fetchData = async () => {
   
      const userData = await getLandDealUsers(user.company_id);

      console.log(userData);

      setAllUserData(userData);

      setSuperAdmins(userData['superuser'] ? userData['superuser'] : []);
      setAdmins(userData['admin'] ? userData['admin'] : []);
      setUsers(userData['viewonly'] ? userData['viewonly'] : []);
    }

    fetchData().catch(console.error);
  }, [newUserCount]); 


  return (
    <>
      <Header />
      <section key={itemMoved}  className="main-wrap ps-0 pe-0">
        <div className="add-property-block">
          <div className="container">
            <div className="gray-box add-property-box">
              <div className="d-flex apb-flex pt-4">
                <AdminLeft cardTitle="Admin for:" userCompany={user.company}/>
                <div className="apb-right">
                  <div className="xl-title text-uppercase">
                    Users & Permissions
                  </div>
                  <div className="d-flex align-items-center apb-top-filter mb-4 bw-top mt-3 pt-4">
                    <div
                      className="search-block sp-search-block ad-search-block"
                      style={{ width: "35%" }}
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by Name"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          onChange={(e)=>{searchChange(e.target.value)}}
                          value={searchText}
                        />
                        <span className="search-icon"></span>
                      </div>
                    </div>
                    {user.username == "testuser" && <div className="text-center">
                      <button
                        type="button"
                        className="theme-trans-btn font-12 text-uppercase add-user-btn sm-btn"
                        onClick={() => {openAddUserModal('superuser',true);}}
                      >
                        <span className="plus-icon"></span> Add User & Company
                      </button>
                    </div>}
                  </div>

                  <div className="up-block">
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <div className="up-box d-flex align-items-center mb-4">
                        <div className="up-box-left">
                          {/* Super User list */}
                          <div className="upb-img text-center">
                            <img src={iconSuperuser} alt="Superuser" />
                          </div>
                          <div className="xs-title text-uppercase text-white ext-center font-18 mt-3">
                            Super User
                          </div>
                          <div className="xs-title ext-center fw-400 font-15 mt-3 text-gray">
                            Make Offers, Accept Offers, List Properties, Edits
                            Listings, View and Edit All Documents
                          </div>
                          <div className="mt-5 text-center">
                            <button
                              type="button"
                              className="theme-trans-btn font-12 text-uppercase add-user-btn sm-btn"
                              onClick={() => {openAddUserModal('superuser')}}
                            >
                              <span className="plus-icon"></span> Add User
                            </button>
                          </div>
                        </div>
                        <div className="up-box-right">
                          <div className="custom2-table up-table">
                            <div className="up-table-header">
                              <table className="table w-100 table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th style={{ width: "30%" }}>Name</th>
                                    <th style={{ width: "50%" }}>Email</th>
                                    <th style={{ width: "20%" }}>&nbsp;</th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div  className="up-table-body">
                              <Droppable droppableId='superuser'>
                                {(provided) => (
                                  <div
                                    className="drag-container"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    <table  className="table-striped table w-100">
                                      <tbody>
                                        {superAdmins && superAdmins.map((item, index) => (
                                          <Draggable
                                            draggableId={item.id.toString()}
                                            index={index}
                                            key={`${item.id}`}
                                          >
                                            {(provided) => (
                                              <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="userswraper"
                                              >
                                                <td style={{ width: "30%" }}>
                                                  <div className="editableField">
                                                    {editingId === item.id ? (
                                                      <input
                                                        type="text"
                                                        defaultValue={item.name}
                                                        onChange={
                                                          handleNameChange
                                                        }
                                                        placeholder="Enter new name"
                                                      />
                                                    ) : (
                                                      <span
                                                        onClick={() =>
                                                          handleRename(item.id)
                                                        }
                                                      >
                                                        {item.name}
                                                      </span>
                                                    )}
                                                    {editingId === item.id ? (
                                                      <button
                                                        onClick={() =>
                                                          handleSaveName(
                                                            item.id, 'superuser', item.username
                                                          )
                                                        }
                                                      >
                                                        Save
                                                      </button>
                                                    ) : null}
                                                  </div>

                                                  {/* {item.name} */}
                                                </td>
                                                <td
                                                  style={{ width: "50%" }}
                                                  className="theme-text"
                                                >
                                                  {item.email}
                                                </td>
                                                <td
                                                  style={{ width: "20%" }}
                                                  className="text-end"
                                                >
                                                  <div className="btn-group cs-dropdown-option">
                                                    <MoveModal id={item.id} location={'superuser'} username={item.username}/>
                                                  
                                                    <div className="dropdown">
                                                          <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <img src={hmMenuIcon} alt="menu" />
                                                          </button>
                                                          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            <li><button className="dropdown-item" onClick={() => handleDelete(item.id, 'superuser', item.username)} type="button">Delete</button></li>
                                                            <li><button className="dropdown-item" onClick={() => handleRename(item.id)} type="button">Rename</button></li>
                                                            <li><button className="dropdown-item" type="button" onClick={() => setMoveFolderModal(item.id)} > Move to</button></li>
                                                          </ul>
                                                      </div>

                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        ))}
                                        {superAdmins && superAdmins.length < 1 ? <tr><td><div className="userEmpty">
                                          <p>Add your first Super User.</p>
                                        </div></td></tr>:''}
                                        {provided.placeholder}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="up-box d-flex align-items-center mb-4">
                        <div className="up-box-left">
                          <div className="upb-img text-center">
                            <img src={iconAdministrator} alt="Administrator" />
                          </div>
                          <div className="xs-title text-uppercase text-white ext-center font-18 mt-3">
                            Administrator
                          </div>
                          <div className="xs-title ext-center fw-400 font-15 mt-3 text-gray">
                            List Properties, Edits Listings, View and Edit All
                            Documents, Can’t List for Sale or Accept Offers
                          </div>
                          <div className="mt-5 text-center">
                            <button
                              type="button"
                              className="theme-trans-btn font-12 text-uppercase add-user-btn sm-btn"
                              onClick={() => {openAddUserModal('admin')}}
                            >
                              <span className="plus-icon"></span> Add User
                            </button>
                          </div>
                        </div>
                        <div className="up-box-right">
                          <div className="custom2-table up-table">
                            <div className=" up-table-header">
                              <table className="table w-100 table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th style={{ width: "30%" }}>Name</th>
                                    <th style={{ width: "50%" }}>Email</th>
                                    <th style={{ width: "20%" }}>&nbsp;</th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div  className="up-table-body">

                                <Droppable droppableId='admin'>
                                {(provided) => (
                                  <div
                                    className="drag-container"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    <table  className="table-striped table w-100">
                                      <tbody>
                                        {admins && admins.map((item, index) => (
                                          <Draggable
                                            draggableId={item.id.toString()}
                                            index={index}
                                            key={`${item.id}`}
                                          >
                                            {(provided) => (
                                              <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="userswraper"
                                              >
                                                <td style={{ width: "30%" }}>
                                                  <div className="editableField">
                                                    {editingId === item.id ? (
                                                      <input
                                                        type="text"
                                                        defaultValue={item.name}
                                                        onChange={
                                                          handleNameChange
                                                        }
                                                        placeholder="Enter new name"
                                                      />
                                                    ) : (
                                                      <span
                                                        onClick={() =>
                                                          handleRename(item.id)
                                                        }
                                                      >
                                                        {item.name}
                                                      </span>
                                                    )}
                                                    {editingId === item.id ? (
                                                      <button
                                                        onClick={() =>
                                                          handleSaveName(
                                                            item.id, 'admin', item.username
                                                          )
                                                        }
                                                      >
                                                        Save
                                                      </button>
                                                    ) : null}
                                                  </div>

                                                  {/* {item.name} */}
                                                </td>
                                                <td
                                                  style={{ width: "50%" }}
                                                  className="theme-text"
                                                >
                                                  {item.email}
                                                </td>
                                                <td
                                                  style={{ width: "20%" }}
                                                  className="text-end"
                                                >
                                                  <div className="btn-group cs-dropdown-option">
                                                    <MoveModal id={item.id} location={'admin'} username={item.username} />
                                                        <div className="dropdown">
                                                          <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <img src={hmMenuIcon} alt="menu" />
                                                          </button>
                                                          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            <li><button className="dropdown-item" onClick={() => handleDelete(item.id, 'superuser', item.username)} type="button">Delete</button></li>
                                                            <li><button className="dropdown-item" onClick={() => handleRename(item.id)} type="button">Rename</button></li>
                                                            <li><button className="dropdown-item" type="button" onClick={() => setMoveFolderModal(item.id)} > Move to</button></li>
                                                          </ul>
                                                      </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </Draggable>
                                        ))}
                                        {admins && admins.length < 1 ? <tr><td><div className="userEmpty">
                                          <p>Add your first Administrator user.</p>
                                        </div></td></tr>:''}
                                        {provided.placeholder}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </Droppable>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="up-box d-flex align-items-center mb-4">
                        <div className="up-box-left">
                          <div className="upb-img text-center">
                            <img src={iconEye} alt="Superuser" />
                          </div>
                          <div className="xs-title text-uppercase text-white ext-center font-18 mt-3">
                            View Only
                          </div>
                          <div className="xs-title ext-center fw-400 font-15 mt-3 text-gray">
                            View all documents and listings. Can’t Add or Edit
                            Properties. Can’t Make or Accept Offers
                          </div>
                          <div className="mt-5 text-center">
                            <button
                              type="button"
                              className="theme-trans-btn font-12 text-uppercase add-user-btn sm-btn"
                              onClick={() => {openAddUserModal('viewonly')}}
                            >
                              <span className="plus-icon"></span> Add User
                            </button>
                          </div>
                        </div>
                        <div className="up-box-right">
                          <div className="custom2-table up-table">
                            <div className=" up-table-header">
                              <table className="table w-100 table-striped mb-0">
                                <thead>
                                  <tr>
                                    <th style={{ width: "30%" }}>Name</th>
                                    <th style={{ width: "50%" }}>Email</th>
                                    <th style={{ width: "20%" }}>&nbsp;</th>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                            <div className="up-table-body">

                                <Droppable droppableId='viewonly'>
                                {(provided) => (
                                  <div
                                    className="drag-container"
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                  >
                                    <table  className="table-striped table w-100">
                                      <tbody>
                                        {users && users.map((item, index) => (
                                          <Draggable
                                            draggableId={item.id.toString()}
                                            index={index}
                                            key={`${item.id}`}
                                          >
                                            {(provided) => (
                                              <tr
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="userswraper"
                                              >
                                                <td style={{ width: "30%" }}>
                                                  <div className="editableField">
                                                    {editingId === item.id ? (
                                                      <input
                                                        type="text"
                                                        defaultValue={item.name}
                                                        onChange={
                                                          handleNameChange
                                                        }
                                                        placeholder="Enter new name"
                                                      />
                                                    ) : (
                                                      <span
                                                        onClick={() =>
                                                          handleRename(item.id)
                                                        }
                                                      >
                                                        {item.name}
                                                      </span>
                                                    )}
                                                    {editingId === item.id ? (
                                                      <button
                                                        onClick={() =>
                                                          handleSaveName(
                                                            item.id, 'viewonly', item.username
                                                          )
                                                        }
                                                      >
                                                        Save
                                                      </button>
                                                    ) : null}
                                                  </div>

                                                  {/* {item.name} */}
                                                </td>
                                                <td
                                                  style={{ width: "50%" }}
                                                  className="theme-text"
                                                >
                                                  {item.email}
                                                </td>
                                                <td
                                                  style={{ width: "20%" }}
                                                  className="text-end"
                                                >
                                                  <div className="btn-group cs-dropdown-option">
                                                    <MoveModal id={item.id} location={'viewonly'} username={item.username} />

                                                  <div className="dropdown">
                                                          <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                                          <img src={hmMenuIcon} alt="menu" />
                                                          </button>
                                                          <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                                            <li><button className="dropdown-item" onClick={() => handleDelete(item.id, 'viewonly', item.username)} type="button">Delete</button></li>
                                                            <li><button className="dropdown-item" onClick={() => handleRename(item.id)} type="button">Rename</button></li>
                                                            <li><button className="dropdown-item" type="button" onClick={() => setMoveFolderModal(item.id)} > Move to</button></li>
                                                          </ul>
                                                      </div>
                                                      </div>
                                                </td> 
                                                
                                              </tr>
                                            )}
                                          </Draggable>
                                        ))}

                                        {users && users.length < 1 ? <tr><td><div className="userEmpty">
                                          <p>Add your first view only user.</p>
                                        </div></td></tr>:''}

                                        {provided.placeholder}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </Droppable>

                            </div>
                          </div>
                        </div>
                      </div>
                    </DragDropContext>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <AddUser addUserModal={addUserModal} setAddUserModal={setAddUserModal} userType={userType} registerForm={registerForm} addCompany={addCompany} />
    </>
  );
};
export default Preferences;
