import mapNew from "../../assets/img/map11.jpg";
import deleteIcon from "../../assets/img/icon-delete-photo.svg";
import imgStep3 from "../../assets/img/mapSte3.png";
import TextField from "../Form/TextField";
import { Formik, Form, Field, useFormikContext } from "formik";
import * as yup from "yup";
import { useEffect, useState, useContext, useRef } from 'react';
import { EnvContext } from '../../context/EnvContext';
import { PropertyContext } from "../../context/PropertyContext";
import { UserContext } from "../../context/UserContext";

const validationSchema = yup.object({
  //map_link: yup.string().required("Map Link field is required."),
  address: yup.string().required("Address field is required."),
});

const MapIntegration = ({ nextStep, setNextActiveHeading, item, tryAddressChange, propertyData, sendFormikRef, setListingAlert, placeIDValidation }) => {

  const { landdealBaseURL } = useContext(EnvContext);
  const { uploadDocument } = useContext(PropertyContext);
  const { user } = useContext(UserContext);

  const [mapSaved, setMapSaved] = useState(false);
  const [shapesDrawn, setShapesDrawn] = useState(propertyData.map_link && propertyData.map_link.includes('/kml/'));

  const [formikData, setFormikData] = useState({
    map_link: propertyData && propertyData.map_link ? propertyData.map_link : (propertyData && propertyData.mapright_link ? propertyData.mapright_link : ""),
    address: propertyData && propertyData.address ? propertyData.address : "",
    place_id: propertyData && propertyData.place_id ? propertyData.place_id : "",
    latitude: propertyData && propertyData.latitude ? propertyData.latitude : "",
    longitude: propertyData && propertyData.longitude ? propertyData.longitude : "",
    city: propertyData && propertyData.city ? propertyData.city : ""
  });

  const formikRef = useRef(null);

  const [mapRadio, setMapRadio] = useState(
    propertyData.map_link && propertyData.map_link.includes('/kml/') ? 'ManuallyDrow' : 
    (propertyData.mapright_link !== undefined ? 'LandIDLink' : 'UploadKmlFile'));





  var boundryBoxes = [];
  var path;
  var currentBoundryBox;

  var place_id;

  // This example adds a user-editable rectangle to the map.
  /**
   * Creates a control that recenters the map on Chicago.
   */
  function createCenterControl(map) {
    const controlButtons = [];
    controlButtons.push({ "type": "Restart Drawing", "title": "Start drawing to add shapes", "buttonel": document.createElement("button") });
    controlButtons.push({ "type": "Edit Shapes", "title": "Stop drawing to edit shapes", "buttonel": document.createElement("button") });
    controlButtons.push({ "type": "Save Map", "title": "Click to save the boundry map", "buttonel": document.createElement("button") });

    controlButtons[2].buttonel.setAttribute("id","savemapbtn");

    controlButtons.forEach(function (i) {
      var controlButton = i.buttonel;

      // Set CSS for the control.
      controlButton.style.backgroundColor = "#fff";
      controlButton.style.border = "2px solid #fff";
      controlButton.style.borderRadius = "3px";
      controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlButton.style.color = "rgb(25,25,25)";
      controlButton.style.cursor = "pointer";
      controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
      controlButton.style.fontSize = "16px";
      controlButton.style.lineHeight = "38px";
      controlButton.style.margin = "8px 0 22px";
      controlButton.style.padding = "0 5px";
      controlButton.style.textAlign = "center";
      controlButton.textContent = i.type;
      controlButton.title = i.title;
      controlButton.type = "button";
    })

    // Setup the click event listeners: simply set the map to Chicago.
    controlButtons[2].buttonel.addEventListener("click", () => {
      if (boundryBoxes.length > 0) {

        var values = {
          "shapes": [],
          "place_id": place_id
        };
  
        boundryBoxes.forEach(function (boundryBox) {
          //console.log(boundryBox.getPath());
          path = boundryBox.getPath();
          var coordinates = '';
          for (var i = 0; i < path.length; i++) {
            coordinates += path.getAt(i).lng() + ',' + path.getAt(i).lat() + ' ';
          }
          //console.log(coordinates);
  
          values.shapes.push(coordinates);
        });
  
        console.log(values);
  
        $.ajax({
          type: 'post',
          url: landdealBaseURL + 'bridge/property/savekml.php',
          data: values,
          success: function (result) {
            console.log(result);
  
            if (result['success']) {
              console.log("Boundry map saved");
              //window.open("https://greenlightautomotivesolutions.com/testmap/savedmap.php", "_blank");
  
              //tryMapLinkChange()
  
              //innerMapLinkChange(result['url']);
  
              //document.getElementById('formik_map_link').value = result['url'];
              //formikObj.map_link = result['url'];
  
              //console.log(formikObj);
  
              //setFormikData({ map_link: result['url'] });
  
              formikRef.current.setFieldValue("map_link", result['url']);
  
              setMapSaved(true);

              
              /*var tempData = formikRef.current.values
              tempData.map_link = result['url'];
              console.log(formikRef.current.values,tempData);
              nextStep(0,tempData);*/
            } else
              alert("Failed to save boundry map");
          },
          error: function (textStatus, errorThrown) {
            console.log(textStatus);
            console.log(errorThrown);
          }
  
        });
      }
    });
    return controlButtons;
  }

  var map;

  function initMap() {
    map = new google.maps.Map(document.getElementById("mapDrow"), {
      //center: { lat: 44.5452, lng: -78.5389 },
      //zoom: 9,
      zoom: 12,
      center: new google.maps.LatLng(30.2672, -97.733330),
      mapTypeId: 'hybrid'
    });

    const geocoder = new google.maps.Geocoder();

    console.log("initiating");


    const input = document.getElementById("pac-input");
    // Set CSS for the control.
    /* input.style.backgroundColor = "#fff";
    input.style.border = "2px solid #fff";
    input.style.borderRadius = "3px";
    input.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    input.style.color = "rgb(25,25,25)";
    input.style.cursor = "pointer";
    input.style.fontFamily = "Roboto,Arial,sans-serif";
    input.style.fontSize = "16px";
    input.style.lineHeight = "38px";
    input.style.margin = "8px 0 22px";
    input.style.padding = "0 5px";
    input.style.textAlign = "center";
    input.style.width = "20%";*/

    const searchBox = new google.maps.places.SearchBox(input);

    //map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    map.addListener("click", (mapsMouseEvent) => {
      console.log(mapsMouseEvent.latLng.toJSON());
    });

    // Create the DIV to hold the control.
    const centerControlDiv = document.createElement("div");
    // Create the control.
    const centerControls = createCenterControl(map);

    centerControls.forEach(function (centerControl) {
      // Append the control to the DIV.
      centerControlDiv.appendChild(centerControl.buttonel);
      map.controls[google.maps.ControlPosition.TOP_CENTER].push(centerControlDiv);
    });



    let markers = [];

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      var drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON]
        },
        polygonOptions: {
          editable: true
        }
      });
      drawingManager.setMap(map);



      centerControls[1].buttonel.addEventListener("click", () => {
        drawingManager.setDrawingMode(null);
      });

      centerControls[0].buttonel.addEventListener("click", () => {
        drawingManager.setDrawingMode('polygon');
      });

      if (propertyData.shapes) {
        propertyData.shapes.forEach(function (points) {
          var newCoords = [];
          for (var i = 0; i < points.length; i++) {
            newCoords.push(new google.maps.LatLng(points[i][0], points[i][1]));

            console.log(points[i]);
          }

          boundryBoxes.push(new google.maps.Polygon({
            path: newCoords,
            strokeWeight: 2,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            editable: true,
            draggable: true
          }));

          currentBoundryBox = boundryBoxes.length - 1;
          boundryBoxes[currentBoundryBox].setMap(map);

          console.log(boundryBoxes[currentBoundryBox].getPath());

          //drawingManager.setDrawingMode(null);
          //polygons.push(newPoly);
          //addNewPolys(newPoly);
        });
      }

      google.maps.event.addListener(drawingManager, "overlaycomplete", function (event) {
        //drawingManager.setDrawingMode(null);

        boundryBoxes.push(event.overlay);
        //boundryBoxes.type = event.type;

        currentBoundryBox = boundryBoxes.length - 1;

        boundryBoxes[currentBoundryBox].setOptions({
          type: event.type,
          strokeColor: '#FF0000',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#FF0000',
          fillOpacity: 0.35,
          editable: true,
          draggable: true
        })

        console.log(boundryBoxes[currentBoundryBox]);

        setShapesDrawn(true);

        ["bounds_changed", "dragend"].forEach((eventName) => {
          console.log(eventName);
          boundryBoxes[currentBoundryBox].addListener(eventName, () => {
            //console.log({ bounds: boundryBoxes[currentBoundryBox].getBounds()?.toJSON(), eventName });
            console.log(eventName);

            boundryBoxes[currentBoundryBox].getPaths().forEach(function (path) {
              path.forEach(function (latlng) {
                console.log(latlng.toJSON());
              });
            });
          });
        });

        boundryBoxes[currentBoundryBox].getPaths().forEach(function (path, index) {

          google.maps.event.addListener(path, 'insert_at', function () {
            console.log("New point");
            path.forEach(function (latlng) { console.log(latlng.toJSON()); });
          });

          google.maps.event.addListener(path, 'remove_at', function () {
            console.log("Point was removed");
            path.forEach(function (latlng) { console.log(latlng.toJSON()); });
          });

          google.maps.event.addListener(path, 'set_at', function () {
            console.log("Point was moved");
            path.forEach(function (latlng) { console.log(latlng.toJSON()); });
          });

        });
      });

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }

        geocoder.geocode({
          'address': place.formatted_address
        }, function (results, status) {
          console.log(results, status);


          results.forEach((result) => {

            if (propertyData && propertyData.place_id && propertyData.place_id != "" && result.place_id != propertyData.place_id) {
              place_id = propertyData.place_id;
              return;
            }

            //setPropertyAddress(place.formatted_address);
            input.value = place.formatted_address;

            if (result.address_components) {
              //tryAddressChange(result);

              placeIDValidation(result.place_id).then((validPlaceID) => {

                console.log(validPlaceID,result);

                place_id = validPlaceID;

                var city = "";
                var state = "";       
  
                Object.keys(result.address_components).map(function(i) {
                    if(result.address_components[i].types.includes("sublocality_level_1")) city = result.address_components[i].short_name;
                    else if(result.address_components[i].types.includes("locality")) city = result.address_components[i].short_name;
                    else if(result.address_components[i].types.includes("administrative_area_level_2")) city = result.address_components[i].short_name;
                    
                    if(result.address_components[i].types.includes("administrative_area_level_1")) state = result.address_components[i].short_name;
                });
                
                formikRef.current.setFieldValue("place_id", validPlaceID);
                formikRef.current.setFieldValue("address", result.formatted_address);
                formikRef.current.setFieldValue("latitude", result.geometry.location.lat());
                formikRef.current.setFieldValue("longitude", result.geometry.location.lng());
                formikRef.current.setFieldValue("city", city + ", " + state);
                //document.getElementById("savemapbtn").click();
  
                //setPropertyData(propData);
  
                console.log(formikRef.current.values,formikData);
              });

            }        

            if (status == google.maps.GeocoderStatus.OK) {

              // get the bounding box geonames parameters from the viewport

              //fit the map to the viewport bounding box
              map.fitBounds(result.geometry.viewport);
              map.setZoom(20);

            }

          })
        });

        const icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        console.log(place.geometry);

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });

    if (propertyData && propertyData.address != "") {
      console.log("address test", propertyData, propertyData.address, input.value);

      //searchBox.set("place", place);
      //input.value = propertyData.address;
      setTimeout(() => {
        google.maps.event.trigger(input, 'focus', {});
        google.maps.event.trigger(input, 'keydown', {
          keyCode: 13
        });
      }, 1000);
      //google.maps.event.trigger(searchBox, 'places_changed');
    }

  }

  function addKmlLayer(src) {
    var kml_map = new google.maps.Map(document.getElementById("kmlMap"), {
      //center: { lat: 44.5452, lng: -78.5389 },
      //zoom: 9,
      zoom: 12,
      center: new google.maps.LatLng(30.2672, -97.733330),
      mapTypeId: 'hybrid'
    });

    var kmlLayer = new google.maps.KmlLayer(src, {
      suppressInfoWindows: true,
      preserveViewport: false,
      map: kml_map
    });
    //setFormikData({ map_link:  });
    formikRef.current.setFieldValue("map_link", src.split('?')[0]);
  }

  function overlayClickListener(overlay) {
    google.maps.event.addListener(overlay, "mouseup", function (event) {
      $('#vertices').val(overlay.getPath().getArray());
    });
  }

  //setTimeout(() => {
  //  initMap();
  //}, 1000);

  const documentChange = async (e) => {
    const formData = new FormData();
    formData.append('place_id', formikRef.current.values.place_id);
    formData.append('user', user.username);
    formData.append('file', e.target.files[0]);
    formData.append('fileName', formikRef.current.values.place_id + ".kml");
    formData.append('folder', 'kml/');
    const upload = uploadDocument(formData);
    upload.then((data) => {
      if (data.url) {

        addKmlLayer(data.url);
      } else if(data.error) alert(data.error);
    });
  }

  useEffect(() => {
    setTimeout(() => {
      initMap();
    }, 1000);
  }, []);

  useEffect(() => {
    sendFormikRef('map',formikRef.current);
  }, [formikRef])

  const prevValues = useRef(null);

  const SendFormikData = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      
      if(JSON.stringify(values) != prevValues.current) {
        nextStep(0,values);      
        console.log(values,JSON.stringify(values));
        prevValues.current = JSON.stringify(values);
      }
    }, [JSON.stringify(values)]);
    return null;
  };

  // var radioBtn = $('input[type="radio"]');
  //   radioBtn.click(function() {
  //       radioBtn.each(function() {
  //           $(this).parent(".map-intgration-box").toggleClass('selected', this.checked);
  //       });
  //   });
  //   const [radioValue, setRadioValue] = useState("UploadKmlFile")

  //window.initMap = initMap;
  return (
    <>
      <div className="accordion-body map-integration-box">
        <Formik
          validationSchema={validationSchema}
          initialValues={formikData}
          enableReinitialize={true}
          innerRef={formikRef}
          onSubmit={(values) => {
            // data send serve side
            console.log(values);

            //var mapSelection = document.querySelector('input[name="mapintgration"]:checked').value;

            if(mapRadio == "ManuallyDrow" && !mapSaved) {
              if(shapesDrawn) {
                document.getElementById("savemapbtn").click();
                nextStep(item + 1, values);
              } else {
                alert("Please draw property boundries before proceeding");
                return;
              }
            } else if(mapRadio == "UploadKmlFile" && values.map_link == "") {
              alert("Please upload KML File");
              return;
            } else if(mapRadio == "LandIDLink" && values.map_link == "") {
              alert("Provide LandID Link");
              return;
            } else {
              nextStep(item + 1, values);
            }
            
          }}
        >
          {({ values }) => (
            <Form>
              <SendFormikData />
              <Field
                type="hidden"
                name="place_id"
                value={values.place_id}
              />
              <Field
                type="hidden"
                name="latitude"
                value={values.latitude}
              />
              <Field
                type="hidden"
                name="longitude"
                value={values.longitude}
              />
              <Field
                type="hidden"
                name="city"
                value={values.city}
              />
              <div className="">
                <div className="row mlr-40">
                  <div className="col-12 plr-40">
                    <div className="mi-form-box">
                      <div className="mb-3">
                        <div className="mi-sm-title mb-2">1. Enter Property Address</div>
                        <div className="input-group search-address-field">
                          {/* <TextField
                            label="Address"
                            placeholder=""
                            name="address"
                          /> */}
                          <TextField
                            label="Address *"
                            type="text"
                            placeholder="Enter your Address"
                            name="address"
                            id="pac-input"
                            value={values.address}
                          />
                          <span className="search-icon"></span>
                        </div>
                      </div>
                      <div>
                        <div className="mi-sm-title mb-4">2. Please select an option to add your boundary map</div>
                        <ul className="nav nav-tabs row map-intgration-tabs" id="myTab" role="tablist">
                          <li className="nav-item col-md-4" role="presentation">
                            <div className={mapRadio === "UploadKmlFile" ? "nav-link active" : "nav-link"} id="UploadKmlFile-tab" data-bs-toggle="tab" data-bs-target="#UploadKmlFile" type="button" role="tab" aria-controls="UploadKmlFile" aria-selected={mapRadio === "UploadKmlFile"}>
                              <div className="map-intgration-box text-center">
                                <div className="circle"></div>
                                <div className="xxs-title font-500 font-16 mt-3 ">Upload KML File</div>
                                <button className="theme-btn text-uppercase md-btn upload-photo-btn file-upload-block mt-3">
                                  <input type="file" name="file" onChange={documentChange} accept=".kml" />
                                  Browse
                                </button>
                                <input type="radio" name="mapintgration" value="UploadKmlFile" checked={mapRadio === "UploadKmlFile"} onChange={(e) => {setMapRadio(e.target.value)}} />
                              </div>
                            </div>
                          </li>
                          <li className="nav-item col-md-4" role="presentation">
                            <div className={mapRadio === "LandIDLink" ? "nav-link active" : "nav-link"} id="LandIDLink-tab" data-bs-toggle="tab" data-bs-target="#LandIDLink" type="button" role="tab" aria-controls="LandIDLink" aria-selected={mapRadio === "LandIDLink"}>
                              <div className="map-intgration-box text-center">
                                <div className="circle"></div>
                                <div className="xxs-title font-500 font-16 mt-3">LandID Link</div>
                                <div className="mt-3 position-relative">
                                  <TextField
                                    placeholder="Paste Link"
                                    name="map_link"
                                    groupclass="mb-0"
                                    id="formik_map_link"
                                    value={values.map_link}
                                  />
                                </div>
                                <input type="radio" name="mapintgration" value="LandIDLink" checked={mapRadio === "LandIDLink"} onChange={(e) => {setMapRadio(e.target.value)}} />
                              </div>
                            </div>
                          </li>
                          <li className="nav-item col-md-4" role="presentation">
                            <div className={mapRadio === "ManuallyDrow" ? "nav-link active" : "nav-link"} id="ManuallyDrow-tab" data-bs-toggle="tab" data-bs-target="#ManuallyDrow" type="button" role="tab" aria-controls="ManuallyDrow" aria-selected={mapRadio === "ManuallyDrow"}>
                              <div className="map-intgration-box text-center">
                                <div className="circle"></div>
                                <div className="xxs-title font-500 font-16 mt-3 ">Manually Draw with <br />Google Maps</div>
                                <input type="radio" name="mapintgration" value="ManuallyDrow" checked={mapRadio === "ManuallyDrow"} onChange={(e) => {setMapRadio(e.target.value)}}/>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                          <div className={(mapRadio ==="UploadKmlFile" ? "active show " : "") + "tab-pane fade"} id="UploadKmlFile" role="tabpanel" aria-labelledby="UploadKmlFile-tab">
                            <div className="mt-5 pt-4">
                            <div id="kmlMap" style={{ width: "100%", height: "460px" }} className={values.place_id ? "" : "hide-map"}></div>
                            </div>
                          </div>
                          <div className={(mapRadio ==="LandIDLink" ? "active show " : "") + "tab-pane fade"} id="LandIDLink" role="tabpanel" aria-labelledby="LandIDLink-tab">
                            <div className="mt-5 pt-4">
                              {values.map_link && values.map_link.includes('id.land') ? <iframe loading='lazy' frameBorder='0' height='460px' width='100%' src={values.map_link}></iframe> : ""}           
                            </div>
                          </div>
                          <div className={(mapRadio ==="ManuallyDrow" ? "active show " : "") + "tab-pane fade"} id="ManuallyDrow" role="tabpanel" aria-labelledby="ManuallyDrow-tab">
                            <div className="tip-text mt-5">
                              Tip: Click your mouse and release to mark each point around the boundary of the property.
                            </div>
                            <div className={values.place_id ? "map-pre-box hide-map" : "map-pre-box"}>                              
                              <div className="loading-map-tag">ENTER PROPERTY ADDRESS</div>
                            </div>
                            <div id="mapDrow" style={{ width: "100%", height: "460px" }} className={values.place_id ? "" : "hide-map"}></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 plr-40">
                    <div className="map-block text-center mt-4">
                      {/* <img
                        src={mapNew}
                        alt="map11"
                        style={{ maxWidth: "470px" }}
                      /> 
                      <div id="mapDrow" style={{ width: "100%", height: "460px" }} className={place_id || propertyData.place_id ? "" : "hide-map"}></div>*/}
                    </div>
                  </div>
                </div>
                <div className="text-end mt-4">
                  <button
                    className="theme-btn text-uppercase w-25 sm-btn"
                    type="submit"
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default MapIntegration;