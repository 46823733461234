
import { useState } from 'react'

import {intFormatter, floatFormatter} from "../../Components/Utils/NumberFormatter.js";

const TopInfo = ({propertyData, openModal, btnSaved}) => {

    return (
        <>
            <section className="top-bar">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between topbar-flex">
                        <div className='loc-title'>
                            <div className="lb-title">Location</div>
                            <div className="xs-title fw-500 mt-1 text-start theme-text"><span className="location-icon"></span> {propertyData.address}</div>
                        </div>
                        <div>
                            <div className="lb-title">Price</div>
                            <div className="xs-title font-20 text-white mt-1 text-start">{floatFormatter(propertyData.list_price,true)}</div>
                        </div>
                        <div>
                            <div className="lb-title">Size</div>
                            <div className="xs-title font-20 text-white mt-1 text-start">{intFormatter(propertyData.acreage)}</div>
                        </div>
                        <div>
                            <div className="lb-title">Price Per Acre</div>
                            <div className="xs-title font-20 text-white mt-1 text-start">{intFormatter(propertyData.price_per_acre,true)}</div>
                        </div>
                        <div className='tb-btn-actions'>
                            <button className={`theme-trns-btn text-uppercase ${btnSaved ? "saved": ""}`} onClick={() => {openModal('save');}} id='savebtn'>
                                <span className="save-icon"></span> Save 
                            </button>
                            <button className="theme-trns-btn text-uppercase ms-4" type='button' onClick={() => {openModal('share');}}><span className="share-icon"></span> Share</button>
                        </div>
                        <div className='text-center mo-btn-block d-block'>
                            <button className="theme-btn text-uppercase" type='button' onClick={() => {openModal('makeoffer');}}>Make Offer</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TopInfo