import React from 'react'
import Header from '../../Common/Header'
import Footer from '../../Common/Footer'

const ReviewOffers = () => {
    return (
        <>
            <Header />
            <section className='review-offer-wrap'>
                <div className='container'>
                    <div className='ro-box'>
                        <div className='md-title pb-4 mb-2 text-uppercase'>Review Offers</div>
                        <div className="accordion review-accordion">
                            <div className="accordion-item">
                                <div className='ra-header'>
                                    <table className='w-100'>
                                        <tr>
                                            <td style={{width:"10%"}}>
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        &nbsp;
                                                    </button>
                                                </h2>
                                            </td>
                                            <td style={{width:"35%"}}>
                                                <div className="loc-title">
                                                    <div className="lb-title">Location</div>
                                                    <div className="xs-title fw-500 mt-2 text-start theme-text">
                                                        <span className="location-icon"></span> 1720 E 16th St, Los Angeles, CA 90021, USA
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{width:"20%"}}>
                                                <div className="lb-title">Price</div>
                                                <div className="xs-title font-20 text-white mt-2 text-start">
                                                    $42,000,000
                                                </div>
                                            </td>
                                            <td style={{width:"10%"}}>
                                                <div className="lb-title">Size</div>
                                                <div className="xs-title font-20 text-white mt-2 text-start">
                                                    10.800
                                                </div>
                                            </td>
                                            <td style={{width:"25%", textAlign:"right"}}>
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" >
                                    <div className="accordion-body p-0">
                                        <div className='ra-body'>
                                            <table className='w-100'>
                                                <tr>
                                                    <td style={{width:"10%"}}></td>
                                                    <td style={{width:"35%"}}>
                                                        <div className="xs-title font-1em fw-500 text-start theme-text">
                                                            ABC Investments Global
                                                        </div>
                                                        <div className="xs-title font-1em fw-500 mt-1 text-start text-white">
                                                            Mary Goldman
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className="lb-title d-lg-none">Amount</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            $20,000,000
                                                        </div>
                                                    </td>
                                                    <td style={{width:"10%"}}>
                                                        <div className="lb-title d-lg-none">Acreage:</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            5.00
                                                        </div>
                                                    </td>
                                                    <td style={{width:"25%", textAlign:"right"}}>
                                                        <button className='red-btn me-2' type='button'>Decline</button>
                                                        <button className='green-btn' type='button'>Accept</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:"10%"}}></td>
                                                    <td style={{width:"35%"}}>
                                                        <div className="xs-title font-1em fw-500 text-start theme-text">
                                                        Land Asset Group
                                                        </div>
                                                        <div className="xs-title font-1em fw-500 mt-1 text-start text-white">
                                                        John Smith
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className="lb-title d-lg-none">Amount</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            $20,000,000
                                                        </div>
                                                    </td>
                                                    <td style={{width:"10%"}}>
                                                        <div className="lb-title d-lg-none">Acreage:</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            5.00
                                                        </div>
                                                    </td>
                                                    <td style={{width:"25%", textAlign:"right"}}>
                                                        <button className='red-btn me-2' type='button'>Decline</button>
                                                        <button className='green-btn' type='button'>Accept</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <div className='ra-header'>
                                    <table className='w-100'>
                                        <tr>
                                            <td style={{width:"10%"}}>
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                        &nbsp;
                                                    </button>
                                                </h2>
                                            </td>
                                            <td style={{width:"35%"}}>
                                                <div className="loc-title">
                                                    <div className="lb-title">Location</div>
                                                    <div className="xs-title fw-500 mt-2 text-start theme-text">
                                                        <span className="location-icon"></span> 1720 E 16th St, Los Angeles, CA 90021, USA
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{width:"20%"}}>
                                                <div className="lb-title">Price</div>
                                                <div className="xs-title font-20 text-white mt-2 text-start">
                                                    $42,000,000
                                                </div>
                                            </td>
                                            <td style={{width:"10%"}}>
                                                <div className="lb-title">Size</div>
                                                <div className="xs-title font-20 text-white mt-2 text-start">
                                                    10.800
                                                </div>
                                            </td>
                                            <td style={{width:"25%", textAlign:"right"}}>
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" >
                                    <div className="accordion-body p-0">
                                        <div className='ra-body'>
                                            <table className='w-100'>
                                                <tr>
                                                    <td style={{width:"10%"}}></td>
                                                    <td style={{width:"35%"}}>
                                                        <div className="xs-title font-1em fw-500 text-start theme-text">
                                                            ABC Investments Global
                                                        </div>
                                                        <div className="xs-title font-1em fw-500 mt-1 text-start text-white">
                                                            Mary Goldman
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        
                                                        <div className="lb-title d-lg-none">Amount</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            $20,000,000
                                                        </div>
                                                    </td>
                                                    <td style={{width:"10%"}}>
                                                        <div className="lb-title d-lg-none">Acreage:</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            5.00
                                                        </div>
                                                    </td>
                                                    <td style={{width:"25%", textAlign:"right"}}>
                                                        <button className='red-btn me-2' type='button'>Decline</button>
                                                        <button className='green-btn' type='button'>Accept</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:"10%"}}></td>
                                                    <td style={{width:"35%"}}>
                                                        <div className="xs-title font-1em fw-500 text-start theme-text">
                                                        Land Asset Group
                                                        </div>
                                                        <div className="xs-title font-1em fw-500 mt-1 text-start text-white">
                                                        John Smith
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className="lb-title d-lg-none">Amount</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            $20,000,000
                                                        </div>
                                                    </td>
                                                    <td style={{width:"10%"}}>
                                                        <div className="lb-title d-lg-none">Acreage:</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            5.00
                                                        </div>
                                                    </td>
                                                    <td style={{width:"25%", textAlign:"right"}}>
                                                        <button className='red-btn me-2' type='button'>Decline</button>
                                                        <button className='green-btn' type='button'>Accept</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <div className='ra-header'>
                                    <table className='w-100'>
                                        <tr>
                                            <td style={{width:"10%"}}>
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                        &nbsp;
                                                    </button>
                                                </h2>
                                            </td>
                                            <td style={{width:"35%"}}>
                                                <div className="loc-title">
                                                    <div className="lb-title">Location</div>
                                                    <div className="xs-title fw-500 mt-2 text-start theme-text">
                                                        <span className="location-icon"></span> 1720 E 16th St, Los Angeles, CA 90021, USA
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{width:"20%"}}>
                                                <div className="lb-title">Price</div>
                                                <div className="xs-title font-20 text-white mt-2 text-start">
                                                    $42,000,000
                                                </div>
                                            </td>
                                            <td style={{width:"10%"}}>
                                                <div className="lb-title">Size</div>
                                                <div className="xs-title font-20 text-white mt-2 text-start">
                                                    10.800
                                                </div>
                                            </td>
                                            <td style={{width:"25%", textAlign:"right"}}>
                                                &nbsp;
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" >
                                    <div className="accordion-body p-0">
                                        <div className='ra-body'>
                                            <table className='w-100'>
                                                <tr>
                                                    <td style={{width:"10%"}}></td>
                                                    <td style={{width:"35%"}}>
                                                        <div className="xs-title font-1em fw-500 text-start theme-text">
                                                            ABC Investments Global
                                                        </div>
                                                        <div className="xs-title font-1em fw-500 mt-1 text-start text-white">
                                                            Mary Goldman
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        
                                                        <div className="lb-title d-lg-none">Amount</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            $20,000,000
                                                        </div>
                                                    </td>
                                                    <td style={{width:"10%"}}>
                                                        <div className="lb-title d-lg-none">Acreage:</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            5.00
                                                        </div>
                                                    </td>
                                                    <td style={{width:"25%", textAlign:"right"}}>
                                                        <button className='red-btn me-2' type='button'>Decline</button>
                                                        <button className='green-btn' type='button'>Accept</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:"10%"}}></td>
                                                    <td style={{width:"35%"}}>
                                                        <div className="xs-title font-1em fw-500 text-start theme-text">
                                                        Land Asset Group
                                                        </div>
                                                        <div className="xs-title font-1em fw-500 mt-1 text-start text-white">
                                                        John Smith
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className="lb-title d-lg-none">Amount</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            $20,000,000
                                                        </div>
                                                    </td>
                                                    <td style={{width:"10%"}}>
                                                        <div className="lb-title d-lg-none">Acreage:</div>
                                                        <div className="xs-title font-1em fw-500 text-start text-white">
                                                            5.00
                                                        </div>
                                                    </td>
                                                    <td style={{width:"25%", textAlign:"right"}}>
                                                        <button className='red-btn me-2' type='button'>Decline</button>
                                                        <button className='green-btn' type='button'>Accept</button>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ReviewOffers