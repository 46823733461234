import React, { useState, useEffect, useContext } from 'react'
import Header from '../../Components/Common/Header'
import ChatMessageField from '../../Components/Chat/ChatMessageField'
import ChatStandaloneList from '../../Components/Chat/ChatStandaloneList'
import ChatBoxHeader from '../../Components/Chat/ChatBoxHeader'
import { useLocation } from 'react-router';
import ChatMessageBody from '../../Components/Chat/ChatMessageBody'
import ChatNewList from '../../Components/Chat/ChatNewList'
import ChatGroupList from '../../Components/Chat/ChatGroupList'
import ChatLayout from './ChatLayout'
import { ChatContext } from '../../context/ChatContext'
import { PropertyContext } from '../../context/PropertyContext'
import { UserContext } from '../../context/UserContext'
import { active } from 'd3'

/*const chatList = [


    {
        name: "Kathy Williams",
        designation: "Acme Corporation",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'online',
        messages: [
            // { text: "My name is Kathy Williams. I'm ready to buy this",className:"sender", id: Date.now(), time: "10:25 pm",data:"",messageType:"text" }
        ]
    },
    {
        name: "Land Deal TEAM",
        designation: "Group Chat",
        chattime: "4:33 PM",
        readmsg: true,
        msgcount: "",
        status: 'offline',
        messages: [
            // { text: "My name is Robbin Kenedy. I'm ready to buy this",className:"sender", id: Date.now(), time: "10:25 pm",data:"",messageType:"text" }
        ]
    },
    {
        name: "Robbin Kenedy",
        designation: "Global Asset Management",
        chattime: "Thrusday",
        readmsg: false,
        msgcount: "",
        status: 'online',
        messages: [
            // { text: "My name is Robbin Kenedy. I'm ready to buy this",className:"sender", id: Date.now(), time: "10:25 pm",data:"",messageType:"text" }
        ]
    },
    {
        name: "Tim Burton",
        designation: "Land Deal",
        chattime: "11:20 AM",
        readmsg: false,
        msgcount: "",
        status: 'online',
        messages: [
            // { text: "My name is Tim Burton. I'm ready to buy this",className:"sender", id: Date.now(), time: "10:25 pm",data:"",messageType:"text" }
        ]
    },
    {
        name: "Marshall McGregor",
        designation: "Acme Corporation",
        chattime: "12/08/18",
        readmsg: false,
        msgcount: "",
        status: 'online',
        messages: [
            // { text: "My name is Marshall McGregor. I'm ready to buy this",className:"sender", id: Date.now(), time: "10:25 pm",data:"",messageType:"text" }
        ]
    },

    {
        name: "John Smith",
        designation: "Tech Solutions Inc.",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'offline',
        messages: [
            // { text: "My name is John Smith. I'm ready to buy this",className:"sender", id: Date.now(), time: "10:25 pm",data:"",messageType:"text" }
        ]
    },

    {
        name: "Emily Johnson",
        designation: "Global Innovations",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'online',
        messages: [
            // { text: "My name is Emily Johnson. I'm ready to buy this",className:"sender", id: Date.now(), time: "10:25 pm",data:"",messageType:"text" }

        ]
    },

    {
        name: "Michael Brown",
        designation: "XYZ Corporation",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'offline',
        messages: []
    },

    {
        name: "Sarah Davis",
        designation: "TechNet Solutions",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'online',
        messages: []
    },

    {
        name: "James Miller",
        designation: "Innovate Tech",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'offline',
        messages: []
    },

    {
        name: "Linda Garcia",
        designation: "Future Enterprises",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'online',
        messages: []
    },

    {
        name: "Robert Wilson",
        designation: "QuickTech Solutions",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'offline',
        messages: []
    },

    {
        name: "Jessica Lee",
        designation: "InnovateX Inc.",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'online',
        messages: []
    },

    {
        name: "David Clark",
        designation: "Tech Innovators",
        chattime: "Today",
        readmsg: true,
        msgcount: "",
        status: 'offline',
        messages: []
    }
]*/
const ChatStandalone = () => {

    const { chatUsers, chatDataByUser, chatList, onlineChatUsers, unreadMessages, socketRef, closeSmChat, setChatUsers, sendChat, unreadByUser, markChatRead, allUsers, uploadDocument, chatAttachmentInfo } = useContext(ChatContext);
    const { user } = useContext(UserContext);

    const [activeChatUser, setActiveChatUser] = useState(null);

    const queryParameters = new URLSearchParams(window.location.search)
    const new_chat = queryParameters.get("new_chat");
    const new_group = queryParameters.get("new_group");

    const [newChat, setNewChat] = useState(new_chat !== null ? true : false);

    //const [chatMsg, setChatMsg] = useState("");
    const [chatOpen, setChatOpen] = useState(false);
    const [updateChat, setUpdateChat] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const openChat = (with_user) => {
        console.log(with_user, chatDataByUser, unreadByUser, chatUsers);

        var is_group = with_user.indexOf("group") >= 0;

        socketRef.current.emit('message', {
            chatCommand : "getSingleChatHistory",
            with_user : is_group ? with_user.replace('group','') : with_user,
            is_group :  is_group
        });

        if(unreadByUser[with_user] > 0) markChatRead(with_user,is_group);

        setActiveChatUser(with_user);
        setChatOpen(true);
    }

    const backArrowBtn = () => {
        setChatOpen(false)
    }
    const { state } = useLocation();

    //const [messages, setMessages] = useState([]);

    const now = new Date();
    const formatDateTime = (date) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // 24-hour format
        };
        return date.toLocaleString(undefined, options);
    }
    const formattedDateTime = formatDateTime(now);
    const sendMessage = (message) => {

        console.log(message,activeChatUser);

        if (message && activeChatUser) {
            //const newMessage = { text: message, id: Date.now(), className: "repaly", time: formattedDateTime, data: "", messageType: "text" };

            sendChat(message,activeChatUser);
            //chatMsg.messages = [...chatMsg.messages, newMessage];
            //setChatMsg(chatMsg);
            //setMessage('');
        }
    };

    const uploadFile = (file) => {
        console.log(file);

        setIsLoading(true)
        if (activeChatUser && file[0]) {

            console.log(file[0]);

            var fileSize = 0;
            var messageType = "";
            // if(['application/pdf'].includes(file[0].type)>-1){
            if((file[0].type).includes('application') || (file[0].type).includes('text')){
                fileSize = ((file[0].size / (1024*1024)));
                var ext = (fileSize<=0)?"KB":"MB";
                fileSize = (fileSize.toFixed(2)).padStart(2, ' ')+ext;
                messageType = "pdf"
            }

            if(['image/jpeg','image/jpg', 'image/png'].indexOf(file[0].type) > -1){
                messageType = "png"
            }

            const formData = new FormData();
            formData.append('file', file[0]);
            formData.append('folder', 'chatfiles/' + user.username + '/');
            formData.append('fileName',file[0].name);
            formData.append('base_url',true);

            const upload = uploadDocument(formData);
            upload.then((data) => {
                if (data.url) {
                    sendChat("",activeChatUser,data.url);
                    
                } else if(data.error) alert(data.error);
            });

            setIsLoading(false);
        }
            
    }

    /* group chat */
    const [newGroup, setNewGroup] = useState(new_group !== null ? true : false);
    const [listItemSelected, setListItemSelected] = useState(false);
    const [groupChatNames, setGroupChatNames] = useState([]);
    const [groupChat, setGroupChat] = useState({messages:[]});
    const [groupName, setGroupName] = useState('');

    const [groupChatUsers, setGroupChatUsers] = useState([]);
    

    const addGroupChatUser = (chatUser) => {

        console.log(chatUser);

        if(groupChatUsers.includes(chatUser.username)) {
            setGroupChatUsers((prev) => prev.filter(username => username !== chatUser.username));
            setGroupChatNames((prev) => prev.filter(name => name !== chatUser.name));
        } else if(groupChatNames.length <= 10) {
            setGroupChatUsers((prev) => [...prev,chatUser.username]);
            setGroupChatNames((prev) => [...prev,chatUser.name]);
        }
    }

    const createGroupChat = () => {

        console.log(groupName,groupChatUsers);
        
        socketRef.current.emit('message', {
            chatCommand : "createGroupChat",
            group_name : groupName,
            users : groupChatUsers
        });

        setNewGroup(false);
        setGroupChatUsers([]);
        setGroupChatNames([]);
        setGroupName('');
    }

    const setGroupChatName = ()=> {
        // console.log(name);

        console.log(groupName);

        if(groupName){
            var groupChats = groupChat;
            groupChats.messages = [];
            groupChats.name = groupName;
            setGroupChat(groupChats);
        }
        setGroupName('');
    }

    /* group chat */
    
    useEffect(()=>{
        if(state){
            openChat(state.name);
        }
    },[state]);

    useEffect(()=>{
        console.log(new_chat,new_group);
        setNewChat(new_chat !== null ? true : false);
        setNewGroup(new_group !== null ? true : false);
    },[new_chat,new_group]);

    return (
        <>
            <Header headerHideForMobile={true} />
            <ChatLayout>
                {newChat ? <ChatNewList
                    chatList={allUsers}
                    openChat={openChat}
                    setNewChat={setNewChat}
                /> : (newGroup ? 
                <ChatGroupList
                    chatList={allUsers}
                    groupChatNames={groupChatNames}
                    addGroupChatUser={addGroupChatUser}
                    listItemSelected={listItemSelected}
                    setGroupChatName={setGroupChatName}
                    setGroupName = {setGroupName}
                    groupName = {groupName}
                    setNewGroup={setNewGroup}
                    createGroupChat={createGroupChat}
                /> : 
                <ChatStandaloneList
                    chatList={chatList}
                    openChat={openChat}
                    activeChatUser={activeChatUser}
                    unreadByUser={unreadByUser}
                    onlineChatUsers={onlineChatUsers}
                    setNewChat={setNewChat}
                    setNewGroup={setNewGroup}
                />)}
                <div className={`chatbox ${chatOpen ? "active" : ""}`}>
                    <div className="modal-dialog-scrollable">
                        <div className="modal-content">
                            <ChatBoxHeader
                                chatHeader={newGroup ? groupName : chatDataByUser[activeChatUser] && chatDataByUser[activeChatUser].length && chatDataByUser[activeChatUser][0]['this_chat_fullname']}
                                backArrowBtn={backArrowBtn}
                                groupChatNames={groupChatNames}
                            />
                            <ChatMessageBody
                                chatList={chatList}
                                chatData={chatDataByUser[activeChatUser]}
                                message={"Chat Standalone"}
                                chatAttachmentInfo={chatAttachmentInfo}
                            />
                            {!newGroup && activeChatUser && <ChatMessageField
                                sendMessage={sendMessage}
                                chatUpdated={updateChat}
                                uploadFile={uploadFile}
                                isLoading={isLoading}
                            />}
                        </div>
                    </div>
                </div>
            </ChatLayout>
        </>
    )
}

export default ChatStandalone