import React, { useState } from 'react'
import Header from '../../Components/Common/Header'
import Footer from '../../Components/Common/Footer'
import userIcon from '../../assets/img/icon-chat-user.svg'
import iconAdd from '../../assets/img/icon-plus.svg'
import iconAttach from '../../assets/img/icon-attach.svg'
import chatImg from '../../assets/img/pdf-card.png'
import { Link } from 'react-router-dom'
import ChatMessageField from '../../Components/Chat/ChatMessageField'
import ChatBoxHeader from '../../Components/Chat/ChatBoxHeader'
import backArrow from '../../assets/img/white-back-arrow.png'
import ChatMessageBody from '../../Components/Chat/ChatMessageBody'
import ChatLayout from './ChatLayout'
import ChatGroupList from '../../Components/Chat/ChatGroupList'


const chatList = [
    {
        name: "Kathy Williams",
        designation: "Acme Corporation",
        chattime: "Today",
        readmsg: true,
        msgcount: "3",
        status: 'online',
        messages: [],
        selected: false
    },
    {
        name: "Land Deal TEAM",
        designation: "Group Chat",
        chattime: "4:33 PM",
        readmsg: true,
        msgcount: "3",
        status: 'offline',
        messages: [],
        selected: false
    },
    {
        name: "Robbin Kenedy",
        designation: "Global Asset Management",
        chattime: "Thrusday",
        readmsg: false,
        msgcount: "3",
        status: 'online',
        messages: [],
        selected: false
    },
    {
        name: "Tim Burton",
        designation: "Land Deal",
        chattime: "11:20 AM",
        readmsg: false,
        msgcount: "3",
        status: 'online',
        messages: [],
        selected: false
    },
    {
        name: "Marshall McGregor",
        designation: "Acme Corporation",
        chattime: "12/08/18",
        readmsg: false,
        msgcount: "3",
        status: 'online',
        messages: [],
        selected: false
    },

    {
        name: "John Smith",
        designation: "Tech Solutions Inc.",
        chattime: "Today",
        readmsg: true,
        msgcount: "5",
        status: 'offline',
        messages: [],
        selected: false
    },

    {
        name: "Emily Johnson",
        designation: "Global Innovations",
        chattime: "Today",
        readmsg: true,
        msgcount: "2",
        status: 'online',
        messages: [],
        selected: false
    },

    {
        name: "Michael Brown",
        designation: "XYZ Corporation",
        chattime: "Today",
        readmsg: true,
        msgcount: "4",
        status: 'offline',
        messages: [],
        selected: false
    },

    {
        name: "Sarah Davis",
        designation: "TechNet Solutions",
        chattime: "Today",
        readmsg: true,
        msgcount: "7",
        status: 'online',
        messages: [],
        selected: false
    },

    {
        name: "James Miller",
        designation: "Innovate Tech",
        chattime: "Today",
        readmsg: true,
        msgcount: "1",
        status: 'offline',
        messages: [],
        selected: false
    },

    {
        name: "Linda Garcia",
        designation: "Future Enterprises",
        chattime: "Today",
        readmsg: true,
        msgcount: "6",
        status: 'online',
        messages: [],
        selected: false
    },

    {
        name: "Robert Wilson",
        designation: "QuickTech Solutions",
        chattime: "Today",
        readmsg: true,
        msgcount: "2",
        status: 'offline',
        messages: [],
        selected: false
    },

    {
        name: "Jessica Lee",
        designation: "InnovateX Inc.",
        chattime: "Today",
        readmsg: true,
        msgcount: "3",
        status: 'online',
        messages: [],
        selected: false
    },

    {
        name: "David Clark",
        designation: "Tech Innovators",
        chattime: "Today",
        readmsg: true,
        msgcount: "5",
        status: 'offline',
        messages: [],
        selected: false
    }
]
const ChatCreateGroup = () => {
    const [chatMsg, setChatMsg] = useState("");
    const [chatOpen, setChatOpen] = useState(false);
    const [listItemSelected, setListItemSelected] = useState(false);
    const [checkboxNames, setCheckboxNames] = useState("")
    const [groupChat, setGroupChat] = useState({messages:[]})
    const [message, setMessage] = useState('');
    const [groupName, setGroupName] = useState('');
    const [updateChat, setUpdateChat] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openChatMsg = (chatItem, chatName) => {
        setChatMsg(chatItem);
        setChatOpen(!chatOpen);
        var checkboxName = [...checkboxNames];
        if (checkboxName.indexOf(chatItem.name) > -1) {
            checkboxName = checkboxName.filter(name => name !== chatItem.name)
        } else {
            checkboxName.push(chatItem.name);
        }
        if(checkboxName.length <= 10){
        setCheckboxNames(checkboxName);
        var groupChats = groupChat;
        groupChats.groupMember = checkboxName;
        setGroupChat(groupChats);
        }
    }
    const backArrowBtn = () => {
        setChatOpen(false)
    }

    const setGroupChatName = ()=> {
        // console.log(name);

        if(groupName){
            var groupChats = groupChat;
            groupChats.messages = [];
            groupChats.name = groupName;
            setGroupChat(groupChats);
            }
            setGroupName('');
    }


    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };
    const now = new Date();
    const formatDateTime = (date) => {
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true, // 24-hour format
        };
        return date.toLocaleString(undefined, options);
    }
    const formattedDateTime = formatDateTime(now);
    const sendMessage = () => {
        if (message && groupChat.name) {
            const newMessage = { text: message, id: Date.now(), time: formattedDateTime,className:"repaly", messageType:"text"};
            groupChat.messages = [...groupChat.messages, newMessage];
            setGroupChat(groupChat);
            setMessage('');
        }
    };
    const uploadFile = (file) => {
        setIsLoading(true)
       if (groupChat.name && file.length) {
            const reader = new FileReader();
            reader.readAsText(file[0]);
            reader.onload = (evt)=>{ 
            var fileSize = 0;
            var messageType = "";
            // if(['application/pdf'].indexOf(file[0].type)>-1){
                if((file[0].type).includes('application') || (file[0].type).includes('text')){
                fileSize = ((file[0].size / (1024*1024)));
                var ext = (fileSize<=0)?"KB":"MB";
                fileSize = (fileSize.toFixed(2)).padStart(2, ' ')+ext;
                messageType = "pdf"
            }

            if(['image/jpeg','image/jpg', 'image/png'].indexOf(file[0].type) > -1){
                messageType = "png"
            }

            // if(fileSize && messageType){
            const newMessage = {
                text: "",
                id: Date.now(),
                time: formattedDateTime,
                data: URL.createObjectURL(file[0]),
                files: file[0],
                className: "repaly",
                messageType,
                fileSize
            };

            groupChat.messages = [...groupChat.messages, newMessage];
            setGroupChat(groupChat);
            setUpdateChat(!updateChat);
            setMessage('');
            setIsLoading(false);
        }
    }
    
    }
    // console.log(groupChat);
    return (
        <>
            <Header headerHideForMobile={true} />
            <ChatLayout>
                <ChatGroupList
                    chatList={chatList}
                    checkboxNames={checkboxNames}
                    openChatMsg={openChatMsg}
                    listItemSelected={listItemSelected}
                    setGroupChat={setGroupChatName}
                    setGroupName = {setGroupName}
                    groupName = {groupName}

                />
                <div className={`chatbox ${chatOpen ? "active" : ""}`}>
                    <div className="modal-dialog-scrollable">
                        <div className="modal-content">
                            <ChatBoxHeader
                                chatMsg={groupChat}
                                backArrowBtn={backArrowBtn}
                                checkboxNames={checkboxNames}

                            />
                              <ChatMessageBody
                                chatMsg={groupChat}
                                message={"Chat Standalone"}
                            />
                            <ChatMessageField
                                handleInputChange={handleInputChange}
                                sendMessage={sendMessage}
                                message={message}
                                chatUpdated={updateChat}
                                uploadFiles={uploadFile}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </ChatLayout>
        </>
    )
}

export default ChatCreateGroup