import React, { useEffect, useState, useContext, useRef } from 'react'
import Header from '../../Components/Common/Header'
import Footer from '../../Components/Common/Footer'
import { Link } from 'react-router-dom'
import AutoComplete from '../../Components/Utils/AutoComplete'
import { Field, Formik, Form } from 'formik'
import TextAreaField from "../../Components/Form/TextAreaField";
import CreatableSelect from 'react-select/creatable'
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext'
import {EnvContext} from '../../context/EnvContext';

const recipientOptions = []; 

const ShareYourProperty = () => {
    const {user} = useContext(UserContext); 
    const { getProperty, getMembers, sendSMS, sendEmail } = useContext(PropertyContext);
    const { landdealBaseURL } = useContext(EnvContext);

    const queryParameters = new URLSearchParams(window.location.search)
    const place_id = queryParameters.get("place_id");

    const [propertyData, setPropertyData] = useState({});
    const [memberData, setMemberData] = useState({});

    const [formikData1, setFormikData1] = useState({
        recipients: "",
        template_email: true,
        place_id: place_id
    }); 

    const [formikData2, setFormikData2] = useState({
        recipients: "",
        message: "",
        subject: "A New Property Has Been Listed @ LandDeal.com"
    }); 

    const [formikData3, setFormikData3] = useState({
        recipients: "",
        message: "",
        primary_image: ""
    }); 

    const formikRef1 = useRef(null);

    const formikRef2 = useRef(null);

    const formikRef3 = useRef(null);

    useEffect(() => {       

        const fetchData = async () => {
            const propData = await getProperty(place_id,user.username);
            setPropertyData(propData);

            formikRef2.current.setFieldValue("message", `Hello LandDeal.com Members-\n\nCheck out a new listing from ${propData.broker_name} at ${propData.broker_company_name}. With ${propData.acreage} acres in ${propData.city} it's a must see:\n${landdealBaseURL}properties/land-details?place_id=${place_id}\n\nRegards,\n${propData.broker_name}\n${propData.broker_company_name}\n${propData.broker_phone_number}`);

            formikRef3.current.setFieldValue("message", propData.broker_name + " posted a new property listing at LandDeal.com\n" + landdealBaseURL + "properties/land-details?place_id=" + propData.place_id);

            formikRef3.current.setFieldValue("primary_image", propData.primary_image);

        }    
        
        const fetchUsers = async () => {
            const memData = await getMembers(user.username);
            
            console.log(memData);

            memData.map((v,i) => {
                recipientOptions.push( { value: v.phone, label: v.first_name + " " + v.last_name});
            });

            setMemberData(memData);
        }    
        
        fetchData();     
        
        fetchUsers();

    }, [place_id]); 

    const [checkedCount, setCheckedCount] = useState(0);

    const showChecked = () => {
        setCheckedCount(document.querySelectorAll("input:checked").length);
    }

    /*useEffect(()=>{
    showChecked();
    },[]);*/

    return (
        <>
            <Header />
            <section className="main-wrap ps-0 pe-0">
                <div className='syp-block pt-5 pb-5'>
                    <div className='container mxw-960'>
                        <div className='syp-success-box'>
                            <div className='xs-title text-uppercase text-white font-18'>Congratulations! Your Property Is Now Published as <Link to="/" className='theme-text text-decoration-none'>{propertyData && propertyData.property_status && propertyData.property_status.replace('_',' ')}</Link></div>
                            <div className='xs-title text-gray font-14 fw-500 mt-3 mb-2'>You can view your property listing here:</div>
                            <div className='xs-title font-16 fw-500 theme-text syp-link'><a className="theme-text" href={landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id}>{landdealBaseURL + "properties/land-details?place_id=" + propertyData.place_id}</a> </div>
                        </div>

                        <div className='syp-dtl-box mt-5'>
                            <div className="xl-title mb-4 text-uppercase">Share Your Property</div>
                            <div className="document-nots-block sharing-method-tabs">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="ToMembers-tab" data-bs-toggle="tab" data-bs-target="#ToMembers" type="button" role="tab" aria-controls="ToMembers" aria-selected="true">To Members</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="CustomEmail-tab" data-bs-toggle="tab" data-bs-target="#CustomEmail" type="button" role="tab" aria-controls="CustomEmail" aria-selected="false" >Custom Email</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="TextMessage-tab" data-bs-toggle="tab" data-bs-target="#TextMessage" type="button" role="tab" aria-controls="TextMessage" aria-selected="false">Text Message</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <Formik
                                        validationSchema=""
                                        initialValues={formikData1}
                                        onSubmit={(values) => {
                                            console.log(values);
                                            sendEmail(values);
                                            alert("Your message has been sent!");
                                        }}
                                        innerRef={formikRef1}
                                        enableReinitialize={true}
                                    >
                                    {({ values }) => (
                                        <div className="tab-pane fade active show" id="ToMembers" role="tabpanel" aria-labelledby="ToMembers-tab">
                                            <Form>
                                                <Field type="hidden" name="template_email" />
                                                <Field type="hidden" name="place_id" />
                                                <div className="gray-box pd-30-50">
                                                    <AutoComplete placeholder="Search by company name. Ex: “Acme Corporation” "/>
                                                    <ul className='syp-list'>
                                                        <li>
                                                            <div className="custom-checkbox sm-checkbox d-inline-block">
                                                                <Field type='checkbox'
                                                                    name="recipients"
                                                                    className="styled-checkbox"
                                                                    id="checkbox_a" onClick={showChecked}
                                                                    value="all"/>
                                                                <label htmlFor="checkbox_a">All Groups</label>
                                                            </div>
                                                        </li>
                                                        {memberData.length > 0 ? (
                                                            memberData.map((v,i) => (
                                                                <li key={i}>
                                                                    <div className="custom-checkbox sm-checkbox d-inline-block">
                                                                        <Field type='checkbox'
                                                                            name="recipients"
                                                                            className="styled-checkbox"
                                                                            id={"checkbox_" + i} onClick={showChecked}
                                                                            value={v.company_id} />
                                                                        <label htmlFor={"checkbox_" + i}>{v.company}</label>
                                                                    </div>
                                                                </li>
                                                            ))) 
                                                                : 
                                                            <h4>Member list is not available yet!</h4>
                                                        }
                                                    </ul>
                                                    <div className='mt-5'>
                                                        <button type='button' className='theme-trans-btn font-12 text-uppercase'><span className='plus-icon'></span> Add New Recipient</button>
                                                    </div>
                                                    <div className='mt-4 text-end'>
                                                        {checkedCount > 0? 
                                                            <button type='button' className='transparent-btn font-12'>
                                                                <span className='theme-text'>{checkedCount}</span>  Selected
                                                            </button> : ""
                                                        }
                                                        <button type='submit' className='theme-btn text-uppercase mnw-150'>Send</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    )}</Formik>
                                    <Formik
                                        validationSchema=""
                                        initialValues={formikData2}
                                        onSubmit={(values) => {
                                            console.log(values);
                                            sendEmail(values);
                                            alert("Your message has been sent!");
                                        }}
                                        innerRef={formikRef2}
                                        enableReinitialize={true}
                                    >
                                    {({ values }) => (
                                        <div className="tab-pane fade" id="CustomEmail" role="tabpanel" aria-labelledby="CustomEmail-tab">
                                            <Form>
                                                <div className="gray-box pd-20-50">
                                                    <div className="custom-form mxw-560">
                                                        <div className="form-group">
                                                            <label className="font-13 mb-2">To</label>
                                                            <Field type="text" name="recipients" className="form-control" placeholder='youremail@domain.com, email2@domain.com' />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="font-13 mb-2">Subject</label>
                                                            <Field type="text"  name="subject"className="form-control" placeholder='Great business pontential property' />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="font-13 mb-1">Message</label>
                                                            <TextAreaField type="text" className="form-control" name="message" />
                                                        </div>
                                                        <div className="text-end mb-5">
                                                            <button type="buttton" className="theme-btn text-uppercase mnw-180 fw-700">Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                           </Form>
                                        </div>
                                    )}</Formik>
                                    <Formik
                                        validationSchema=""
                                        initialValues={formikData3}
                                        onSubmit={(values) => {
                                            console.log(values);
                                            sendSMS(values);
                                            alert("Your message has been sent!");
                                         }}
                                         innerRef={formikRef3}
                                         enableReinitialize={true}
                                    >
                                    {({ values }) => (
                                        
                                        <div className="tab-pane fade" id="TextMessage" role="tabpanel" aria-labelledby="TextMessage-tab">
                                            <Form>
                                                <Field type="hidden" name="primary_image" />
                                                <div className="gray-box pd-20-50">
                                                    <div className="custom-form mxw-560">
                                                        <div className="form-group">
                                                            <label className="font-13 mb-1">Select Recipients</label>
                                                            <CreatableSelect 
                                                                className="create-tags"
                                                                placeholder="Select Recipient"
                                                                name="recipients"
                                                                onChange={(newValue) => {values.recipients = newValue; console.log(newValue)}}
                                                                // onClick={}
                                                                isMulti options={recipientOptions} />
                                                            {/*<div className="mt-2">
                                                                <button className="trns-edit-btn text-uppercase font-12"><span className="plus-icon"></span> Add Recipients</button>
                                                            </div>*/}
                                                        </div>
                                                        <div className="form-group mt-5">
                                                            <label className="font-13 mb-1">Message</label>
                                                            <TextAreaField type="text" className="form-control" name="message" />
                                                        </div>
                                                        <div className="text-end mb-5">
                                                            <button type="submit" className="theme-btn text-uppercase mnw-180 fw-700">Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    )}</Formik>
                                </div>
                            </div>
                            <div className='mt-4 text-center'>
                                <button type='button' className='theme-trans-btn font-12 text-uppercase mnw-150'>GO BACK</button>
                            </div>
                        </div>                        
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ShareYourProperty