import mainLogo from '../../../assets/img/logo-powerbook-Logo-v1.svg';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RegisterModal from './RegisterModal';
import SignIn from './SignIn';

const Header = () => {
    const [isMob, setisMob] = useState(false);
    const onChangeBodyClass = (value) => {
        setisMob(value);
        value
            ? document.body.classList.add('overflow-active')
            : document.body.removeAttribute('class');
    };
    
    const [registerModal, setRegisterModal] = useState(false);
    const openRegisterModal = () => {
        setRegisterModal(true);
    }
    const [signIn, setSignIn] = useState(false);
    const openSignInModal = () => {
        setSignIn(true);
    }


    return (
        <>
            <header className="header-wrap frontend-header">
                <div className="container-fluid ps-5 pe-5">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="main-logo">
                            <Link to="/"><img src={mainLogo} alt="logo" /></Link>
                        </div>
                        <div className='d-inline-flex align-items-center'>
                            <div className= {`d-flex align-items-center justify-content-between menu-block ${isMob ? 'active' : ''}`}>
                                <div className="main-menu">
                                    <ul className="d-inline-flex align-items-center">
                                        <li>
                                            <Link to="/#home">Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/#about">About</Link>
                                        </li>
                                        <li>
                                            <Link to="/#whoWeAre">Who We Are</Link>
                                        </li>
                                        <li>
                                            <Link to="/#contact">Contact</Link>
                                        </li>
                                    </ul>
                                    
                                    <div className='menu-for-mobile mt-5 ps-4 pe-3'>
                                        <div>
                                            <button className='theme-btn text-uppercase w-100' type='button' onClick={openRegisterModal}>Apply to Join</button>
                                        </div>
                                        <div>
                                            <button className='theme-trans-btn text-uppercase w-100 mt-3' type='button' onClick={openSignInModal}>Log In</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='main-action ms-5'>
                                <button className='theme-btn text-uppercase' type='button' onClick={openRegisterModal}>Apply to Join</button>
                                <button className='theme-trans-btn text-uppercase ms-3' type='button' onClick={openSignInModal}>Log In</button>
                            </div>
                        </div>
                        <div className={`overflow-bg ${isMob ? 'active' : ''}`}></div>
                        <div
                            className={`mobile-inner-header ${isMob ? 'active' : ''}`}
                            onClick={() => onChangeBodyClass(!isMob)}
                        >
                            <div
                                className={`mobile-inner-header-icon mobile-inner-header-icon-out ${
                                    isMob ? 'active' : ''
                                }`}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <RegisterModal  registerModal={registerModal} setRegisterModal={setRegisterModal} />
            <SignIn signIn={signIn} setSignIn={setSignIn} />
        </>
    )
}

export default Header