import React from 'react'
import MapMarker from '../../Components/MapMarker/MapMarker'
import AutoComplete from '../../Components/Utils/AutoComplete'

const Search = ({allProperties, currentProperties, handleFilterChange}) => {
    
    return (
        <>
            <section className="map-wrap">
	             {/* <iframe
		          className="map"
		          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d455078.30215715966!2d-81.54765209216457!3d26.989082427541987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dc3dfb49f743a5%3A0xe80023328481fb5b!2sGlades%20County%2C%20FL%2C%20USA!5e0!3m2!1sen!2sin!4v1682524921876!5m2!1sen!2sin"
		          width="100%"
		          height="324"
		          frameborder="0"
		          style={{ border: "0" }}
		          allowfullscreen=""
		          aria-hidden="false"
		          tabindex="0"
		        ></iframe> */}

				<MapMarker allProperties={allProperties}/>
                <AutoComplete allProperties={allProperties} currentProperties={currentProperties} handleFilterChange={handleFilterChange}/>
            </section>
        </>
    )
}

export default Search