import React, { useState, useContext, useEffect, useRef } from "react";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Frontend/Common/Footer";
import { Formik, Form, Field, useFormikContext } from 'formik';
import TextField from "../../Components/Form/TextField";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AdminLeft from "../../Components/Admin/AdminLeft";
import {UserContext} from '../../context/UserContext';

const Payment = () => {
    const {user} = useContext(UserContext);
    const [startDate, setStartDate] = useState(new Date());
    const [formikData, setFormikData] = useState({
        name_on_card: "",
        credit_card_number: "",
        expiration_date: "",
        security_code: "",
    });
    $('input[name=credit_card_number]').keypress(function(){
        var rawNumbers = $(this).val().replace(/-/g,'');
        var cardLength = rawNumbers.length;
        if(cardLength !==0 && cardLength <=12 && cardLength % 4 == 0){
          $(this).val($(this).val());
        }
    });
    return (
        <>
            <Header />
            <section className="main-wrap ps-0 pe-0">
                <div className="add-property-block">
                    <div className="container">
                        <div className="gray-box add-property-box">
                            <div className="d-flex apb-flex pt-4">
                                <AdminLeft cardTitle="Property Settings for:" userCompany={user.company}/>
                                <div className="apb-right">
                                    <div className="xl-title text-uppercase">
                                        Payment
                                    </div>
                                    <div className="mb-4 bw-top mt-3 pt-4 payment-block">

                                        <div className="payment-box">
                                            <Formik
                                                initialValues={formikData}
                                                onSubmit={(values) => {
                                                    console.log(values);
                                                }}
                                            >
                                                {(formik, setFieldValue) => (
                                                    <Form>
                                                        <div className="custom-form mxw-350">
                                                            <div className="row">
                                                                <div className="col-12 text-white">
                                                                    <TextField
                                                                        label="Name on Card"
                                                                        type="text"
                                                                        placeholder="Name on Card"
                                                                        name="name_on_card"
                                                                    />
                                                                </div>
                                                                <div className="col-12 text-white">
                                                                    <TextField
                                                                        label="Credit Card Number"
                                                                        type="text"
                                                                        placeholder="Credit Card Number"
                                                                        name="credit_card_number"
                                                                        maxlength="16"
                                                                    />
                                                                </div>
                                                                <div className="col-md-6 text-white form-group">
                                                                    <label for="expiration_date">Expiration Date</label>
                                                                    <div className="date-picker">
                                                                        <DatePicker 
                                                                            selected={startDate} 
                                                                            onChange={(date) => setStartDate(date)} 
                                                                            className="form-control"
                                                                            name="expiration_date"
                                                                            dateFormat='MM-yy' 
                                                                        />
                                                                        <span className="calender-icon"></span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 text-white">
                                                                    <TextField
                                                                        label="3 Digit Security Code"
                                                                        type="text"
                                                                        placeholder=" "
                                                                        name="security_code"
                                                                    />
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="custom-checkbox sm-checkbox d-inline-block">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name="saveCCDetails"
                                                                            className="styled-checkbox"
                                                                            id='saveCCDetails'
                                                                        />
                                                                        <label htmlFor="saveCCDetails">Save credit card for future purchases</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-5 pt-5">
                                                                    <button type="button" className="transparent-btn theme-text w-50 font-14 fw-700 text-uppercase sm-btn">Cancel</button>
                                                                    <button type="button" className="theme-btn w-50 text-uppercase font-14 fw-700 sm-btn">Save</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};
export default Payment;
