import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export const plugins = [
    {
      afterDraw: (chart: { tooltip?: any; scales?: any; ctx?: any }) => {
        // eslint-disable-next-line no-underscore-dangle
        if (chart.tooltip._active && chart.tooltip._active.length) {
          // find coordinates of tooltip
          const activePoint = chart.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.element;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;

          // draw vertical line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#ffffff';
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];

export const options = {
  scales: {
    yAxes: [
      {
        type: "logarithmic",
        ticks: {
          callback: function (value, index, values) {
            return "$" + value.toFixed(0); // format y-axis tick labels as currency
          },
          min: 0,
          max: 100000000000,
          autoSkip: true,
          maxTicksLimit: 10,
          beginAtZero: true
        },
      },
    ],
  },
  title: {
    display: true,
    text: "Estimated ROI by Campaign",
  },
};



// const arbitraryLine = {
//     id:'arbitraryLine',
//     beforeDataSetsDraw
// }

const labels = ["1", "5", "15", "20", "25", "30", "35", "45", "50"];

export const data = {
  labels,
  datasets: [
    {
      label: 'year',
      data: [
        1000, 1500, 2500, 3000, 4000, 5000, 8000, 14000, 16000, 18000, 20000,
        18090,
      ],
      borderColor: "rgb(238, 160, 21)",
      backgroundColor: "rgb(238, 160, 21)",
      tension: 0.4,
      fill: false
    },
  ],
};

const LineChart = () => {
  return (
    <>
      <Line data={data} options={options} plugins={plugins}/>
    </>
  );
};

export default LineChart;
