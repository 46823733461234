import React from 'react'
import { Field, Form, Formik } from 'formik'
import closeIcon from '../../assets/img/close-modal.svg'
const EditModal = ({ showModal, setShowModal, propertyData, updatePropertyData, editFields, editTitle }) => {

    return (
        <>
            <div className={`modal fade custom-modal common-modal save-modal ${showModal ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-450 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile text-uppercase">Edit {editTitle}</div>
                            <button type="button" className="btn-close" onClick={() => {setShowModal(false);}}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={() => {setShowModal(false);}}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-4 text-center font-26 text-uppercase">Edit {editTitle}</div>

                            <Formik
                                // validationSchema={validationSchema}
                                initialValues={propertyData}
                                enableReinitialize={true}
                                onSubmit={(values) => {
                                    console.log(values)
                                    updatePropertyData(values)
                                    setShowModal(false);
                                }}
                            >
                                {({
                                    values
                                }) => (
                                    <Form className='custom-form'>
                                        {editFields.map((v,i) => {  
                                            return <div className='form-group' key={i}>
                                                <label className="font-13 mb-1 printout-label">{v.replace('broker_','').replace('_',' ')}</label>
                                                <Field
                                                    name={v}
                                                    type='text'
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                            </div>
                                        })}
                                        <button type="submit" className="theme-btn w-100 md-btn font-14 text-uppercase mt-4">Save</button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditModal
