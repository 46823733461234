import deleteIcon from "../../assets/img/icon-delete-photo.svg";
import TextField from "../Form/TextField";
import { Formik, Form, Field, FieldArray, useFormikContext } from "formik";
import * as yup from "yup";
import TextAreaField from "../Form/TextAreaField";
import { ValidationError } from "../Errors/ValidationError";
import CreatableSelect from 'react-select/creatable';
import { useRef, useState, useEffect, useContext } from "react";
import {UserContext} from '../../context/UserContext';
import ZoomTextareaModal from "./ZoomTextareaModal";

const validationSchema = yup.object({
  property_website: yup
    .string()
    .required("Property website field is required."),
  //description: yup.string().required("Description field is required."),
  //amenities: yup.string().required("Amenities field is required."),
  broker_name: yup.string().when("is_broker", {
    is: false,
    then: () => yup.string().required("Must enter broker name")
  }),
  broker_company_name: yup.string().when("is_broker", {
    is: false,
    then: () => yup.string().required("Must enter company name")
  }),
  broker_phone_number: yup.string().when("is_broker", {
    is: false,
    then: () => yup.string().required("Must enter phone number")
  }),
  broker_email: yup.string().email().when("is_broker", {
    is: false,
    then: () => yup.string().email().required("Must enter email addresss")
  }),
  is_broker: yup.boolean().required("Active broker field is required."),
  /*social_links: yup.array()
  .of(
    yup.object().shape({
      type: yup.string().required('Required'), // these constraints take precedence
      url: yup.string().required('Required'), // these constraints take precedence
    })
  )
  .required('Required'), // these constraints are shown if and only if inner constraints are satisfied */
  property_website: yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct url!'
)
});
const amenityOptions = [
  { value: 'Homesite', label: 'Homesite'},
  { value: 'Horse', label: 'Horse'},
  { value: 'Hunting', label: 'Hunting'},
  { value: 'Lakefront', label: 'Lakefront'},
  { value: 'Oceanfront', label: 'Oceanfront'  },
  { value: 'Recreational', label: 'Recreational'  },
  { value: 'Riverfront', label: 'Riverfront'  },
  { value: 'Timberland', label: 'Timberland'  },
  { value: 'Income', label: 'Income-Producing'  },
  { value: 'Undeveloped', label: 'Undeveloped'  },
  { value: 'Waterfront', label: 'Waterfront'  },
  { value: 'Aviation', label: 'Aviation'  },
  { value: 'Beach', label: 'Beach'  },
  { value: 'Boating', label: 'Boating'  },
  { value: 'Camping', label: 'Camping'  },
  { value: 'Canoeing', label: 'Canoeing'  },
  { value: 'Kayaking', label: 'Kayaking'  },
  { value: 'Conservation', label: 'Conservation'},
  { value: 'Fishing', label: 'Fishing'},
  { value: 'Horseback Riding', label: 'Horseback Riding'},
  { value: 'Off-Roading', label: 'Off-Roading'},
  { value: 'RVing', label: 'RVing'},
]; 

const ExtendedInfo = ({nextStep, setNextActiveHeading, item, propertyData, sendFormikRef}) => {

  const {user} = useContext(UserContext); 

  const [formikData, setFormikData] = useState({
    property_website: propertyData && propertyData.property_website ? propertyData.property_website : "",
    description: propertyData && propertyData.description ? propertyData.description : "",
    legal_description: propertyData && propertyData.legal_description ? propertyData.legal_description : "",
    amenities: propertyData && propertyData.amenities ? propertyData.amenities.map((v,i) => { return { value: v, label: v}}) : "",
    is_broker: propertyData && propertyData.is_broker ? String(propertyData.is_broker) : "0",
    broker_name: propertyData && propertyData.broker_name ? propertyData.broker_name : user.first_name + " " + user.last_name,
    broker_company_name: propertyData && propertyData.broker_company_name ? propertyData.broker_company_name : user.company,
    broker_phone_number: propertyData && propertyData.broker_phone_number ? propertyData.broker_phone_number : user.phone,
    broker_email: propertyData && propertyData.broker_email ? propertyData.broker_email : user.email,
    social_links: propertyData && propertyData.social_links ? propertyData.social_links : []
  }); 

  const formikRef = useRef(null);

  useEffect(() => {
    sendFormikRef('extended',formikRef.current);
  }, [formikRef])
  
  const prevValues = useRef(null);

  const SendFormikData = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      
      if(JSON.stringify(values) != prevValues.current) {
        nextStep(0,values);      
        console.log(values,JSON.stringify(values));
        prevValues.current = JSON.stringify(values);
      }
    }, [JSON.stringify(values)]);
    return null;
  };

  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentZoomField, setCurrentZoomField] = useState(false);
  const [zoomText, setZoomText] = useState("");

  const updateZoomText = (val) => {
    formikRef.current.setFieldValue(currentZoomField,val);
    setZoomText(val);
  }

  const openzoomtextmodal = () => {
    setOpenZoomModal(true);
  }

  return (
    <>
      <div className="accordion-body extend-info-box">
        <Formik
          validationSchema={validationSchema}
          initialValues={formikData}
          enableReinitialize={true}
          innerRef={formikRef}
          onSubmit={(values) => {
            // data send serve side
            console.log(values);
            nextStep(item+1,values);
          }}
        >
          {({ values }) => (
            <Form>
              <SendFormikData />
              <div className="row mlr-40">
                <div className="col-md-6 plr-40">
                  <TextField
                    label="Property Website"
                    type="text"
                    placeholder="http://www.yourland.com"
                    name="property_website"
                  />
                  <div className="form-group mb-0">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <label htmlFor="description" className="mb-0">Description</label>
                      <div>
                        <button type="button" className="trns-edit-btn text-uppercase fw-700" onClick={() => {setOpenZoomModal(true); setCurrentZoomField('description'); setZoomText(values.description);}}><span className="zoom-icon"></span> Open Full Screen</button>
                      </div>
                    </div>
                    <TextAreaField
                      className="form-control h-80"
                      placeholder="Write your description"
                      name="description"
                    />
                  </div>
                  <div className="form-group  mb-0">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <label htmlFor="legal_description" className="mb-0">Full Legal Description</label>
                      <div>
                        <button type="button" className="trns-edit-btn text-uppercase fw-700" onClick={() => {setOpenZoomModal(true); setCurrentZoomField('legal_description'); setZoomText(values.legal_description);}}><span className="zoom-icon"></span> Open Full Screen</button>
                      </div>
                    </div>
                    <TextAreaField
                     className="form-control h-80"
                     placeholder="Write your description"
                     name="legal_description"
                    />
                  </div>
                  <div className="form-group">
                    <label>Social Links</label>
                    <FieldArray name="social_links">
                      {({ push, remove }) => (
                        <>
                          {values.social_links.map((value, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-5 col-4">
                                <div className="form-group">
                                  <Field
                                      component="select"
                                      className="form-control"
                                      name={`social_links.${index}.type`}
                                    >
                                    <option value="facebook">Facebook</option>
                                    <option value="instagram">Instagram</option>
                                    <option value="youtube">Youtube</option>
                                    <option value="twitter">Twitter</option>
                                    <option value="linkedin">LinkedIn</option>
                                    <option value="other">Other</option>
                                    </Field>
                                </div>
                              </div>
                              <div className="col-md-5 col-6">
                                <div className="form-group">
                                
                                  <Field
                                    type="text"
                                    name={`social_links.${index}.url`}
                                    className="form-control"
                                    placeholder="www.facebook.com/yourland"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 col-2 text-center pt-2">
                                <button
                                  type="button" className="action-link"
                                  onClick={() => remove(index)}
                                >
                                  <img src={deleteIcon} alt="delete" />
                                </button>
                              </div>
                            </div>
                          ))}

                          <div className="mt-3">
                            <button
                              type="button"
                              className="trns-edit-btn text-uppercase"
                              onClick={() => push({ type: '', url: '' })}
                            >
                              <span className="plus-icon"></span> Add Social
                              Platform
                            </button>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>
                <div className="col-md-6 plr-40">
                  {/* <TextAreaField
                    className="form-control h-80"
                    label="Amenities"
                    name="amenities"
                    tip="Tip: Enter all property amenities separated by comma"
                  /> */}
                  
                  <div className="form-group">
                    <label>Amenities</label>
                    <CreatableSelect 
                      className="create-tags"
                      placeholder="Select Amenities"
                      name="amenities"
                      onChange={(newValue) => {values.amenities = newValue; console.log(newValue)}}
                      // onClick={}
                      isMulti 
                      options={amenityOptions} 
                      defaultValue={values.amenities}
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex align-items-center active-broker">
                      <label>Active Broker?</label>
                      <div className="cr-bg d-flex ms-3">
                        <div className="custom-radio active-bg">
                          <Field
                            type="radio"
                            name="is_broker"
                            value="1"
                            id="activeBrokerYes"
                          />
                          <label
                            htmlFor="activeBrokerYes"
                            className="text-uppercase"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="custom-radio">
                          <Field
                            type="radio"
                            name="is_broker"
                            value="0"
                            id="activeBrokerNo"
                          />
                          <label
                            htmlFor="activeBrokerNo"
                            className="text-uppercase"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <ValidationError name="is_broker" />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        label={(values.is_broker == 1 ? "Broker" : "Company") + " Name"}
                        type="text"
                        placeholder="Enter your broker name"
                        name="broker_name"
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label={(values.is_broker == 1 ? "Broker" : "") + " Company"}
                        type="text"
                        placeholder="Enter your Company name"
                        name="broker_company_name"
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label={(values.is_broker == 1 ? "Broker" : "Company") + " Phone"}
                        type="text"
                        placeholder="Enter your phone number"
                        name="broker_phone_number"
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        label={(values.is_broker == 1 ? "Broker" : "Company") + " Email"}
                        type="text"
                        placeholder="Enter your email"
                        name="broker_email"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 plr-40 text-end mt-2">
                  <button
                    type="submit"
                    className="theme-btn text-uppercase w-25 sm-btn"
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ZoomTextareaModal 
        openZoomModal={openZoomModal} 
        setOpenZoomModal={setOpenZoomModal}
        zoomText={zoomText}
        updateZoomText={updateZoomText}
      />
    </>
  );
};

export default ExtendedInfo;
