import speedometer from '../../assets/img/speedometer-graph.jpg'
import ReactSpeedometer from "react-d3-speedometer"

const TimeRemaining = () => {
    return (
        <>
            <div className="gray-box p-0">
                <div className="d-flex align-items-center red-heading justify-content-between">
                    <div className="box-title text-uppercase">Time Remaining</div>
                    <div className="xs-title fw-400">7 Day 4h 23m</div>
                </div>
                <div className="p-2 speedometer-box">
                    <div className='position-relative spm-block'>
                        <div className='spm-lbl-mid'>50<span>M</span></div>
                        <ReactSpeedometer maxValue={100}
                            ringWidth={25}
                            width={300}
                            height={170}
                            maxSegmentLabels={4}
                            value={25}
                            needleColor="#fff"
                            segments={1000}
                            needleHeightRatio={0.65}
                        />
                        <div className='circle-needle'><span></span></div>
                        <div className='d-flex align-items-center justify-content-between speedmeter-label'>
                            <div>1<span>M</span></div>
                            <div>100<span>M</span></div>
                        </div>
                    </div>
                </div>
                <div className="speedometer-table mt-2">
                    <table className="w-100">
                        <tbody>
                            <tr>
                                <th className="fw-600 theme-text">Buy it now price</th>
                                <td>$41.5 M</td>
                            </tr>
                            <tr>
                                <th className="fw-600">Current High Bid</th>
                                <td>$38.2 M</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mt-30 pb-5">
                    <div className="xs-title fw-400 w-100 text-center text-uppercase">Reserve Proximity <span
                        className="info-icon ms-2"></span></div>
                    <div className="mt-3 text-center ps-4 pe-4">
                        <div className="progress-block mb-5">
                            <div className="lb-title text-center text-white font-13 mb-2 pb-1">Half Way</div>
                            <div className="progress custom-progress">
                                <div className="progress-bar" role="progressbar" aria-valuenow="50"
                                    aria-valuemin="0" aria-valuemax="100" style={{ width: "50%" }}>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                                <div>0</div>
                                <div>10</div>
                            </div>
                        </div>
                        <button type="button" className="theme-btn text-uppercase md-btn w-100 mt-3">Enter
                            Bid</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeRemaining