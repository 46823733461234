import { Link } from 'react-router-dom'
import closeIcon from '../../assets/img/close-modal.svg'
import inviteIcon from '../../assets/img/invite-icon.svg'
import logoV1 from '../../assets/img/Logo-v1.svg'

const WelcomeModal = ({open, close, dontShow, proceedBtn}) => {
  return (
    <>
         <div className={`modal fade custom-modal common-modal make-offer-modal welcome-modal ${open ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-740">
                    <div className="modal-content">
                        {/* <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile">Contact Info</div>
                            <button type="button" className="btn-close" onClick={close}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div> */}
                        <div className="modal-body">
                            <button type="button" className="btn-close" onClick={close}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className='welcome-top-box'>
                                <div className='text-center invite-img'>
                                    <img src={inviteIcon} alt='logo'/>
                                </div>
                                <div className='text-center wl-title mt-4 mb-3'>Welcome to</div>
                                <div className='text-center logo-v1'>
                                    <img src={logoV1} alt='logo'/>
                                </div>
                            </div>
                            <div className='wl-content'>
                                <p>Our mission here is to directly connect landowners to freely conduct business & foster lifelong relationships.</p>
                                <p>The site is currently in Beta so if you see any issues don’t be alarmed, please let us know and our team will be implementing improvements ASAP to make your experience here as enjoyable as possible. </p>
                                <p>You can reach us anytime at <a href='mailto:help@landdeal.com'>help@landdeal.com</a></p>
                                <div className='text-center mt-5'>
                                    <button type='button' className='theme-btn text-uppercase sm-btn mnw-190 d-inline-block' onClick={proceedBtn}>Proceed</button>
                                </div>
                                <div className='text-center mt-4'>
                                    <div className="custom-checkbox sm-checkbox mt-2">
                                        <input className="styled-checkbox" id="dontShow" type="checkbox" value="dontShow" onClick={dontShow}/>
                                        <label htmlFor="dontShow"><span>Don’t show again</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default WelcomeModal