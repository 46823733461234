import React, { useEffect, useRef, useState } from "react";
import folderIcon from "../../assets/img/folder-icon.svg";
import excelicon from "../../assets/img/file-photo/file-excel.svg";
import pdficon from "../../assets/img/file-photo/file-pdf.svg";
import docicon from "../../assets/img/file-photo/file-word.svg";
import imageicon from "../../assets/img/file-photo/file-photo.svg";
import sort from "../../assets/img/file-photo/sort.svg";
import hmMenuIcon from "../../assets/img/dots.png";
import privateIcon from "../../assets/img/icon-private.svg";
import membersOnlyIcon from "../../assets/img/icon-members.svg";
import publicIcon from "../../assets/img/icon-public.svg";
import documentIcon from "../../assets/img/document.svg";
import dragIcon from "../../assets/img/drag.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BasicFunction } from "./reactShortable";
import closeIcon from "../../assets/img/close-modal.svg";
import { useDropzone } from "react-dropzone";


var subCurrentDirectory = "";
const Drag = ({ BreadCrumbArrayChange,documentData,documentChange,moveDocumentWrapper, deleteDocumentWrapper, addFolderWrapper, editPrivacyWrapper, currentPath, setCurrentPath }) => {
  const toastInfo = () => toast.info(`Name can't be blank`);
  const [moveto_folderValue,setMovetoFolderValue]  = useState([]);

  const [currentDocuments, setCurrentDocuments] = useState(documentData);

  const [allDocuments, setAllDocuments] = useState(documentData);

  const [hiddenDocuments, setHiddenDocuments] = useState([]);

  const [existingField, setexistingField] = useState([]);

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [currentFolder, setCurrentFolder] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [showNotification, setShowNotification] = useState(false);

  const [lastMoveData, setLastMoveData] = useState({ source: null, destination: null });

  const notificationShownRef = useRef(false);

  const breadCrumbHomeData =()=> ([ { id: "home", name: "Home",obj_key:"Home" }  ])
  const [BreadCrumbArray, setBreadCrumbArray] = useState(breadCrumbHomeData());


  useEffect(()=>{ setBreadCrumbArray(breadCrumbHomeData())  },BreadCrumbArrayChange)

  const updateDocument = (id, field, value) => {

    setCurrentDocuments(currentDocuments.map((item, i) => {
      if (item.id == id) item[field] = value;
      return item;
    }));

    setAllDocuments(allDocuments.map((item, i) => {
      if (item.id == id) item[field] = value;
      return item;
    }));

  }


  const remameDocument = (documents, this_id, new_name, editable) => {
    return documents.map((item) => {
      if (item.id == this_id) {
        item.name = new_name;
        item.editable = editable;
      }
      return item;
    })
  }

  // ... (other code)

  function drop(ev) {


    ev.preventDefault();
    if (isEditing) return;

    if (!ev.target.id) return;

    var this_id = ev.dataTransfer.getData("text");
    var target_id = ev.target.id;
    document.getElementById(target_id)?.classList.remove("dragged-over");
    ev.target.classList.remove("dragged-over");


    if (this_id == target_id) return;
    const targetFolderObj = currentDocuments.find((item) => item.id === target_id);
    const fileObj         = currentDocuments.find((item) => item.id === this_id);

    const folderName = targetFolderObj?.name;
    const fileName = fileObj?.name;

    if (folderName && fileName && !notificationShownRef.current && targetFolderObj.type == 'folder') {
      setHiddenDocuments((prev) => [...hiddenDocuments, this_id]);
      moveFile(this_id, targetFolderObj.obj_key);
    }
    
    return;
  }

   useEffect(() => {
    const timer = setTimeout(() => {
      setShowNotification(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [showNotification]);


  useEffect(() => {

    // setCurrentDocuments(documentData);
    setCurrentDocuments(documentData.findWithFolderNFile(currentPath));
    setAllDocuments(documentData);
    setHiddenDocuments([]);

    if (currentPath) {
      for (var i = 0; i < documentData.length; i++) {
        if (documentData[i].type == "folder" && documentData[i].name + "/" == currentPath) openFolder(documentData[i], documentData);
      }
    }

  }, [documentData]);


  function drag(ev) {
    if (isEditing) return;
    ev.dataTransfer.setData("text", ev.target.id);
    ev.target.classList.add("dragged");
    ev.target.classList.add("grabbing"); // Add the 'grabbing' class
  }

  function dragEnd(ev) {
    if (isEditing) return;
    ev.target.classList.remove("dragged");
    ev.target.classList.remove("grabbing"); // Remove the 'grabbing' class
  }

  function dragOver(ev) {
    ev.preventDefault();
    const targetItemId = ev.target.id;
    const targetItem = document.getElementById(targetItemId);
     targetItem?.classList.add("dragged-over");
  }
  
  function dragLeave(ev) {
    ev.preventDefault();
    const targetItemId = ev.target.id;
    const targetItem = document.getElementById(targetItemId);
    targetItem?.classList.remove("dragged-over");
  }

  const selectDocument = (id) => {

    if (isEditing) return;
    var selected_files = selectedDocuments;

    var this_index = selected_files.indexOf(id);

    if (this_index >= 0) selected_files.splice(this_index, 1);
    else selected_files.push(id);

    setSelectedDocuments(selected_files);
  };

  useEffect(() => {
    var data = allDocuments.filter((item) => (currentPath == "" ? !item.obj_key.includes('/') : item.obj_key.substring(0, item.obj_key.lastIndexOf("/")) == currentPath.substring(1)))
    setCurrentDocuments(data);
    setSelectedDocuments([]);

  }, [currentPath]);

  const openFolder = (currentFolderObj, useDocumentData = null) => {

    if (!currentFolderObj) return;


    var breadCrumb = BreadCrumbArray;

    if (isEditing) return;

    if (currentFolderObj.id === "home") {
      breadCrumb = [{ id: "home", name: "Home" }];

    } else if (currentFolderObj) {

      setCurrentPath(currentPath + currentFolderObj.name + "/");

      const breadCrumbIndex = breadCrumb.map((item) => item.id).indexOf(currentFolderObj.id);

      //console.log(breadCrumbIndex);

      if (breadCrumbIndex === -1) {
        breadCrumb.push(currentFolderObj);
      } else {
        breadCrumb.splice(breadCrumbIndex + 1, breadCrumb.length);
      }
    }

    var newPath = "";

    if (breadCrumb.length > 1) { breadCrumb.map((v, i) => { if (i > 0) newPath = newPath + "/" + v.name; }); }

    setBreadCrumbArray(breadCrumb);
    setCurrentPath(newPath);

    subCurrentDirectory = newPath;
  };

  // console.log(currentDocuments);

  const deleteDocument = (ids, type) => {
    if (isEditing) return;
    if (type && type == 'folder' && !confirm("Are you sure you want to delete this folder? All files in this folder will also be deleted")) return;

    var this_property = currentDocuments.filter(({ id }) => id === ids)[0];

    setSelectedDocuments(selectedDocuments.filter((item) => item !== ids));
    deleteDocumentWrapper(this_property.obj_key + (type && type == 'folder' ? '/' : ''));
  };


  const moveFile = (fileId, targetPath) => {

    var temp_name = "";
    var demo = subCurrentDirectory.split("/");


    demo.pop();
    if(demo[demo.length-1] !== "/"){ temp_name = demo[demo.length-1]; }
    subCurrentDirectory =demo.join("/");

    if(targetPath == "parent_folder"){ getRootFolders(subCurrentDirectory); return;}
    var this_file = currentDocuments.filter((item) => item.id === fileId)[0];

    setOpenMove();
    if (!this_file) { console.error("File not found"); return; }
    var target_folder = {name:""};

    if(targetPath !== "my_drive"){
      allDocuments.filter((item) => item.obj_key === targetPath)[0];
    if (!target_folder) {
        console.error("Target folder not found");
        return;
      }
    }{
      var path = targetPath.split('/').pop();
      target_folder.name = path;
    }

    if(targetPath == "my_dirve"){
      target_folder.name = temp_name;
      console.log(target_folder);
      console.log(targetPath);
      return;
    }

    notificationShownRef.current = true;

    // Reset the notificationShownRef.current to false after a delay (e.g., 2 seconds)
    setTimeout(() => { notificationShownRef.current = false; }, 2000);
    
    // show notification
    toast.success(`File "${this_file.name}" moved to "${target_folder.name}"`);                                                 
    var addSumExtra  = (this_file.type == "folder" ? "/" : "");

    setCurrentDocuments(currentDocuments.filter((item)=> item.id !== fileId ));
    setSelectedDocuments(selectedDocuments.filter((item) => item !== fileId));
    if(this_file.type == 'folder'){
        allDocuments.map((item)=>{
        let startIndex = item.obj_key.indexOf(this_file.name);
        const oldObjKey = item.obj_key;
        if (startIndex !== -1) {
          let resultString = item.obj_key.substring(startIndex);
          item.obj_key = targetPath + '/' + resultString;
          moveDocumentWrapper(oldObjKey, item.obj_key,"delay");
        } 
      })
    }
    else{
      moveDocumentWrapper(this_file.obj_key + addSumExtra, targetPath + '/' + this_file.name);
    }
  }
  const renameSelectedPropery = (fileId, index) => {
    if (isEditing) return;
    var property = [...currentDocuments];
    property[index].editable = false;
    setIsEditing(true);
    setCurrentDocuments(property);
  };

  const nameUpdate = (target, ids, status) => {
    var editable = false;

    if (status == "changesDone") {
      if (!target) {
        toastInfo();
        return;
      }
      editable = true;

      var this_property = currentDocuments.filter(({ id }) => id === ids)[0];

      var folder = this_property.obj_key && this_property.obj_key.substring(0, this_property.obj_key.lastIndexOf('/')) + "/";

      if (folder == "/") folder = "";

      // console.log(this_property);

      if (this_property.is_new_folder) {
        addFolderWrapper(folder + this_property.name);
        updateDocument(this_property.id, 'is_new_folder', false);
      } else moveDocumentWrapper(this_property.obj_key + (this_property.type == "folder" ? "/" : ""), folder + this_property.name + (this_property.type == "folder" ? "/" : ""));

    }

    var property = currentDocuments;
    property = remameDocument(property,ids, target, editable);

    setIsEditing(!editable);
    setCurrentDocuments(property);
  };

  const [sortOrder, setSortOrder] = useState({
    name: true,
    date: true
  });

  function sortByname() {

    if  (sortOrder["name"]) currentDocuments.sort((a, b) => a.name.localeCompare(b.name));
    else currentDocuments.sort((a, b) => b.name.localeCompare(a.name));
       
    setSortOrder({ name: !sortOrder['name'],date: sortOrder['date']    });
    setCurrentDocuments(currentDocuments);
  }

  function sortByDate() {
    if (sortOrder["date"]) currentDocuments.sort((a, b) => new Date(a.date) - new Date(b.date));
    else currentDocuments.sort((a, b) => new Date(b.date) - new Date(a.date));
    setSortOrder({
      name: sortOrder['name'],
      date: !sortOrder['date']
    });
    setCurrentDocuments(currentDocuments);
  }

  const [openView, setOpenView] = useState();
  const closeOpenView = () => { setOpenView(false); };

  const OpenModal = (props) => {
    const { mime, url, id } = props;
    return (
      <div
        className={`modal sm pmovemodal fade custom-modal common-modal ${openView === id ? "show" : ""
          }`}
        id="moveFolderModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="moveFolderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mxw-820 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-1">
              <button
                type="button"
                className="btn-close"
                onClick={closeOpenView}
              >
                <img src={closeIcon} alt="close" />
              </button>
              {
                (mime === "application/pdf" || mime === "pdf") && 
                <div className="pdfwrap">
                  <iframe src={url} />
                </div>
              }
              {
                (mime.includes('image')) && 
                <div className="imagewrap">
                  <img src={url} className="img-fluid" />
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Function to check if the dragged file is inside an inner folder
  const isInnerFolder = (folderId) => {
    return (
      folderId !== "home" &&
      currentDocuments.find((item) => item.id === folderId)?.type === "folder"
    );
  };

  const [openMove, setOpenMove] = useState(null);
  
  const getRootFolders = (id) => {
    var folders = currentDocuments.filter((item) => item.type === "folder")
      if(id !== undefined){
           var temp_arr = [];
          allDocuments.map((item)=>{
            if(id == "" && (!item.obj_key.includes("/") && item.type == "folder")){ temp_arr.push(item); }
            if(id !== "" && item.obj_key.substring(0,item.obj_key.lastIndexOf("/")) == id.substring(1))
             { temp_arr.push(item); } } );
            folders = temp_arr;
      } 
      // if(currentPath){
      // folders.push({name:"My Drive",obj_key:"my_drive",id:"0"})
      // }
      if(!(id=="")){
        folders.push({name:"Parent Folder",obj_key:"parent_folder",id:"0"})
      }
        setMovetoFolderValue(folders);

  };


 
  const MoveModal = (props) => {
    const draggedFileIsInnerFolder = isInnerFolder(selectedDocuments[0]);

    const currentDirectoryId = draggedFileIsInnerFolder
      ? selectedDocuments[0]
      : "home";


    return (
      <div
        className={`modal sm pmovemodal fade custom-modal common-modal ${openMove === props.id ? "show" : ""
          }`}
        id="moveFolderModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="moveFolderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mxw-520 modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-1">
              <button
                type="button"
                className="btn-close"
                onClick={() => {

                  subCurrentDirectory=currentPath;
                  setOpenMove(null)}}
              >
                <img src={closeIcon} alt="close" />
              </button>
              <h6 className="mb-1 mt-4 text-gold text-center text-uppercase d-flex align-items-center justify-content-center">
                <img src={folderIcon} className="moveUpIocn pe-2" />
                Move to
              </h6>
              <div className="mt-3 bw-top folder-list">
                <ul className="p-3">
                  {moveto_folderValue.map((folder) => (
                    <li key={folder.id} className="d-flex align-items-center">
                      <button
                        onClick={() => moveFile(props.id, folder.obj_key, props.mine)}
                        type="button"
                        className="move-right-btn"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={folderIcon}
                            alt="file"
                            className="me-3 folder-icon"
                          />
                          <p className="mb-0">{folder.name}</p>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleMoveToClick = (fileId) => {
    setSelectedDocuments([fileId]); // Update the selectedDocuments with the selected folder ID
    setOpenMove(fileId);
    getRootFolders()
  };

  const editPrivacy = (id, name, privacy) => {
    // console.log(id, name, privacy);
    updateDocument(id, 'privacy', privacy);
    editPrivacyWrapper(name, privacy);
  }


  const downloadProperty = (item) => {
    if (item.file !== "folder") {
      window.open(item.url, '_blank').focus();;
    }

  }

  const [boxHoveClass,setBoxHoveClass] = useState(false);
  
const { getRootProps, getInputProps } = useDropzone({onDrop: acceptedFiles => {  documentChange(acceptedFiles);   }});

  return (
    <>
      <nav aria-label="breadcrumb" className="folder-breadcrumb">
        <ol className="breadcrumb" style={{ color: "white" }}>
          {BreadCrumbArray.map((item) => {
            return (
              <li
                key={item.id}
                onClick={() => { openFolder(item) }}
                onDrop={(event) => console.log(event)}
                onDragOver={(event) => console.log(event)}
                onDragLeave={(event) => console.log(event)}
                id={item.id}
                className="breadcrumb-item" >
                <div className={`draggable-box ${item.dragged ? "dragged" : ""
                      }`}
                  draggable="true"
                  onDragStart={(event) => console.log(event)}
                  onDragEnd={(event) => console.log(event)}
                  onDrop={(event) => console.log(event)}
                  onDragOver={(event) => console.log(event)}
                  onDragLeave={(event) => console.log(event)}
                  id={item.id} 
                >{item.name}
                </div>
              </li>
            );
          })}
        </ol>
      </nav>


      <div className="custom2-table position-relative">
      <div {...getRootProps({ className: "dropzone" })}>
                                    
        <div
         className={`drag-complete-box ${boxHoveClass?'active':''}`} 
         draggable={true} 

         onDragEnter={()=>setBoxHoveClass(true)}
         onDragLeave={()=>setBoxHoveClass(false)}
         onDrop={()=>setBoxHoveClass(false)}
         id="box">
          <input type="file"/>

        </div>
        </div>
        <table className="table w-100 table-striped">
          <thead>
            <tr>
              <th style={{ padding: "0" }}>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th
                        style={{ width: "5%" }}
                        className="align-top hd-in-mob"
                      >
                        <div className="custom-checkbox sm-checkbox position-relative">
                          <input
                            type="checkbox"
                            name="toMemberChk"
                            className="styled-checkbox"
                            id="AllPropertyDoc"
                          />
                          <label htmlFor="AllPropertyDoc"></label>
                        </div>
                      </th>
                      <th style={{ width: "70%", padding: "0px" }}>
                        <table className="w-100">
                          <tbody>
                            <tr>
                              <th
                                className="cursor-pointer"
                                onClick={sortByname}
                                style={{ width: "50%" }}>
                                <p className="d-flex align-items-center mb-0" > Name
                                  <span className={` ${sortOrder['name'] ? "dropIcon top" : 'dropIcon'}`} ></span>
                                </p>
                              </th>
                              <th
                                onClick={sortByDate}
                                style={{ width: "25%" }}
                                className="text-center cursor-pointer hd-in-mob" >
                                <p className="d-flex align-items-center mb-0" >
                                  Date Created <span className={` ${sortOrder['date'] ? "dropIcon top" : 'dropIcon'}`} ></span>
                                </p>
                              </th>
                              <th
                                style={{ width: "25%" }}
                                className="text-center" >
                                Privacy
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </th>
                      <th style={{ width: "25%" }} className="text-end">
                        View
                      </th>
                    </tr>
                  </thead>
                </table>
              </th>
            </tr>
          </thead>
          <tbody>
            {currentDocuments && currentDocuments.map((item, i) => {
              //if ((!folderOpen && item.is_child)) return null;

              if (hiddenDocuments && hiddenDocuments.indexOf(item.id) >= 0) return null;
              return (
                <tr
                  key={`${item.id}-${i}`}
                  className={`${item.type} ${item.dragged ? "invisible" : ""}`}
                  onDrop={(event) => drop(event)}
                  onDragOver={(event) => dragOver(event)}
                  onDragLeave={(event) => dragLeave(event)}
                  id={item.id}
                >
                  <td style={{ padding: "0" }}>
                    <OpenModal mime={item.mime} url={item.url} id={item.id} />
                    <MoveModal id={item.id} name={item.name} mime={item.mime} />
                    <div className={`draggable-box ${item.dragged ? "dragged" : ""
                      }`}
                      draggable="true"
                      onDragStart={(event) => drag(event)}
                      onDragEnd={(event) => dragEnd(event)}
                      onDrop={(event) => drop(event)}
                      onDragOver={(event) => dragOver(event)}
                      onDragLeave={(event) => dragLeave(event)}
                      id={item.id} >
                      <div className="align-items-center justify-content-between drag-action">
                        <div className="drag-icon">
                          <img src={dragIcon} />
                        </div>
                        <span>{item.name}</span>
                      </div>
                      <table
                        className="w-100"
                        id={item.id}
                        onDoubleClick={(event) => { if (item.type == "folder") openFolder(item); else setOpenView(item.id); }} >
                        <tbody>
                          <tr>
                            <td className="hd-in-mob" style={{ width: "5%" }}>
                              <div className="custom-checkbox sm-checkbox position-relative">
                                <input
                                  type="checkbox"
                                  name="toMemberChk"
                                  className="styled-checkbox folderCheckbox"
                                  style={{ opacity: 1 }}
                                  id={item.id}
                                  defaultChecked={
                                    selectedDocuments.indexOf(item.id) > -1
                                  }
                                  onChange={(e) => { selectDocument(item.id); }}
                                />
                              </div>
                            </td>
                            <td style={{ width: "70%", padding: "0px" }}>
                              <table className="w-100">
                                <tbody>
                                  <tr>
                                    <td style={{ width: "50%" }} id={item.id}>
                                      <div className="d-flex align-items-center cs-tbl-folder">
                                        <span className="cs-tbl-icon">
                                          <img
                                            onDoubleClick={
                                              item.type !== "folder"
                                                ? () => setOpenView(item.id)
                                                : null
                                            }
                                            src={
                                              item.mime === "folder"
                                                ? folderIcon : item.mime === "application/pdf"
                                                  ? pdficon : item.mime === "application/msword" || item.mime === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    ? docicon : item.mime === "text/csv" || item.mime === "application/vnd.ms-excel" || item.mime == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                      ? excelicon : item.mime && item.mime.includes("image")
                                                        ? imageicon : documentIcon
                                            }
                                            id={item.id}
                                            alt="file"
                                            className="me-3 folder-icon"
                                          />
                                        </span>
                                        <span>
                                          <input
                                            type="text"
                                            className="update-folder-name"
                                            value={item.name}
                                            id={item.id}
                                            onKeyUp={(event) => {
                                              if (event.keyCode == 13) {
                                                nameUpdate(
                                                  event.target.value,
                                                  item.id,
                                                  "changesDone"
                                                );
                                              }
                                            }}
                                            onBlur={(event) => {
                                              nameUpdate(
                                                event.target.value,
                                                item.id,
                                                "changesDone"
                                              );
                                            }}
                                            style={{
                                              display: item.editable
                                                ? "none"
                                                : "",
                                            }}
                                            onChange={(e) => {
                                              nameUpdate(
                                                e.target.value,
                                                item.id,
                                                "changeInProcess"
                                              );
                                            }}
                                            disabled={item.editable}
                                          />
                                        </span>
                                        <span
                                          style={{
                                            WordBreak:"break-word",
                                            display: !item.editable
                                              ? "none"
                                              : "",
                                          }}
                                          id={item.id}
                                        >
                                          {" "}
                                          {item.name}
                                        </span>
                                      </div>
                                    </td>

                                    <td
                                      style={{ width: "25%" }}
                                      id={item.id}
                                      className="text-gray text-center hd-in-mob"
                                    >
                                      {item.date}
                                    </td>
                                    <td
                                      style={{ width: "25%" }}
                                      id={item.id}
                                      className="text-gray text-center font-11"
                                    >
                                      {item.type !== "folder" &&
                                        (<div>
                                          <div>
                                            <img
                                              src={item.privacy == "Private" ? privateIcon : (item.privacy == "Public" ? publicIcon : membersOnlyIcon)}
                                              id={item.id}
                                              alt="lock"
                                            />
                                          </div>

                                          <div className="hd-in-mob">
                                            {item.privacy}
                                          </div>
                                        </div>)
                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td style={{ width: "25%" }} className="text-end">
                              <div className="btn-group cs-dropdown-option">
                                <button
                                  type="button"
                                  className="btn btn-secondary dropdown-toggle"
                                >
                                  <img src={hmMenuIcon} alt="menu" />
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      id={item.id}
                                      onClick={(event) =>
                                        deleteDocument(item.id, item.type)
                                      }
                                    >
                                      Delete
                                    </button>
                                  </li>
                                  {item.type !== "folder" &&<li>
                                    <button
                                      className="dropdown-item"
                                      type="button"
                                      id={item.id}
                                      onClick={(event) =>
                                        downloadProperty(item)
                                      }
                                    >
                                      Download
                                    </button>
                                  </li>}
                                  <li>
                                    <button
                                      className="dropdown-item"
                                      id={item.id}
                                      onClick={(event) =>
                                        renameSelectedPropery(
                                          item.id,
                                          i
                                        )
                                      }
                                      type="button"
                                    >
                                      Rename
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => handleMoveToClick(item.id)}
                                      className="dropdown-item"
                                      type="button">
                                      Move to
                                    </button>
                                  </li>
                                  {item.type !== "folder" &&
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        Edit Privacy
                                      </button>
                                      <ul className="dropdown-menu dropdown-menu-end">
                                        <li>
                                          <button
                                            className="dropdown-item"
                                            type="button"
                                            onClick={() => { editPrivacy(item.id, item.name, "Public"); }}
                                          >
                                            Public
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className="dropdown-item"
                                            type="button"
                                            onClick={() => { editPrivacy(item.id, item.name, "Members Only"); }}
                                          >
                                            Members Only
                                          </button>
                                        </li>
                                        <li>
                                          <button
                                            className="dropdown-item"
                                            type="button"
                                            onClick={() => { editPrivacy(item.id, item.name, "Private"); }}
                                          >
                                            Private
                                          </button>
                                        </li>
                                      </ul>
                                    </li>
                                  }
                                </ul>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              );
            })}
            </tbody>
        </table>
      </div>
    </>
  );
};
export default Drag;
