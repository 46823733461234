import Pagination from "../../Components/Common/Pagination";
import PropertyItem from "./PropertyItem";
import PropertyOverview from "./PropertyOverview";
import Search from "./Search";
import { useState, useEffect, useContext } from "react";
import dataFile from "./property.json";
import Header from "../../Components/Common/Header";
import Footer from "../../Components/Common/Footer";
import { UserContext } from '../../context/UserContext';
import { PropertyContext } from "../../context/PropertyContext";
import { useLocation, useNavigate } from 'react-router-dom';
import ReadMoreItemTextModal from "../../Components/Common/ReadMoreItemTextModal";


const Home = () => {
  const { user } = useContext(UserContext);
  const { getProperties } = useContext(PropertyContext);

  const queryParameters = new URLSearchParams(window.location.search);
  const parent_property = queryParameters.get("parent_property");
  const location = useLocation();

  const [allProperties, setAllProperties] = useState({});
  const [currentProperties, setCurrentProperties] = useState({});
  const [currentPageData, setCurrentPageData] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // change this as needed
  const pageCount = Math.ceil(currentProperties.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  //const selectedStatus = currentProperties.map((item, index) => {
  //  return `${item.status}-${item.id}`;
  //});

  var values = {
    //"place_id": place_id
  }

  useEffect(() => {

    const fetchData = async () => {
      var values = { user: user.username }
      if (parent_property !== null) values.parent_property = parent_property;
      const propData = await getProperties(values);
      setAllProperties(propData);
      setCurrentProperties(propData);
      setCurrentPageData(propData.slice(startIndex, endIndex));
    }

    fetchData();

  }, [parent_property]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    setCurrentPageData(allProperties.slice(startIndex, endIndex))
  };

  const [filterValues, setFilterValues] = useState({
    state: queryParameters.get("state"),
    property_group: queryParameters.get("property_group"),
    amenity: queryParameters.get("amenity"),
    acres: queryParameters.get("acres"),
    searchText: queryParameters.get("searchText") ?? ""
  });

  const acreGroups = [
    { key: 0, value: "0 - 10 acres", min: 0, max: 10 },
    { key: 1, value: "11 - 50 acres", min: 10, max: 50 },
    { key: 2, value: "51 - 100 acres", min: 50, max: 100 },
    { key: 3, value: "101 - 200 acres", min: 100, max: 200 },
    { key: 4, value: "201 - 500 acres", min: 200, max: 500 },
    { key: 5, value: "501 - 1000 acres", min: 500, max: 1000 },
    { key: 6, value: "1000+ acres", min: 1000, max: 9999999 }
  ];

  const handleFilterChange = (filterName, filterValue) => {

    const temp_filter_values = { ...filterValues };

    temp_filter_values[filterName] = filterValue;

    setFilterValues(temp_filter_values);

  }

  const setFilterResults = (properties) => {
    setCurrentPageData(properties.slice(startIndex, endIndex))
    setCurrentProperties(properties);
    setCurrentPage(1);
  }

  const handleSwitchChange = (values) => {

    var temp_propety_data = [...allProperties];

    Object.keys(temp_propety_data).map(function (i) {
      if (temp_propety_data[i].place_id == values.place_id) temp_propety_data[i].property_status = values.property_status;
    });

    setAllProperties(temp_propety_data);
  };

  const navigate = useNavigate();

  const [propertyFilter, setPropertyFilter] = useState("");

  useEffect(() => {
    const acreMin = filterValues.acres > 0 ? acreGroups[filterValues.acres - 1].min : 0;
    const acreMax = filterValues.acres > 0 ? acreGroups[filterValues.acres - 1].max : 9999999;

    if (!allProperties.length) return;

    let filteredProperties = allProperties.filter((property) =>
      acreMin < property.acreage && property.acreage <= acreMax
      && (!filterValues.amenity || property.amenities.indexOf(filterValues.amenity) > 0)
      && (!filterValues.state || property.state == filterValues.state)
      && (!filterValues.property_group || property.property_group == filterValues.property_group)
      && (
        property.address?.toLowerCase().includes(filterValues.searchText?.toLowerCase()) ||
        property.description?.toLowerCase().includes(filterValues.searchText?.toLowerCase()) ||
        property.property_name?.toLowerCase().includes(filterValues.searchText?.toLowerCase())
      ) && (!propertyFilter || (propertyFilter == "own" && property.own) || (propertyFilter == "saved" && property.saved))
    );

    setCurrentPageData(filteredProperties.slice(startIndex, endIndex))
    setCurrentProperties(filteredProperties);
    setCurrentPage(1);

    var filterParams = new URLSearchParams(Object.entries(filterValues).filter(([key, val]) => val != null && val != undefined && val != "")).toString();

    var newPath = window.location.pathname + "?" + (parent_property !== null ? "&parent_property=" + parent_property + "&" : "") + "&" + filterParams;

    navigate(newPath);

  }, [allProperties, filterValues, propertyFilter]);


  const [readMoreModal, setReadMoreModal] = useState(false)
  const [readMoreText, setReadMoreText] = useState("")
  const readMoreOpenModal = (description) => {
    setReadMoreModal(!readMoreModal)
    setReadMoreText(description)
  }
  const closeReadMoreModal = () => {
    setReadMoreModal(false)
  }

  return (
    <>
      <Header />
      <section className="main-wrap ps-0 pe-0">
        <section className="main-bg">
          {allProperties && allProperties.length > 0 && <Search allProperties={allProperties} currentProperties={currentProperties} handleFilterChange={handleFilterChange} />}
          {allProperties && allProperties.length > 0 && <PropertyOverview propertyTitleName={"LandDeal Properties"} allProperties={allProperties} currentProperties={currentProperties} handleFilterChange={handleFilterChange} acreGroups={acreGroups} />}
          <section className="custom-accordion">
            <div className="container mxw-1140">
              <div className="manage-propertiy-tabs">
                {!parent_property && <ul className="nav nav-tabs pt-4 mb-5" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="AllProperties-tab" data-bs-toggle="tab" data-bs-target="#AllProperties" type="button" role="tab" aria-controls="AllProperties" aria-selected="true" onClick={() => setPropertyFilter("")}>All Properties</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="AllProperties-tab" data-bs-toggle="tab" data-bs-target="#AllProperties" type="button" role="tab" aria-controls="AllProperties" aria-selected="true" onClick={() => setPropertyFilter("own")}>My Properties</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="SavedProperties-tab" data-bs-toggle="tab" data-bs-target="#SavedProperties" type="button" role="tab" aria-controls="SavedProperties" aria-selected="false" onClick={() => setPropertyFilter("saved")}>Saved Properties</button>
                  </li>
                </ul>}
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="AllProperties" role="tabpanel" aria-labelledby="AllProperties-tab">
                    <table className="custom-table-inside">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>&nbsp;</th>
                          <th style={{ width: "20%" }} className="text-start">
                            Property Photo
                          </th>
                          <th style={{ width: "55%" }} colSpan="3">
                            <table className="custom-sub-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "50%" }}>Description</th>
                                  <th
                                    style={{ width: "25%" }}
                                    className="text-center"
                                  >
                                    Acreage
                                  </th>
                                  <th
                                    style={{ width: "25%" }}
                                    className="text-center"
                                  >
                                    Location
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </th>
                          <th style={{ width: "20%" }} className="text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div className="accordion" id="accordionExample">
                      {currentPageData.length > 0 ? (
                        currentPageData.map((item) => (
                          <PropertyItem
                            key={item.place_id}
                            property={item}
                            handleSwitchChange={handleSwitchChange}
                            readMoreOpenModal={readMoreOpenModal}
                          />
                        ))
                      ) : (
                        <h4>Property list is not available yet!</h4>
                      )}
                    </div>
                  </div>
                  <div className="tab-pane fade" id="SavedProperties" role="tabpanel" aria-labelledby="SavedProperties-tab">
                  <table className="custom-table-inside">
                      <thead>
                        <tr>
                          <th style={{ width: "5%" }}>&nbsp;</th>
                          <th style={{ width: "20%" }} className="text-start">
                            Property Photo
                          </th>
                          <th style={{ width: "55%" }} colSpan="3">
                            <table className="custom-sub-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "50%" }}>Description</th>
                                  <th
                                    style={{ width: "25%" }}
                                    className="text-center"
                                  >
                                    Acreage
                                  </th>
                                  <th
                                    style={{ width: "25%" }}
                                    className="text-center"
                                  >
                                    Location
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </th>
                          <th style={{ width: "20%" }} className="text-center">
                            Status
                          </th>
                        </tr>
                      </thead>
                    </table>
                    <div className="accordion" id="accordionExample">
                      {currentPageData.length > 0 ? (
                        currentPageData.map((item) => (
                          <PropertyItem
                            key={item.place_id}
                            property={item}
                            handleSwitchChange={handleSwitchChange}
                            readMoreOpenModal={readMoreOpenModal}
                          />
                        ))
                      ) : (
                        <h4>Property list is not available yet!</h4>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <Pagination
                currentPage={currentPage}
                totalPages={pageCount}
                onPageChange={handlePageChange}
              />
            </div>
          </section>
        </section>
      </section>
      <Footer />
      <ReadMoreItemTextModal
        readMoreModal={readMoreModal}
        readMoreText={readMoreText}
        closeReadMoreModal={closeReadMoreModal}
      />
    </>
  );
};

export default Home;
