// import DetailMap from './DetailMap'
// import TopInfo from './TopInfo'
// import DetailRight from './DetailRight'
// import Header from '../common/Header'
// import Footer from '../common/Footer'
// import DetailLeftDesc from './DetailLeftDesc'
// import DetailBanner from './DetailBanner'

// import DetailBanner from "../../LandDetail/DetailBanner"
// import DetailLeftDesc from "../../LandDetail/DetailLeftDesc"
// import DetailRight from "../../LandDetail/DetailRight"
// import TopInfo from "../../LandDetail/TopInfo"
// import Footer from "../../Components/Common/Footer"
// import Header from "../../Components/Common/Header"
import Footer from '../../Components/Common/Footer'
import Header from '../../Components/Common/Header'
import imgPhoto from '../../assets/img/photo-large.png'
import { Link } from "react-router-dom"
import { useState, useEffect, useContext } from 'react';
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext';
//import imgPhoto from '../../img/photo-large.png'
import { floatFormatter } from '../../Components/Utils/NumberFormatter';

const LiveAuctions = () => {
    const {user} = useContext(UserContext); 
    const { getProperties } = useContext(PropertyContext);

    const [allProperties, setAllProperties] = useState({});
    const [allPropertiesRows, setAllPropertiesRows] = useState({});

    useEffect(() => {      
    
        const fetchData = async () => {
            const propData = await getProperties({user:user.username});
            setAllProperties(propData);

            console.log(propData);

            var tempPropData = [];
            var size = 3;
            for(var i = 0; i < propData.length; i += size) {
                console.log(i);
                tempPropData.push(propData.slice(i, i+size));
            }

            console.log(tempPropData)

            setAllPropertiesRows(tempPropData);
        }        
  
        fetchData();      
        
    }, []); 

    return (
        <>
            <Header />
            <section className="main-wrap ps-0 pe-0">
                <section className="dtl-block pt-5 live-auction-block">
                    <div className="container">
                        <div className="xl-title mb-4">MEMBERS ONLY LISTINGS</div>
                        <div className="gray-box mt-2 p-0 custom-member-tabs">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="liveAuction-tab" data-bs-toggle="tab" data-bs-target="#liveAuction"
                                        type="button" role="tab" aria-controls="liveAuction" aria-selected="true"><span className="la-icon"></span> Live <br/>Auctions</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="CompletedAuctions-tab" data-bs-toggle="tab" data-bs-target="#CompletedAuctions"
                                        type="button" role="tab" aria-controls="CompletedAuctions" aria-selected="false"><span className="ca-icon"></span> Completed <br/>Auctions</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="ActiveRequests-tab" data-bs-toggle="tab" data-bs-target="#ActiveRequests"
                                        type="button" role="tab" aria-controls="ActiveRequests" aria-selected="false"><span className="cr-icon"></span> Active <br/>Requests</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active pb-4" id="liveAuction" role="tabpanel" aria-labelledby="liveAuction-tab">
                                    <div className="search-block position-relative top-0 pt-5 pb-5">
                                        <div className="container mxw-1140">
                                            <div className="input-group mb-3">
                                                <span className="search-icon"></span>
                                                <input type="text" className="form-control" placeholder="Search by location, type or keyword. Ex: “Texas Oil and Gas” " aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span className="input-group-text text-center d-block text-white text-uppercase" id="basic-addon2">Search</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timed-auction-block pb-5">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="md-title pt-0 mb-4 before-border">Fixed Price</div>
                                            </div>
                                        </div>
                                        {allPropertiesRows.length > 0 ? (
                                            allPropertiesRows.map((chunk,i) => (
                                                <div className="row" key={"row_"+i}>
                                                    {chunk.length > 0 ? (
                                                        chunk.map((property,j) => (
                                                            <div className="col-lg-4 col-12" key={j}>
                                                                <div className="property-box">
                                                                    <div className="pb-img">
                                                                        <Link to={"/properties/live-auction-details?place_id=" + property.place_id}><img src={property.images && property.images[0]} alt="pb" /></Link>
                                                                    </div>
                                                                    <div className="pb-content">
                                                                        <div className="px-2 py-3">
                                                                            <div className="xs-title avenir-next fw-600 text-start text-white">{property.property_name}</div>
                                                                            <div className="d-flex align-items-center justify-content-between pt-2">
                                                                                <div className="lb-title">Acres: <span className="text-white">{property.acreage}</span></div>
                                                                                <div className="lb-title">Location: <span className="text-white">{property.city}</span></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="px-2 py-3 border-t">
                                                                            <div className="d-flex align-items-center justify-content-between">
                                                                                <div className="w-50">
                                                                                    <div className="lb-title text-start">Time Remaining:</div>
                                                                                    <div className="sm-title font-22 text-start avenir fw-900"></div>
                                                                                </div>
                                                                                <div className="ps-3 border-l w-50">
                                                                                    <div className="lb-title text-start">Current High Bid:</div>
                                                                                    <div className="sm-title font-22 text-start avenir fw-900">{floatFormatter(property.list_price,true)}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="py-2">
                                                                            <Link to={"/properties/live-auction-details?place_id=" + property.place_id} className="d-block text-decoration-none theme-btn md-btn fw-500 w-100">VIEW PROPERTY</Link>
                                                                        </div>
                                                                        <div className="pt-4 text-center">
                                                                            <a href="#" className="view-dtl-link"><span className="plus-circle-icon"></span> VIEW PROPERTY</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <h4>Property list is not available yet!</h4>
                                                    )}
                                                </div>
                                            ))
                                        ) : (
                                            <h4>Property list is not available yet!</h4>
                                        )}
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="md-title pt-0 mb-4 before-border">Timed Auctions</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="CompletedAuctions" role="tabpanel" aria-labelledby="CompletedAuctions-tab">
                                    <div className="search-block position-relative top-0 pt-5 pb-5">
                                        <div className="container mxw-1140">
                                            <div className="input-group mb-3">
                                                <span className="search-icon"></span>
                                                <input type="text" className="form-control" placeholder="Search by location, type or keyword. Ex: “Texas Oil and Gas” " aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span className="input-group-text text-center d-block text-white text-uppercase" id="basic-addon2">Search</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timed-auction-block pb-5">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="md-title pt-0 mb-4 before-border">Timed Auctions</div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="property-box">
                                                    <div className="pb-img">
                                                        <Link to="/properties/live-auction-details"><img src={imgPhoto} alt="pb" /></Link>
                                                    </div>
                                                    <div className="pb-content">
                                                        <div className="px-2 py-3">
                                                            <div className="xs-title avenir-next fw-600 text-start text-white">High Yield Timber Plot</div>
                                                            <div className="d-flex align-items-center justify-content-between pt-2">
                                                                <div className="lb-title">Acres: <span className="text-white">1,600</span></div>
                                                                <div className="lb-title">Location: <span className="text-white">Missoula, MT</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 py-3 border-t">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="w-50">
                                                                    <div className="lb-title text-start">Time Remaining:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">8h 29m</div>
                                                                </div>
                                                                <div className="ps-3 border-l w-50">
                                                                    <div className="lb-title text-start">Current High Bid:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">$17.4M</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-2">
                                                            <Link to="/properties/live-auction-details" className="d-block text-decoration-none theme-btn md-btn fw-500 w-100">VIEW PROPERTY</Link>
                                                        </div>
                                                        <div className="pt-4 text-center">
                                                            <a href="#" className="view-dtl-link"><span className="plus-circle-icon"></span> VIEW PROPERTY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="property-box">
                                                    <div className="pb-img">
                                                        <Link to="/properties/live-auction-details"><img src={imgPhoto} alt="pb" /></Link>
                                                    </div>
                                                    <div className="pb-content">
                                                        <div className="px-2 py-3">
                                                            <div className="xs-title avenir-next fw-600 text-start text-white">High Yield Timber Plot</div>
                                                            <div className="d-flex align-items-center justify-content-between pt-2">
                                                                <div className="lb-title">Acres: <span className="text-white">1,600</span></div>
                                                                <div className="lb-title">Location: <span className="text-white">Missoula, MT</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 py-3 border-t">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="w-50">
                                                                    <div className="lb-title text-start">Time Remaining:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">8h 29m</div>
                                                                </div>
                                                                <div className="ps-3 border-l w-50">
                                                                    <div className="lb-title text-start">Current High Bid:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">$17.4M</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-2">
                                                            <Link to="/properties/live-auction-details" className="d-block text-underline-none theme-btn md-btn fw-500 w-100">VIEW PROPERTY</Link>
                                                        </div>
                                                        <div className="pt-4 text-center">
                                                            <a href="#" className="view-dtl-link"><span className="plus-circle-icon"></span> VIEW PROPERTY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="property-box">
                                                    <div className="pb-img">
                                                        <Link to="/properties/live-auction-details"><img src={imgPhoto} alt="pb" /></Link>
                                                    </div>
                                                    <div className="pb-content">
                                                        <div className="px-2 py-3">
                                                            <div className="xs-title avenir-next fw-600 text-start text-white">High Yield Timber Plot</div>
                                                            <div className="d-flex align-items-center justify-content-between pt-2">
                                                                <div className="lb-title">Acres: <span className="text-white">1,600</span></div>
                                                                <div className="lb-title">Location: <span className="text-white">Missoula, MT</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 py-3 border-t">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="w-50">
                                                                    <div className="lb-title text-start">Time Remaining:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">8h 29m</div>
                                                                </div>
                                                                <div className="ps-3 border-l w-50">
                                                                    <div className="lb-title text-start">Current High Bid:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">$17.4M</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-2">
                                                            <Link to="/properties/live-auction-details" className="d-block text-underline-none theme-btn md-btn fw-500 w-100">VIEW PROPERTY</Link>
                                                        </div>
                                                        <div className="pt-4 text-center">
                                                            <a href="#" className="view-dtl-link"><span className="plus-circle-icon"></span> VIEW PROPERTY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="ActiveRequests" role="tabpanel" aria-labelledby="ActiveRequests-tab">
                                    <div className="search-block position-relative top-0 pt-5 pb-5">
                                        <div className="container mxw-1140">
                                            <div className="input-group mb-3">
                                                <span className="search-icon"></span>
                                                <input type="text" className="form-control" placeholder="Search by location, type or keyword. Ex: “Texas Oil and Gas” " aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <span className="input-group-text text-center d-block text-white text-uppercase" id="basic-addon2">Search</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="timed-auction-block pb-5">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="md-title pt-0 mb-4 before-border">Fixed Price</div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="property-box">
                                                    <div className="pb-img">
                                                        <Link to="/properties/live-auction-details"><img src={imgPhoto} alt="pb" /></Link>
                                                    </div>
                                                    <div className="pb-content">
                                                        <div className="px-2 py-3">
                                                            <div className="xs-title avenir-next fw-600 text-start text-white">High Yield Timber Plot</div>
                                                            <div className="d-flex align-items-center justify-content-between pt-2">
                                                                <div className="lb-title">Acres: <span className="text-white">1,600</span></div>
                                                                <div className="lb-title">Location: <span className="text-white">Missoula, MT</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 py-3 border-t">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="w-50">
                                                                    <div className="lb-title text-start">Time Remaining:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">8h 29m</div>
                                                                </div>
                                                                <div className="ps-3 border-l w-50">
                                                                    <div className="lb-title text-start">Current High Bid:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">$17.4M</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-2">
                                                            <Link to="/properties/live-auction-details" className="d-block text-underline-none theme-btn md-btn fw-500 w-100">VIEW PROPERTY</Link>
                                                        </div>
                                                        <div className="pt-4 text-center">
                                                            <a href="#" className="view-dtl-link"><span className="plus-circle-icon"></span> VIEW PROPERTY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="property-box">
                                                    <div className="pb-img">
                                                        <Link to="/properties/live-auction-details"><img src={imgPhoto} alt="pb" /></Link>
                                                    </div>
                                                    <div className="pb-content">
                                                        <div className="px-2 py-3">
                                                            <div className="xs-title avenir-next fw-600 text-start text-white">High Yield Timber Plot</div>
                                                            <div className="d-flex align-items-center justify-content-between pt-2">
                                                                <div className="lb-title">Acres: <span className="text-white">1,600</span></div>
                                                                <div className="lb-title">Location: <span className="text-white">Missoula, MT</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 py-3 border-t">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="w-50">
                                                                    <div className="lb-title text-start">Time Remaining:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">8h 29m</div>
                                                                </div>
                                                                <div className="ps-3 border-l w-50">
                                                                    <div className="lb-title text-start">Current High Bid:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">$17.4M</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-2">
                                                            <Link to="/properties/live-auction-details" className="d-block text-underline-none theme-btn md-btn fw-500 w-100">VIEW PROPERTY</Link>
                                                        </div>
                                                        <div className="pt-4 text-center">
                                                            <a href="#" className="view-dtl-link"><span className="plus-circle-icon"></span> VIEW PROPERTY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="property-box">
                                                    <div className="pb-img">
                                                        <Link to="/properties/live-auction-details"><img src={imgPhoto} alt="pb" /></Link>
                                                    </div>
                                                    <div className="pb-content">
                                                        <div className="px-2 py-3">
                                                            <div className="xs-title avenir-next fw-600 text-start text-white">High Yield Timber Plot</div>
                                                            <div className="d-flex align-items-center justify-content-between pt-2">
                                                                <div className="lb-title">Acres: <span className="text-white">1,600</span></div>
                                                                <div className="lb-title">Location: <span className="text-white">Missoula, MT</span></div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 py-3 border-t">
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <div className="w-50">
                                                                    <div className="lb-title text-start">Time Remaining:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">8h 29m</div>
                                                                </div>
                                                                <div className="ps-3 border-l w-50">
                                                                    <div className="lb-title text-start">Current High Bid:</div>
                                                                    <div className="sm-title font-22 text-start avenir fw-900">$17.4M</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="py-2">
                                                            <Link to="/properties/live-auction-details" className="d-block text-underline-none theme-btn md-btn fw-500 w-100">VIEW PROPERTY</Link>
                                                        </div>
                                                        <div className="pt-4 text-center">
                                                            <a href="#" className="view-dtl-link"><span className="plus-circle-icon"></span> VIEW PROPERTY</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            <Footer />

        </>
    )
}

export default LiveAuctions