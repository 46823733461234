import pinIcon from '../../assets/img/pin-icon.jpg'

const AuctionPropertyResources = () => {
    return (
        <>
            <div className="gray-box pe-2">
                <div className="box-title text-uppercase">Property Resources</div>
                <div className="at-scroll mt-4">
                    <ul className="at-list">
                        <li><span className="pin-icon"><img src={pinIcon} alt="pin" /></span> Location Map</li>
                        <li><span className="pin-icon"><img src={pinIcon} alt="pin" /></span> Aerial Photos</li>
                        <li><span className="pin-icon"><img src={pinIcon} alt="pin" /></span> Topography Map</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AuctionPropertyResources