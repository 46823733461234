import { all } from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const AutoComplete = ({ allProperties, currentProperties, handleFilterChange, optionCount = 5, placeholder }) => {

  const [allOption, setAllOption] = useState(allProperties || []);
  const [searchText, setSearchText] = useState("");

  const handleChange = (e) => {
    setSearchText(e.target.value);
    handleFilterChange("searchText", e.target.value);
  };

  const selectHandle = (val) => {
    console.log(val);
  };

  //setAllOption(filteredProperties);

  useEffect(() => {
    setAllOption(currentProperties);
  }, [currentProperties]);
  return (
    <>
      <div className="search-block">
        <div className="container mxw-1140 px-5">
          <div className="input-group mb-3">
            <span className="search-icon"></span>
            <input
              type="text"
              className="form-control"
              placeholder={placeholder ? placeholder : "Search by location, type or keyword"}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              onChange={handleChange}
              value={searchText}
            />
            <span
              className="input-group-text text-center d-block text-white text-uppercase"
              id="basic-addon2"
            >
              Search
            </span>
          </div>
          <div
            className="cs-search-list"
            style={{
              display: searchText ? "block" : "none"
            }}
          >
            {allOption == undefined || !allOption.length ? (
              <div className="autocomplete-items text-center xs-title gray-text fw-500 font-17">Property not found !</div>
            ) : (
              allOption.map((option, index) => (
                <Link to={"/properties/land-details?place_id=" + option.place_id} className='sl-link' key={index}>
                  <div key={`${index}`} onClick={() => selectHandle(option)} className="autocomplete-items d-flex align-items-center">
                    <div className="cssl-img"><img src={option.images && option.images.length > 0 ? option.images[0] : ""} alt={option.property_name} /></div>
                    <div className="cssl-content">
                      <div className="xs-title fw-600 black-text text-start">{option.city}</div>
                      <div className="xs-title gray-text fw-500 text-start font-17 mt-1">{option.property_name}</div>
                    </div>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoComplete;
