import React, { useState } from "react";
import { ReactSortable } from "react-sortablejs";

export const BasicFunction = (props) => {
  const [state, setState] = useState([
    { id: 1, name: "shrek" },
    { id: 2, name: "fiona" },
    { id: 3, name: "a" },
    { id: 4, name: "fdiona" },
    { id: 5, name: "fiwona" },
    { id: 6, name: "fieona" },
  ]);
  const onDragDropEnds = (oldIndex, newIndex) => {
    console.log('Drag and drop other tasks');
    console.log(oldIndex, newIndex);
  };



  return (
    <ReactSortable
          ghostClass="dropArea"
          handle=".dragHandle"
          filter=".ignoreDrag"
          preventOnFilter={true}
          className="grid-container"
          onEnd={({ oldIndex, newIndex }) => onDragDropEnds(oldIndex, newIndex)}
            direction= {(e)=>console.log(e)}
            dragoverBubble= {false}
            list={state} setList={setState}>

            {state.map((item,index) => (
                <div className="grid-items card shadow-sm rounded-3 overflow-hidden">
                <div className="card-body">
                <h3 className=" h5 card-title m-0">{item.name}</h3>
                </div>
                <div className="card-footer d-flex justify-content-between">
                <span>{item.id}</span>
                <span className="btn btn-white border shadow-sm dragHandle">=</span>
                </div>
                </div>
        // <div style={{width:"100px", border:"1px solid white",color:'white'}} key={item.id}>{item.name} {item.id }</div>
      ))}
    </ReactSortable>
  );
};