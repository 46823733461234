import AuctionType from '../../Components/RightWidgets/AuctionType'
import AuctionPropertyResources from '../../Components/RightWidgets/AuctionPropertyResources'
import ContactSeller from '../../Components/RightWidgets/ContactSeller'
import SimilarListings from '../../Components/RightWidgets/SimilarListings'
import TimeRemaining from '../../Components/RightWidgets/TimeRemaining'

const AuctionRight = ({propertyData}) => {
    return (
        <>
            <div className="dtl-right">
                <TimeRemaining propertyData={propertyData}/>
                <AuctionType propertyData={propertyData}/>
                <AuctionPropertyResources propertyData={propertyData}/>
                <ContactSeller propertyData={propertyData}/>
                <SimilarListings propertyData={propertyData}/>
            </div>
        </>
    )
}

export default AuctionRight