import { useField, Field } from "formik";
import { ValidationError } from "../Errors/ValidationError";

const TextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <div className={props.groupclass || `form-group`}>
        {label?<label htmlFor={field.name}>{label || ""}</label>: ""}
        <Field id={props.id}
          className={props.class || `form-control`}
          {...field}
          {...props}
        />
        {props.tip ? <div className="tip-text mt-1">
                    {props.tip}
                  </div> : ""}
        <ValidationError name={field.name} />
      </div>
    </>
  );
};

export default TextField;
