import React from 'react'
import { useEffect } from 'react';

const Map = ({propertyData}) => {
    var src = propertyData.map_link;

    useEffect(() => {

        setTimeout(() => {
            //console.log(document.getElementById('map'));
            var map = new google.maps.Map(document.getElementById('map2'), {
                center: new google.maps.LatLng(-19.257753, 146.823688),
                zoom: 10,
                mapTypeId: 'hybrid'
            });



            var kmlLayer = new google.maps.KmlLayer(src, {
                suppressInfoWindows: true,
                preserveViewport: false,
                map: map
            });
            kmlLayer.addListener('click', function (event) {
                console.log('click');
                var content = event.featureData.infoWindowHtml;
                var testimonial = document.getElementById('capture');
                testimonial.innerHTML = content;
            });
        }, 1000);
    }, [propertyData.place_id]); 

    return (
        <>

            <div className="gray-box mt-30">
                <div className="box-title text-uppercase">Map</div>
                <div className="mt-3">
                    <div id="map2" style={{ width: "100%", height: "460px" }}></div>
                </div>
            </div>
        </>
    )
}

export default Map