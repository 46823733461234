import { BrowserRouter } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import './assets/css/chat.css';
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import AppRoutes from './Routes/AppRoutes';
function App() {
  return (
    <>
      <div className="App">
        {/* <BrowserRouter  basename='/properties/'> */}
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
