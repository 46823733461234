import React from 'react'
import iconChat from '../../assets/img/icon-chat.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <footer className="footer-wrap text-end">
                <Link to="/chat-standalone" className="chat-btn d-inline-flex align-items-center">
                    <div className="chaticon">
                        <img src={iconChat} alt="chat" />
                    </div>
                    <div className="sm-title text-uppercase font-16 fw-600">LIVE CHAT</div>
                </Link>
            </footer>
        </>
    )
}

export default Footer