
import { Link } from 'react-router-dom'
import userIcon from '../../assets/img/icon-chat-user.svg'
import backArrow from '../../assets/img/white-back-arrow.png'
import { useState } from 'react';

const ChatNewList = ({ chatList, openChat, setNewChat }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const filteredChatList = chatList.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const sortedChatList = [...filteredChatList].sort((a, b) => a.name.localeCompare(b.name));
    let currentLetter = null;
    return (
        <>
            <div className="chatlist p-0">
                <div className="modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="msg-head d-flex align-items-center justify-content-between d-md-none group-mob-header mb-3">
                            <button className='white-back-arrow cursor-pointer d-md-none' onClick={() => {setNewChat(false);}}>
                                <img src={backArrow} />
                            </button>
                            <div className='d-flex align-items-center w-100'>
                                <div className='chat-sm-title text-white fw-500 me-3'>New Chat</div>
                            </div>
                        </div>
                        <div className="chat-header d-none d-md-block pb-0">
                            <div className='d-flex align-items-center justify-content-between mb-4'>
                                <span className='ch-xs-title' onClick={() => {setNewChat(false);}}>
                                    <span className='chat-back-arrow me-2'></span>New Chat
                                </span>
                                <div className='member-count'>3 of 10</div>
                            </div>
                            <div className="msg-search w-100">
                                <div className='position-relative search-input'>
                                    <input type="text" className="form-control" placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <span className="gray-search-icon"></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body p-0">
                            <div className="chat-lists">
                                <div className="chat-list">
                                    {/* {chatList.map((item, index)=>  */}
                                    {chatList.map((item, index) => {
                                        var showLetter = true;
                                        if(currentLetter != item.first_letter) {
                                            currentLetter = item.first_letter;
                                            showLetter = true;
                                        } else
                                            showLetter = false;

                                        return (
                                            <div key={index}>
                                                {showLetter ? <div className='character-text'>{currentLetter}</div> : <></>}
                                                <button type="button" className="d-flex align-items-center w-100 unread" onClick={() => openChat(item.username)}>
                                                    <div className="flex-shrink-0">
                                                        <span className='user-icon'><img src={userIcon} alt="user" /></span>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h3>{item.name}</h3>
                                                        <p>{item.company}</p>
                                                    </div>
                                                </button>
                                            </div>
                                        )

                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatNewList