import {useState} from 'react'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
        <>
            <div className="text-center pagination-block">
                <nav aria-label="...">
                    <ul className="pagination">
                    <li className={`page-item back-item${currentPage === 1 ? ' disabled' : ''}`}>
                        <a  className="page-link" onClick={() => onPageChange(currentPage - 1)}>
                        Back
                        </a>
                    </li>
                    {pageNumbers.map((number) => (
                        <li key={number} className={`page-item${currentPage === number ? ' active' : ''}`}>
                        <a  className="page-link" onClick={() => onPageChange(number)}>
                            {number}
                        </a>
                        </li>
                    ))}
                    <li className={`page-item next-item${currentPage === totalPages ? ' disabled' : ''}`}>
                        <a  className="page-link" onClick={() => onPageChange(currentPage + 1)}>
                        Next
                        </a>
                    </li>
                        {/* <li className="page-item back-item">
                            <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Back</a>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item active" aria-current="page">
                            <a className="page-link" href="#">2</a>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item"><a className="page-link" href="#">4</a></li>
                        <li className="page-item"><a className="page-link" href="#">5</a></li>
                        <li className="page-item"><a className="page-link" href="#">6</a></li>
                        <li className="page-item next-item">
                            <a className="page-link" href="#">Next</a>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Pagination