import closeIcon from '../../assets/img/close-modal.svg'

const ListingAlert = ({listAlert, setListingAlert, editListing}) => {
    return (
        <>
            <div className={`modal fade custom-modal common-modal make-offer-modal ${listAlert ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-700  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile">Listing Alert!</div>
                            <button type="button" className="btn-close" onClick={() => {setListingAlert(false)}}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={() => {setListingAlert(false)}}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className='listing-box'>
                                <div className="fb-heading mb-4 text-center font-40 m-d-none">Listing Alert!</div>
                                <div className='xs-title text-center avenir-next fw-500 font-16'>You are creating a property listing with the same <br/>address as a current listing.</div>
                                <div className='xs-title text-center avenir-next fw-500 font-16 mt-3'>Would you like to:</div>
                                <div className='row mt-5 btns-block'>
                                    <div className='w-50 pe-5'>
                                        <button type="submit" className="theme-btn text-uppercase w-100 sm-btn" onClick={() => {editListing()}}>Edit Original</button>
                                    </div>
                                    <div className='w-50 ps-5'>
                                        <button type="submit" className="theme-btn text-uppercase w-100 sm-btn" onClick={() => {setListingAlert(false)}}>Make New Listing</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListingAlert