import iconCamera from '../../assets/img/icon-photos-small.png'
import iconPrimary from '../../assets/img/icon-primary.png'
import iconDelete from '../../assets/img/icon-delete-photo.png'
import { useDropzone } from "react-dropzone";


import { ReactSortable } from "react-sortablejs";

import { useState, useEffect, useContext, useRef } from 'react';

//import { EnvContext } from '../../context/EnvContext'
import { PropertyContext } from "../../context/PropertyContext";

const makeArray = (array) => {

}
const PhotosUpload = ({ nextStep, item, finishForm, propertyData, allValues }) => {
    const imgs = [];

    //const { s3FileUpload, dataURItoBlob } = useContext(EnvContext);
    const { uploadDocument, editFileHeaders } = useContext(PropertyContext);
    const [boxHoverClass, setBoxHoverClass] = useState(false);


    // const [imgsSrc, setImgsSrc] = useState(propertyData.images); //urls.map((item, index) => ({ id: "item" + index, item: item.result }))
    const [imgsSrc, setImgsSrc] = useState(propertyData.images.filter((item, index) => { return !item.includes('staticmaps'); }).map((item, index) => ({ id: "item" + index, item: item, key: propertyData.images_list[index].key })));
    const [imgURLs, setIMGURLs] = useState(propertyData.images);
    /*const imgUploades = (e) => {
        for (const file of e.target.files) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {

                file.src = dataURItoBlob(reader.result);
                console.log([...imgsSrc, ...reader.result]);

                setImgsSrc((imgs) => [...imgsSrc, reader.result]);

                var url = await s3FileUpload(file, 'propertyphotos/' + (propertyData.place_id ? propertyData.place_id : ""));

                // console.log("got url",url);

                setIMGURLs([...imgURLs, url]);
                nextStep(0, { "images": imgURLs });
            };
            reader.onerror = () => {
                // console.log(reader.error);
            };
        }
    };*/

    const readFileContents = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (e) => {

                /*file.src = dataURItoBlob(reader.result);

                // var url ="";
                var url = await s3FileUpload(file, 'propertyphotos/' + (propertyData.place_id ? propertyData.place_id : ""));
                var result = reader.result
                resolve({ url, result });*/

                const formData = new FormData();
                formData.append('place_id', allValues.place_id ? allValues.place_id : "");
                formData.append('file', file);
                formData.append('fileName', file.name);
                formData.append('folder', 'propertyphotos/');
                formData.append('metadata', JSON.stringify({ 'order': imgURLs.length - 1 }));
                const upload = uploadDocument(formData);
                upload.then((data) => {
                    var url = data.url;
                    var result = reader.result;
                    resolve({ url, result, data });
                });
            };
            reader.onerror = () => {
                // console.log(reader.error);
            };
        });
    }
    const readAllFiles = async (AllFiles) => {
        const urls = await Promise.all(AllFiles.map(async (file) => {
            const url = await readFileContents(file);
            return url;
        }));
        return urls;
    }
    let lastIndexUsed = 0;
    const handleUpload = (e) => {

        let AllFiles = [];
        if (e.target) {
            [...e.target.files].map(file => AllFiles.push(file));
        }
        else {
            [...e].map(file => AllFiles.push(file));
        }



        readAllFiles(AllFiles).then(urls => {

            var img = urls.map((item) => {
                let index = lastIndexUsed;
                let id = "item" + index;

                while (imgsSrc.some(img => img.id === id)) {
                    index++;
                    id = "item" + index;
                }

                lastIndexUsed = index + 1;

                return {
                    id: id,
                    item: item.result,
                    key: item.data.data.key
                };
            })
            var url = urls.map((item, index) => item.url);
            img = [...imgsSrc, ...img];
            setImgsSrc(img);
            setIMGURLs([...imgURLs, ...url]);

            nextStep(0, { "images": [...imgURLs, ...url] });
        })
            .catch(err => {
                alert(err);
            });
    }

    const submitTest = () => {
        // console.log(imgURLs);
    };

    useEffect(() => {
        nextStep(0, { "images": imgURLs });
    }, [imgURLs]);



    const [checked, setChecked] = useState([]);
    const checkPrimary = (e) => {
        var checkeds = [...checked];
        console.log(checkeds);
        console.log(e.target.id);

        if (checkeds.indexOf(e.target.id) > -1) {
            checkeds = checkeds.filter((item) => item !== e.target.id);
        }
        else {
            checkeds.push((e.target.id))
        }
        setChecked(checkeds);
    };

    const isSortingRef = useRef(false);

    const updateOrder = (updatedList) => {
        if (!isSortingRef.current) return;
        isSortingRef.current = false;
        setImgsSrc(updatedList);
    };

    const isMounted = useRef(false);

    const deleteItem = () => {
        const filteredImgsSrc = imgsSrc.filter(item => !checked.includes(item.id));
        setImgsSrc(filteredImgsSrc);
        setIMGURLs(filteredImgsSrc.map(item => item.item));
        setChecked([]); // Clear the checked array after deletion
    };

    const setAsPrimary = () => {
        setImgsSrc(imgsSrc.filter(item => checked.includes(item.id)).concat(imgsSrc.filter(item => !checked.includes(item.id))));
    }

    useEffect(() => {
        if (isMounted.current) {
            console.log(imgsSrc);
            for (var i = 0; i < imgsSrc.length; i++) {
                editFileHeaders(imgsSrc[i].key, { "order": i });
            }
        } else
            isMounted.current = true;
    }, [imgsSrc]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            console.log(acceptedFiles);
            handleUpload(acceptedFiles);
        }
    });


    return (
        <>
            <div className="accordion-body upload-photo-box">
                <div className="row">
                    <div className="col-lg-7 order-2">
                        <div className='up-img-flex'>
                            <div className="d-flex align-items-center upload-action-block">
                                <div>
                                    <button type='button'
                                        className="xs-title font-12 text-start text-uppercase text-decoration-none text-white action-link upload-photo-btn">
                                        <input type="file" onChange={handleUpload} name="file" accept="image/*" multiple />
                                        <img src={iconCamera} /> Add More Photos
                                    </button>
                                </div>
                                <div className="ms-5">
                                    <button type='button'
                                        className="xs-title font-12 text-start text-uppercase text-decoration-none text-white action-link" onClick={setAsPrimary}>
                                        <img src={iconPrimary} /> Set as Primary
                                    </button>
                                </div>
                                <div className="ms-5">
                                    <button type='button'
                                        className="xs-title font-12 text-start text-uppercase text-decoration-none text-white action-link" onClick={deleteItem}>
                                        <img src={iconDelete} /> Delete</button>
                                </div>
                            </div>
                            {/* <div {...getRootProps({ className: "dropzone" })} className='grid-overflow'> */}
                                <ReactSortable
                                    ghostClass="dropArea"
                                    handle=".dragHandle"
                                    filter=".ignoreDrag"
                                    preventOnFilter={true}
                                    className="grid-container"
                                    dragoverBubble={false}
                                    list={imgsSrc}
                                    onUpdate={() => isSortingRef.current = true}
                                    setList={(updatedList) => updateOrder(updatedList)}>

                                    {imgsSrc && <>
                                        {imgsSrc.map((item, index) => (
                                            <div className={(checked.indexOf('primaryCheck' + item.id) > -1) ? "pl-box checked dragHandle mt-3" : "pl-box dragHandle mt-3"} key={index}>
                                                <div style={{ padding: "12px" }}>
                                                    <div className="pl-img">
                                                        <img src={item.item} />
                                                    </div>
                                                    <div className="custom-checkbox sm-checkbox mt-2">
                                                        <input className="styled-checkbox" id={item.id} type="checkbox"
                                                            checked={checked.indexOf(item.id) > -1}
                                                            value={item.id} onClick={(e) => checkPrimary(e)} onChange={(e) => console.log(e.target.value)} />
                                                        <label htmlFor={item.id}><span>Primary {item.id}</span></label>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}</>
                                    }
                                </ReactSortable>
                            {/* </div> */}
                        </div>
                    </div>

                    

                    <div className="col-lg-5">
                        <div
                            className={`drag-complete-box ${boxHoverClass ? 'active' : ''}`}
                            draggable={true}
                            onDragEnter={() => setBoxHoverClass(true)}
                            onDragLeave={() => setBoxHoverClass(false)}
                            onDrop={() => setBoxHoverClass(false)}
                            {...getRootProps({ className: "dropzone" })}
                        >
                            <div className="form-box ptb-100">
                                <div className="xs-title fw-500">Select up to 20 photos you wish to upload</div>
                                <div className="mt-5 text-center">
                                    <button className="theme-trns-btn text-uppercase md-btn plr-65 upload-photo-btn">
                                        <input type="file" onChange={handleUpload} name="file" accept="image/*" multiple />
                                        Browse Photos
                                    </button>
                                </div>
                                <div className="mt-5 text-center tip-text">Maximum file size 5mb, file format
                                    allowed jpg, png, gif</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-end mt-4">
                <button className="theme-btn text-uppercase w-25 sm-btn" onClick={finishForm}>FINISH</button>
            </div>

        </>
    )
}

export default PhotosUpload