import React from "react";
import { Routes, Route } from "react-router-dom";
import LandDetail from "../Pages/LandDetail/LandDetail";
import LiveAuctions from "../Pages/LiveAuctions/LiveAuctions";
import LiveAuctionDetails from "../Pages/LiveAuctions/LiveAuctionDetails";
import AddProperty from "../Pages/AddProperty/AddProperty";
import Home from "../Pages/Home/Home";
import FrontendHome from "../Pages/Frontend/Home/FrontendHome";
import ShareYourProperty from "../Pages/AddProperty/ShareYourProperty";

import { useEffect, useContext, useState } from 'react';
import { UserContext } from '../context/UserContext';
import SelectProperty from "../Pages/Admin/SelectProperty";
import AddDocuments from "../Pages/Admin/AddDocuments";
import Preferences from "../Pages/Admin/Preferences";
import { ReactSortable } from "react-sortablejs";
import { BasicFunction } from "../Pages/Admin/reactShortable";
import PrintoutBuilder from "../Pages/Print/PrintoutBuilder";
import Payment from "../Pages/Admin/Payment";
import FrontendRegister from "../Pages/FrontendRegister/FrontendRegister";
import ChatCreateGroup from "../Pages/Chat/ChatCreateGroup";
import NewChat from "../Pages/Chat/NewChat";
import ChatStandalone from "../Pages/Chat/ChatStandalone";
import NoChat from "../Pages/Chat/NoChat";
import ReviewOffers from "../Components/Frontend/Common/ReviewOffers";
import LandDealWalkThrough from "../Components/Common/LandDealWalkThrough";
const AppRoutes = () => {

	const { user, loggedInCheck } = useContext(UserContext);

	const [currDealership, setCurrDealership] = useState(null);

	const [checkedLogin, setCheckedLogin] = useState(null);

	useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		console.log('App useEffect');
		if (!checkedLogin) {
			checkLogin();
		}
	}, []);

	const checkLogin = async () => {
		console.log('checking login');
		await loggedInCheck();
		setCheckedLogin(true);
		console.log(user);
	};

    // Build Rought
    // return <Routes>
    //         <Route exact path="/properties" Component={Home} />
    //         <Route exact path="/properties/land-details" Component={LandDetail}/>
    //         <Route exact path="/properties/land-details" Component={LandDetailsDesc}/>
    //         <Route exact path="/properties/add-my-property" Component={AddProperty} />
    //     </Routes>;

    // Local Rought
    return <Routes>
        {checkedLogin && user && user.username != "publicuser" ? (
			<>
            <Route exact path="/register" Component={FrontendRegister}  /> 
                <Route exact path="/properties" Component={Home} />
                <Route exact path="/properties/land-details" Component={LandDetail}/>
                <Route exact path="/properties/add-my-property" Component={AddProperty} />
                <Route exact path="/properties/live-auctions" Component={LiveAuctions} />
                <Route exact path="/properties/live-auction-details" Component={LiveAuctionDetails} />
                <Route exact path="/properties/share-your-property" Component={ShareYourProperty} />
                <Route exact path="/properties/shorting" Component={BasicFunction} />
                <Route exact path="/chat-standalone" Component={ChatStandalone}/>
                <Route exact path="/chat-standalone:/id" Component={ChatStandalone}/>
                <Route exact path="/chat-group" Component={ChatCreateGroup}/>
                <Route exact path="/" Component={FrontendHome} />
                <Route exact path="/review-offers" Component={ReviewOffers} />
                <Route path="*" Component={FrontendHome}  /> 
                <Route path="properties">
                    <Route path="admin">
                        <Route path="select-property" Component={SelectProperty} />
                        <Route path="add-documents" Component={AddDocuments} />
                        <Route path="preferences" Component={Preferences} />
                        <Route path="payment" Component={Payment} />
                    </Route>
                </Route>
                <Route exact path="/properties/printout-builder" Component={PrintoutBuilder} />
            </>
        ) : (
        checkedLogin ?  
            (
            <>
            <Route exact path="/register" Component={FrontendRegister}  /> 
                <Route exact path="/" Component={FrontendHome} />
                <Route path="*" Component={FrontendHome}  /> 
                <Route exact path="/properties" Component={Home} />
                <Route exact path="/properties/land-details" Component={LandDetail}/>
            </>
        ) : (
            <>
                <Route exact path="/register" Component={FrontendRegister}  /> 
                <Route exact path="/" Component={FrontendHome} />
                {/* <Route path="*" Component={FrontendHome}  />   */}
            </>
        ))}
        <Route exact path="/walk-through" Component={LandDealWalkThrough} />
        </Routes>;
};

export default AppRoutes;
