import { useState } from 'react'

const JoinMembership = ({ cssClassName, registerForm }) => {
    const [formData, setFormData] = useState({

    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.name == "terms" ? e.target.checked : e.target.value
        })
    }
    return (
        <>
            <div className={cssClassName}>
                <form className="custom-form row mlr-40" onSubmit={(e) => { e.preventDefault(); console.log(formData); registerForm(formData); }}>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>First Name</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="first_name"/>
                    </div>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>Last Name</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="last_name"/>
                    </div>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>Email</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="email"/>
                    </div>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>Company</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="company"/>
                    </div>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>Phone</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="phone"/>
                    </div>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>City</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="city"/>
                    </div>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>State</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="state"/>
                    </div>
                    <div className='form-gorup col-md-6 mb-4 plr-40'>
                        <label className='text-uppercase font-12'>Zip Code</label>
                        <input type='text' className='form-control' required onChange={onChangeInput} name="zip"/>
                    </div>
                    <div className='col-12 mt-4 text-end plr-40'>
                        <button type="submit" className='theme-btn lg-btn text-uppercase w-40'>Apply Now</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default JoinMembership