import Header from '../../Components/Common/Header'
import Footer from '../../Components/Common/Footer'
import Gallery from '../../Components/LandDetails/Gallery'
import graphImg from '../../assets/img/graph2.jpg'
import Map from '../../Components/LandDetails/Map'
import LiveAuctions from './LiveAuctions'
import AuctionRight from './AuctionRight'
import { Link } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react';
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext'
import ShareModal from '../../Components/Common/ShareModal'

const LiveAuctionDetails = () => {
  const {user} = useContext(UserContext); 
  const { getProperty } = useContext(PropertyContext);
  const [shareModal, setShareModal] = useState(false);
  const openShareModal = () => {
      setShareModal(true);
  }

  const queryParameters = new URLSearchParams(window.location.search)
  const place_id = queryParameters.get("place_id")

  console.log(place_id);

  const [propertyData, setPropertyData] = useState({});

  useEffect(() => {       

      const fetchData = async () => {
          const propData = await getProperty(place_id,user.username);
          setPropertyData(propData);
      }        
      
      fetchData();        

  }, [place_id]); 
  return (
    <>
      <Header />
      {propertyData && propertyData.place_id !== undefined && 
        <section className="main-wrap ps-0 pe-0">
          <section className="dtl-block">
            <div className="container">
              <div className="dtl-flex d-flex">
                <div className="dtl-left">
                  <div className="dtl-left-heading mb-3 live-auction-header">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <Link to="/live-auctions" className="backtoresult"><span className="back-arrow"></span> Back to
                          Results</Link>
                      </div>
                      <div className="d-inline-flex align-items-center justify-content-between">
                        <div className="ms-3">
                          <button className="theme-trns-btn text-uppercase"><span className="save-icon"></span>
                            Save</button>
                        </div>
                        <div className="ms-3">
                          <button className="theme-trns-btn text-uppercase" type='button' onClick={openShareModal}><span className="share-icon"></span>
                            Share</button>
                          {propertyData && <ShareModal shareModal={shareModal} setShareModal={setShareModal} propertyData={propertyData}/>}
                        </div>
                        <div className="ms-3">
                          <button className="theme-trns-btn text-uppercase"><span className="envlope-icon"></span>
                            Contact</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="gray-box">
                    <div className="d-flex justify-content-between mb-3 box-title-flex">
                      <div>
                        <div className="box-title text-uppercase">$42,000,000</div>
                        <div className="xs-title fw-500 mt-3 text-start theme-text font-16"><span
                          className="location-icon"></span> TBD1
                          Smalley Rd , Cascade,
                          ID 83611</div>
                      </div>
                      <div>
                        <div className="text-end">
                          <div className="d-inline-flex align-items-center">
                            <div className="lb-title font-14 ps-2">Size:</div>
                            <div className="ps-2 font-18 fw-400 ml-auto">10.800</div>
                            <div className="lb-title font-14 ps-2">Acres</div>
                          </div>
                        </div>
                        <div className="text-end mt-3 pt-2">
                          <div className="d-inline-flex align-items-center">
                            <div className="lb-title font-14 ps-2">Type:</div>
                            <div className="xs-title font-14 fw-300 ps-2 ml-auto">Recreational, Timber, Hunting,
                              Camping</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Gallery propertyData={propertyData} liveAuction={true}/>
                    <div className="desc-content ps-2 pe-2">
                      <div className="sm-title fw-500 text-start mt-4">Hot Springs Summit Ranch</div>
                      <div className="content mt-2">
                        <p>Hot Springs Summit Ranch is being offered for the first time for sale as
                          10,830.87
                          acres. Rarely are there opportunities to own a contiguous property of this size
                          and
                          a private hot spring within minutes of a major resort town. This is a great
                          opportunity to own a ranch that is geographically positioned with spectacular
                          mountain views, numerous creeks throughout the property, and borders USFS and
                          State
                          lands. Lush creek valley meadows to the top of timbered mountain peaks all
                          within
                          less than 30 miles of McCall offering the finest of accommodations. <br /><a
                            href="#" className="theme-text text-decoration-none">Read More</a></p>
                      </div>
                    </div>
                    <div className="gray-div mt-4 pt-4 border-t">
                      <div className="d-flex align-items-center justify-content-between ps-4 pe-4 estimate-block">
                        <div>
                          <div className="d-inline-block flex-wrap align-items-center text-center">
                            <div className="xs-title fw-400 w-100 text-center">Estimated Revenue: <span
                              className="info-icon ms-2"></span></div>
                            <div className="d-inline-block align-items-center  w-100 mt-3">
                              <div className="md-title d-inline-block align-middle">$2M</div>
                              <div className="lb-title ps-3 font-12 d-inline-block align-middle">per year</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-inline-block flex-wrap align-items-center text-center">
                            <div className="xs-title fw-400 w-100 text-center">Estimated Profit: <span
                              className="info-icon ms-2"></span></div>
                            <div className="d-inline-block align-items-center  w-100 mt-3">
                              <div className="md-title d-inline-block align-middle">$1.5M</div>
                              <div className="lb-title ps-3 font-12 d-inline-block align-middle">per year</div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-inline-block flex-wrap align-items-center text-center">
                            <div className="xs-title fw-400 w-100 text-center">Payback Time <span
                              className="info-icon ms-2"></span></div>
                            <div className="text-center w-100 mt-3">
                              <div className="md-title">28 yrs</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-3">
                        <div className="box-title fw-500 text-center mb-3 mt-5">Investment Projection</div>
                        <img src={graphImg} alt="graph" />
                      </div>
                      <div className="mt-2 lb-title font-12 text-center">YEARS</div> */}
                    </div>
                  </div>
                  <div className="document-nots-block mt-30">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="amenities-tab" data-bs-toggle="tab"
                          data-bs-target="#amenities" type="button" role="tab" aria-controls="amenities"
                          aria-selected="true">Amenities</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="Notes-tab" data-bs-toggle="tab" data-bs-target="#Notes"
                          type="button" role="tab" aria-controls="Notes"
                          aria-selected="false">Notes</button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="amenities" role="tabpanel"
                        aria-labelledby="amenities-tab">
                        <div className="gray-box">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="box-title text-uppercase">Amenities</div>
                          </div>
                          <div className="mt-4 ps-4">
                            <div className="mt-4 d-flex justify-content-between pe-4">
                              <div>
                                <div className="xs-title fw-500 text-start theme-text">Activities</div>
                              </div>
                              <div>
                                <div className="xs-title fw-500 text-start theme-text">Present Use</div>
                              </div>
                              <div>
                                <div className="xs-title fw-500 text-start theme-text">Lot Description
                                </div>
                              </div>
                            </div>
                            <div className="activity-scroll mt-2">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <ul className="list-item">
                                    <li>Camping</li>
                                    <li>Hunting/Fishing</li>
                                    <li>Off-roading</li>
                                    <li>RVing</li>
                                    <li>Camping</li>
                                    <li>Hunting/Fishing</li>
                                    <li>Off-roading</li>
                                    <li>RVing</li>
                                  </ul>
                                </div>
                                <div>
                                  <ul className="list-item">
                                    <li>Hunting/Fishing</li>
                                    <li>Timber</li>
                                    <li>Farm/Ranch</li>
                                  </ul>
                                </div>
                                <div>
                                  <ul className="list-item">
                                    <li>Acreage</li>
                                    <li>Creek</li>
                                    <li>Heavily Treed</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="Notes" role="tabpanel" aria-labelledby="Notes-tab">
                        <div className="gray-box">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="box-title text-uppercase">Notes</div>
                          </div>
                          <div className="mt-4">

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {propertyData.mapright_link !== undefined ? <iframe loading='lazy' frameBorder='0' height='460px' width='100%' src={propertyData.mapright_link}></iframe> : <Map liveAuction={true} propertyData={propertyData}/>}
                </div>
                <AuctionRight propertyData={propertyData}/>
              </div>
            </div>
          </section>
        </section>
      }
      <Footer />
    </>
  )
}

export default LiveAuctionDetails