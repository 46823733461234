import { useState } from 'react';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { sliderValue } from './MakeOffer';
import {intFormatter} from "../../Components/Utils/NumberFormatter";

const RangeSliderCom = ({setAcres,acres,max}) => {
    //console.log(acres);
  return (
    <>
      <div className="am-title">{intFormatter(acres[1])}</div>
      <div className='mt-2 mb-2'>
        <RangeSlider 
          defaultValue={[0, acres]}
          min={0}
          max={max}
          step={acres}
          onInput={(item)=>{ 
            setAcres(item);
          }}
          value={acres}
          className="single-thumb"
          thumbsDisabled={[true, false]}
          rangeSlideDisabled={true}
        />
      </div>
    </>
  )
}

export default RangeSliderCom