import closeIcon from '../../assets/img/close-modal.svg'
import { Field, Formik, Form } from 'formik'
import {useState, useContext, useRef, useEffect} from 'react'
import { PropertyContext } from '../../context/PropertyContext';


export var sliderValue  = 0;
const ContactInfoModal = ({ contactInfo, setContactInfo, offerValues }) => {
    const { sendEmail } = useContext(PropertyContext);

    const closeContactInfo = () => {
        setContactInfo(false);
    }

    const formikRef = useRef(null);

    const [formikData, setFormikData] = useState({
        fullname:"",
        email:"",
        phone:"",
        company:"",
        place_id:offerValues ? offerValues.place_id : "",
        message:offerValues ? offerValues.message : "",
        user:offerValues ? offerValues.user : "",
        template_email:offerValues ? offerValues.template_email : "",
        recipients:offerValues ? offerValues.recipients : "",
        acres:offerValues ? offerValues.acres : "",
        offer:offerValues ? offerValues.offer : ""
    });

    useEffect(() => {
        formikRef.current.setFieldValue("place_id",offerValues ? offerValues.place_id : "");
        formikRef.current.setFieldValue("message",offerValues ? offerValues.message : "");
        formikRef.current.setFieldValue("user",offerValues ? offerValues.user : "");
        formikRef.current.setFieldValue("template_email",offerValues ? offerValues.template_email : "");
        formikRef.current.setFieldValue("recipients",offerValues ? offerValues.recipients : "");
        formikRef.current.setFieldValue("acres",offerValues ? offerValues.acres : "");
        formikRef.current.setFieldValue("offer",offerValues ? offerValues.offer : "");
        
    }, [offerValues]);

    return (
        <>
            <div className={`modal fade custom-modal common-modal make-offer-modal ${contactInfo ? "show" : ""}`} id="makeOffer" data-bs-backdrop="static" data-bs-keyboard="false"
                tabIndex="-1" aria-labelledby="MakeOfferLabel" aria-hidden="true">
                <div className="modal-dialog mxw-560">
                    <div className="modal-content">
                        <div className="modal-header modal-header-mobile d-none">
                            <div className="mh-mobile">Contact Info</div>
                            <button type="button" className="btn-close" onClick={closeContactInfo}>
                                <img src={closeIcon} alt="close" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <button type="button" className="btn-close m-d-none" onClick={closeContactInfo}>
                                <img src={closeIcon} alt="close" />
                            </button>
                            <div className="fb-heading mb-5 text-center font-40">Contact Info</div>
                            <Formik
                                initialValues={formikData} 
                                validationSchema=""
                                enableReinitialize={true}
                                innerRef={formikRef}
                                onSubmit={(values) => {
                                    console.log(values);
                                    sendEmail(values);
                                    alert("Your Offer Has Been Sent");
                                    closeContactInfo();
                                }}
                            >
                            {(formik) => (
                                <Form className='custom-form'>  
                                    <Field type="hidden" name="place_id" value={offerValues ? offerValues.place_id : ""} />
                                    <Field type="hidden" name="message" value={offerValues ? offerValues.message : ""} />
                                    <Field type="hidden" name="user" value={offerValues ? offerValues.user : ""} />
                                    <Field type="hidden" name="template_email" value={offerValues ? offerValues.template_email : ""} />
                                    <Field type="hidden" name="recipients" value={offerValues ? offerValues.recipients : ""} />
                                    <Field type="hidden" name="acres" value={offerValues ? offerValues.acres : ""} />
                                    <Field type="hidden" name="offer" value={offerValues ? offerValues.offer : ""} />
                                    <div className='form-group'>
                                        <label>FULL NAME</label>
                                        <Field
                                            type='text' 
                                            name="fullname" 
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>EMAIL</label>
                                        <Field
                                            type='email' 
                                            name="email" 
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>PHONE</label>
                                        <Field
                                            type='phone' 
                                            name="phone" 
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label>COMPANY</label>
                                        <Field
                                            type='text' 
                                            name="company" 
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className='col-12 plr-35 text-center'>
                                        <button className='theme-btn md-btn text-uppercase w-290 mt-5' type="submit">Make Offer</button>
                                    </div>
                                </Form>
                            )}</Formik>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default ContactInfoModal