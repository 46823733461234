import React, { useEffect, useState, useContext } from 'react'
import Header from '../../Components/Common/Header';
import Footer from '../../Components/Frontend/Common/Footer';
import { Link } from 'react-router-dom';
import {UserContext} from '../../context/UserContext';
import { PropertyContext } from '../../context/PropertyContext';
import { useNavigate } from 'react-router-dom';
import {intFormatter,} from "../../Components/Utils/NumberFormatter";

const SelectProperty = () => {
    const {user} = useContext(UserContext); 
    const { getProperties } = useContext(PropertyContext);

    const navigate = useNavigate();

    const [allProperties, setAllProperties] = useState({});
    const [currentProperties, setCurrentProperties] = useState([]);
    const [searchText, setSearchText] = useState("");

    const handleChange = ({ target }) => {
        setSearchText(target.value);
        let tempOptions = [...allProperties];
        //   console.log(tempOptions)
        var record_filter = tempOptions.filter((obj) =>
            obj.city?.toLowerCase().includes(target.value?.toLowerCase())
        );


        if (record_filter.length) {
            setCurrentProperties(record_filter)
        } else {
            setCurrentProperties([]);
        }
    };
    const selectHandle = (val) => {
        setSearchText(val.city)
        console.log(val);

        navigate("/properties/admin/add-documents?place_id=" + val.place_id);
    };

    const [propertyOverviewData,setPropertyOverviewData] = useState({
        property_count:0,
        private_count:0,
        public_count:0,
        members_only_count:0,
        acreage:0
      });



    useEffect(() => {             
    
        const fetchData = async () => {

            const propData = await getProperties({user:user.username,admin:true});
            setAllProperties(propData);

            console.log(propData);

            var temp_overview = {
                property_count:0,
                private_count:0,
                public_count:0,
                members_only_count:0,
                acreage:0
              };
    
              Object.keys(propData).map(function(i) {
                temp_overview.property_count++;
                temp_overview.private_count += propData[i].property_status == "private" ? 1 : 0;
                temp_overview.public_count += propData[i].property_status == "public" ? 1 : 0;
                temp_overview.members_only_count += propData[i].property_status == "members_only" ? 1 : 0;
                temp_overview.acreage += propData[i].acreage;
              });
    
              setPropertyOverviewData(temp_overview);
            
        }        
  
        fetchData();      
        
    }, []); 

    useEffect(() => {             

        if (Object.keys(allProperties).length == 0) return;
    
        var obj = {target:{}};
        obj.target.value = searchText;

        handleChange(obj); 
        
    }, [allProperties]); 

    return (
        <>
            <Header />
            <section className="main-wrap ps-0 pe-0 pb-0 select-doc-wrap">
                <div className='select-property-block'>
                    <div className='container'>
                        <div className='select-property-box pt-5 pb-5'>
                            <div className='container mxw-680'>
                                <div className='syp-success-box pt-4 pb-4'>
                                    <div className='xs-title text-gray font-14 fw-500 mb-2'>Admin for:</div>
                                    <div className='xs-title text-uppercase text-white font-18'>{user.company}</div>
                                </div>
                                <div className="d-flex justify-content-between pt-5 pb-5 align-items-center sp-flex">
                                    <div className="sp-col">
                                        <div className="xs-title theme-text text-uppercase">Properties</div>
                                        <div className="sm-title text-white mt-2">{intFormatter(propertyOverviewData.property_count)}</div>
                                    </div>
                                    <div className="sp-col">
                                        <div className="xs-title theme-text text-uppercase">Private</div>
                                        <div className="sm-title text-white mt-2">{intFormatter(propertyOverviewData.private_count)}</div>
                                    </div>
                                    <div className="sp-col">
                                        <div className="xs-title theme-text text-uppercase">Public</div>
                                        <div className="sm-title text-white mt-2">{intFormatter(propertyOverviewData.public_count)}</div>
                                    </div>
                                    <div className="sp-col">
                                        <div className="xs-title theme-text text-uppercase">Members Only</div>
                                        <div className="sm-title text-white mt-2">{intFormatter(propertyOverviewData.members_only_count)}</div>
                                    </div>
                                    <div className="sp-col">
                                        <div className="xs-title theme-text text-uppercase">Total Acres</div>
                                        <div className="sm-title text-white mt-2">{intFormatter(propertyOverviewData.acreage)}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="md-title pt-0 text-uppercase px-5 text-center">Select Property to Manage</div>
                                    <div className="search-block sp-search-block mt-3">
                                        <div className="container mxw-1140 px-5">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by location, type or keyword"
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    onChange={handleChange}
                                                    value={searchText}
                                                />
                                                <span className="search-icon"></span>
                                            </div>
                                            <div
                                                className="cs-search-list"
                                                style={{
                                                    display: "block",
                                                }}
                                            >

                                                {!currentProperties.length ? (
                                                    <div className="autocomplete-items text-center xs-title gray-text fw-500 font-17">City not found !</div>
                                                ) : (
                                                    currentProperties.map((option, index) => (
                                                        <div key={`${index}`} onClick={() => selectHandle(option)} className="autocomplete-items">
                                                            <div className="cssl-content d-flex align-items-center">
                                                                <div className='map-icon'></div>
                                                                <div className="xs-title black-text text-start">{option.city}</div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default SelectProperty